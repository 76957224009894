import * as React from "react";
import { forwardRef, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import AuthCodeInput from "../inputs/authCodeInput";
import ReusableButton from "../../../../reusableButton";
import { Link } from "gatsby";
import errorIcon from "../../../../widgets/emailSignupWidget/errorIcon.svg";

// eslint-disable-next-line react/display-name
export const VerifyForm = forwardRef((props: { classes: ClassNameMap<string>, setResult: (value: (prevState: undefined) => undefined) => void, signUpError: any, onCodeSubmit: Promise<void>, handleCodeSubmit: any, result: any }, ref: React.MutableRefObject<null>) => {
  useEffect(() => {
    const keyEnter = (event: any) => {
      if (event.key === 'Enter' || event.code === "NumpadEnter") {
        event.preventDefault()
        props.handleCodeSubmit(props.result)
      }
    }

    document.addEventListener('keydown', keyEnter)

    return () => {
      document.removeEventListener('keydown', keyEnter)
    }
  }, [props?.result])

  return <div ref={ref}>
    <Grid>
      <Typography variant={"h4"} className={props.classes.signUpTitle}>Welcome</Typography>
      <Typography variant={"body1"} className={props.classes.verificationText}>
        Please enter the code we sent you via email below and you are all set to go!
        </Typography>
    </Grid>
    <Grid className={props.classes.holderForAuth}>
      <AuthCodeInput setResult={props.setResult} />
      {props.signUpError &&
        <span className={props.classes.errorText}>
          <img
            className={props.classes.errorIcon}
            src={errorIcon}
            alt="error"
          />
          Invalid code
        </span>
      }
      <div className={props.classes.authButtonContainer}>
        <ReusableButton type={"submit"} linkText={"CONTINUE"} arrowIcon={false} partner={false} customClickEvent={() => props.onCodeSubmit()} />
        <Typography className={props.classes.alreadySignedInText} variant={"body1"}>
          <Link className={props.classes.linkText} to={"mailto:community@boringmoney.co.uk"}>Need help?</Link>
        </Typography>
      </div>
    </Grid>
  </div>;
})

