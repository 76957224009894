import React from "react";
import { makeStyles, useStyles } from "@material-ui/core/styles";

// Custom hook for MUI ratings icon styles
export default useStyles = makeStyles((theme) => ({
  // for use in our and your ratings widgets
  starImageWidget: {
    marginRight: "0.125em",
    marginLeft: "0.125em",
    height: ".85em",
    [theme.breakpoints.down("md")]: {
      height: "1em",
    },
  },
  //  for use in adviser, fund and provider page review cards components
  starImage: {
    marginRight: "0.125em",
    marginLeft: "0.125em",
  },
  // for use in adviser likes components
  starImageAdviser: {
    marginRight: "0.1em",
    marginLeft: "0.1em",
    height: "16px",
  },
  // for use on adviser, company and fund rate form
  starImageRate: {
    paddingRight: ".5em",
    height: "1em",
  },
  // provider reviews widget
  providerReviewsWidgetStarImage: {
    marginRight: "0.125em",
    marginLeft: "0.125em",
    height: '18px'
  }
}));
