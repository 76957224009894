import React from "react";
import { Hidden } from "@material-ui/core";
import CustomerReviewsWidget from "./customerReviewsWidget";

const CustomerReviewsWidgetDecider = ({ companyReference }) => {
  return (
    <>
      <Hidden mdDown implementation="js">
        <CustomerReviewsWidget companyReference={companyReference} itemsPerPage={8} />
      </Hidden>
      <Hidden lgUp implementation="js">
        <CustomerReviewsWidget companyReference={companyReference} itemsPerPage={4} />
      </Hidden>
    </>
  );
};

export default CustomerReviewsWidgetDecider;
