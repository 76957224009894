import React, { useState, useEffect } from "react";
import * as styles from "./prosConsWidget.module.css";
import proTick from "./img/pro-tick.svg";
import conTick from "./img/con-tick.svg";
import proTitleTick from "./img/pro-title-tick.svg";
import conTitleTick from "./img/con-title-tick.svg";

import visibleProTick from "./img/visible-pro-tick.svg";
import visibleConTick from "./img/visible-con-tick.svg";
import visibleProTitleTick from "./img/visible-pro-title-tick.svg";
import visibleConTitleTick from "./img/visible-con-title-tick.svg";

import { Grid } from "@material-ui/core";




const PprosConsWidget = ({ prosTitle, pros, consTitle, cons }) => {

  const [isVisibleTheme, setIsVisibleTheme] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes('/visible/experts')) {
      setIsVisibleTheme(true);
    }
  }, [])

  return (
    <div>
      <Grid container direction={'row'} spacing={3} alignItems="stretch">
        {prosTitle &&
          <Grid xs={12} sm={consTitle ? 6 : 12} md={consTitle ? 6 : 12} lg={consTitle ? 6 : 12} xl={consTitle ? 6 : 12} item>
            <div className={isVisibleTheme ? styles.visibleProsContainer : styles.prosContainer}>
              <Grid container className={isVisibleTheme ? styles.visibleTitleContainer : styles.titleContainer} alignItems="center" justify="center">
                <Grid xs={1} alignItems="center" justify="center" item>
                  <img className={isVisibleTheme ? styles.visibleTitleImg : styles.titleImg} src={isVisibleTheme ? visibleProTitleTick : proTitleTick} />
                </Grid>
                <Grid xs={11} item>
                  <label className={isVisibleTheme ? styles.visibleProsTitleText : styles.titleText}>{prosTitle}</label>
                </Grid>
              </Grid>
              <Grid container>
                {pros && Array.isArray(pros) && pros.map(pro =>
                  <Grid container>
                    <Grid xs={1} className={styles.itemImgContainer} item>
                      <img className={isVisibleTheme ? styles.visibleItemImg : styles.itemImg} src={isVisibleTheme ? visibleProTick : proTick} />
                    </Grid>
                    <Grid xs={11} item>
                      <label className={isVisibleTheme ? styles.visibleItemText : styles.itemText}>{pro}</label>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {!isVisibleTheme && <div className={styles.prosGradient}></div>}
            </div>
          </Grid>
        }
        {consTitle &&
          <Grid xs={12} sm={prosTitle ? 6 : 12} md={prosTitle ? 6 : 12} lg={prosTitle ? 6 : 12} xl={prosTitle ? 6 : 12} item>
            <div className={isVisibleTheme ? styles.visibleConsContainer : styles.consContainer}>
              <Grid container className={isVisibleTheme ? styles.visibleTitleContainer : styles.titleContainer} alignItems="center" justify="center">
                <Grid xs={1} item>
                  <img className={isVisibleTheme ? styles.visibleTitleImg : styles.titleImg} src={isVisibleTheme ? visibleConTitleTick : conTitleTick} />
                </Grid>
                <Grid xs={11} item>
                  <label className={isVisibleTheme ? styles.visibleConsTitleText : styles.titleText}>{consTitle}</label>
                </Grid>
              </Grid>
              <Grid container>
                {cons && Array.isArray(cons) && cons.map(con =>
                  <Grid container>
                    <Grid xs={1} className={styles.itemImgContainer} item>
                      <img className={isVisibleTheme ? styles.visibleItemImg : styles.itemImg} src={isVisibleTheme ? visibleConTick : conTick} />
                    </Grid>
                    <Grid xs={11} item>
                      <label className={isVisibleTheme ? styles.visibleItemText : styles.itemText}>{con}</label>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {!isVisibleTheme && <div className={styles.consGradient}></div>}
            </div>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default PprosConsWidget;
