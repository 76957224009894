import React from "react";
import clientConfig from "../../client-config";
import BasePortableText from "@sanity/block-content-to-react";
import BlockContent from "@sanity/block-content-to-react";
import serializers from "./serializers";
import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Figure } from "./Figure";

const useStyles = makeStyles((theme) => ({
  margin: {
    // marginTop: "48px",
    pointerEvents: "all",
  },
}));
export function DashboardIDPasser(dashID) {
  return ({ dashID })
}


function PortableText({ blocks, numReviews, dashId }) {
  const classes = useStyles();
  // const serializers = {
  //   types: {
  //     mainImage: Figure,
  //     block: BlockRenderer
  // }}
  let adjustedBlocks = blocks;

  try {
    adjustedBlocks.map((obj, index) =>
      index > 0
        ? (obj.prevStyle = adjustedBlocks[index - 1].style)
        : (obj.prevStyle = "FirstBlock")
    );


    function DashboardIDPasser(dashID) {
      return ({ dashID })
    }

    if (typeof dashId!="undefined") {
      DashboardIDPasser(dashId)
      //console.log("dashId", dashId)
    }

     return (
      <div className={classes.margin}>

        {/* {blocks.map(
        ({ children, style }) =>
          children === undefined ? (
            ""
          ) : (
            <Typography variant={style === "normal" ? "subtitle1" : style}>
              {children.map(({ text }) => text)}
            </Typography>
          )
        // console.log(style),
      )} */}
        <BasePortableText
          blocks={adjustedBlocks}
          serializers={serializers}
          numReviews={numReviews}
          {...clientConfig.sanity}
         />

      </div>
    );
  } catch (e) {
    return null;
  }

}

export default PortableText;
