import React, { useState, useEffect } from "react";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import DisplayCompanyReviewsCard from "./customerReviewsCard";
import CustomerReviewsFilters from "./customerReviewsFilters";
import Chevron from "./img/chevron.svg";
import * as styles from "./customerReviews.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2em',
    paddingBottom: '2em',
    '& .Mui-selected': {
      background: '#3C465F;',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& .MuiPaginationItem-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial !important'
      }
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    },
    '& .MuiButtonBase-root': {
      padding: '8px !important'
    }
  },
  reviewsNumberLabel: {
    color: '#262641',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'block',
    paddingTop: '2em',
    paddingBottom: '2em'
  },
  textAlignRight: {
    textAlign: 'right'
  }
}));

const CustomerReviewsWidget = ({ companyReference, itemsPerPage }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [flushState, setFlushState] = useState(false);

  const [allReviews, setAllReviews] = useState([]);
  const [fiveStarsReviewsNumber, setFiveStarsReviewsNumber] = useState(0);
  const [fourStarsReviewsNumber, setFourStarsReviewsNumber] = useState(0);
  const [threeStarsReviewsNumber, setThreeStarsReviewsNumber] = useState(0);
  const [twoStarsReviewsNumber, setTwoStarsReviewsNumber] = useState(0);
  const [oneStarReviewsNumber, setOneStarReviewsNumber] = useState(0);

  const [searchPhrase, setSearchPhrase] = useState('');

  const [allFilteredReviews, setAllFilteredReviews] = useState([]);
  const [displayReviews, setDisplayReviews] = useState([]);

  const [formState, setFormState] = useState({
    oneStar: false,
    twoStars: false,
    threeStars: false,
    fourStars: false,
    fiveStars: false
  });

  const {
    oneStar,
    twoStars,
    threeStars,
    fourStars,
    fiveStars
  } = formState;

  const [scrollToFilters, setScrollToFilters] = useState('');

  useEffect(() => {
    (() => {
      if (scrollToFilters != '') {
        window.location.href = "#filters-container";
      }
    })();
  }, [scrollToFilters]);

  useEffect(() => {
    (() => {
      filterReviews();

    })();
  }, [searchPhrase]);

  useEffect(() => {
    (async () => {
      var model = {
        CompanyId: companyReference.dashboardId,
        Offset: 0,
        ItemsPerPage: 999999999
      };

      var reviews = await callApiForReviews(model);

      setAllReviews(reviews);

      setAllFilteredReviews(reviews);

      setFiveStarsReviewsNumber(reviews.filter(fiveStarsFilterFunction).length);

      setFourStarsReviewsNumber(reviews.filter(fourStarsFilterFunction).length);

      setThreeStarsReviewsNumber(reviews.filter(threeStarsFilterFunction).length);

      setTwoStarsReviewsNumber(reviews.filter(twoStarsFilterFunction).length);

      setOneStarReviewsNumber(reviews.filter(oneStarFilterFunction).length);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      filterReviews();
    })();
  }, [formState]);

  useEffect(() => {
    getReviews(1);
  }, [allFilteredReviews]);

  var filterReviews = function () {
    if (oneStar && twoStars && threeStars && fourStars && fiveStars) {
      if (searchPhrase && searchPhrase != '') {
        setAllFilteredReviews(allReviews.slice().filter(searchPhraseFilterFunction));
      } else {
        setAllFilteredReviews(allReviews);
      }
    }
    else
      if (!oneStar && !twoStars && !threeStars && !fourStars && !fiveStars) {
        if (searchPhrase && searchPhrase != '') {
          setAllFilteredReviews(allReviews.slice().filter(searchPhraseFilterFunction));
        } else {
          setAllFilteredReviews(allReviews);
        }
      }
      else {
        var filteredReviewsChunk = [];

        if (oneStar) {
          filteredReviewsChunk =
            [].concat(filteredReviewsChunk, allReviews.filter(oneStarFilterFunction));
        }

        if (twoStars) {
          filteredReviewsChunk =
            [].concat(filteredReviewsChunk, allReviews.filter(twoStarsFilterFunction));
        }

        if (threeStars) {
          filteredReviewsChunk =
            [].concat(filteredReviewsChunk, allReviews.filter(threeStarsFilterFunction));
        }

        if (fourStars) {
          filteredReviewsChunk =
            [].concat(filteredReviewsChunk, allReviews.filter(fourStarsFilterFunction));
        }

        if (fiveStars) {
          filteredReviewsChunk =
            [].concat(filteredReviewsChunk, allReviews.filter(fiveStarsFilterFunction));
        }

        if (searchPhrase && searchPhrase != '') {
          setAllFilteredReviews(filteredReviewsChunk.slice().filter(searchPhraseFilterFunction));
        } else {
          setAllFilteredReviews(filteredReviewsChunk);
        }
      }
  }

  var oneStarFilterFunction = function (review) {
    return review.StarRating >= 0 && review.StarRating < 3;
  };

  var twoStarsFilterFunction = function (review) {
    return review.StarRating >= 3 && review.StarRating < 5;
  };

  var threeStarsFilterFunction = function (review) {
    return review.StarRating >= 5 && review.StarRating < 7;
  };

  var fourStarsFilterFunction = function (review) {
    return review.StarRating >= 7 && review.StarRating < 9;
  };

  var fiveStarsFilterFunction = function (review) {
    return review.StarRating >= 9;
  };

  var searchPhraseFilterFunction = function (review) {
    return review.Review.toLowerCase().includes(searchPhrase.toLowerCase());
  }

  var dateSortFunction = function (a, b) {
    a = new Date(a.Date);
    b = new Date(b.Date);
    return a > b ? -1 : a < b ? 1 : 0;
  };

  var ratingSortFunction = function (a, b) {
    return a.StarRating > b.StarRating ? -1 : a.StarRating > b.StarRating ? 1 : 0;
  }

  var callApiForReviews = async function (model) {

    return fetch(process.env.GATSBY_QUESTIONAPI + '/CompanyDisplayRatings/CompanyDisplayRatings', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  const HandleChange = function (event) {
    setFormState({ ...formState, [event.target.name]: event.target.checked });
  };

  var getReviews = async function (pageNumber) {
    setPage(pageNumber);

    try {
      setTotalPages(Math.ceil(allFilteredReviews.length / itemsPerPage));
    } catch (e) {
      setTotalPages(0)
    }

    var slice = allFilteredReviews.slice((pageNumber * itemsPerPage - itemsPerPage), pageNumber * itemsPerPage);

    setDisplayReviews(slice);
  }

  const classes = useStyles();

  const handlePageChange = async (event, value) => {
    setScrollToFilters(scrollToFilters + '.');

    await getReviews(value);

    setFlushState(!flushState)
  };

  var sortChange = function (event) {
    var sortFunction = dateSortFunction;

    if (event.target.value === 'rating') {
      sortFunction = ratingSortFunction;
    }

    if (searchPhrase && searchPhrase != '') {
      setAllFilteredReviews(allFilteredReviews.slice().sort(sortFunction).slice().filter(searchPhraseFilterFunction));
    } else {
      setAllFilteredReviews(allFilteredReviews.slice().sort(sortFunction));
    }
  }

  const handleSearch = function (searchTextValue) {
    setSearchPhrase(searchTextValue);
  }

  return (
    <>
      <CustomerReviewsFilters
        oneStar={oneStar}
        twoStars={twoStars}
        threeStars={threeStars}
        fourStars={fourStars}
        fiveStars={fiveStars}
        fiveStarsReviewsNumber={fiveStarsReviewsNumber}
        fourStarsReviewsNumber={fourStarsReviewsNumber}
        threeStarsReviewsNumber={threeStarsReviewsNumber}
        twoStarsReviewsNumber={twoStarsReviewsNumber}
        oneStarReviewsNumber={oneStarReviewsNumber}
        allReviewsLength={allReviews.length}
        HandleChange={HandleChange}
        HandleSearch={handleSearch}
      />

      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Grid container alignItems="center" justify="flex-start">
              <Grid item xs={12}>
                <label className={classes.reviewsNumberLabel}>{allFilteredReviews.length} reviews</label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item xs={12} className={classes.textAlignRight}>
                <select className={styles.filterDropdown} onChange={sortChange}>
                  <option value={'MostRecent'}>
                    {'Most recent'}
                  </option>
                  <option value={'rating'}>
                    {'Rating'}
                  </option>
                </select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {displayReviews.length > 0 && <Grid containera>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {displayReviews.map((review) => (
              <DisplayCompanyReviewsCard review={review} flushState={flushState} />

            ))}
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Grid item>
            {totalPages > 1 &&
              <Pagination
                count={totalPages}
                className={classes.root}
                page={page}
                onChange={handlePageChange}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    classes={{ selected: classes.selected }}
                  />
                )}
              />}
          </Grid>
        </Grid>
      </Grid>
      }
    </>
  );
};

export default CustomerReviewsWidget;
