import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import * as styles from "./youTubeEmbed.module.css";

const useStyles = makeStyles((theme) => {
  return {
    containerForHeroBlockVideo:{
    [theme.breakpoints.down("xl")]:{
      width: "568px",
      height: "320px",
    },
    [theme.breakpoints.down("lg")]:{
      flexShrink: "1",
    },
    [theme.breakpoints.down("sm")]:{
      width: "335px",
      height: "188px",
      margin: "4.16666666666667% 0 24px 0",
    },
    // Tablet specific resizing fix
      [theme.breakpoints.between("sm", "md")]: {
        height: "320px",
        width: "568px",
        flexShrink: "1",
      },

      // overflow: "hidden",
      // paddingTop: "56.25%",
      // position: "relative",
      //
      // "& > iframe":{
      //   border: 0,
      //   height: "100%",
      //   left: 0,
      //   position: "absolute",
      //   top: 0,
      //   width: "100%",
      // }
  },
    containerForInfoPageVideo: {
      overflow: "hidden",
      paddingTop: "56.25%",
      position: "relative",

      "& > iframe":{
        border: 0,
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%",
      }
    }
}});

const YoutubeEmbed = ({ embedId, type}) => {

  const classes = useStyles();

  return (
    <div className={type === "linkCategory" || type === "information" || type === "landingPage" ? classes.containerForHeroBlockVideo : styles.videoResponsive}>
     <iframe
      width={type === "linkcategory" || "information" ? "100%" : "853"}
      height={type === "linkcategory" || "information" ? "100%" : "480"}
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      name={`Youtube video: ${embedId}`}
    />
   </div>
  )
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default YoutubeEmbed;
