import * as React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Checkbox,
  Grid,
  Icon,
  Typography,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
// @ts-ignore
import CheckedRadio from "../icons/CheckedRadio.svg";
// @ts-ignore
import UncheckedRadio from "../icons/UncheckedRadio.svg";
// @ts-ignore
import ErrorRadio from "../icons/ErrorRadio.svg";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "@hookform/error-message";


function RadioInput(props) {
  const { type, text, image, value, name, errors } = props;

  const useStyles = makeStyles((theme) => ({
    itemText: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      marginRight: 0,
      marginLeft: 0,
      width: "100%",
      height: "100%",
      "& .MuiFormControlLabel-label	": {
        display: type === "imageBlocks" ? "none" : "contents",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
      },
      "& .MuiIconButton-root":{
        padding: "8px 16px 8px 0px",
        "&:hover": {
          backgroundColor: "none",
          background: "none",
        },
        [theme.breakpoints.down("md")]:{
          padding: "8px 12px 8px 0px"
        }
      },
    },
    itemTextImage: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      marginRight: 0,
      marginLeft: 0,
      width: "100%",
      height: "100%",
      "& .MuiFormControlLabel-label	": {
        display: "none",
      },
      "& .MuiIconButton-root":{
        padding: "10px 10px 0px 0px",
        "&:hover": {
          backgroundColor: "none",
          background: "none",
        },
        [theme.breakpoints.down("md")]:{
          padding: "8px 8px 0px 0px"
        }
      },
    },
    paraImageBlock: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      margin: "10px",
      textAlign: "center",
    },
    imageBlock: {
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "var(--radius-radius-8, 8px)",
      border: "1px solid var(--border-borders-light, #DBE3EF)",
      boxShadow: "0px 0px 1px 0px rgba(12, 26, 75, 0.56)",
      height: "100%",
      justifyContent: "flex-start",
      "&:hover": {
        border: "1px solid var(--border-icons, #94A4D6)",
        // outline: "0.5px solid var(--border-icons, #94A4D6)",
        boxShadow:
          "0px 0px 0px 3px rgba(148, 164, 214, 0.20), 0px 2px 4px 0px rgba(64, 64, 64, 0.10)",
      },
    },
    imageBlockActive: {
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "var(--radius-radius-8, 8px)",
      border: "1px solid var(--border-borders-dark-blue, #3C465F)",
      // outline: "0.5px solid var(--border-borders-dark-blue, #3C465F)",
      boxShadow:
        "0px 0px 0px 3px rgba(148, 164, 214, 0.20), 0px 2px 4px 0px rgba(64, 64, 64, 0.10)",
      height: "100%",
      justifyContent: "flex-start",
      overflow: "hidden",
      backgroundColor: "#3C465F",
    },
    textContainer: {},
    textContainerActive: {
      backgroundColor: "#3C465F",
      borderRadius: "0 0 8px 8px",
      width: "100%",
      flexGrow: 1,
      "& > p": {
        color: "#FFFFFF",
      },
    },
    imageContainer: {
      height: "110px",
      width: "100%",
      borderRadius: "8px 8px 0 0",
      backgroundColor: "#F5F7FA",
      backgroundImage: image ? `url(${image})` : "unset",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    imageContainerActive: {
      height: "110px",
      width: "100%",
      backgroundColor: "#F5F7FA",
      backgroundImage: image ? `url(${image})` : "unset",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    radioIconImageBlock: {
      alignSelf: "flex-start",
      marginLeft: "auto",
    },
    gridItem: {
      padding: "6px",
    },
    listItem:{

    }
  }));
  const classes = useStyles();

  const [radioChecked, setRadioChecked] = useState(false);

  const radioRef = useRef(null);

  useEffect(() => {
    setRadioChecked(radioRef.current.checked);
  }, [radioRef.current?.checked]);

  return (
    <>
      <Grid
        xs={type === "imageBlocks" ? 6 : 12}
        className={type === "imageBlocks" ? classes.gridItem : classes.listItem}
        item
      >
        <FormControlLabel
          inputRef={radioRef}
          className={
            type === "imageBlocks" ? classes.itemTextImage : classes.itemText
          }
          value={value}
          control={
            <StyledRadio
              radioChecked={radioChecked}
              classes={classes}
              label={text}
              radioType={type}
              errors={errors}
              name={name}
            />
          }
          label={text}
        />
      </Grid>
    </>
  );
}


function StyledRadio(props) {
  const { label, classes, radioType, radioChecked, errors, name } = props;

  if (radioType === "imageBlocks") {
    return (
      <Grid
        container
        className={radioChecked ? classes.imageBlockActive : classes.imageBlock}
      >
        <Grid container item className={radioChecked ? classes.imageContainerActive : classes.imageContainer}>
          <Radio
            disableRipple={true}
            disableFocusRipple={true}
            disableTouchRipple={true}
            required={true}
            className={classes.radioIconImageBlock}
            {...props}
            icon={
              <img
                alt="unchecked"
                src={errors[`${name}`] ? ErrorRadio : UncheckedRadio}
              />
            }
            checkedIcon={<img alt="checked" src={CheckedRadio} />}
          />
        </Grid>
        <Grid
          className={
            radioChecked ? classes.textContainerActive : classes.textContainer
          }
        >
          <p className={classes.paraImageBlock}>{label}</p>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Radio
        disableRipple={true}
        disableFocusRipple={true}
        disableTouchRipple={true}
        required={true}
        {...props}
        icon={
          <img
            alt="unchecked"
            src={errors[`${name}`] ? ErrorRadio : UncheckedRadio}
          />
        }
        checkedIcon={<img alt="checked" src={CheckedRadio} />}
      />
    );
  }
}

export default RadioInput;
