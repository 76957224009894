import * as React from "react";
import { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Select, TextField, Typography } from "@material-ui/core";
// @ts-ignore
import errorIcon from "../../../../widgets/emailSignupWidget/errorIcon.svg";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
// @ts-ignore
import DownArrow from "../icons/DownArrow.svg";
import AuthenticatorNoRedirect from "../../../../auth0/AuthenticatorNoRedirect";
import jwtDecode from "jwt-decode";

function DataCaptureInput(props) {
  const {
    errorText,
    inputFieldBrazeCustomAttributeKey,
    inputFieldLabel,
    inputFieldPlaceholderText,
    inputFieldType,
    requiredField,
    control,
    errors,
    register,
    index,
    setValue,
    signupRedirectLink,
    isSignUpPage,
    isLoginPage,
    handleChangeSignup,
    handleEmailSubmitSignup,
    age,
    setAge,
    auth,
    stage
  } = props;

  console.log("stage", stage)

  const [errorActive, setErrorActive] = useState(false);
  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const regex = /^[0-9\b]+$/;
    if (event?.target?.value === "" || regex.test(event?.target?.value)) {
      setAge(event.target.value);
      setValue("age", Number(event.target.value), { shouldDirty: true });
    }
  };

  var reqField = requiredField;

  if (auth && inputFieldLabel.toLowerCase() === "email") {
    reqField = false;
  }

  //console.log(' {required: requiredField}', requiredField, reqField, inputFieldLabel, auth)

  const useStyles = makeStyles((theme) => ({
    inputFieldWhite: {
      borderRadius: "8px",
      width: "100%",
      height: "48px",
      // background: "#F7F8FA",
      background: "#F5F7FA",
      border: "0.5px solid #DBE3EF",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },
      "& > div": {
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& input::placeholder": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#8C8CA2",
        opacity: 1,
      },
    },
    selectionFieldWhite: {
      borderRadius: "8px",
      width: "100%",
      height: "48px",
      // background: "#F7F8FA",
      background: "#FFFFFF",
      border: "0.5px solid #DBE3EF",
      boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.56)",
      // content: `${inputFieldPlaceholderText}`,
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },
      "& .MuiSelect-select": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#2F2F4E",
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        paddingTop: 0,
        paddingBottom: 0,
        height: "100%",
        "&:focus": {
          border: "none",
          borderRadius: "16px !important",
          backgroundColor: "inherit !important",
        },
      },
      "& > div": {
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& input::placeholder": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        opacity: 1,
        color: "#8C8CA2",
      },
    },
    selectionFieldWhitePlaceholder: {
      borderRadius: "8px",
      width: "100%",
      height: "48px",
      // background: "#F7F8FA",
      background: "#FFFFFF",
      border: "0.5px solid #DBE3EF",
      boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.56)",
      // content: `${inputFieldPlaceholderText}`,
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },
      "& .MuiSelect-select": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#8C8CA2",
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        paddingTop: 0,
        paddingBottom: 0,
        height: "100%",
        "&:focus": {
          border: "none",
          borderRadius: "16px !important",
          backgroundColor: "inherit !important",
        },
      },
      "& > div": {
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& input::placeholder": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#666687",
        opacity: 1,
      },
    },
    inputFieldWhiteError: {
      borderRadius: "8px",
      color: "#666687",
      width: "100%",
      height: "48px",
      background: "#F5F7FA",
      border: "1px solid #FF0F5F",
      boxShadow: "0px 0px 0px 4px rgba(255, 15, 95, 0.1)",
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },

      "& > div": {
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {},
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& input::placeholder": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#666687",
        opacity: 1,
      },
    },
    selectionFieldWhiteError: {
      borderRadius: "8px",
      color: "#666687",
      width: "100%",
      height: "48px",
      background: "#F5F7FA",
      border: "1px solid #FF0F5F",
      boxShadow: "0px 0px 0px 4px rgba(255, 15, 95, 0.1)",
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },
      "& > div": {
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& .MuiSelect-select": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#2F2F4E",
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        paddingTop: 0,
        paddingBottom: 0,
        height: "100%",
        "&:focus": {
          backgroundColor: "inherit !important",
          border: "none",
          borderRadius: "16px !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {},
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& input::placeholder": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#3C465F",
        opacity: 1,
      },
    },
    selectionFieldWhiteErrorPlaceholder: {
      borderRadius: "8px",
      color: "#666687",
      width: "100%",
      height: "48px",
      background: "#FFFFFF",
      border: "1px solid #FF0F5F",
      boxShadow: "0px 0px 0px 4px rgba(255, 15, 95, 0.1)",
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },
      "& > div": {
        height: "100%",
        "&::before": {
          borderBottom: "none !important",
        },
      },
      "& .MuiSelect-select": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#8C8CA2",
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        paddingTop: 0,
        paddingBottom: 0,
        height: "100%",
        "&:focus": {
          backgroundColor: "inherit !important",
          border: "none",
          borderRadius: "16px !important",
        },
      },
      "& input": {
        paddingLeft: "1rem",
        paddingRight: "1rem",
      },
      "&:hover": {},
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& input::placeholder": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#3C465F",
        opacity: 1,
      },
    },

    errorText: {
      fontWeight: 400,
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      paddingTop: "8px",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2F2F4E",
    },
    inputHolder: {
      flexGrow: 1,
      maxWidth: "initial",
      alignContent: "baseline",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
        paddingBottom: "16px",
      },
    },
    inputHolderHidden: {
      flexGrow: 1,
      maxWidth: "initial",
      alignContent: "baseline",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 0,
        paddingBottom: "16px",
      },
      display: "none",
    },
    subscriptionContainer: {
      marginTop: "9px",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    errorIcon: {
      height: "16px",
      paddingRight: "8px",
    },
    inputLabel: {
      color: "#2F2F4E",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      paddingBottom: "8px",
    },
    downArrow: {
      paddingRight: "16px",
    },
    selectPlaceholder: {
      // display: "none",
    },
    ageText: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#2F2F4E",
      paddingTop: "8px",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "24px",
      },
    },
    genderText: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      color: "#2F2F4E",
      paddingTop: "8px",
    },
    noDisplay: {
      display: "none",
    },
  }));

  const classes = useStyles();

  const holderEl = useRef();

  function getType(type) {
    switch (type) {
      case "First Name":
        return "firstName";
      case "Last Name":
        return "lastName";
      case "Email":
        return "email";
      case "Text Input Field":
        return inputFieldLabel;
      case "Gender":
        return "gender";
      case "Date of Birth":
        return "age";
      default:
        return "Text Input";
    }
  }

  const fieldType = getType(inputFieldType);

  function menuOpts() {
    return <div></div>;
  }

  function getHelperText(type) {
    const HelperText = {
      age: (
        <Typography variant={"body1"} className={classes.ageText}>
          So we can be more precise with things like pensions.
        </Typography>
      ),
      gender: (
        <Typography variant={"body1"} className={classes.genderText}>
          So we can share the most relevant content.
        </Typography>
      ),
      default: <></>,
    };

    return HelperText[type] || HelperText.default;
  }

  //var auth = true;
  var emailToken = "";

  //auth = AuthenticatorNoRedirect();

  if (auth) {
    try {
      const tok =
        typeof window !== "undefined" && localStorage.getItem("id_token");
      const tokenData = jwtDecode(tok);
      emailToken = tokenData.email;
    } catch (e) {
      //auth = null
    }
  }

  //hide the firstname/lastname if this is a user who has already added these with old form
  if (inputFieldType === "First Name" && stage.toLowerCase() =="true") {
    return null;
  }

  if (inputFieldType === "Last Name" && stage.toLowerCase() == "true") {
    return null;
  }


  if (inputFieldType === "Email" && auth) {
    return null;
  }
  return (
    <Grid
      container
      item
      xs={12}
      md={
        inputFieldType === "First Name" || inputFieldType === "Last Name"
          ? 6
          : 12
      }
      className={
        inputFieldType === "Email" && auth
          ? classes.inputHolderHidden
          : classes.inputHolder
      }
    >
      <label className={classes.inputLabel}>{inputFieldLabel}</label>
      {inputFieldType === "Text Input Field" ? (
        <>
          <Controller
            {...setValue(
              `brazeCustomAttributes.${index}.customAttributeKey`,
              inputFieldBrazeCustomAttributeKey
            )}
            {...register(`brazeCustomAttributes.${index}.customAttributeValue`)}
            control={control}
            rules={
              fieldType === "email"
                ? {
                    required: reqField,
                    pattern: {
                      value: /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Entered value does not match email format",
                    },
                  }
                : { required: reqField }
            }
            render={({ field }) => (
              <TextField
                {...field}
                placeholder={inputFieldPlaceholderText}
                ref={holderEl}
                id={inputFieldType.replace(/\s/g, "")}
                className={
                  errorActive
                    ? classes.inputFieldWhiteError
                    : classes.inputFieldWhite
                }
                value={inputFieldType === "Email" && auth ? emailToken : null}
                required={reqField}
                InputLabelProps={{
                  style: {
                    color: "#666687",
                    fontSize: "16px",
                    lineHeight: "24px",
                  },
                }}
              />
            )}
          />
          <ErrorMessage
            name={`brazeCustomAttributes.${index}.customAttributeValue`}
            errors={errors}
            render={() => {
              setErrorActive(true);
              return (
                <span className={classes.errorText}>
                  <img
                    className={classes.errorIcon}
                    src={errorIcon}
                    alt="error"
                  />
                  {errorText}
                </span>
              );
            }}
          />
        </>
      ) : (
        <>
          <Controller
            name={fieldType}
            control={control}
            rules={
              // fieldType === "email"
              //   ? {
              //     required: requiredField,
              //     pattern: {
              //       value: /\S+@\S+\.\S+/,
              //       message: "Entered value does not match email format",
              //     },
              //   }
              //   : fieldType === "age"
              //     ? {
              //       required: errorText,
              //       max: {
              //         value: 119,
              //         message: "Age must be less then 120 years old",
              //       },
              //       min: {
              //         value: 18,
              //         message: "Age must be 18 years old or above",
              //       },
              //     }
              //     : {required: requiredField}
              fieldType === "email"
                ? {
                    required: reqField,
                    pattern: {
                      value: /^[\w\-.+]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Invalid email format",
                    },
                  }
                : fieldType === "gender"
                ? {
                    required: true,
                  }
                : fieldType === "age"
                ? {
                    required: errorText,
                    max: {
                      value: 119,
                      message: "Age must be less then 120 years old",
                    },
                    min: {
                      value: 18,
                      message: "Age must be 18 years old or above",
                    },
                  }
                : { required: reqField }
            }
            render={
              ({ field }) =>
                inputFieldType === "Gender" ? (
                  <Select
                    native
                    {...field}
                    variant={"standard"}
                    disableUnderline
                    IconComponent={() => (
                      <img className={classes.downArrow} src={DownArrow} />
                    )}
                    className={
                      errors.gender
                        ? field.value
                          ? classes.selectionFieldWhiteError
                          : classes.selectionFieldWhiteErrorPlaceholder
                        : field.value
                        ? classes.selectionFieldWhite
                        : classes.selectionFieldWhitePlaceholder
                    }
                    inputProps={{}}
                    label={"Select your gender"}
                  >
                    <option value={""}>Select your gender</option>
                    <option value={"Female"}>Female</option>
                    <option value={"Male"}>Male</option>
                    <option value={"Other"}>Other</option>
                    <option value={"Prefer not to say"}>
                      Prefer not to say
                    </option>
                  </Select>
                ) : inputFieldType === "Date of Birth" ? (
                  <>
                    <TextField
                      {...field}
                      type="tel"
                      placeholder={inputFieldPlaceholderText}
                      ref={holderEl}
                      inputMode={"numeric"}
                      value={age}
                      name="age"
                      pattern={"[0-9]*"}
                      id="Date of Birth"
                      {...register("age", {
                        onChange: (
                          e: React.ChangeEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >
                        ) => {
                          handleInput(e);
                        },
                      })}
                      className={
                        errors.age
                          ? classes.inputFieldWhiteError
                          : classes.inputFieldWhite
                      }
                      required={requiredField}
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#666687",
                          fontSize: "16px",
                          lineHeight: "24px",
                        },
                      }}
                    />
                  </>
                ) : (
                  <TextField
                    {...field}
                    placeholder={inputFieldPlaceholderText}
                    ref={holderEl}
                    id={inputFieldType.replace(/\s/g, "")}
                    // className={
                    //   fieldType === "firstName" ?
                    //     (errors.firstName ?
                    //          classes.inputFieldWhiteError
                    //         : classes.inputFieldWhite)
                    //       :
                    //       errorActive
                    //         ? classes.inputFieldWhiteError
                    //         : classes.inputFieldWhite
                    // }
                    className={
                      errorActive
                        ? classes.inputFieldWhiteError
                        : classes.inputFieldWhite
                    }
                    value={
                      inputFieldType === "Email" && auth ? emailToken : null
                    }
                    required={reqField}
                    InputLabelProps={{
                      style: {
                        color: "#666687",
                        fontSize: "16px",
                        lineHeight: "24px",
                      },
                    }}
                  />
                )
              //   ) : (
              //     <TextField
              //       {...field}
              //       placeholder={inputFieldPlaceholderText}
              //       ref={holderEl}
              //       id={inputFieldType.replace(/\s/g, '')}
              //       className={
              //         fieldType === "firstName" ?
              //           (errors.firstName ?
              //                classes.inputFieldWhiteError
              //               : classes.inputFieldWhite)
              //             :
              //             errorActive
              //               ? classes.inputFieldWhiteError
              //               : classes.inputFieldWhite
              //       }

              //           value={
              //             inputFieldType === "Email" && auth
              //               ? emailToken : null

              //           }

              //       required={requiredField}
              //       InputLabelProps={{
              //         style: {
              //           color: "#666687",
              //           fontSize: "16px",
              //           lineHeight: "24px",
              //         },
              //           }}
              //     />
              // )
            }
          />
          <ErrorMessage
            name={fieldType}
            errors={errors}
            render={() => {
              setErrorActive(true);
              return (
                <span className={classes.errorText}>
                  <img
                    className={classes.errorIcon}
                    src={errorIcon}
                    alt="error"
                  />
                  {fieldType === "age" && errors.age.message !== ""
                    ? errors.age.message
                    : fieldType === "email" && errors.email.message ? errors.email.message :  errorText}
                </span>
              );
            }}
          />
           {getHelperText(fieldType)}
        </>
      )}
    </Grid>
  );
}

export default DataCaptureInput;
