import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  surveyContainer: {
    display: "flex",
    "& .sv-popup":{
      [theme.breakpoints.up("sm")]:{
        top: "auto",
        left: "auto",
        position: "absolute",
        maxWidth: "100%",
      },
    }
  },
}));

function SurveyWidget(props) {
  const { surveyID } = props;
  const [surveyData, setSurveyData] = useState(null);
  const surveyRef = useRef(null);

  const classes = useStyles();

  function sendDataToServer(submittedSurveyData: any) {
    // window.parent.postMessage(JSON.stringify(model), '*');

    const userId =
      typeof window !== "undefined" && localStorage.getItem("BrazeID");
    //send Ajax request to your web server.
    console.log("The results are:" + JSON.stringify(survey.data));

    var quizId = surveyID;

    var myHeaders = new Headers({ "content-type": "application/json" });

    var sessionId = "00000000-0000-0000-0000-000000000000";

    var model = {
      ID: userId,
      SessionID: sessionId,
      QuizID: quizId,
      UserQuizResponse: JSON.stringify(submittedSurveyData),
      URL: window.location.href,
    };

    //BRAZE Logging
    // window.surveyToBraze(model);

    var raw = JSON.stringify(model);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.GATSBY_QUIZ_API}/quizresp`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("result: ", result);
        window.surveyToBraze(quizId, result);
      })
      .catch((error) => console.log("error", error));
  }

  async function initSurvey() {
    const response = await fetch(
      `${process.env.GATSBY_QUIZ_API}/?QuizID=${surveyID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    console.log("data", data);

    return data;
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await initSurvey();
      const parsed = JSON.parse(data);
      setSurveyData(parsed.QuizJSON);
    };

    fetchData().catch(console.error);
  }, []);

  const survey = new Model(surveyData);
  const storageItemKey = `survey-${surveyID}`;

  function saveSurveyData(survey) {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    window.localStorage.setItem(storageItemKey, JSON.stringify(data));
  }

  function saveSurveyDataPageChange(survey) {
    const data = survey.data;
    data.pageNo = survey.currentPageNo;
    window.localStorage.setItem(storageItemKey, JSON.stringify(data));
    if (useRef !== null) {
      window.scrollTo({
        top: surveyRef.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }


  survey.onValueChanged.add(saveSurveyData);
  survey.onCurrentPageChanged.add(saveSurveyDataPageChange);
  survey.questionErrorLocation = "bottom"

  survey.focusFirstQuestionAutomatic = false;
  survey.focusOnFirstError = false;

  const prevData = window.localStorage.getItem(storageItemKey) || null;
  if (prevData) {
    const data = JSON.parse(prevData);
    survey.data = data;
    if (data.pageNo) {
      survey.currentPageNo = data.pageNo;
    }
  }

  // Empty the local storage after the survey is completed
  survey.onComplete.add(() => {
    window.localStorage.setItem(storageItemKey, "");
  });
  const surveyComplete = useCallback((sender) => {
    sendDataToServer(sender.data);
  }, []);

  survey.onComplete.add(surveyComplete);
  return (
    <Grid container  className={classes.surveyContainer}>

    <div ref={surveyRef}></div>
      <Survey model={survey} />
    </Grid>
  );
}

export default React.memo(SurveyWidget);
