import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';

const AuthenticatorNoRedirect = () => {

 

  try {
    const tok = localStorage.getItem('id_token');

    const tokenData = jwtDecode(tok);

    if (typeof window !== 'undefined') {
      if (tokenData.exp > Date.now() / 1000) {
        // Token found and not expired, user is authenticated

        return true;
      } else {
        // Token found but expired, user is not authenticated
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');
       
        localStorage.removeItem('profile');
        //localStorage.removeItem('custom_attr');
        //localStorage.removeItem('genMkt');
        return false;
      }
    }
  } catch (e) {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('id_token');
      localStorage.removeItem('access_token');

      localStorage.removeItem('profile');
    }
    //localStorage.removeItem('custom_attr');
    //localStorage.removeItem('genMkt');
    return false;
  }
  


}

export default AuthenticatorNoRedirect
