import React, { useState, useEffect, useRef } from "react";
import YourRatingIcon from "./icon/your-rating.svg";
import YourRatingIconVisible from "./icon/your-rating-visible.svg";
import { makeStyles } from "@material-ui/core/styles";
import Rating from '@material-ui/lab/Rating';
import StarIcon from "./icon/Star.svg";
import StarIconVisible from "./icon/StarVisible.svg";
import EmptyStarIcon from "./icon/empty-star.svg";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";
import * as styles from "./companyRatings.module.css";
import { styled } from '@mui/system';
import useStarIcons from "./starIcons"

import {
  compose,
  spacing,
  palette,
  styleFunctionSx
} from "@material-ui/system";

import { checkIsCampusTheme } from "../lib/helpers";

const styleFunction = styleFunctionSx(compose(spacing, palette));
const StyledGrid = styled(Grid)(styleFunction);

const useStyles = makeStyles((theme) => ({
  rightHandSideContainer: {
    minHeight: '100px'
  },
  ratingContainer: {
    borderRadius: '8px',
    background: 'white',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    marginBottom: '1em'
  },
  ratingContainerVisible: {
    borderRadius: '8px',
    background: 'white',
    border: "0.5px solid #D4D4D4",
    marginBottom: '1em'
  },
  yourRatingLabel: {
    color: '#262641',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: '600'
  },
  yourRatingLabelVisible: {
    color: "#3C465F",
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '700'
  },
  howOurRatingWorksLabel: {
    color: '#2F2F4E',
    fontSize: '14px',
    lineHeight: '20px'
  },
  howOurRatingWorksLabelVisible: {
    color: "#3C465F",
    fontSize: '14px',
    lineHeight: '20px'
  },
  yourRatingIcon: {
    marginLeft: '1em'
  },
  rightGrid: {
    textAlign: 'right',
    minHeight: '100px',
    padding: '1em'
  },
  ratingWord: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    height: '1em'
  },
  numberOfReviewsAnchor: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    height: '1em'
  },
  imageContainer: {
    width: '100px'
  },
  mobileContainer: {
    background: 'linear-gradient(93.42deg, #4776E6 50.88%, #436DCF 99.68%)',
    borderRadius: '8px',
    padding: '1.5em',
    height: '120px',
    marginBottom: '1em'
  },
  mobileContainerVisible: {
    background: "#6464FF",
    borderRadius: '8px',
    padding: '1.5em',
    height: '120px',
    marginBottom: '1em'
  },
  mobileOurRatingLabel: {
    color: 'white',
    letterSpacing: '-0.02em',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '600'
  },
  mobileHowRatingWorksLabel: {
    color: 'white',
    fontSize: '14px',
    lineHeight: '20px'
  },
  mobileRightHandSideContainer: {
    textAlign: 'right'
  }
}));


export default function YourRatingWidget({ companyReference }) {

  var ratingWord = '';
  var reviewCount = 0;
  try {
    if ((companyReference != null) && (typeof companyReference.noOfReviews != "undefined")) {
      reviewCount = companyReference.noOfReviews
    }
  } catch (e) {

  }

  const classes = useStyles();
  const [numberOfReviews, setNumberOfReviews] = useState(reviewCount);
  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  const starIcons = useStarIcons();

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true);
      setIsCampusTheme(isCampusTheme);
    })();
  }, [])

  if (companyReference) {
    switch (parseInt(companyReference.yourRating)) {
      case 1:
        ratingWord = 'Bad';

        break;
      case 2:
        ratingWord = 'Bad';

        break;
      case 3:
        ratingWord = 'Poor';

        break;
      case 4:
        ratingWord = 'Poor';

        break;
      case 5:
        ratingWord = 'Average';

        break;
      case 6:
        ratingWord = 'Average';

        break;
      case 7:
        ratingWord = 'Average';

        break;
      case 8:
        ratingWord = 'Great';

        break;
      case 9:
        ratingWord = 'Excellent';

        break;
      case 10:
        ratingWord = 'Excellent';
        break;
      default:
      // code block
    }
  }


  return (
    finishedCheckingTheme ?
    <>

        <StyledGrid
          container
          alignItems="center"
          justify="center"
          className={isCampusTheme ? classes.ratingContainerVisible : classes.ratingContainer}
          sx={{ display: { xl: 'flex !important', lg: 'flex !important', md: 'none !important', sm: 'none !important', xs: 'none !important' } }}
        >
          <Grid item xs={6}>
            <Grid container alignItems="center">
              <Grid item className={classes.imageContainer}>
                <img src={isCampusTheme ? YourRatingIconVisible : YourRatingIcon} className={classes.yourRatingIcon} />
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center"
                  justify="center">
                  <Grid item xs={12}>
                    <label className={isCampusTheme ? classes.yourRatingLabelVisible : classes.yourRatingLabel}>Your rating</label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={6} className={`${isCampusTheme ? styles.yourRatingBackgroundVisible : styles.yourRatingBackground} ${classes.rightHandSideContainer}`}>
            <Grid container alignItems="center"
              justify="center" className={classes.rightGrid}>
              <Grid item xs={12}>
                <Rating
                  readOnly
                  name="Rating"
                  precision={0.5}
                  value={companyReference && companyReference.yourRating / 2}
                  icon={
                    <img
                      src={isCampusTheme ? StarIconVisible : StarIcon}
                      alt="StarIcon"
                      className={starIcons.starImageWidget}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={starIcons.starImageWidget}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <label className={classes.ratingWord}>
                  {ratingWord}
                </label>
              </Grid>
              <Grid item xs={12}>
                <label className={classes.numberOfReviewsAnchor}>
                  {numberOfReviews} reviews
                    </label>
              </Grid>
            </Grid>
          </Grid>
        </StyledGrid>

        <StyledGrid container className={isCampusTheme ? classes.mobileContainerVisible : classes.mobileContainer} alignItems="center" justify="center" sx={{ display: { xl: 'none !important', lg: 'none !important', md: 'flex !important', sm: 'flex !important', xs: 'flex !important' } }}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <label className={classes.mobileOurRatingLabel}>Your rating</label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container className={classes.mobileRightHandSideContainer}>
              <Grid item xs={12}>
                <Rating
                  readOnly
                  name="Rating"
                  precision={0.5}
                  value={companyReference && companyReference.yourRating / 2}
                  icon={
                    <img
                      src={isCampusTheme ? StarIconVisible : StarIcon}
                      alt="StarIcon"
                      className={starIcons.starImageWidget}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={starIcons.starImageWidget}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <label className={classes.ratingWord}>
                  {ratingWord}
                </label>
              </Grid>
              <Grid item xs={12}>
                <label className={classes.numberOfReviewsAnchor}>
                  {numberOfReviews} reviews
                    </label>
              </Grid>
            </Grid>
          </Grid>
        </StyledGrid>
    </> : <></>
  );
};

//export default YourRatingWidget;
