import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RecaptchaLoader from "../../lib/RecaptchaLoader";

import {
  Button,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Grid,
  Radio,
} from "@material-ui/core";
import arrowRight from "./img/arrow-right.svg";
import success from "./img/success.svg";
import ArrowLeft from "../icon/ArrowLeft.svg";
import Send from "../icon/Send.svg";
import Step3MessageSent from "./components/contactFormStep3MessageSent";
import CloseSquare from "../icon/CloseSquare.svg";
import { createClient } from "../../lib/helpers";
import ContactFormStep3MessageSent from "./components/contactFormStep3MessageSent";
import DangerTriangle from "../icon/DangerTriangle.svg";
import Recaptcha from "react-recaptcha";
import { useStaticQuery, graphql } from "gatsby";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    marginBottom: "1em",
  },
  form: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    height: "100%",
    position: "relative",
  },
  headerContainer: {
    marginBottom: "1em",
  },
  messageField: {
    width: "100% !important",
    "& div": {
      width: "100%",
    },
  },
  basicFieldName: {
    marginBottom: "16px",
    width: "96%",
    [theme.breakpoints.down("xs")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
    "& input": {},
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  basicFieldNameFullWidth: {
    marginBottom: "16px",
    width: "100%",
    "& input": {
      padding: "0",
      lineHeight: "24px",
      height: "initial",
    },
    "& div": {
      padding: "1em",
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      padding: "0 !important",
      border: "none",
    },
  },
  disclaimerContainer: {
    padding: "8px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginBottom: "16px",
  },
  disclaimer: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#2F2F4E",
    maxWidth: "312px",
    paddingLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "263px",
    },
  },
  nextButton: {
    backgroundColor: "#DBE3EF !important",
    borderRadius: "8px",
    width: "123px",
    height: "56px",
    fontWeight: "600",
    letterSpacing: "0.04em;",
    position: "absolute",
    bottom: "0",
    right: "0",
    padding: "0",
    [theme.breakpoints.down("xs")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
    "& img": {
      marginLeft: ".4em",
    },
  },
  error: {
    border: "1px solid #FF0F5F",
    borderRadius: "8px",
    marginBottom: "0",
  },
  errorLabel: {
    color: "#FF0F5F",
    display: "block",
  },

  assetsHeader: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
  },
  assetsFieldset: {
    marginTop: "1em",
  },
  popupSubmitButton: {
    backgroundColor: "#FF0F5F",
    borderRadius: "8px",
    padding: "16px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    "&:hover": {
      backgroundColor: "#FF0F5F",
    },
  },
  popupSubmitButtonText: {
    color: "#fff",
    paddingRight: "8px",
  },
  popupBackButton: {
    padding: "1em",
    background: "#FFFFFF",
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    borderRadius: "8px",
  },
  popupBackButtonText: {
    paddingLeft: "8px",
  },
  backButtonContainer: {
    position: "absolute",
    bottom: "0",
    left: "0",
  },
  stepThreeContainer: {
    textAlign: "center",
    "& *": {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  successHeader: {
    fontWeight: "600",
    marginTop: "1em",
    marginBottom: "1em",
    fontSize: "20px",
    lineHeight: "28px",
  },
  successText: {
    width: "95%",
  },
  assetLabel: {
    whiteSpace: "nowrap",
    marginLeft: "-5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      marginLeft: "-8px",
    },
  },
  popupHeader: {
    marginBottom: "1em",
  },
  sendAMessage: {
    color: "#3C455C",
  },
  collapsePopupButton: {
    minWidth: "24px !important",
    width: 24,
    height: 24,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  recap: {
    display: "none",
  },
}));

let recaptchaInstance;
const executeCaptcha = (e) => {
  e.preventDefault();
  recaptchaInstance.reset();
  recaptchaInstance.execute();
};

const GetDictionary = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityDictionary(
        filter: {
          key: {
            in: [
              "contact_popup_first_step_header_text"
              "contact_popup_second_step_header_text"
              "contact_popup_message_placeholder"
              "contact_popup_message_required_error_message"
              "contact_popup_email_placeholder"
              "contact_popup_email_required_error_message"
              "contact_popup_email_invalid_error_message"
              "contact_popup_next_button_text"
              "contact_popup_first_name_placeholder"
              "contact_popup_first_name_required_error_message"
              "contact_popup_last_name_placeholder"
              "contact_popup_last_name_required_error_message"
              "contact_popup_phone_placeholder"
              "contact_popup_assets_required_error_message"
              "contact_popup_back_button_text"
              "contact_popup_submit_button_text"
              "contact_popup_assets_label"
              "contact_popup_thankyou_message"
              "contact_popup_thankyou_message_body"
              "contact_popup_close_button_text"
              "contact_popup_disclaimer_text"
            ]
          }
        }
      ) {
        nodes {
          value
          key
        }
      }
    }
  `);

  return data.allSanityDictionary.nodes;
};

export default function ContactForm({
  dashboardId,
  onClickingAwayHandler,
  isCoursePage,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const classes = useStyles();
  var apiURL = process.env.GATSBY_QUESTIONAPI;

  const [firstStepHeaderText, setFirstStepHeaderText] = useState("");
  const [secondStepHeaderText, setSecondStepHeaderText] = useState("");
  const [messagePlaceholder, setMessagePlaceholder] = useState("");
  const [messageRequiredErrorMessage, setMessageRequiredErrorMessage] =
    useState("");
  const [emailPlaceholder, setEmailPlaceholder] = useState("");
  const [emailRequiredErrorMessage, setEmailRequiredErrorMessage] =
    useState("");
  const [emailInvalidErrorMessage, setEmailInvalidErrorMessage] = useState("");
  const [nextButtonText, setNextButtonText] = useState("");
  const [firstNamePlaceholder, setFirstNamePlaceholder] = useState("");
  const [firstNameRequiredErrorMessage, setFirstNameRequiredErrorMessage] =
    useState("");
  const [lastNamePlaceholder, setLastNamePlaceholder] = useState("");
  const [lastNameRequiredErrorMessage, setLastNameRequiredErrorMessage] =
    useState("");
  const [phonePlaceholder, setPhonePlaceholder] = useState("");
  const [assetsRequiredErrorMessage, setAssetsRequiredErrorMessage] =
    useState("");
  const [backButtonText, setBackButtonText] = useState("");
  const [submitButtonText, setSubmitButtonText] = useState("");
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [thankYouMessageBody, setThankYouMessageBody] = useState("");
  const [closeButtonText, setCloseButtonText] = useState("");
  const [assetsLabel, setAssetsLabel] = useState("");
  const [disclaimerText, setDisclaimerText] = useState("");
  const [messageSubmitted, setMessageSubmitted] = useState(false);
  const [dictionary, setDictionary] = useState(GetDictionary);

  useEffect(() => {
    (async () => {
      setFirstStepHeaderText(
        dictionary.find((x) => x.key === "contact_popup_first_step_header_text")
          .value
      );
      setSecondStepHeaderText(
        dictionary.find(
          (x) => x.key === "contact_popup_second_step_header_text"
        ).value
      );
      setMessagePlaceholder(
        dictionary.find((x) => x.key === "contact_popup_message_placeholder")
          .value
      );
      setMessageRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "contact_popup_message_required_error_message"
        ).value
      );
      setEmailPlaceholder(
        dictionary.find((x) => x.key === "contact_popup_email_placeholder")
          .value
      );
      setEmailRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "contact_popup_email_required_error_message"
        ).value
      );
      setEmailInvalidErrorMessage(
        dictionary.find(
          (x) => x.key === "contact_popup_email_invalid_error_message"
        ).value
      );
      setNextButtonText(
        dictionary.find((x) => x.key === "contact_popup_next_button_text").value
      );
      setFirstNamePlaceholder(
        dictionary.find((x) => x.key === "contact_popup_first_name_placeholder")
          .value
      );
      setFirstNameRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "contact_popup_first_name_required_error_message"
        ).value
      );
      setLastNamePlaceholder(
        dictionary.find((x) => x.key === "contact_popup_last_name_placeholder")
          .value
      );
      setLastNameRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "contact_popup_last_name_required_error_message"
        ).value
      );
      setPhonePlaceholder(
        dictionary.find((x) => x.key === "contact_popup_phone_placeholder")
          .value
      );
      setAssetsRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "contact_popup_assets_required_error_message"
        ).value
      );
      setBackButtonText(
        dictionary.find((x) => x.key === "contact_popup_back_button_text").value
      );
      setSubmitButtonText(
        dictionary.find((x) => x.key === "contact_popup_submit_button_text")
          .value
      );
      setAssetsLabel(
        dictionary.find((x) => x.key === "contact_popup_assets_label").value
      );
      setThankYouMessage(
        dictionary.find((x) => x.key === "contact_popup_thankyou_message").value
      );
      setThankYouMessageBody(
        dictionary.find((x) => x.key === "contact_popup_thankyou_message_body")
          .value
      );
      setCloseButtonText(
        dictionary.find((x) => x.key === "contact_popup_close_button_text")
          .value
      );
      setDisclaimerText(
        dictionary.find((x) => x.key === "contact_popup_disclaimer_text").value
      );
    })();
  }, [dictionary]);

  useEffect(() => {
    (async () => {
      if (messageSubmitted) {
        var model = {
          AdviserId: dashboardId,
          Url: window.location.href,
        };

        if (window.dataLayer) {
          window.dataLayer.push({
            message_submitted_value: JSON.stringify(model),
          });

          window.dataLayer.push({ event: "message_submitted" });
        }

        window.trackAdviserQuestionSubmit(model);
      }
    })();
  }, [messageSubmitted]);

  function onSubmit(data) {
    data.AdviserId = dashboardId;

    data.IsVisibleMessage = isCoursePage;

    fetch(apiURL + "/ReaderMessage/PostMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error("Error:", error);
      });

    data.ReaderEmail &&
      data.Message &&
      data.AdviserId &&
      data.ReaderFirstName &&
      data.ReaderLastName &&
      data.ReaderAssetId &&
      setStep(3);

    data.ReaderEmail &&
      data.Message &&
      data.AdviserId &&
      data.ReaderFirstName &&
      data.ReaderLastName &&
      data.ReaderAssetId &&
      setMessageSubmitted(true);
  }
  const [step, setStep] = React.useState(1);
  function handleOnClickingAwayHandler() {
    onClickingAwayHandler();
  }
  function handleNext(data) {
    data.ReaderEmail && data.Message && setStep(2);
  }
  function handleBack(data) {
    setStep(1);
  }

  return (
    <form className={classes.form}>
      <RecaptchaLoader />
      <Recaptcha
        ref={(e) => (recaptchaInstance = e)}
        sitekey="6LcWa-IbAAAAAAY8dsZcdQVnp8ycB-9iM0dbl6g8"
        size="invisible"
        verifyCallback={handleSubmit(onSubmit)}
        className={classes.recap}
      />
      {(step === 1 || step === 2) && (
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          className={classes.popupHeader}
        >
          <Typography variant="h4" className={classes.sendAMessage}>
            {step === 1 && firstStepHeaderText}{" "}
            {step === 2 && secondStepHeaderText}
          </Typography>

          <Button
            className={classes.collapsePopupButton}
            onClick={() => handleOnClickingAwayHandler()}
          >
            <img src={CloseSquare} alt="CloseSquare" />
          </Button>
        </Grid>
      )}
      {step === 1 && (
        <div>
          <Controller
            name="Message"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                multiline
                rows={6}
                placeholder={messagePlaceholder}
                className={`${
                  errors.Message?.type === "required" ? classes.error : ""
                } ${classes.basicFieldNameFullWidth}`}
              />
            )}
          />
          {errors.Message?.type === "required" && (
            <span className={classes.errorLabel}>
              {messageRequiredErrorMessage}
            </span>
          )}

          <Controller
            name="ReaderEmail"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                placeholder={emailPlaceholder}
                type="email"
                className={`${
                  errors.ReaderEmail?.type === "required" ||
                  errors.ReaderEmail?.type === "pattern"
                    ? classes.error
                    : ""
                } ${classes.basicFieldNameFullWidth}`}
              />
            )}
          />
          {(errors.ReaderEmail?.type === "required" && (
            <span className={classes.errorLabel}>
              {emailRequiredErrorMessage}
            </span>
          )) ||
            (errors.ReaderEmail?.type === "pattern" && (
              <span className={classes.errorLabel}>
                {emailInvalidErrorMessage}
              </span>
            ))}
          <Button
            onClick={handleSubmit(handleNext)}
            className={classes.nextButton}
          >
            <span>{nextButtonText}</span>
            <img src={arrowRight} />
          </Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Controller
                name="ReaderFirstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder={firstNamePlaceholder}
                    className={`${
                      errors.ReaderFirstName?.type === "required"
                        ? classes.error
                        : ""
                    } ${classes.basicFieldName}`}
                  />
                )}
              />
              {errors.ReaderFirstName?.type === "required" && (
                <span className={classes.errorLabel}>
                  {firstNameRequiredErrorMessage}
                </span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              container
              justify="flex-end"
            >
              <Controller
                name="ReaderLastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder={lastNamePlaceholder}
                    className={`${
                      errors.ReaderLastName?.type === "required"
                        ? classes.error
                        : ""
                    } ${classes.basicFieldName}`}
                  />
                )}
              />
              {errors.ReaderLastName?.type === "required" && (
                <span className={classes.errorLabel}>
                  {lastNameRequiredErrorMessage}
                </span>
              )}
            </Grid>
          </Grid>
          <Controller
            name="Phone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                placeholder={phonePlaceholder}
                className={classes.basicFieldNameFullWidth}
              />
            )}
          />
          <section className={classes.assetsContainer}>
            <label className={classes.assetsHeader}>{assetsLabel}</label>
            <Controller
              name="ReaderAssetId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl
                  component="fieldset"
                  {...field}
                  className={`${
                    errors.ReaderAssetId?.type === "required"
                      ? classes.error
                      : ""
                  } ${classes.assetsFieldset}`}
                >
                  <RadioGroup {...field}>
                    <Grid container spacing={1}>
                      <Grid item xs="4">
                        <FormControlLabel
                          classes={{
                            label: classes.assetLabel,
                          }}
                          value="f4c5632e-0c59-4b50-8bef-112fdf029fd7"
                          control={
                            <Radio
                              classes={{
                                mui: classes.assetLabel,
                              }}
                            />
                          }
                          label="£0 - £50k"
                        />
                      </Grid>
                      <Grid item xs="4">
                        <FormControlLabel
                          classes={{
                            label: classes.assetLabel,
                          }}
                          value="1d4510d4-c0dd-45ac-b4e6-5f048af44978"
                          control={<Radio />}
                          label="£50-£300k"
                        />
                      </Grid>
                      <Grid item xs="4">
                        <FormControlLabel
                          classes={{
                            label: classes.assetLabel,
                          }}
                          value="34399963-5800-4c2c-a3a5-d60dffa3eab3"
                          control={<Radio />}
                          label="£300k+"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
            {errors.ReaderAssetId?.type === "required" && (
              <span className={classes.errorLabel}>
                {assetsRequiredErrorMessage}
              </span>
            )}
          </section>
          <Grid
            item
            container
            alignItems="flex-start"
            className={classes.disclaimerContainer}
          >
            <Grid item>
              <img src={DangerTriangle} alt="DangerTriangle" />
            </Grid>
            <Grid item className={classes.disclaimer}>
              <Typography variant="body2" className={classes.disclaimer}>
                {isCoursePage
                  ? "Experts pay us a small fee when they respond to your enquiry"
                  : disclaimerText}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justify="space-between"
            className={classes.backButtonContainer}
          >
            <Button
              onClick={() => handleBack()}
              className={classes.popupBackButton}
            >
              <img src={ArrowLeft} alt="ArrowLeft" />
              <Typography
                variant="button"
                className={classes.popupBackButtonText}
              >
                {backButtonText}
              </Typography>
            </Button>
            <Button
              type="submit"
              className={classes.popupSubmitButton}
              onClick={executeCaptcha}
            >
              <Typography
                variant="button"
                className={classes.popupSubmitButtonText}
              >
                {submitButtonText}
              </Typography>
              <img src={Send} alt="Send" />
            </Button>
          </Grid>
        </div>
      )}
      {step === 3 && (
        <ContactFormStep3MessageSent
          onClickingAwayHandler={handleOnClickingAwayHandler}
          thankYouMessage={thankYouMessage}
          thankYouMessageBody={thankYouMessageBody}
          closeButtonText={closeButtonText}
        />
      )}
    </form>
  );
}
{
  /* <section>
<label>Your investable assets</label>
<Controller
  name="ReaderAssetId"
  control={control}
  render={({ field }) => (
    <FormControl component="fieldset" {...field}>
      <RadioGroup {...field}>
        <FormControlLabel
          value="f4c5632e-0c59-4b50-8bef-112fdf029fd7"
          {...field}
          control={<Radio {...field}/>}
          label="£0 - £50k"
        />
        <FormControlLabel
        {...field}
          value="1d4510d4-c0dd-45ac-b4e6-5f048af44978"
          control={<Radio {...field}/>}
          label="£50-£300k"
        />
        <FormControlLabel
        {...field}
          value="34399963-5800-4c2c-a3a5-d60dffa3eab3"
          control={<Radio {...field} />}
          label="£300k+"
        />
      </RadioGroup>
    </FormControl>
  )}
/>
</section> */
}
