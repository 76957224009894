import React, { useState, useEffect, useRef } from "react";
import PortableText from "./portableText";
import { makeStyles } from "@material-ui/core/styles";
import { Figure } from "./Figure";
import { buildImageObj, checkIsCampusTheme, checkIsLesson } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import {
  Hidden,
} from "@material-ui/core";

import serializers from "./serializers";
import { ResizeObserver } from "resize-observer";

const TextboxWidget = ({ title, text, image, imagePosition }) => {
  const [containerDivVisible, setContainerDiv] = useState();
  const [floatElementVisible, setFloatElement] = useState();
  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {

    setLoaded(true)
  }, [])

  const containerDiv = useRef(null);
  const floatElement = useRef(null);

  useEffect(() => {
    (async () => {
      var isTextbox = true
      var isCampusTheme = await checkIsCampusTheme(isTextbox);
      var isLessonPage = await checkIsLesson()

     // console.log("is campous themne", isCampusTheme)
     // console.log("lesson page var", isLessonPage)
      if (isCampusTheme){
        setIsCampusTheme(isCampusTheme)
      } else if (isLessonPage){

        if (isLessonPage[0].theme !== "Boring Money theme"){
          setIsCampusTheme(true)
        } else {
          setIsCampusTheme(false)
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!containerDivVisible) {
      return;
    }

    if (!floatElementVisible) {
      return;
    }

    if (containerDiv.current != null && floatElement.current != null) {
      new ResizeObserver(setFloatElementHeight).observe(document.body);

      setFloatElementHeight();
    }

  }, [containerDivVisible, floatElementVisible]);

  const setFloatElementHeight = function () {
    if (!(imagePosition === 'Bottom left' || imagePosition === 'Bottom right')) {
      return;
    }

    floatElement.current.style["shape-outside"] = 'inset(calc(100% - ' + (Math.ceil(containerDiv.current.clientWidth / 3) + 30).toString() + 'px) 0 0)';
    floatElement.current.style['margin-top'] = (containerDiv.current.clientHeight - floatElement.current.clientHeight.toString() + "px")
   // console.log("container div", containerDiv)
    //console.log("float el", floatElement)

  }


  var imagePadding = '1.5em';

  const useStyles = makeStyles((theme) => ({
    desktopImage: {
      borderRadius: '12px',
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%'
    },
    containerDiv: {
      cursor: 'initial',
    },
    wrapper: {
      display: 'flex',
      background: '#FFFFFF',
      border: '1px solid #DBE3EF',
      boxShadow: '0px 2px 4px rgb(64 64 64 / 10%)',
      borderRadius: '12px',
      padding: '1.5em'
    },
    campusWrapper: {
      display: 'flex',
      background: '#F7F5E7',
      border: '1px solid #DBE3EF',
      boxShadow: '0px 2px 4px rgb(64 64 64 / 10%)',
      borderRadius: '12px',
      padding: '1.5em'
    },
    floatRight: {
      float: 'right',
      marginLeft: "1.5em",
    },
    floatLeft: {
      float: 'left',
    },
    float: {
      display: 'flex',
      alignItems: 'flex-end',
      width: '33%',

    },
    borderRadius: {
      borderRadius: '9.55224px',
    },
    shapedOutside: {
    },
    marginBottomRight: {
      marginBottom: imagePadding,
      marginRight: imagePadding
    },
    marginBottomLeft: {
      marginLeft: imagePadding,
      marginBottom: imagePadding,
    },
    marginRight: {
      marginRight: imagePadding,
    },
    marginLeft: {
      marginLeft: imagePadding
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '36px',
      marginBottom: '1em',
      display: 'block',
      color: '#262641'
    },
    campusTitle: {
      fontFamily: 'Poppins',
      color: '#3C465F !important',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '24px',
      lineHeight: '36px',
      marginBottom: '1em',
    },
    mobileCrop: {
      maxWidth: '100%',
      objectFit: 'crop'
    },
    marginBottom: {
      marginBottom: '1em'
    },
    textContainer: {
      '& p:last-child': {
        paddingBottom: '0 !important'
      }
    },
    campusTextContainer: {
      '& h1, h2, h3, h4, h5, h6': {
        fontFamily: 'Poppins',
        color: '#3C465F !important'
      },
      '& p, ul, li, ol': {
        fontFamily: 'Poppins',
        color: '#3C465F !important'
      }
    },
    campusTopRightHolder: {
      width: "100%",
    },
  }));

  var getImage = function (marginBottom) {
    return (<img className={`${classes.mobileCrop} ${classes.borderRadius} ${marginBottom ? classes.marginBottom : ''}`} src={(image && typeof image !== 'undefined' && image.asset) ? imageUrlFor(buildImageObj(image))
      .width(600)
      .height(337)
      .fit("crop")
      .url() : ''}
      alt="" />);
  }

  var img = null;

  if (typeof image && typeof image !== 'undefined' && image.asset) {
    img = imageUrlFor(buildImageObj(image))
  }


  const classes = useStyles();

  return (
    <>
      {
        <div className={isCampusTheme ? classes.campusWrapper : classes.wrapper}>
          <div className={isCampusTheme && imagePosition === "Top right" ? classes.campusTopRightHolder : null }>
            {title &&
              <div>
                <label className={isCampusTheme ? classes.campusTitle : classes.title}>{title}</label>
              </div>
            }
            <Hidden only={['xs']} implementation="js">
              {loaded &&
                <div ref={el => {
                  floatElement.current = el;
                  setFloatElement(!!el);
                }}
                     className={`${classes.float} ${classes.borderRadius} ${imagePosition === 'Top left' || imagePosition === 'Bottom left' ? classes.floatLeft : classes.floatRight} ${imagePosition === 'Bottom left' || imagePosition === 'Bottom right' ? classes.shapedOutside : ''} ${imagePosition === 'Top left' ? classes.marginBottomRight : ''} ${imagePosition === 'Top right' ? classes.marginBottomLeft : ''} ${imagePosition === 'Bottom left' ? classes.marginRight : ''} ${imagePosition === 'Bottom right' ? classes.marginLeft : ''}`}>
                  {img && <img className={classes.desktopImage} src={img
                    .width(300)
                    .height(300)
                    .fit("crop")
                    .url()}
                               alt="" />
                  }
                </div>
              }
              <div ref={el => { containerDiv.current = el; setContainerDiv(!!el); }} className={`${isCampusTheme ? classes.campusTextContainer : classes.textContainer} textbox-widget-text`}>
                <PortableText serializers={serializers} blocks={text} />
              </div>
            </Hidden>
            <Hidden only={['sm', 'md', 'xl', 'lg']} implementation="js">

              {(imagePosition === 'Top left' || imagePosition === 'Top right') && getImage('marginBottom')}
              <div className={`${isCampusTheme ? classes.campusTextContainer : ''} textbox-widget-text`} >
                <PortableText serializers={serializers} blocks={text} />
              </div>
              {(imagePosition === 'Bottom left' || imagePosition === 'Bottom right') && getImage()}
            </Hidden>
          </div>
        </div>
      }
    </>
  );
}


export default TextboxWidget;

