import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from 'react-helmet';

export default function WealthWizard(props) {

  
  const { title } = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      color: "#2F2F4E",
    },
    wwContainer: {
      width: "100%"
      
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>

        <div id={title} className={classes.wwContainer} context-root="wealth-wizards"></div>
      </div>

      <Helmet>
        <script defer src="https://turo-green.wealthwizards.io/pension-guidance/main.js" />
      </Helmet>
    </>
  )

}

