import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import { Grid, Button, Typography } from "@material-ui/core";
import ContainerForLinks from "../../containerForLinks";
import Container from "../../container";
import Transparent from "../../icon/Transparent.svg"
import serializers from "../../serializers";

import { useLocation } from '@reach/router';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  olTips: {
    listStyle: "none",
    counterReset: "item",
    paddingLeft: "0px",
  },

  liTips: {
    display: "flex",
    alignItems: "baseline",
    counterIncrement: "item",
    marginBottom: "5px",
    '&::before': {
      marginRight: "10px",
      content: "counter(item)",
      background: "#94a4d6",
      borderRadius: "100%",
      color: "white",
      width: "1.5em",
      textAlign: "center",
      display: "inline-block",
    },
  },
  para: {
    display: "inline"
  },
  bulletContainer: {
    background: "#3C465F",
    borderRadius: "100%",
    display: "block",
    border: '1px solid #DBE3EF',
    boxShadow: '0px 2px 4px rgb(64 64 64 / 10%)',
    height: '24px',
    width: '24px',
    position: 'relative'
  },
  bullet: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: "center",
    color: 'white',
    fontWeight: '600'
  },
  tipText: {
    display: 'inline-block'
  },
  tipTextContainer: {
    width: '94.8% !important',
    maxWidth: '94.8% !important',
    flexBasis: '94.8% !important',
    [theme.breakpoints.down("md")]: {
      paddingLeft: '1em'
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '1.5em'
    },
    '& p': {
      paddingBottom: '0 !important',
      color: '#262641',
      fontSize: '14px',
      lineHeight: '20px'
    }
  },
  shortColumn: {
    width: '5% !important',
    maxWidth: '5% !important'
  },
  tipsContainer: {
    border: '1px solid #DBE3EF',
    backgroundColor: 'white',
    borderRadius: '8px',
    marginBottom: '1em',
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))'
  },
  descriptionParagraph: {
    '& p': {
      color: '#2F2F4E',
      fontSize: '16px',
      lineHeight: '24px',
      paddingTop: '1em',
      paddingBottom: '1em'
    }
  },
  tipRow: {
    boxShadow: 'inset 0px -1px 0px #e6e8f0',
    padding: '16px'
  },
  lastTip: {
    boxShadow: 'initial !important'
  },
  tipsTitle: {
    color: '#262641',
    fontSize: '36px',
    lineHeight: '47px'
  }
}));

export default function Tips(props) {

  const { title, tipElements, fromPage, description } = props;
  //console.log('description: ', description);
  const classes = useStyles();
  var arr = [];

  const location = useLocation();



  var qs = queryString.parse(location.search).tips;

  if ((qs != null) && (qs != "")) {

    const myArr = qs.split(",");

    for (var i = 0; i < myArr.length; i++) {
      myArr[i] = parseInt(myArr[i]) - 1;
    }

    for (var i = 0; i < tipElements.length; i++) {

      if (myArr.includes(i)) {
        arr.push(tipElements[i])
      }
    }
  }
  else {
    arr = tipElements;
  }
  //console.log(title)
 // var updatedTitle = title.replace("5", arr.length)

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.tipsTitle}>
            {title && title}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.descriptionParagraph}>
          {fromPage ?
            <BasePortableText
              blocks={description}
              serializers={serializers}
              {...clientConfig.sanity}
              className={classes.para}
            />
            :
            <BasePortableText
              blocks={description._rawChildren}
              serializers={serializers}
              {...clientConfig.sanity}
              className={classes.para}
            />
          }
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.tipsContainer}>
            {arr && arr.map((el, index) => {

              if (fromPage) {
                return <Grid item className={`${classes.tipRow} ${(index+1) == arr.length ? classes.lastTip: ''}`} xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} className={classes.shortColumn}>
                      <div className={classes.bulletContainer}>
                        <label className={classes.bullet}>{index + 1}</label>
                      </div>
                    </Grid>
                    <Grid item className={classes.tipTextContainer} xs={11}>
                      <BasePortableText
                        blocks={el.tipText}
                        serializers={serializers}
                        {...clientConfig.sanity}
                        className={classes.tipText}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }
              else {
                return <Grid item className={classes.tipRow} xs={12}>
                  <Grid container alignItems="center">
                    <Grid item xs={1} className={classes.shortColumn}>
                      <div className={classes.bulletContainer}>
                        <label className={classes.bullet}>{index + 1}</label>
                      </div>
                    </Grid>
                    <Grid item className={classes.tipTextContainer} xs={11}>
                      <BasePortableText
                        blocks={el._rawTipText}
                        serializers={serializers}
                        {...clientConfig.sanity}
                        className={classes.tipText}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }

            }
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
