import React, { useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import SanityImage from "gatsby-plugin-sanity-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import * as styles from "./Figure.module.css";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { checkIsCampusTheme, checkIsLesson } from "../lib/helpers";

const useStyles = makeStyles((theme) => ({
  mainImage: {
    display: "block",
    position: "relative",
    width: "100%",
    borderRadius: "8px",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",
    },
  },
  blockQuote: {
    display: 'block',
    position: 'relative',
    width: '56px',
    height: '56px',
    borderRadius: '8px',
    '& img': {
      display: 'block',
      position: 'absolute',
      top: "0",
      left: "0",
      width: "56px",
      height: "56px",
      verticalAlign: "top",
      objectFit: "cover",
    }
  },
  mainImageNoBottomRadius: {
    display: "block",
    position: "relative",
    width: "100%",
    backgroundColor: "lightGrey",
    borderRadius: "12px 12px 0 0",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",
    },
  },
  bigBlockImage: {
    display: "block",
    position: "relative",
    width: "100%",
    backgroundColor: "lightGrey",
    borderRadius: "8px 8px 0 0",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",
    },
  },
  bigBlockSingleImage: {
    display: "block",
    position: "relative",
    width: "100%",
    backgroundColor: "lightGrey",
    borderRadius: "8px",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",
    },
  },
  adviserFilteredImage: {
    display: "block",
    position: "relative",
    width: "100%",
    backgroundColor: "lightGrey",
    borderRadius: "10px",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",

    },
  },
  visibleAdviserFilteredImage: {
    display: "block",
    position: "relative",
    width: "100%",
    backgroundColor: "lightGrey",
    borderRadius: "200px",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",

    },
  },
  mainImageHeroSection: {
    display: "block",
    position: "relative",
    width: "100%",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",
    },
  },
  comparisonTableImage: {
    display: "block",
    position: "relative",
    width: "100%",
    borderRadius: "0",
    "& img": {
      display: "block",
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      verticalAlign: "top",
      objectFit: "cover",
    },
  }
}));

export const Figure = ({ node, isAdviserImage, isAdviserFilteredImage, isVisibleAdviserFilteredImage, isBlockQuoteImage }) => {
  const classes = useStyles();

  const [isCampusTheme, setIsCampusTheme] = useState(false);

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme(false);
      var isLessonPage = await checkIsLesson()
      if (isCampusTheme){
        setIsCampusTheme(isCampusTheme)
      } else if (isLessonPage){

        if (isLessonPage[0].theme !== "Boring Money theme"){
          setIsCampusTheme(true)
        } else {
          setIsCampusTheme(false)
        }
      }
    })();
  }, []);
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  let dimensions;
  if (isAdviserImage === true) {
    dimensions = { maxWidth: 55, maxHeight: 55 };
  } else {
    if (node.isBigBlockGroupImage === true) {
      dimensions = {
        maxWidth: 309,
        maxHeight: 174,
        objectFit: "crop",
      };
    } else if (node.isHeroSectionImage === true) {
      dimensions = {
        maxWidth: 720,
        maxHeight: 500,
        objectFit: "crop",
      };
    } else if (node.isHeroSectionImagePadding === true) {
      dimensions = {
        maxWidth: 568,
        maxHeight: 350,
        objectFit: "crop",
      };
    } else if (node.isPeopleBioImage === true || node.isAdviserFilteredImage === true || node.isVisibleAdviserFilteredImage) {
      dimensions = {
        maxWidth: 300,
        maxHeight: 300,
        objectFit: "crop",
      };
    } else if (node.isComparisonTableImage === true) {
      dimensions = {
        maxWidth: 275,
        maxHeight: 90,
        objectFit: "crop",
      };
    } else if (node.isBlockQuoteImage === true) {
      dimensions = {
        maxWidth: 56,
        maxHeight: 56,
        objectFit: "crop",
      };
    } else
      dimensions = {
        maxWidth: 1200,
        maxHeight: Math.floor((9 / 16) * 1200),
        objectFit: "crop",
      };
  }
  const gatsbyImageData = getGatsbyImageData(
    node,
    {
      maxWidth: 1200,
      maxHeight: Math.floor((9 / 16) * 1200),
      objectFit: "crop",
    },
    clientConfig.sanity
  );
  // {mainImage && mainImage.asset && (
  //   <div className={styles.mainImage}>
  //     <img
  //       className={classes.mainImage}
  //       src={imageUrlFor(buildImageObj(mainImage))
  //         .width(1200)
  //         .height
  //         .fit("crop")
  //         .auto("format")
  //         .url()}
  //       alt={mainImage.alt}
  //     />
  //   </div>
  // )}
  return (
    // <Grid container alignItems="center" justify="center" >
    <figure className={styles.figure}>
      <SanityImage
        asset={node.asset}
        alt={node.alt}
        className={
          node.isHeroSectionImage === true
            ? classes.mainImageHeroSection
            : (node.isAdviserFilteredImage ? classes.adviserFilteredImage :
              (node.isBigBlockGroupImage ? classes.bigBlockImage :
                node.isComparisonTableImage ? classes.comparisonTableImage : (node.isBlockQuoteImage ? classes.blockQuote : (node.isBigBlockGroupSingleImage ? classes.bigBlockSingleImage : (node.isVisibleAdviserFilteredImage ? classes.visibleAdviserFilteredImage:classes.mainImage)))))
        }
        width={dimensions.maxWidth}
        height={dimensions.maxHeight}
        crop={node.crop}
        hotspot={node.hotspot}
      // config={{fit: "min"}}
      />
      {
        (node.isAdviserFilteredImage || isAdviserFilteredImage || node.isBlockQuoteImage) && (
          <div className={styles.adviserOverlay}></div>
        )


      }
      {
        node.isVisibleAdviserFilteredImage && <div className={styles.visibleAdviserOverlay}></div>
      }
      {!node.isComparisonTableImage && !node.isBlockQuoteImage && node.caption && (
        <figcaption className={styles.caption}>
          <Typography variant="body1">
            <em className={isCampusTheme ? styles.quoteTextVisible : styles.quoteText}>{node.caption}</em>
          </Typography>
        </figcaption>
      )}
    </figure>
    // </Grid>
  );
};
