import React from "react";
import collapseIcon from "./img/collapse-icon.svg";
import { makeStyles } from "@material-ui/core/styles";

const CollapseIcon = () => {

  const useStyles = makeStyles((theme) => ({
    container: {
      background: '#ECF1F4',
      borderRadius: '8px',
      position: 'relative',
      height: '32px',
      width: '32px',
      marginLeft: "auto",
      marginTop: "16px",
      marginBottom: "16px",
    },
    img: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '45%',
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <img className={classes.img} src={collapseIcon} />
      </div>
    </>
  );
};

export default CollapseIcon;
