import React, { useState, useEffect, useRef} from "react";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../client-config";
import serializers from "../serializers";
import ContainerForLinks from "../containerForLinks";
import * as styles from "./tables.module.css";
import { Hidden } from "@material-ui/core";


function Table(props) {
  const { title, tableRows, rowCount, tableStyle } = props;

  var styleContent = "";

  if (tableStyle == "DB-W-LB") {
    styleContent = styles.DBWLB;

  }

  if (tableStyle == "DB-W") {
    styleContent = styles.DBW;

  }

  if (tableStyle == "ALL-W") {
    styleContent = styles.ALLW;

  }

  if (tableStyle == "L-DB-R-W") {
    styleContent = styles.LDBRW;
  }

  //const [width, setWidth] = useState(0);
  //const [height, setHeight] = useState(0);

  //useEffect(() => {
  //  const updateDimensions = () => {
  //    setWidth(window.innerWidth);
  //    setHeight(window.innerHeight);
  //  }

  //  window.addEventListener("resize", updateDimensions);
  //  return () => window.removeEventListener("resize", updateDimensions);
  //}, []);

  const [width, setWidth] = useState(0);
  const elementRef = useRef();

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <>
      <div ref={elementRef}></div>
      <Hidden mdUp implementation="js">
        {tableRows[0] && tableRows[0].cells &&
          tableRows[0].cells.length > 3 &&
          <div className={styles.hackOne} >
            <div className={styles.hackTwo}>
              <table className={`${styleContent} ${styles.mobileLargeTable}`} style={{ width: tableRows[0].cells.length * Math.ceil(width / 3) }}>

                {tableRows.map((item, i) => {
                  return <CreateRow row={item} count={i} totalRows={tableRows.length} />
                })}

              </table>
            </div>
          </div>
        }

        {tableRows[0] && tableRows[0].cells &&
          tableRows[0].cells.length <= 3 &&

          <div className={styles.respTable}>
            <table className={styleContent}>


              {tableRows.map((item, i) => {
                return <CreateRow row={item} count={i} totalRows={tableRows.length} />
              })}

            </table>
          </div>
        }
      </Hidden>

      <Hidden smDown implementation="js">
        <div className={styles.respTable}>
          <table className={styleContent}>


            {tableRows.map((item, i) => {
              return <CreateRow row={item} count={i} totalRows={tableRows.length} />
            })}

          </table>
        </div>
      </Hidden>
    </>
  )
}


export default Table;

function CreateRow(props) {
  const { row, count, totalRows } = props;

  var headerRow = "";

  if (count == 0) {
    headerRow = styles.headerRow;
  }
  
  return <tr className={headerRow}>
    {row && row.cells.map((cell, index) =>
      <CreateCells cells={cell} index={index} isTopLeft={count == 0 && index == 0} isTopRight={count == 0 && index == (row.cells.length - 1)} isBottomLeft={count == (totalRows - 1) && index == 0} isBottomRight={count == (totalRows - 1) && index == (row.cells.length - 1)} />
    )}
  </tr>

}

function CreateCells(props) {
  const { cells, index, isTopLeft, isTopRight, isBottomLeft, isBottomRight } = props;

  var cellblock = cells.value;
  var cellStyle = cells.tableStyle;
  var cellLink = cells.cellLink;
  var styleContent = "";

  if (cellStyle == "DB") {
    styleContent = styles.DB;

  }

  if (cellStyle == "LB") {
    styleContent = styles.LB;

  }
  if (cellStyle == "W") {
    styleContent = styles.W;

  }


  if ((typeof cellblock !== 'undefined')) {

    for (var i = 0; i < cellblock.length; i++) {

      if (typeof cellLink !== 'undefined') {
        return <td className={`${styleContent} ${isTopLeft ? styles.topLeft : ''} ${isTopRight ? styles.topRight : ''} ${isBottomLeft ? styles.bottomLeft : ''} ${isBottomRight ? styles.bottomRight : ''} ${styles.linkCell}`}>
          <ContainerForLinks reference={cellLink} _type={cellLink._type}>
            <BasePortableText blocks={cellblock[i]} serializers={serializers} {...clientConfig.sanity} />
          </ContainerForLinks>
        </td>
      }
      else {
        return <td className={`${styleContent} ${isTopLeft ? styles.topLeft : ''} ${isTopRight ? styles.topRight : ''} ${isBottomLeft ? styles.bottomLeft : ''} ${isBottomRight ? styles.bottomRight : ''}`}>

          <BasePortableText blocks={cellblock[i]} serializers={serializers} {...clientConfig.sanity} />

        </td>
      }

    }

  }
  else {
    return <td className={`${styleContent} ${isTopLeft ? styles.topLeft : ''} ${isTopRight ? styles.topRight : ''} ${isBottomLeft ? styles.bottomLeft : ''} ${isBottomRight ? styles.bottomRight : ''}`}></td>;
  }



}
