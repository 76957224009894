import * as React from "react";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
// @ts-ignore
import CircleArrowWhite from "./icon/CircleArrowWhite.svg"
import ContainerForLinks from "./containerForLinks";
// @ts-ignore
import emailIcon from "./widgets/emailSignupWidget/subButton.svg";
// @ts-ignore
import WhiteSpinner from "./icon/WhiteSpinner.svg"
// @ts-ignore
import BlackSpinner from "./icon/BlackSpinner.svg"

type ReusableButtonProps = {
  buttonColour?: "red" | "white" | "grey" | "orange" | "dark-grey";
  reference?: {
    _type: string;
    slug: {
      _key?: string;
      _type: string;
      current: string;
    };
    subfolder: {
      folder: string;
    };
  };
  linkText: string | JSX.Element;
  customClickEvent?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  arrowIcon: boolean;
  disabled?: boolean;
  newTab?: boolean;
  children?: JSX.Element | any;
  variant?: "small" | "medium" | "large";
  loading?: boolean;
  arrowType?: boolean;
  arrowPosition?: "right" | "left";
  subscribeIcon?: boolean;
  noLRPadding?: boolean;
  partner?: boolean;
  type?: "submit" | "reset" | "button" | null;
  logCtaWidgetGoogleAnalyticsEvent?: boolean;
  fullWidth?: boolean;
  active?: boolean;
}

const ReusableButton = ({ reference, newTab, buttonColour = "red", variant = "medium", linkText, children, arrowIcon, loading = false, subscribeIcon = false, customClickEvent, noLRPadding, partner, logCtaWidgetGoogleAnalyticsEvent, type, fullWidth, active}: ReusableButtonProps) => {

  const getPadding = (variant) => {
    if (variant === "large") {
      return noLRPadding ? "18px 0px 18px 0px !important" : "18px 32px 18px 32px !important";
    } else if (variant === "medium") {
      return noLRPadding ? "16px 0px 16px 0px !important" : "16px 24px 16px 24px !important";
    } else if (variant === "small") {
      return noLRPadding ? "10px 0px 10px 0px !important" : "10px 18px 10px 18px !important";
    }
  };
  const getFontSizesRect = (variant) => {
    if (variant === "large") {
      return {
        labelFontSize: "16px",
        labelWeight: 500,
        labelLineHeight: "20px",
      };
    } else if (variant === "medium") {
      return {
        labelFontSize: "14px",
        labelWeight: 500,
        labelLineHeight: "16px",
      };
    } else if (variant === "small") {
      return {
        labelFontSize: "12px",
        labelWeight: 500,
        labelLineHeight: "16px",
      };
    }
  };

  const buttonPadding = getPadding(variant)
  const { labelFontSize, labelWeight, labelLineHeight } = getFontSizesRect(variant)

  const StyledButton = buttonColour === "orange" && withStyles({
    root: {
      padding: buttonPadding,
      backgroundColor: "#FFD574 !important",
      transition: "background 0.3s !important",
      borderRadius: `8px !important`,
      width: "100%",

      "&:hover": {
        backgroundColor: "#FFE3A2 !important",
      },
      "&:active": {
        backgroundColor: "#F2CA6E !important",
      },
      "&:focus": {
        backgroundColor: "#F2CA6E !important",
        border: "2px solid #FFFFFF !important",
        outline: "2px solid #F2CA6E !important",
        boxShadow:
          "inset 0px -2px 0px #F2CA6E, inset -2px 0px 0px #F2CA6E, inset 0px 2px 0px #F2CA6E, inset 2px 0px 0px #F2CA6E !important",
        "&:before": {
          border: "2px solid #F2CA6E !important",
        },
      },
      "&:disabled": {
        background: "#EAEAEF",
        border: "1px solid #DCDCE4",
      },
    },
    label: {
      "& span": {
        fontSize: `${labelFontSize} !important`,
        fontWeight: `${labelWeight} !important` as any,
        lineHeight: `${labelLineHeight} !important`,
        textTransform: "uppercase",
        color: "#3C465F",
      },
    },
  })(Button) || buttonColour === "red" && withStyles({
    root: {
      padding: buttonPadding,
      backgroundColor: partner ? "#FB755C" : "#FF0F5F",
      //FB755C hex orange
      transition: "background 0.3s !important",
      borderRadius: `8px !important`,
      width:  "100%",

      "&:hover":{
        backgroundColor: "#ffffff",
        boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
        "& > * span":{
          color: partner ? "#FB755C !important" : "#FF0F5F !important",
        },
           "& img": {
          filter: "invert(58%) sepia(16%) saturate(325%) hue-rotate(201deg) brightness(93%) contrast(86%)",
        }
      },
      "&:active": {
        // backgroundColor: "#FF3377 !important",
        "& img": {
          filter: "invert(58%) sepia(16%) saturate(325%) hue-rotate(201deg) brightness(93%) contrast(86%)",
        }
      },
      // "&:focus-visible": {
      //   backgroundColor: "#FF0F5F!important",
      //   border: "2px solid #FFFFFF !important",
      //   outline: "2px solid #FF0F5F !important",
      //   boxShadow: "inset 0px -2px 0px #FF0F5F, inset -2px 0px 0px #FF0F5F, inset 0px 2px 0px #FF0F5F, inset 2px 0px 0px #FF0F5F",
      //   "&:before": {
      //     border: "2px solid #FF0F5F !important",
      //   },
      // },
      "&:disabled": {
        background: "#EAEAEF",
        border: "1px solid #DCDCE4",
      },
    },
    label: {
      "& span": {
        // fontSize: `${labelFontSize} !important`,
        // fontWeight: `${labelWeight} !important` as any,
        // lineHeight: `${labelLineHeight} !important`,
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
      },
    },
  })(Button) || buttonColour === "white" && withStyles({
    root: {
      padding: buttonPadding,
      background: "#FFFFFF",
      transition: "background 0.3s !important",
      borderRadius: `8px !important`,
      width:  "100%",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      "&:hover":{
        backgroundColor: "#FF0F5F",
        "& > * span":{
          color: "#FFFFFF !important",
        },
           "& img": {
          filter: "invert(58%) sepia(16%) saturate(325%) hue-rotate(201deg) brightness(93%) contrast(86%)",
        }
      },
      "&:active": {
        // backgroundColor: "#FF3377 !important",
        "& img": {
          filter: "invert(58%) sepia(16%) saturate(325%) hue-rotate(201deg) brightness(93%) contrast(86%)",
        }
      },
      "&:focus-visible": {
        backgroundColor: "#FF0F5F!important",
        border: "2px solid #FFFFFF !important",
        outline: "2px solid #FF0F5F !important",
        boxShadow: "inset 0px -2px 0px #FF0F5F, inset -2px 0px 0px #FF0F5F, inset 0px 2px 0px #FF0F5F, inset 2px 0px 0px #FF0F5F",
        "&:before": {
          border: "2px solid #FF0F5F !important",
        },
      },
      "&:disabled": {
        background: "#EAEAEF",
        border: "1px solid #DCDCE4",
      },
    },
    label: {
      "& span": {
        // fontSize: `${labelFontSize} !important`,
        // fontWeight: `${labelWeight} !important` as any,
        // lineHeight: `${labelLineHeight} !important`,
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FF0F5F",
      },
    },
  })(Button) || buttonColour === "grey" && withStyles((theme) => ({
    root: {
      padding: buttonPadding,
      backgroundColor: "#FFFFFF",
      transition: "background 0.3s !important",
      borderRadius: `8px !important`,
      border: '1px solid #3C465F',
      boxSizing: "border-box",
      height:  "auto",
      "&:hover": {
        backgroundColor: "#3C465F",
        "& span":{
          color: "#FFFFFF",
        }
      },
      "&:active": {
        backgroundColor: "#3C465F !important",
      },
      "&:focus-visible": {
        backgroundColor: "#FFFFFF!important",
        boxShadow: "inset 0px -2px 0px #3C465F, inset -2px 0px 0px #3C465F, inset 0px 2px 0px #3C465F, inset 2px 0px 0px #3C465F",
        "& span":{
          color: "#3C465F !important",
        }
      },
      "&:disabled": {
        background: "#EAEAEF",
        border: "1px solid #3C465F",
      },

      [theme.breakpoints.down(1945)]: {
        width: '75%'
      },
      [theme.breakpoints.down(1300)]: {
        width: '80%'
      },

      [theme.breakpoints.down(1200)]: {
        width: '88%'
      },
      [theme.breakpoints.down(1101)]: {
        width: '92%'
      },
      [theme.breakpoints.down(1050)]: {
        width: '100%'
      }



    },
    label: {
      "& span": {
        fontSize: `${labelFontSize} !important`,
        fontWeight: `${labelWeight} !important` as any,
        lineHeight: `${labelLineHeight} !important`,
        textTransform: "uppercase",
        color: "#3C465F",
      },
    },
  }))(Button) ||buttonColour === "dark-grey" && withStyles((theme) => ({
    root: {
      maxHeight: "56px",
      padding: buttonPadding,
      backgroundColor: active ? "#3C465F" : "#F5F7FA",
      transition: "background 0.3s !important",
      borderRadius: `8px !important`,
      border: '1px solid #DBE3EF',
      boxSizing: "border-box",
      height:  "auto",
      [theme.breakpoints.down("md")]:{
        padding: "14px 16px 14px 16px !important",
      },
      "&:hover": {
        backgroundColor: "#3C465F",
        "& span":{
          color: "#FFFFFF",
        }
      },
      "&:active": {
        backgroundColor: "#3C465F !important",
      },
      "&:focus-visible": {
        backgroundColor: "#FFFFFF!important",
        boxShadow: "inset 0px -2px 0px #3C465F, inset -2px 0px 0px #3C465F, inset 0px 2px 0px #3C465F, inset 2px 0px 0px #3C465F",
        "& span":{
          color: "#3C465F !important",
        }
      },
      "&:disabled": {
        background: "#EAEAEF",
        border: "1px solid #3C465F",
      },


    },
    label: {
      "& span": {
        fontSize: variant === "large" ? "18px" :`${labelFontSize} !important`,
        fontWeight: 600,
        lineHeight: variant === "large" ? "24px" : `${labelLineHeight} !important`,
        textTransform: "uppercase",
        color: active ? "#FFFFFF" : "#2F2F4E ",
      },
    },
  }))(Button) ;

  const useStyles = makeStyles((theme) => ({
    buttonMargin: {
      paddingTop: "25px",
      paddingBottom: "25px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    buttonNoMargin: {
      width: fullWidth ? "100%" : "auto",
      [theme.breakpoints.down("sm")]: {
        width: buttonColour === "dark-grey" ? "auto" : "100%",
      },
    },
    buttonGeneral: {},
    buttonCircular: {
      background: "#FFD574",
      borderRadius: "61px",
      height: "auto",
      padding: "18px 24px 18px 24px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "auto",
      },
    },
    buttonText: {
      color: "#3C465F",
      fontSize: "inherit",
      fontWeight: "inherit",
      lineHeight: "inherit",
      letterSpacing: "inherit",
      display: "flex",
    },
    buttonRect: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    courseButtonText: {
      color: "#3C465F",
      textTransform: "uppercase",
    },
    courseButtonTextWhite: {
      color: "#3C465F",
    },
    buttonImage: {
      marginLeft: ".8em",
      height: "1em",
    },
    visibleAnchor: {
      textDecoration: 'none'
    },
    loadingSpinner:{
      marginLeft: "8px",
      animation: "$spin 5s linear infinite",
      height: labelLineHeight,
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(-360deg)"
      },
      "100%": {
        transform: "rotate(0deg)"
      }
    },
    emailIcon:{
      paddingLeft: "9px",
    },
  }));

  // const getSpinner = () => <img className={classes.loadingSpinner} src={buttonColour === "pink" ? WhiteSpinner : BlackSpinner}/>

  const classes = useStyles()

  const ArrowRight = CircleArrowWhite;

  return (
  <Grid
    className={classes.buttonNoMargin}
  >

    {reference?._type === "widgetCategory"  ? (
      <ContainerForLinks
        reference={reference}
        _type="widgetCategory"
        customClassName={undefined}
        logCtaWidgetGoogleAnalyticsEvent={logCtaWidgetGoogleAnalyticsEvent}
        newTab={newTab}
        onClickHandler={undefined}
        queryParams={undefined}
        isMenuItem={undefined}
        logSidebarLinkGoogleAnalyticsEvent={undefined}
      >
        <StyledButton
          className={
              `${classes.buttonRect}`
          }
          onClick={(e) => {
            customClickEvent && customClickEvent(e)}}

        >
          <Typography variant="button" className={classes.buttonText}>
            {linkText}
          </Typography>
        </StyledButton>
      </ContainerForLinks>
    ) : (
      <ContainerForLinks
        reference={reference}
        _type={reference?._type}
        customClassName={undefined}
        logCtaWidgetGoogleAnalyticsEvent={logCtaWidgetGoogleAnalyticsEvent}
        newTab={newTab}
        onClickHandler={undefined}
        queryParams={undefined}
        isMenuItem={undefined}
        logSidebarLinkGoogleAnalyticsEvent={undefined}
      >
        <StyledButton
          type={type || null}
          className={
             `${classes.buttonRect}`
          }
          onClick={(e) => {
            customClickEvent && customClickEvent(e)}}
        >
          <Typography
            variant="button"
            className={
              classes.buttonText
            }
          >
            {linkText}{subscribeIcon && <img className={classes.emailIcon} src={emailIcon} />}
            {children}
          </Typography>
          {/*{!arrowIcon && loading &&*/}
          {/*  <img className={classes.loadingSpinner} src={LoadingSpinner}/>*/}
          {/*  // getSpinner()*/}
          {/*}*/}
          {arrowIcon && <img
            alt={"arrow"}
            src={ArrowRight}
            className={classes.buttonImage}
          />
          }
        </StyledButton>
      </ContainerForLinks>
    )}
  </Grid>
  )
}

export default ReusableButton;
