import * as React from "react"
import { useState } from "react";
import { Grid } from "@material-ui/core"
import AuthCode from "react-auth-code-input";
import { makeStyles } from "@material-ui/core/styles";

function AuthCodeInput(props) {

  const { setResult } = props;


  const handleOnChangeAuth = (res: string) => {
    setResult(res);
  }

  const useStyles = makeStyles((theme) => ({
    authContainer:{
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    authBox:{
      borderRadius: "8px",
      width: "100%",
      // background: "#F7F8FA",
      background: "#F5F7FA",
      border: "0.5px solid #DBE3EF",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      fontWeight: 500,
      fontSize: "36px",
      lineHeight: "36px",
      textAlign: "center",
      letterSpacing: "-0.02em",
      outline: "none",
      color: "#2F2F4E",
      padding: "8px",
      [theme.breakpoints.up("md")]: {
        height: "56px",
      },
      "&::placeholder":{
        color: "#8C8CA2",
      },
      "&:hover": {
        border: "1px solid #94A4D6",
        boxShadow:
          "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& active": {
        border: "1px solid #94A4D6",
        boxShadow: "0px 0px 0px 4px rgba(148, 164, 214, 0.2), 0px 2px 4px rgba(64, 64, 64, 0.1)",
      },
      "& focus": {
        border: "2px solid #94A4D6",
      },
      "& focus-visible":{
        border: "none",
      },
      // "& input::placeholder": {
      //   fontWeight: 400,
      //   fontSize: "16px",
      //   lineHeight: "24px",
      //   color: "#666687",
      //   opacity: 1,
      // },
    }
  }))

  const classes = useStyles()
  return (
    <AuthCode allowedCharacters={"numeric"} onChange={handleOnChangeAuth} placeholder={0} containerClassName={classes.authContainer}
              inputClassName={classes.authBox}
    />
  )
}

export default AuthCodeInput;
