import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from '@material-ui/lab/Rating';
import StarIcon from "../icon/Star.svg";
import EmptyStarIcon from "../icon/empty-star.svg";
import ContainerForSVG from "../containerForSVG";
import HeartIcon from "../icon/HeartIcon.svg";
import VisibleHeartIcon from "../icon/VisibleHeart.svg";
import useStarIcons from "../starIcons";

const useStyles = makeStyles((theme) => ({
  likesRatingContainer: {
    width: '179px',
    border: '1px solid #DBE3EF',
    borderRadius: '8px',
    padding: '.5em'
  },
  visibleLikesRatingContainer: {
    width: '179px',
    border: '1px solid #DBE3EF',
    borderRadius: '8px',
    padding: '.5em'
  },
  likes: {
    color: "#8C8CA1",
    marginRight: "8px",
    fontWeight: "500",
    lineHeight: "16px",
  },
  likesAdviserPage: {
    color: "#8C8CA2",
    marginRight: "17px",
    fontWeight: "500",
    lineHeight: "16px",
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  relative: {
    position: 'relative'
  },
  ratingElement: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  likesRatingSmallContainer: {
    alignItems: "center"
  },
  marginTop: {
    marginTop: '.8em'
  },
  paddedTop: {
    paddingTop: '.8em'
  },
  likesContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    width: "119px",
    height: "34px",
    justifyContent: "center",
    alignItems: "center",
  },
  likesContainerAdviserPage: {
    borderRadius: "8px",
    width: "119px",
    height: "34px",
    justifyContent: "center",
    alignItems: "center",
  },
  likesSmallContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  amountOfLikes: {
    marginLeft: "10px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  amountOfLikesAdviserPage: {
    marginLeft: "10px",
    fontSize: "16px",
    lineHeight: "24px",
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  visibleAdviserAmountOfLikes: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    fontSize: '16px',
    lineHeight: '20px'
  },
  visibleAdviserLikes: {
    color: '#3C465F',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase'
  },
  heartIconAdviserPage: {
    display: 'inline-block',
    verticalAlign: 'middle'
  }
}));
function AdviserLikes(props) {
  const classes = useStyles();
  const starIcons = useStarIcons();

  const { amountOfLikes, averageRating, isAdviserPage, isDirectoryCard, isVisibleAdviser } = props;

  return (
    <>
      {
        averageRating != undefined && averageRating != null && averageRating > 0 ?

          <Grid container className={isVisibleAdviser ? classes.visibleLikesRatingContainer : classes.likesRatingContainer }>
            {averageRating != undefined && averageRating != null && averageRating > 0 &&
              <Grid container className={classes.averageRatingContainer} alignItems="center" justify="flex-start">

                <ContainerForSVG>

                  <Grid container className={classes.likesRatingSmallContainer}>
                  <Typography variant="body2" className={`${isAdviserPage ? classes.likesAdviserPage : classes.likes} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                      Likes
                  </Typography>
                  <img src={isVisibleAdviser ? VisibleHeartIcon : HeartIcon} alt="HeartIcon" className={isAdviserPage ? classes.heartIconAdviserPage : ''}/>
                  <Typography variant="body2" className={`${isAdviserPage ? classes.amountOfLikesAdviserPage : classes.amountOfLikes} ${isVisibleAdviser ? classes.visibleAdviserAmountOfLikes : ''}`}>
                      {amountOfLikes}
                    </Typography>
                  </Grid>

                </ContainerForSVG>
                <Grid item className={classes.paddedTop}>
                <Typography variant="body2" className={`${isAdviserPage ? classes.likesAdviserPage : classes.likes} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                    Rating
                  </Typography>
                </Grid>
                <Grid item className={`${classes.relative} ${classes.marginTop}`}>
                  <Rating
                    name="Rating"
                    precision={0.5}
                    value={averageRating}
                    className={classes.ratingElement}
                    readOnly
                    icon={
                      <img
                        src={StarIcon}
                        alt="StarIcon"
                        className={starIcons.starImageAdviser}
                      />
                    }
                    emptyIcon={
                      <img
                        src={EmptyStarIcon}
                        alt="EmptyStarIcon"
                        className={starIcons.starImageAdviser}
                      />
                    }
                  />
                </Grid>
              </Grid>
            }

          </Grid>
          :
          <Grid container className={isAdviserPage ? classes.likesContainerAdviserPage : classes.likesContainer}>
            <ContainerForSVG>

              {
                isAdviserPage ? <div className={classes.likesSmallContainer}>
                  <Typography variant="body2" className={`${isAdviserPage ? classes.likesAdviserPage : classes.likes} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                    Likes
                  </Typography>
                  <img src={isVisibleAdviser ? VisibleHeartIcon : HeartIcon} alt="HeartIcon" className={isAdviserPage ? classes.heartIconAdviserPage : ''} />
                  <Typography variant="body2" className={`${isAdviserPage ? classes.amountOfLikesAdviserPage : classes.amountOfLikes} ${isVisibleAdviser ? classes.visibleAdviserAmountOfLikes : ''}`}>
                    {amountOfLikes}
                  </Typography>
                </div> :
                  <Grid container className={classes.likesSmallContainer}>
                    <Typography variant="body2" className={isAdviserPage ? classes.likesAdviserPage : classes.likes}>
                      Likes
                  </Typography>
                    <img src={HeartIcon} alt="HeartIcon" className={isAdviserPage ? classes.heartIconAdviserPage : ''} />
                    <Typography variant="body2" className={isAdviserPage ? classes.amountOfLikesAdviserPage : classes.amountOfLikes}>
                      {amountOfLikes}
                    </Typography>
                  </Grid>
              }
            </ContainerForSVG>
          </Grid>
      }
    </>
  )
}

export default AdviserLikes;
