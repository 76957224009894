import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

function AdvertWidget(props) {
  const { zoneId, mobileZoneId } = props;

  const useStyles = makeStyles((theme) => ({
    advertContainer: {
      textAlign: "center",
      [theme.breakpoints.down(900)]: {
        display: "none",
      },
    },
    advertContainerMobile: {
      textAlign: "center",
      [theme.breakpoints.up(900)]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      {zoneId !== undefined && (
        <div className={classes.advertContainer}>
          <iframe
            id="a5f1f9b0"
            name="a5f1f9b0"
            data-src={`https://revive.boringmoney.co.uk/www/delivery/afr.php?zoneid=${zoneId}&cb=12345`}
            frameborder="0"
            scrolling="no"
            width="600"
            height="250"
            allow="autoplay"
            name={`${zoneId}`}
            data-cookiefirst-category="advertising"
            data-revive-type="true"
          >
            <a
              href="https://revive.boringmoney.co.uk/www/delivery/ck.php?n=aeaf1089&cb=12345"
              target="_blank"
            >
              <img
                src={`https://revive.boringmoney.co.uk/www/delivery/avw.php?zoneid=${zoneId}&amp;cb=12345&n=aeaf1089"`}
                border="0"
                alt=""
              />
            </a>
          </iframe>
        </div>
      )}
      {mobileZoneId !== undefined && (
        <div className={classes.advertContainerMobile}>
          <iframe
            id="a5f1f9b0"
            name="a5f1f9b0"
            src={`https://revive.boringmoney.co.uk/www/delivery/afr.php?zoneid=${mobileZoneId}&cb=12345`}
            frameborder="0"
            scrolling="no"
            width="300"
            height="250"
            allow="autoplay"
            name={`${zoneId}`}
          >
            <a
              href="https://revive.boringmoney.co.uk/www/delivery/ck.php?n=aeaf1089&cb=12345"
              target="_blank"
            >
              <img
                src={`https://revive.boringmoney.co.uk/www/delivery/avw.php?zoneid=${mobileZoneId}&amp;cb=12345&n=aeaf1089"`}
                border="0"
                alt=""
              />
            </a>
          </iframe>
        </div>
      )}
    </>
  );
}

export default AdvertWidget;
