// extracted by mini-css-extract-plugin
export var consContainer = "prosConsWidget-module--consContainer--Osr9-";
export var consGradient = "prosConsWidget-module--consGradient--NS+9D";
export var itemImg = "prosConsWidget-module--itemImg--EHGPH";
export var itemImgContainer = "prosConsWidget-module--itemImgContainer--WZEcc";
export var itemText = "prosConsWidget-module--itemText--ygZBH";
export var prosContainer = "prosConsWidget-module--prosContainer--uoHFO";
export var prosGradient = "prosConsWidget-module--prosGradient--pRzrv";
export var titleContainer = "prosConsWidget-module--titleContainer--PdpYa";
export var titleImg = "prosConsWidget-module--titleImg--bU7Ei";
export var titleText = "prosConsWidget-module--titleText--KOZkT";
export var visibleConsContainer = "prosConsWidget-module--visibleConsContainer--rXdhL";
export var visibleConsTitleText = "prosConsWidget-module--visibleConsTitleText--AOhju";
export var visibleItemImg = "prosConsWidget-module--visibleItemImg--9ydoY";
export var visibleItemText = "prosConsWidget-module--visibleItemText--55rTM";
export var visibleProsContainer = "prosConsWidget-module--visibleProsContainer--CGr8w";
export var visibleProsTitleText = "prosConsWidget-module--visibleProsTitleText--M8R0N";
export var visibleTitleContainer = "prosConsWidget-module--visibleTitleContainer--aY1p2";
export var visibleTitleImg = "prosConsWidget-module--visibleTitleImg--GCI3j";