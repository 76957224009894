import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import StarIcon from "../../icon/Star.svg";
import EmptyStarIcon from "../../icon/empty-star.svg";
import Rating from "@material-ui/lab/Rating";
import useStarIcons from "../../starIcons"

const useStyles = makeStyles((theme) => ({
  reviewDateContainer: {
    paddingTop: '.5em',
    paddingBottom: '.5em',
    width: '50%',
    display: 'inline-block'
  },
  reviewCard: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DBE3EF',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    borderRadius: '8px',
    padding: '1em',
    height: '100%',
    position: 'relative'
  },
  dateContainer: {
    textAlign: 'right'
  },
  dateLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#BDBDBD'
  },
  reviewBody: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#2F2F4E',
    //display: '-webkit-box',
    wordWrap: 'break-word',
    //overflow: 'hidden',
    //height: '50px',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  nameLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
    paddingTop: '.5em',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontWeight: '600'
  },
  nameContainer: {
  },
  reviewCardSetHeight: {
    height: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    borderRadius: "8px",
    padding: "1em",
    // position: "relative",
  },
  reviewBodyOpen: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F2F4E",
    // whiteSpace: 'no-wrap',
    // paddingBottom: '2.3em',
    // display: '-webkit-box',
    // wordWrap: 'break-word',
    // overflow: 'hidden',
    // height: '72px',
    // textOverflow: 'ellipsis',
    // WebkitLineClamp: 3,
    // WebkitBoxOrient: 'vertical',
    width: "100%",
  },
  titleLabelOpen: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#262641",
    // whiteSpace: 'no-wrap',
    // display: '-webkit-box',
    // wordWrap: 'break-word',
    // overflow: 'hidden',
    // minHeight: "48px",
    // textOverflow: 'ellipsis',
    // WebkitLineClamp: 2,
    // WebkitBoxOrient: 'vertical',
    width: "100%",
    marginBottom: "8px ",
  },
  readButton: {
    cursor: "pointer",
    color: "#0070EA",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0px",
  },
}));

export default function CustomerReviewsCard(props) {
  const { review, dictionary, flushState, isForAdivser } = props;
  const classes = useStyles();
  const starIcons = useStarIcons();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  const ref = useRef(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setScrollHeight(ref.current.scrollHeight);
  });

  useEffect(() => {
    setOpen(false);
  }, [flushState]);
  return (
    <Grid item xs={12} >
      <div container className={
        open
          ? classes.reviewCard
          : classes.reviewCardSetHeight
      }>
        <div item xs={6} alignItems='flex-start' className={classes.reviewDateContainer}>
          <Rating
            readOnly
            name="Rating"
            precision={0.5}
            value={review.StarRating / 2}
            icon={
              <img
                src={StarIcon}
                alt="StarIcon"
                className={starIcons.starImage}
              />
            }
            emptyIcon={
              <img
                src={EmptyStarIcon}
                alt="EmptyStarIcon"
                className={starIcons.starImage}
              />
            }
          />
        </div>
        <div item xs={6} alignItems='flex-end' className={`${classes.dateContainer} ${classes.reviewDateContainer}`}>
          <Typography variant="body2" className={classes.dateLabel}>{review.Date}</Typography>
        </div>
        <Grid item xs={12}>
          <Typography variant='body2' className={open ? classes.reviewBodyOpen : classes.reviewBody}
            ref={ref}>{review.Review}</Typography>
        </Grid>
        {/*scrollHeight > height ? (
          <a onClick={handleClick}>
            <Typography variant="button" className={`${classes.readButton}`}>
              {open ? "Read Less >" : "Read More >"}
            </Typography>
          </a>
        ) : open ? (
          <a onClick={handleClick}>
            <Typography variant="button" className={`${classes.readButton}`}>
              Read Less >
              </Typography>
          </a>
        ) : (
              <Grid className={classes.fallback} />
            )*/}
        <Grid item xs={12} className={classes.nameContainer}>
          <Typography variant='body2' className={classes.nameLabel}>{review.ReaderFirstName}</Typography>
        </Grid>
      </div>
    </Grid>
  );
}
