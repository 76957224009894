import React, { useRef, useState, useEffect } from 'react'
import IframeResizer from 'iframe-resizer-react'
import Container from "../../../components/container";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import * as styles from "../../landingPageComponents/adviserList.module.css";
import { checkIsCampusTheme, checkIsLesson } from "../../../lib/helpers";


export default function FrameWidget(props) {

  const { href } = props;
  const useStyles = makeStyles((theme) => ({
    calculatorContainer: {
      // marginTop: "15px",
      // paddingBottom: "30px",
      // marginTop: "35px",
      width: "100%",
      textAlign: "center",
    },

  }));

  const classes = useStyles();
  const [iframeHeight, setIframeHeight] = useState(0);
  const [habemusIframeMessage, setHabemusIframeMessage] = useState(false);
  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [isB2CLesson, setIsB2CLesson] = useState(false)

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme(false);
      var isLessonPage = await checkIsLesson()

      if (isCampusTheme){
        setIsCampusTheme(isCampusTheme)
      } else if (isLessonPage){
        //console.log("lesson pg", isLessonPage)
        if (isLessonPage[0].theme === "Boring Money theme"){
          setIsB2CLesson(true)
        } else if (isLessonPage[0].theme === "Boring Money Campus theme") {
          setIsCampusTheme(true)
        } else {
          setIsCampusTheme(false)
        }
      }
    })();
  }, []);

  useEffect(() => {
    (() => {
      window.addEventListener('message', function (e) {
        var data = e.data;
        var origin = e.origin;
        var window = e.window;

        if (typeof data === 'string' && !isJsonObject(data)) {

          var id = '';
          var height = '';

          var hashSplit = data.split('#');

          if (hashSplit.length > 1) {
            var heightId = hashSplit[1];

            var colonSplit = heightId.split(':');

            if (colonSplit.length === 2) {
              id = colonSplit[0];

              height = parseInt(colonSplit[1]);

              if (href.includes(id)) {
                setHabemusIframeMessage(true);

                setIframeHeight(height);

                var iframe = document.querySelector('iframe[src="' + href + '"]');

                iframe.height = height;
              }
            }
          }
        }

        if (isJsonObject(data)) {
          data = JSON.parse(data);
        }

        if (!data.isMindQuiz || !data.height) {
          return false;
        }

        if ((data.context && data.context !== 'iframe.resize') || (data.isMindQuiz && data.isMindQuiz !== 'true')) {
          return false;
        }

        if (data.src) {
          var sourceUrl = (new URL(data.src));

          var iframeUrl = (new URL(href));

          var sourceDomainAndPath = sourceUrl.hostname + sourceUrl.pathname;
          var iframeUrlDomainAndPath = iframeUrl.hostname + iframeUrl.pathname;

          if (sourceDomainAndPath === iframeUrlDomainAndPath) {
            var iframe = document.querySelector('iframe[src="' + href + '"]');

            if (!iframe) {
              return false;
            }

            setHabemusIframeMessage(true);

            setIframeHeight(data.height);

            iframe.height = data.height;

            return;
          }
        }

        if (data.isMindQuiz && data.isMindQuiz === 'true') {
          var iframe = document.querySelector('iframe[src="' + href + '"]');

          setHabemusIframeMessage(true);

          setIframeHeight(data.height);

          if (iframe) {
            iframe.height = data.height;
          }
        }

        return;
      });
    })();
  });

  function isJsonObject(strData) {
    try {
      JSON.parse(strData);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <>
      <Grid className={''}>
        <div className={`${classes.calculatorContainer} iframeHolder`}>
          <div className={isCampusTheme ? styles.visibleFiltersContainerCalc : isB2CLesson ? styles.filtersContainerCalcB2C : styles.filtersContainerCalc}>
            <IframeResizer
              heightCalculationMethod="max"
              src={href}
              style={{ minWidth: '100%', border: '0px', height: habemusIframeMessage ? iframeHeight : 'initial' }} />
          </div>
        </div>
      </Grid>
    </>
  )

}

