import React from "react";
import PropTypes from "prop-types";
import * as styles from "./vimeoEmbed.module.css";
import { makeStyles } from "@material-ui/core/styles";

import Vimeo from '@u-wave/react-vimeo';

interface VimeoEmbedProps {
  embedId: string;
  type: string;
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      [theme.breakpoints.down("xl")]: {
        width: "568px",
        height: "320px",
      },
      [theme.breakpoints.down("lg")]: {
        flexShrink: "1",
      },
      [theme.breakpoints.down("sm")]: {
        width: "335px",
        height: "188px",
        margin: "4.16666666666667% 0 24px 0",
      },
      // Tablet specific resizing fix
      [theme.breakpoints.between("sm", "md")]: {
        height: "320px",
        width: "568px",
        flexShrink: "1",
      },
    },
    availableCourses: {
      overflow: 'hidden',
      position: 'relative',
      height: '176px'
    }
  };
});

const VimeoEmbed = ({ embedId, type }: VimeoEmbedProps): JSX.Element => {
  const classes = useStyles();

  const myCallback = () => window.vimeoCompleted(embedId);

  const myStartCallback = () => window.vimeoStarted(embedId);


  return (
      <div className={type === "linkCategory" || type === "information" || type === "landingPage" ? classes.container : ( type=== "availableCourses" ? classes.availableCourses : styles.videoResponsive)}>


      <Vimeo video={embedId} className={styles.vimPlayer} autoplay={false} onEnd={() => myCallback()} onPlay={() => myStartCallback()} pip={true} playsInline={false} />


    </div>
  );
};

VimeoEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default VimeoEmbed;
