// extracted by mini-css-extract-plugin
export var ALLW = "tables-module--ALLW--oUYT8";
export var DB = "tables-module--DB--oZJjI";
export var DBW = "tables-module--DBW--4mufK";
export var DBWLB = "tables-module--DBWLB--nln1n";
export var LB = "tables-module--LB--Mi1lc";
export var LDBRW = "tables-module--LDBRW--dREmw";
export var WH = "tables-module--WH--gudE1";
export var bottomLeft = "tables-module--bottomLeft--857QO";
export var bottomRight = "tables-module--bottomRight--PqLKT";
export var hackOne = "tables-module--hackOne--rEyLO";
export var hackTwo = "tables-module--hackTwo--wBQFZ";
export var headerRow = "tables-module--headerRow--1otNH";
export var linkCell = "tables-module--linkCell--qv4Mn";
export var mobileLargeTable = "tables-module--mobileLargeTable--wtwFC";
export var respTable = "tables-module--respTable--HhgYB";
export var topLeft = "tables-module--topLeft--jtXf+";
export var topRight = "tables-module--topRight--Y5j98";