import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
} from "@material-ui/core";
import Graphic from "./RateAdviserGraphic.svg";

const useStyles = makeStyles((theme) => ({
  questionAnswerSubtext: {
    color: "#2F2F4E",
    textAlign: "center",
    marginBottom: "23px",
    width: '100%'
  },
  questionAnswerTitle: {
    color: "#3C455C",
    textAlign: "center",
    marginTop: "7px",
    marginBottom: "16px",
    // width: "100%"
  },
  closeButton: {
    boxShadow: "0px 2px 4px 0px #4040401A",
    padding: "0"
  },
  closeButtonText: {
    padding: "16px 24px 16px 24px",
    color: "#FF0F5F",
    borderRadius: "8px",

  }
}));

export default function RateCompanyStep3MessageSent(props) {
  const classes = useStyles();
  function handleOnClickingAwayHandler() {
    props.onClickingAwayHandler();
  }
  return (
    <Grid container item justify="center">
      <img src={Graphic} alt="Graphic" />

      <Typography variant="h4" className={classes.questionAnswerTitle}>
        {props.thankYouMessage}
      </Typography>
      <Typography variant="body2" className={classes.questionAnswerSubtext}>
        {props.thankYouMessageBody}
      </Typography>
      <Button
        className={classes.closeButton}
        onClick={() => handleOnClickingAwayHandler()}
      >
        <Typography variant="button" className={classes.closeButtonText}>{props.closeButtonText}</Typography>
      </Button>
    </Grid>
  );
}
