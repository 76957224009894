import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "../icon/Star.svg";
import EmptyStarIcon from "../icon/empty-star.svg";
import ContainerForSVG from "../containerForSVG";
import HeartIcon from "../icon/HeartIcon.svg";
import useStarIcons from "../starIcons";
import VisibleHeartIcon from "../icon/VisibleHeart.svg";

const useStyles = makeStyles((theme) => ({
  likesRatingContainer: {
    width: "100%",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    padding: ".5em",
    marginTop: "1.6em",
    display: "inherit",
    [theme.breakpoints.down(365)]: {
      display: "none",
    },
  },
  visibleLikesRatingContainer: {
    width: "100%",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    padding: ".5em",
    marginTop: "1.6em",
    display: "inherit",
    marginBottom: '1em',
    [theme.breakpoints.down(365)]: {
      display: "none",
    },
  },
  likesRatingContainerDirectory: {
    width: "100%",
    borderTop: "0.5px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
    marginTop: "0.75rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    display: "inherit",
    [theme.breakpoints.down(365)]: {
      // display: "none",
    },
  },

  likes: {
    color: "#8C8CA1",
    marginRight: "8px",
    fontWeight: "500",
    lineHeight: "16px",
  },
  likesDirectory: {
    color: "#5A5A5A",
    marginRight: "8px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
  },
  likesDirectoryCourse: {
    marginRight: "8px",
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#3C465F",
  },
  likesXs: {
    color: "#8C8CA1",
    marginRight: "8px",
    fontWeight: "500",
    lineHeight: "16px",
    display: "inline-block",
    verticalAlign: "middle",
  },
  starsHolder: {
    position: "relative",
    width: "105px",
    [theme.breakpoints.down(365)]: {
      // display: "none",
    },
  },
  starsHolderXs: {
    position: "relative",
    display: "none",
    height: "24px",
    marginTop: ".2em",

    [theme.breakpoints.down(365)]: {
      display: "block",
    },
  },
  ratingElement: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down(365)]: {
      // display: "none",
    },
  },
  ratingElementXs: {
    display: "none",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    [theme.breakpoints.down(365)]: {
      display: "flex",
    },
  },
  likesRatingSmallContainer: {
    alignItems: "center",
    paddingLeft: ".5em",
    [theme.breakpoints.down(365)]: {
      display: "none",
    },
  },

  likesRatingContainerXs: {
    display: "none",
    width: "100%",
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    padding: "0.5em 0.5em 0.5em 0.7em",
    marginTop: "1.6em",
    [theme.breakpoints.down(365)]: {
      display: "flex",
    },
  },

  likesRatingSmallContainerXs: {
    display: "none",

    [theme.breakpoints.down(365)]: {
      display: "flex",
    },
  },
  likesContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    width: "100%",
    height: "39px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.6em",
  },
  visibleLikesContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    width: "100%",
    height: "39px",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.6em",
    marginBottom: '1em'
  },
  likesSmallContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  likesSmallContainerDirectory: {
    alignItems: "center",
  },
  amountOfLikes: {
    marginLeft: "10px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  amountOfLikesXs: {
    fontSize: "16px",
    lineHeight: "24px",
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: ".5em",
  },
  heartImage: {
    display: "block",
  },
  heartImageXs: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  xsSecondRow: {
    height: "24px",
    marginTop: ".2em",
  },
  averageRatingContainerXs: {
    [theme.breakpoints.down(341)]: {
      marginLeft: "-0.25em",
    },
    [theme.breakpoints.down(320)]: {
      marginLeft: "-0.5em",
    },
  },
  averageRatingContainer: {
    flexWrap: "nowrap",
  },
  likesAdviserPage: {
    color: "#8C8CA2",
    marginRight: "17px",
    fontWeight: "500",
    lineHeight: "16px",
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  visibleAdviserAmountOfLikes: {
    fontFamily: 'Poppins',
    color: '#3C465F',
    fontSize: '16px',
    lineHeight: '20px'
  },
  visibleAdviserLikes: {
    color: '#3C465F',
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase'
  },
}));
function AdviserLikes(props) {
  const classes = useStyles();
  const starIcons = useStarIcons();


  const { amountOfLikes, averageRating, isDirectoryCard, isVisibleAdviser, isCoursePage } = props; // re-commit

  return (
    <>
      {averageRating != undefined &&
        averageRating != null &&
        averageRating > 0 ? (
          <Grid container>
            <Grid
              container
              className={
                isDirectoryCard
                  ? classes.likesRatingContainerDirectory
                  : (isVisibleAdviser ? classes.visibleLikesRatingContainer : classes.likesRatingContainer)
              }
              alignItems="center"
              justify={isDirectoryCard ? "start" : "center"}
            >
              {!isDirectoryCard && (
                <Grid item className={classes.likesRatingSmallContainer} xs={5}>
                  <Grid container alignItems="center" justify="flex-start">
                    <Grid item>
                      <Typography variant="body2" className={`${classes.likes} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                        Likes
                    </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        className={classes.heartImage}
                        src={isVisibleAdviser || isCoursePage ? VisibleHeartIcon : HeartIcon}
                        alt="HeartIcon"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        className={`${classes.amountOfLikes} ${isVisibleAdviser ? classes.visibleAdviserAmountOfLikes : ''}`}
                      >
                        {amountOfLikes}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {averageRating != undefined &&
                averageRating != null &&
                averageRating > 0 && (
                  <Grid item xs={7}>
                    <Grid
                      container
                      className={classes.averageRatingContainer}
                      alignItems="center"
                      justify={isDirectoryCard ? "flex-start" : "flex-end"}
                    >
                      <Grid item>
                      <Typography variant="body2" className={`${isDirectoryCard ? (isCoursePage ? classes.likesDirectoryCourse : classes.likesDirectory) : classes.likes} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                          Rating
                      </Typography>
                      </Grid>
                      <Grid item className={classes.starsHolder}>
                        <Rating
                          name="Rating"
                          precision={0.5}
                          value={averageRating}
                          className={classes.ratingElement}
                          readOnly
                          icon={
                            <img
                              src={StarIcon}
                              alt="StarIcon"
                              className={starIcons.starImageAdviser}
                            />
                          }
                          emptyIcon={
                            <img
                              src={EmptyStarIcon}
                              alt="EmptyStarIcon"
                              className={starIcons.starImageAdviser}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
            {!isDirectoryCard &&
              <Grid
                container
                className={classes.likesRatingContainerXs}
                alignItems="center"
                justify="flex-start"
              >
                <Grid item className={classes.likesRatingSmallContainerXs} xs={7}>
                  <Grid container alignItems="center" justify="flex-start">
                    <Grid item xs={12}>
                    <Typography variant="body2" className={`${classes.likesXs} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                        Likes
                  </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.xsSecondRow}>
                      <Grid container alignItems="center" justify="flex-start">
                        <Grid item xs={6}>
                          <img
                            className={classes.heartImageXs}
                          src={isVisibleAdviser || isCoursePage ? VisibleHeartIcon : HeartIcon}
                            alt="HeartIcon"
                          />
                          <Typography
                            variant="body2"
                            className={classes.amountOfLikesXs}
                          >
                            {amountOfLikes}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {averageRating != undefined &&
                  averageRating != null &&
                  averageRating > 0 && (
                    <Grid item xs={5}>
                      <Grid
                        container
                        className={classes.averageRatingContainerXs}
                        alignItems="center"
                        justify="flex-end"
                      >
                        <Grid item xs={12}>
                          <Typography variant="body2" className={`${classes.likesXs} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                            Rating
                      </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.starsHolderXs}>
                          <Rating
                            name="Rating"
                            precision={0.5}
                            value={averageRating}
                            className={classes.ratingElementXs}
                            readOnly
                            icon={
                              <img
                                src={StarIcon}
                                alt="StarIcon"
                                className={starIcons.starImageAdviser}
                              />
                            }
                            emptyIcon={
                              <img
                                src={EmptyStarIcon}
                                alt="EmptyStarIcon"
                                className={starIcons.starImageAdviser}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            }
          </Grid>
        ) : (
          <Grid container className={isDirectoryCard ? classes.likesRatingContainerDirectory : (isVisibleAdviser ? classes.visibleLikesContainer : classes.likesContainer)}>
            <ContainerForSVG>
              <Grid container className={isDirectoryCard ? classes.likesSmallContainerDirectory : classes.likesSmallContainer}>
                <Typography variant="body2" className={`${isCoursePage ? classes.likesDirectoryCourse : classes.likes} ${isVisibleAdviser ? classes.visibleAdviserLikes : ''}`}>
                  Likes
              </Typography>
                <img src={isVisibleAdviser || isCoursePage ? VisibleHeartIcon : HeartIcon} alt="HeartIcon" />
                <Typography variant="body2" className={`${classes.amountOfLikes} ${isVisibleAdviser ? classes.visibleAdviserAmountOfLikes : ''}`}>
                  {amountOfLikes}
                </Typography>
              </Grid>
            </ContainerForSVG>
          </Grid>
        )}
    </>
  );
}

export default AdviserLikes;
