import React, { useState, useEffect, useContext } from "react";
import PortableText from "../portableText";
import ContainerForLinks from "../containerForLinks";
import * as styles from "./ctaWidget.module.css";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "../popups/askQuestionPopup";
import { useMediaQuery } from "@material-ui/core";
import { Grid, Button, Typography } from "@material-ui/core";

import {
  createClient,
  checkIsCampusTheme,
  checkIsLesson,
} from "../../lib/helpers";
import CampusCTA from "../landingPageComponents/blocks/reuseableCampusComponents/campusCTA";
import axios from "axios";
import fileDownload from "js-file-download";
import ReusableButton from "../reusableButton";
import AuthenticatorNoRedirect from "../auth0/AuthenticatorNoRedirect";
import {AuthContext} from "../../context/AuthContext";

const client = createClient();

//<a className={styles.pinkCta} href={cta.link}>{cta.title}</a>

const CtaWidget = ({
  leftSideText,
  linkTitle,
  reference,
  partner,
  newTab,
  isForumPage,
  leftSideTitle,
  customClickEvent,
  downloadFile,
  downloadUrl,
  loggedInOptions,
}) => {
  const [isCampusTheme, setIsCampusTheme] = useState(
    isForumPage ? true : false
  );
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);
  const [triggerDownloadFile, setTriggerDownloadFile] = useState(false);
  const [loggedInDisplayOption, setLoggedInDisplayOption] =
    useState(loggedInOptions);
  const [displayCta, setDisplayCta] = useState(true);
  const [authStatusChecked, setAuthStatusChecked] = useState(false);

  var ctaStyle = styles.pinkCta;
  var ctaStyleLeftStripe = styles.pinkCta;

  const theme = useTheme();

  const matchesMobile = useMediaQuery(theme.breakpoints.down("md"));

  var authedValue = useContext(AuthContext);
  var isAuthed = authedValue.isAuthed

  useEffect(() => {
    if (loggedInDisplayOption) {
      if (
        loggedInDisplayOption === "Display only for logged out users" &&
        !isAuthed
      ) {
        setDisplayCta(true);
      } else if (
        loggedInDisplayOption === "Display only for logged in users" &&
        isAuthed
      ) {
        setDisplayCta(true);
      } else if (
        loggedInDisplayOption ===
        "Display for both logged out and logged in users"
      ) {
        setDisplayCta(true)
      } else {
        setDisplayCta(false);
      }
    }
  }, [isAuthed]);

  if (typeof partner == "undefined" || partner == false) {
    ctaStyle = isCampusTheme ? styles.campusPinkCta : styles.pinkCta;
    ctaStyleLeftStripe = styles.leftStripe;
  } else {
    ctaStyle = isCampusTheme ? styles.campusPinkCta : styles.orangeCta;
    ctaStyleLeftStripe = styles.leftStripeOrange;
  }

  useEffect(() => {
    (async () => {
      if (!isForumPage) {
        var isCampusTheme = await checkIsCampusTheme();

        var isLessonTheme = await checkIsLesson();

        var isVisibleAdviserPage =
          window.location.href.includes("visible/experts");

        setFinishedCheckingTheme(true);
        if (isCampusTheme) {
          setIsCampusTheme(isCampusTheme);
        } else if (isLessonTheme) {
          if (isLessonTheme[0].theme !== "Boring Money theme") {
            setIsCampusTheme(true);
          } else {
            setIsCampusTheme(false);
          }
        } else if (isVisibleAdviserPage) {
          setIsCampusTheme(true);
        }
      } else {
        setFinishedCheckingTheme(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (triggerDownloadFile) {
      if (downloadUrl) {
        var lastIndexOfDot = downloadUrl.lastIndexOf(".");

        if (lastIndexOfDot != -1) {
          var lastIndexOfForwardSlash = downloadUrl.lastIndexOf("/");

          var fileName = downloadUrl.slice(
            lastIndexOfForwardSlash + 1,
            downloadUrl.length
          );

          axios
            .get(downloadUrl, {
              responseType: "blob",
            })
            .then((res) => {
              fileDownload(res.data, fileName);
            });
        }
      }
    }
  }, [triggerDownloadFile]);

  var onClickHandler = function () {
    if (downloadFile) {
      setTriggerDownloadFile(true);
    }
  };

  var campusOnClickHandler = function () {
    if (downloadFile) {
      setTriggerDownloadFile(true);
    }

    if (customClickEvent) {
      customClickEvent();
    }
  };

  if (displayCta) {
    if (reference != null && reference._type == "widgetCategory") {
      return (
        <Grid
          container
          className={
            isCampusTheme
              ? styles.campusCtaWidgetContainer
              : styles.ctaWidgetContainer
          }
          alignItems="center"
          justify="center"
        >
          {!isCampusTheme && <div className={styles.leftStripe}></div>}
          <Grid
            item
            spacing={3}
            xs={12}
            sm={7}
            md={7}
            lg={8}
            className={styles.leftSideTextContainer}
          >
            <p>
              <PortableText blocks={leftSideText}/>
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={4}
            spacing={3}
            className={
              isCampusTheme
                ? styles.campusAnchorContainer
                : styles.anchorContainer
            }
          >
            <Dialog
              buttonText={linkTitle}
              noButtonHover={false}
              linkType="link"
              ctaClass={true}
            />
          </Grid>
        </Grid>
      );
    } else {
      if (!finishedCheckingTheme) {
        return <></>;
      } else
        return (
          <Grid
            container
            className={
              isCampusTheme
                ? styles.campusCtaWidgetContainer
                : styles.ctaWidgetContainer
            }
            alignItems="center"
            justify="center"
          >
            {!isCampusTheme && <div className={ctaStyleLeftStripe}></div>}
            <Grid
              item
              spacing={3}
              xs={12}
              sm={7}
              md={7}
              lg={8}
              xl={9}
              className={
                isCampusTheme
                  ? styles.leftSideTextContainerVisible
                  : styles.leftSideTextContainer
              }
            >
              {isForumPage ? (
                <p>
                  <div>
                    <div>
                      <Typography variant={"h5"}>
                        <strong>{leftSideTitle}</strong>
                      </Typography>
                      <Typography variant={"body1"}>{leftSideText} </Typography>
                    </div>
                  </div>
                </p>
              ) : (
                <p className={isCampusTheme ? styles.leftTextPara : null}>
                  <PortableText blocks={leftSideText}/>
                </p>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              lg={4}
              xl={3}
              spacing={3}
              className={
                isCampusTheme
                  ? styles.campusAnchorContainer
                  : styles.anchorContainer
              }
            >
              {isCampusTheme ? (
                <CampusCTA
                  customClickEvent={campusOnClickHandler}
                  linkText={linkTitle}
                  buttonType={"regular"}
                  arrowIcon={false}
                  pageType={matchesMobile ? "available courses" : "regular"}
                  reference={reference}
                  newTab={newTab}
                />
              ) : (
                <>
                  {
                    reference && (
                      <ReusableButton
                        newTab={newTab}
                        partner={partner}
                        linkText={linkTitle}
                        arrowIcon={false}
                        reference={reference}
                        customClickEvent={onClickHandler}
                        logCtaWidgetGoogleAnalyticsEvent={true}
                      />
                    )
                    //   <ContainerForLinks
                    // className={isCampusTheme ? styles.campusPinkCta : styles.pinkCta}
                    // reference={reference}
                    // _type={reference._type}
                    // children={linkTitle}
                    // customClassName={ctaStyle}
                    // logCtaWidgetGoogleAnalyticsEvent={true}
                    // newTab={newTab}
                    // onClickHandler={onClickHandler}
                    // />
                  }
                  {/*{customClickEvent &&*/}
                  {/*  <span className={styles.campusPinkCta} onClick={() => customClickEvent()}>*/}
                  {/*{linkTitle}*/}
                  {/*  </span>}*/}
                </>
              )}
            </Grid>
          </Grid>
        );
    }
  } else {
    return <>
    </>
  }
};

export default CtaWidget;
