import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import StarIcon from "../../icon/Star.svg";
import StarIconVisible from "../../icon/StarVisible.svg";
import EmptyStarIcon from "../../icon/white-empty-star.svg";
import Rating from "@material-ui/lab/Rating";
import useStarIcons from "../../starIcons"
import ContainerForLinks from "../../containerForLinks";

const useStyles = makeStyles((theme) => ({
  reviewDateContainer: {
    paddingTop: '.5em',
    paddingBottom: '.5em',
    width: '50%',
    display: 'inline-block'
  },
  reviewCard: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DBE3EF',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    borderRadius: '8px',
    padding: '1em',
    height: '100%',
    position: 'relative'
  },
  dateContainer: {
    textAlign: 'right'
  },
  dateLabel: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#BDBDBD'
  },
  reviewBody: {
    fontSize: '14px',
    lineHeight: '20px',
    color: "#2F2F4E",
    display: "-webkit-box",
    wordWrap: "break-word",
    overflow: "hidden",
    height: "64px",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    width: "100%",
  },
  reviewBodyVisible: {
    fontSize: '14px',
    lineHeight: '20px',
    color: "#3C465F !important",
    display: "-webkit-box",
    wordWrap: "break-word",
    overflow: "hidden",
    height: "64px",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    width: "100%",
  },
  nameLabel: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
    paddingTop: '.5em',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    fontWeight: '600'
  },
  nameContainer: {
  },
  reviewCardSetHeight: {
    height: "124px",
    borderTop: "1px solid #DBE3EF",
    paddingTop: "1em",
    paddingBottom: '1em'
  },
  reviewCardSetHeightVisible: {
    height: "124px",
    borderTop: "1px solid #D4D4D4",
    paddingTop: "1em",
    paddingBottom: '1em'
  },
  reviewCardWithReadMore: {
    height: "150px",
    borderTop: "1px solid #DBE3EF",
    paddingTop: "1em",
    paddingBottom: '1em'
  },
  reviewCardWithReadMoreVisible: {
    height: "150px",
    borderTop: "1px solid #D4D4D4",
    paddingTop: "1em",
    paddingBottom: '1em',
    "& > div":{
      "& > p":{
        color: "#3C465F !important",
      }
    }
  },
  reviewBodyOpen: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2F2F4E",
    width: "100%",
  },
  reviewBodyOpenVisible: {
    fontSize: "16px",
    lineHeight: "24px",
    width: "100%",
    color: "#3C465F",
  },
  titleLabelOpen: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#262641",
    width: "100%",
    marginBottom: "8px ",
  },
  readButton: {
    cursor: "pointer",
    color: "#0070EA",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textDecoration: 'none'
  },
  readButtonVisible: {
    cursor: "pointer",
    color: "rgba(100, 100, 255, 1)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textDecoration: 'none'
  },
}));

export default function CustomerReviewsCard(props) {
  const { review, dictionary, flushState, isForAdivser, companyReviewPageReference, isCampusTheme } = props;
  const classes = useStyles();
  const starIcons = useStarIcons();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setScrollHeight(ref.current.scrollHeight);
  });

  useEffect(() => {
    setOpen(false);
  }, [flushState]);
  return (
    <Grid item xs={12} >
      {isCampusTheme}
      <div container className={
        scrollHeight > height
          ? `${isCampusTheme ? classes.reviewCardWithReadMoreVisible : classes.reviewCardWithReadMore}`
          : `${isCampusTheme ? classes.reviewCardSetHeightVisible : classes.reviewCardSetHeight}`
      }>
        <div item xs={6} alignItems='flex-start' className={classes.reviewDateContainer}>
          <Rating
            readOnly
            name="Rating"
            precision={0.5}
            value={review.StarRating / 2}
            icon={
              <img
                src={isCampusTheme ? StarIconVisible : StarIcon}
                alt="StarIcon"
                className={starIcons.providerReviewsWidgetStarImage}
              />
            }
            emptyIcon={
              <img
                src={EmptyStarIcon}
                alt="EmptyStarIcon"
                className={starIcons.providerReviewsWidgetStarImage}
              />
            }
          />
        </div>
        <div item xs={6} alignItems='flex-end' className={`${classes.dateContainer} ${classes.reviewDateContainer}`}>
          <Typography variant="body2" className={classes.dateLabel}>{review.Date}</Typography>
        </div>
        <Grid item xs={12}>
          <Typography variant='body2' className={open ? `${isCampusTheme ? classes.reviewBodyOpenVisible : classes.reviewBodyOpen}` : `${isCampusTheme ? classes.reviewBodyVisible : classes.reviewBody}`}
            ref={ref}>{review.Review}</Typography>
        </Grid>
        {scrollHeight > height ? (

            companyReviewPageReference && companyReviewPageReference._type &&
          <ContainerForLinks
            reference={companyReviewPageReference}
            _type={companyReviewPageReference._type}
            customClassName={isCampusTheme ? classes.readButtonVisible : classes.readButton}
          >
            {'Read More >'}
          </ContainerForLinks>

        ) : (
              <Grid className={classes.fallback} />
            )}

      </div>
    </Grid>
  );
}
