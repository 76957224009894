import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  baseContainer: {
    position: 'relative',
    height: '8px'
  },
  emptyFirstElement: {
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    background: '#F5F7FA',
    height: '8px',
    position: 'absolute',
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    width: '18%',
    marginRight: '2%',
    left: 0
  },
  emptyFirstElementVisible: {
    border: "1px solid #D4D4D4",
    background: '#FFFFFF',
    borderRadius: "8px 0px 0px 8px",
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: 0
  },
  fullFirstElementVisible: {
    background: "#6464FF",
    borderRadius: "8px 0px 0px 8px",
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: 0
  },
  emptySecondElement: {
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    background: '#F5F7FA',
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '20%',
  },
  emptySecondElementVisible: {
    background: '#FFFFFF',
    border: "1px solid #D4D4D4",
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '20%',
  },
  fullSecondElementVisible: {
    background: "#6464FF",
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '20%',
  },
  emptyThirdElement: {
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    background: '#F5F7FA',
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '40%',

  },
  emptyThirdElementVisible: {
    background: '#FFFFFF',
    border: "1px solid #D4D4D4",
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '40%',

  },
  fullThirdElementVisible: {
    background: "#6464FF",
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '40%',
  },
  emptyFourthElement: {
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    background: '#F5F7FA',
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '60%',

  },
  emptyFourthElementVisible: {
    border: "1px solid #D4D4D4",
    background: '#FFFFFF',
    height: '8px',
    position: 'absolute',
    width: '18%',
    marginRight: '2%',
    left: '60%',

  },
  emptyFifthElement: {
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    background: '#F5F7FA',
    height: '8px',
    position: 'absolute',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    width: '20%',
    left: '80%',

  },
  emptyFifthElementVisible: {
    border: "1px solid #D4D4D4",
    background: '#FFFFFF',
    height: '8px',
    position: 'absolute',
    width: '20%',
    borderRadius: "0px 8px 8px 0px",
    left: '80%',
  },
  fullFifthElementVisible: {
    background: "#6464FF",
    borderRadius: "0px 8px 8px 0px",
    height: '8px',
    position: 'absolute',
    width: '20%',
    left: '80%',

  },
  fullFirstElement: {
    height: '8px',
    position: 'absolute',
    background: '#3C465F',
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    width: '18%',
    marginRight: '2%',
    left: 0
  },
  fullSecondElement: {
    height: '8px',
    position: 'absolute',
    background: '#3C465F',
    width: '18%',
    marginRight: '2%',
    left: '20%',

  },
  fullThirdElement: {
    height: '8px',
    position: 'absolute',
    background: '#3C465F',
    width: '18%',
    marginRight: '2%',
    left: '40%',

  },
  fullFourthElement: {
    height: '8px',
    position: 'absolute',
    background: '#3C465F',
    width: '18%',
    marginRight: '2%',
    left: '60%',

  },
  fullFourthElementVisible: {
    height: '8px',
    position: 'absolute',
    background: "#6464FF",
    width: '18%',
    marginRight: '2%',
    left: '60%',
  },
  fullFifthElement: {
    height: '8px',
    position: 'absolute',
    background: '#3C465F',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    width: '20%',
    left: '80%',

  }
}));

export default function OverallScoreWidget({
  score, isCampusTheme }) {

  const classes = useStyles();

  return (
    <div className={classes.baseContainer}>
      <div className={isCampusTheme ? classes.emptyFirstElementVisible : classes.emptyFirstElement}></div>
      <div className={isCampusTheme ? classes.emptySecondElementVisible : classes.emptySecondElement}></div>
      <div className={isCampusTheme ? classes.emptyThirdElementVisible : classes.emptyThirdElement}></div>
      <div className={isCampusTheme ? classes.emptyFourthElementVisible : classes.emptyFourthElement}></div>
      <div className={isCampusTheme ? classes.emptyFifthElementVisible : classes.emptyFifthElement}></div>
      {
        score > 0 && <div className={isCampusTheme ? classes.fullFirstElementVisible : classes.fullFirstElement}></div>
      }
      {
        score >= 3  && <div className={isCampusTheme ? classes.fullSecondElementVisible : classes.fullSecondElement}></div>
      }
      {
        score >= 5 && <div className={isCampusTheme ? classes.fullThirdElementVisible : classes.fullThirdElement}></div>
      }
      {
        score >= 7 && <div className={isCampusTheme ? classes.fullFourthElementVisible : classes.fullFourthElement}></div>
      }
      {
        score > 8 && <div className={isCampusTheme ? classes.fullFifthElementVisible : classes.fullFifthElement}></div>
      }
    </div>
  );
};
