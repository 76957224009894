import React from "react";
import Checked from "../../icon/checked.svg";
import NonChecked from "../../icon/non-checked.svg";
import FocusNonChecked from "../../icon/focus-checkbox.svg";
import ErrorCheckbox from "../../icon/error-checkbox.svg";
import DisabledCheckbox from "../../icon/disabled-checkbox.svg";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Checkbox, Icon } from "@material-ui/core";

const ReusableCheckbox = ({ handleChange, checked, isSidebar, error }) => {

  const useStyles = makeStyles((theme) => ({
    nonCheckedIcon:{
      content: `url(${NonChecked})`
    },
    checkedIcon:{
      content: `url(${Checked})`
    },
    errorIcon:{
      content: `url(${ErrorCheckbox})`
    },
  }))
  const classes = useStyles()

  const checkBoxStyles = (theme) => ({
    root: {
      display: "inline-block",
      padding: "0 !important",
      marginRight: isSidebar ? "12px" : "24px",
      marginTop: "3px",
      verticalAlign: "top",
      height: "100%",
      "&$checked": {
        color: "#3D70B2",
      },
      "&.Mui-disabled":{
        "& span":{
          "& span":{
            "& img":{
              content: `url(${DisabledCheckbox})`
            }
          }
        },
      },
      "&.Mui-focusVisible": {
        // border: "2px solid #94A4D6",
        // borderRadius: "6px",
        "& .MuiIcon-root":{
          height: "28px",
          width: "28px",
        },
        "& span":{
          "& span":{
            "& img":{
              height: "28px",
              width: "28px",
              content: `url(${FocusNonChecked})`
            }
          }
        },
      },
      "& .MuiIconButton-label": {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
  });

  const iconStyles = (theme) => ({});

  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
  const CustomIcon = withStyles(iconStyles)(Icon);
  return (
    <>
      <CustomCheckbox
        disableRipple={true}
        icon={
          <CustomIcon disableRipple >
            <img className={error ? classes.errorIcon : classes.nonCheckedIcon}/>
          </CustomIcon>
        }
        checkedIcon={
          <CustomIcon disableRipple >
            <img className={classes.checkedIcon}/>
          </CustomIcon>
        }
        checked={checked}
        onChange={handleChange}
        name="marketingConsent"
      />
    </>
  );
};

export default ReusableCheckbox;
