import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PortableText from "./portableText";
import * as styles from "./companyReviewWidget.module.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Portal,
  Grid,
  ClickAwayListener,
  Button,
  Typography,
} from "@material-ui/core";
import RateCompanyForm from "./popups/rateCompanyForm";
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { checkIsCampusTheme } from "../lib/helpers";
import CampusCTA from "./landingPageComponents/blocks/reuseableCampusComponents/campusCTA";
import { useMediaQuery } from "@material-ui/core";

import { toggleBlur, toggleOff } from "../redux/reducers/blur"

//<a className={styles.pinkCta} href={cta.link}>{cta.title}</a>

const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: "fixed",
    width: 400,
    height: 537,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    zIndex: 999,
    borderRadius: "8px",
    padding: "1.5em",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    [theme.breakpoints.down("xs")]: {
      width: 335,
      padding: "16px",
      overflowY: 'scroll'

    },
    [theme.breakpoints.down(345)]: {
      width: 300,
      padding: "16px",
    },
  },
  backdrop: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
    background:
      "linear-gradient(90deg, rgba(96, 108, 136, 0.45) 0%, rgba(63, 76, 107, 0.45) 100%)",
    backdropFilter: "blur(5px)",
  },
  contactMeButton: {
    borderRadius: "8px",
    /* Drop shadow Mas */
    height: "56px",
    backgroundColor: '#FF0F5F',
    padding: "1em 2em",
    /* Drop shadow Mas */
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginLeft: '0',
    },
    marginLeft: '1.5em',
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: '#FF0F5F',
      boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
      '& span': {
        color: '#FF0F5F',
      }
    },
  },
  contactMeButtonText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#FFFFFF',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
    "&:hover": {
      color: '#FF0F5F',
    },
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  rateButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "& > div":{
      width: "100%",
    }
  },
  rateButtonLabel: {

    cursor: 'pointer',
    padding: '1em 2em 1em 2em',

  },
  leftTextPara:{
      margin: "0 !important",
  },
}));

const CompanyReviewWidget = ({ leftSideText, buttonText, company, dispatch, isBlur }) => {
  const classes = useStyles();
  const location = useLocation();

  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  const [open, setOpen] = React.useState(false);
  //console.log('company: ', company);
  const handleClick = () => {
    setOpen((prev) => !prev);
    dispatch(toggleBlur())
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const matchesTabletUpwards = useMediaQuery('(min-width: 599px)')
  //console.log("tablet upwards", matchesTabletUpwards)
  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true);
      setIsCampusTheme(isCampusTheme);
    })();
  }, [])

  useEffect(() => {
    (async () => {
      var reviewQueryStringValue = queryString.parse(location.search).review;

      if (reviewQueryStringValue != null && reviewQueryStringValue != undefined && reviewQueryStringValue != '') {
        setOpen(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!open){
      dispatch(toggleOff())
    }
    (async () => {
      if (open) {
        if (window.dataLayer) {
          var model = {
            Source: 'Rate provider widget',
            CompanyName: company.companyDisplayName
          }

          window.dataLayer.push({ 'company_review_popup_open_value': JSON.stringify(model) });

          window.dataLayer.push({ event: 'company_review_popup_open' });
        }
      }
    })();
  }, [open]);

  return (
    finishedCheckingTheme ?
    <Grid
      container
      className={isCampusTheme ? styles.ctaWidgetContainerVisible : styles.ctaWidgetContainer}
      alignItems="center"
      justify="center"
    >
      {!isCampusTheme &&  <div className={styles.leftStripe}></div>}
      <Grid
        item
        spacing={3}
        xs={12}
        sm={7}
        md={7}
        lg={8}
        className={isCampusTheme ? styles.leftSideTextContainerVisible : styles.leftSideTextContainer}
      >
        <p className={isCampusTheme ? classes.leftTextPara : null}>
          <PortableText blocks={leftSideText} />
        </p>
      </Grid>

      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        lg={4}
        spacing={3}
        className={isCampusTheme ? styles.anchorContainerVisible : styles.anchorContainer}
      >

        <div className={classes.contactMeButtonContainer}>
          {open && <div className={classes.backdrop} />}
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.rateButtonContainer}>
              {!isCampusTheme ?
                <Button className={`${classes.contactMeButton}`} onClick={handleClick}>
                  <Typography
                    variant="button"
                    className={`${classes.contactMeButtonText}`}
                  >
                    {buttonText}
                  </Typography>
                </Button>
                :
                <CampusCTA pageType={matchesTabletUpwards ? "regular" : "available courses"} linkText={buttonText}
                           buttonType={"circular"} arrowIcon={false} customClickEvent={handleClick} variant={"large"} />
              }
              {open ? (
                <Portal>
                  <Grid className={classes.dropdown}>
                    <RateCompanyForm onClickingAwayHandler={handleClickAway} companyId={company.dashboardId} source={'Rate provider widget'} companyName={company.companyDisplayName}/>
                  </Grid>
                </Portal>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      </Grid>
    </Grid>
      : <></>
  );
};

export default connect(state => ({
  isBlur: state.blurReducer.isBlur
}), null)(CompanyReviewWidget);
