import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import PortableText from "../../portableText";
import clientConfig from "../../../../client-config";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";
import ContainerForLinks from "../../containerForLinks";
import Container from "../../container";
import serializers from "../../serializers";
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
import { AddTrailingSlashToUrl } from "../../../lib/helpers";

import Rating from "@material-ui/lab/Rating";
import StarIcon from "../../icon/Star.svg";
import EmptyStarIcon from "../../icon/StarUnfilled.svg";
import ShieldDone from "../../icon/ShieldDone.svg";
import TickSquare from "../../icon/quiz-results-check.svg";
import CampusTickSquare from "../../icon/campus-tick.svg";
import ReadMoreChevron from "../../icon/read-more-chevron.svg";
import VisibleTick from "../../icon/visible-tick.svg";
import WouldRecommend from "./comparisonTableComponents/wouldRecommend";
import { withStyles } from '@material-ui/core/styles';

const StyledRating = withStyles((theme) => ({
  root: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))(Rating);

const useStyles = makeStyles((theme) => ({
  imgComp: {
    width: "140px",
    border: "1px solid #d8dadc",
    height: "140px"
  },
  compDiv: {
    /* marginRight: "10px",*/
    height: "140px",
    width: "140px",
    display: "inline-block",
    /* textAlign: "center",*/
    //border: "1px solid #d8dadc",
    borderRadius: "10px",
    backgroundColor: "#f5f7fa",
    overflow: "hidden"
  },
  holdingDiv: {
    overflow: "hidden",
    width: "100%",
    border: "1px solid #d8dadc",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "white",
    marginBottom: "10px",
  },
  nutshellDiv: {
    display: "inline-block",
    verticalAlign: "top",
    paddingLeft: "30px",
    paddingRight: "10px",
    width: "40%",
  },
  ratingsDiv: {
    display: "inline-block",
    verticalAlign: "top",
    paddingRight: "10px",
  },
  nsUL: {
    border: "0px",
    padding: "0px",
    margin: "0px",
    marginLeft: "18px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "8px",
    height: "100%",
  },
  paperContained: {
    textAlign: "center",
    //borderRadius: "8px",
    //boxShadow: "0px 2px 4px 0px #4040401A",
    height: "100%",
    //border: "1px solid #DBE3EF",
    //padding: "18px",
  },
  comparisonUnitContainer: {
    flexGrow: 1,
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "12px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    marginBottom: "24px",
    // height: "242px",
    maxWidth: "100%",
  },
  comparisonUnitImageContainer: {
    marginBottom: "12px",
    maxWidth: "100%",
  },
  comparisonUnitScoreContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    // minHeight: "90px"
  },
  inANutshellText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: '500',
    lineHeight: "20px",
    paddingLeft: "4px",
    paddingBottom: "2px",
    paddingTop: "2px",
    width: "91%",
    [theme.breakpoints.down(1290)]: {
      paddingBottom: '0',
      paddingTop: '9px',
      lineHeight: '1em',
      width: '88%'
    },
    [theme.breakpoints.down(1090)]: {
      width: '81%'
    }
  },
  leftAlignText: {
    textAlign: "left",
  },
  weSayContainer: {
    backgroundColor: "#F5F7FA",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px 0px #4040401A",
    borderRadius: "6px",
    padding: "8px",
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      justifyContent: "center",
    },
  },
  weSayTitle: {
    fontSize: "18px",
    letterSpacing: "0.01em",
    lineHeight: "32px",
    fontWeight: "500",
    color: "#3C455C",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      paddingLeft: "1px",
      paddingRight: "1px",
    },
  },
  weSayResizer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      paddingLeft: "1px",
      paddingRight: "1px",
    },
  },
  mobileWeSayResizer: {
    position: 'initial',
    left: 'initial',
    transform: 'initial'
  },
  moreInfoButton: {
    padding: "16px 7px 16px 7px",

    "&:hover": {
      background: "#fff",
    },
  },
  reviewButton: {
    backgroundColor: "#FFF",
    width: "100%",
    padding: "16px 24px 16px 24px",
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    color: '#3C465F',
    fontSize: '15px',
    border: '1px solid #3C465F',
    borderRadius: '0 !important',
    "&:hover": {
      background: "#FFF",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#38377A",
      fontFamily: 'Poppins'
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "33px",
    },
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    },
    [theme.breakpoints.down(900)]: {
      marginTop: "15px",
    }
  },

  visitSiteButtonAffiliate: {
    backgroundColor: "#FB755C",
    width: "100%",
    padding: "16px 24px 16px 24px",
    fontSize: '15px',
    borderRadius: '0 !important',
    "&:hover": {
      background: "#ffffff",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#38377A",
      fontFamily: 'Poppins'
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "33px",
    },
    marginTop: "25px",
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    }
  },
  visitSiteButton: {
    backgroundColor: "#FB755C",
    color: '#FFF',
    width: "100%",
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    fontSize: '15px',
    padding: "16px 24px 16px 24px",
    borderRadius: '0 !important',
    "&:hover": {
      background: "#FB755C",
    },
    "&:hover $buttonTextVisitSite": {
      color: "'#FFF",
      fontFamily: 'Poppins'
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "initial",
    }
    ,
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    }
  },
  buttonTextMoreInfo: {
    color: "#3C465F",
    backgroundColor: "#FFF",
    textTransform: 'uppercase',
    fontSize: '15px',
    [theme.breakpoints.down(1290)]: {
      fontSize: '13.92px'
    }
  },
  buttonTextVisitSite: {
    color: "#fff",
    textTransform: 'uppercase',
    [theme.breakpoints.down(1290)]: {
      fontSize: '13.92px'
    }
  },
  sectionTitle: {
    color: "#38377A",
    fontWeight: "700",
    paddingBottom: "5px",
    textTransform: 'uppercase',
    fontFamily: 'Poppins',
    fontSize: '15px',
    [theme.breakpoints.down(1290)]: {
      fontSize: '15x',
      paddingBottom: '3px',
      lineHeight: '1em'
    },
    [theme.breakpoints.down(900)]: {
      fontSize: '14px'
    }
  },
  ratingSectionTitle: {
    color: "#38377A",
    fontWeight: "700",
    textTransform: 'uppercase',
    fontSize: '12px',
    textAlign: 'center',
    fontFamily: 'Poppins',
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: '12px'
    }
    ,
    [theme.breakpoints.down(900)]: {
      fontSize: '14px'
    }
  },
  mobileRatingSectionTitle: {
    color: "#38377A",
    fontWeight: "700",
    textTransform: 'uppercase',
    fontSize: '12px',
    textAlign: 'left',
    fontFamily: 'Poppins',
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: '12px'
    }
    ,
    [theme.breakpoints.down(900)]: {
      fontSize: '14px'
    }
    ,
    [theme.breakpoints.down(367)]: {
      fontSize: '13px'
    }
  },
  inANutshellContainer: {
    minHeight: "24px",
    [theme.breakpoints.down(900)]: {
      minHeight: "32px",
    },
    '& p': {
      color: '#38377A !important'
    }
  },
  comparisonTablePortableText: {
    "& p": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      letterSpacing: "0em",
      color: "#3C455C",
      paddingBottom: "12px !important",
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
      },
    },
  },
  comparisonTableWouldRecommend: {
    fontWeight: "500",
    color: "#3C455C",
    display: 'inline-block',
    fontSize: '1em',
    lineHeight: '24px',
    whiteSpace: 'break-spaces'
  },
  comparisonTableBasedOn: {
    color: "#8C8CA1",
    lineHeight: "24px",
    fontWeight: "500",
    display: 'inline-block',
    fontSize: '.85em'
  },
  comparisonUnitFirst: {
    [theme.breakpoints.up("md")]: {
      maxWidth: '19.15%'
    },
  },
  comparisonUnitSecond: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "37.183727%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  comparisonUnitThird: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "19.51006124234471%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  comparisonUnitFourth: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "24.14698162729659%",
    },
  },
  buttonContainerHelper1: {
    // [theme.breakpoints.down("sm")]: {
    //   order: "2",
    // },
    [theme.breakpoints.down(1340)]: {
      width: "100%",
      order: "2",
    },
    textDecoration: "none",
  },
  buttonContainerHelper2: {
    // [theme.breakpoints.down("sm")]: {
    //   order: "1",
    // },
    [theme.breakpoints.down(1340)]: {
      width: "100%",
      order: "1",
    },
    "&:hover":{
      textDecoration: "none !important",
    },
    textDecoration: "none",
    width: "100%",
  },
  scale: {
    padding: "3px",
    [theme.breakpoints.down(1290)]: {
      padding: '1px',
      width: '20px'
    },
    [theme.breakpoints.down(1200)]: {
      padding: '1px',
      width: '19px'
    },
    [theme.breakpoints.down(1160)]: {
      padding: '1px',
      width: '18px'
    },
    [theme.breakpoints.down(1100)]: {
      padding: '1px',
      width: '17px'
    },
    [theme.breakpoints.down(1060)]: {
      padding: '1px',
      width: '16px'
    },
    [theme.breakpoints.down(1040)]: {
      padding: '1px',
      width: '15px'
    },
    [theme.breakpoints.down(1000)]: {
      padding: '1px',
      width: '14px'
    },
    [theme.breakpoints.down(980)]: {
      padding: '1px',
      width: '13px'
    },
    [theme.breakpoints.down(959)]: {
      padding: '1px',
      width: '20px'
    },
  },
  spinnerText: {
    textAlign: 'left',
    paddingLeft: '1em'
  },
  relativePositioned: {
    position: 'relative',
    height: '90px'
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '100%'
  },
  imageContainer: {
    width: '156px',
    height: '140px',
    display: 'block',
    float: 'left',
    paddingRight: '16px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down(1290)]: {
      width: '103px',
      height: '87px'
    }
  },
  nutshellContainer: {
    width: '54%',
    [theme.breakpoints.down(1290)]: {
      width: '54%'
    },
    [theme.breakpoints.down(1200)]: {
      width: '52%'
    },
    '& p': {
      color: '#3C465F !important'
    }
  },
  ratingContainer: {
    width: '21%',
    [theme.breakpoints.down(1200)]: {
      width: '23%'
    },
  },
  mobileRatingContainer: {
    height: '1.5em'
  },
  buttonsContainer: {
    minWidth: '25%',
    width: '20%',
    [theme.breakpoints.down(1290)]: {
    }
  },
  providerContainer: {
    background: '#FFFFFF',
    borderRadius: '32px',
    maxWidth: '100%',
    margin: '0',
    overflow: 'hidden',
    padding: '24px',
    boxShadow: "0px 6px 0px #6464FF",
    position: 'relative',
    marginBottom: '1em',
    border: '2px solid #6464FF',
    [theme.breakpoints.up(1290)]: {
      minHeight: '165px',
    },
    paddingLeft: 0,
    paddingRight: 0
  },
  sponsoredProviderContaier: {
    background: '#FFFFFF',
    borderRadius: '32px',
    maxWidth: '100%',
    margin: '0',
    overflow: 'hidden',
    padding: '24px',
    boxShadow: "0px 6px 0px #FB755C",
    position: 'relative',
    marginBottom: '1em',
    border: '2px solid #FB755C',
    [theme.breakpoints.up(1290)]: {
      minHeight: '165px',
    }
  },
  columnsContainer: {
    width: 'calc(100% - 156px)',
    display: 'block',
    float: 'right',
    [theme.breakpoints.down(1290)]: {
      width: 'calc(100% - 103px)',
    },
    '& div p': {
      color: '#3C465F !important'
    }
  },
  logoImage: {
    border: "1.13208px solid #D5D4F2",
    borderRadius: '24px',
    maxWidth: '100%',
    width: '140px',
    height: '140px',
    [theme.breakpoints.down(1290)]: {
      width: '87px',
      height: '87px'
    }
  },
  imageWrapper: {
    width: '100%'
  },
  buttonMarginTop: {
    marginTop: '25px',
    [theme.breakpoints.down(1290)]: {
      marginTop: '7.5px',
    }
  },
  ratingSectionTitlePaddedTop: {
    paddingTop: '1em'
  },
  tickSquare: {
    paddingRight: '.3em',

    [theme.breakpoints.down(1290)]: {
      height: '1em',
      marginTop: '9px'
    }
    ,

    [theme.breakpoints.down(900)]: {
      height: '1.4em',
    }
  },
  mobileProviderContainer: {
    boxShadow: '0px 6px 0px #6464FF',
    borderRadius: '28px',
    border: '2px solid #6464FF',
    backgroundColor: '#ffffff',
    marginBottom: '1em',
    '& div p': {
      color: '#3C465F !important'
    }
  },
  mobileSponsoredProviderContainer: {
    boxShadow: '0px 6px 0px #6464FF',
    borderRadius: '28px',
    border: '2px solid #6464FF',
    padding: '1em',
    backgroundColor: '#ffffff',
    marginBottom: '1em',
    '& div p': {
      color: '#3C465F !important'
    }
  },
  mobileLogoImage: {
    border: "0.783019px solid #D5D4F2",
    borderRadius: '16px',
    maxWidth: '100%',
    width: '110px',
    height: '110px'
  },
  ratingWrapper: {
    width: '100%',
    position: 'relative',
    height: '2em'
  },
  positionRelative: {
    position: 'relative'
  },
  mobileNutshellContainer: {
    padding: '.25em 1em 1em 1em'
  },
  mobileTopButtonPadding: {
    paddingTop: '1em 1em 0 1em'
  },
  awardImage: {
    width: '100px',
    height: '100px',
    [theme.breakpoints.down(1163)]: {
      width: '80px',
      height: '80px'
    },
    [theme.breakpoints.down(1085)]: {
      width: '70px',
      height: '70px'
    },
    [theme.breakpoints.down(1013)]: {
      width: '60px',
      height: '60px'
    },
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  mobileAwardImage: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100px',
    height: '100px',
    [theme.breakpoints.down(1300)]: {

    },
    [theme.breakpoints.down(367)]: {
    }
  },
  awardImageContainer: {
    display: 'block',
    width: '100%',
    position: 'relative',
    height: '100px',
    [theme.breakpoints.down(1163)]: {
      height: '80px'
    },
    [theme.breakpoints.down(1085)]: {
      height: '70px'
    },
    [theme.breakpoints.down(1013)]: {
      height: '60px'
    },
    [theme.breakpoints.down(959)]: {
      height: '100px'
    }
  },
  mobileAwardImageContainer: {
    width: '100px'
  },
  mobileOurRatingContainer: {
    width: '65%',
    float: 'right',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '122px',
    '& div p': {
      color: '#38377A !important'
    }
  },
  mobileLogoContainer: {
    width: '110px',
    float: 'left'
  },
  mobileLeftSide: {
    width: 'calc(100% - 100px)',
    position: 'relative'
  },
  mobileCompanyNameContainer: {
    padding: '1em'
  },
  mobileSecondRowContainer: {
    padding: '0 1em .3em 1em'
  },
  readMoreContainer: {
  },
  positionRelative: {
    position: 'relative',
    minHeight: '141px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  readMoreAnchor: {
    fontSize: '13px',
    lineHeight: '18px',
    color: '#3C465F',
    fontWeight: 500,
    fontFamily: 'Poppins',
    cursor: 'pointer',
  },
  mobileReadMoreAnchor: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#3C465F',
    fontWeight: 500,
    fontFamily: 'Poppins'
  },
  readMoreChevron: {
    verticalAlign: 'middle',
    paddingLeft: '10px',
    cursor: 'pointer',
  },
  providerContainerWithReadMore: {
    paddingBottom: '10px !important'
  },
  readMoreButtonContainer: {
    paddingTop: '5px',
    paddingLeft: '51px',
    [theme.breakpoints.down(1290)]: {
      paddingLeft: 24
    },
  },
  mobileReadMoreButtonContainer: {
    paddingTop: '16px',
    paddingBottom: '16px',
    textAlign: 'center',
  },
  readMoreTextContainer: {
    paddingTop: '20px',
    borderTop: '1px solid #D4D4D4',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  readMoreButtonContainerOpen: {
    paddingBottom: '20px'
  },
  mobileLeftRightPadding: {
    padding: '0 1em 0 1em'
  },
  mobileReadMoreTextContainer: {
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: '20px',
    borderTop: '1px solid #D4D4D4',
  }
}));

export default function RecProviders(props) {

  //console.log(props)
  const { isSponsoredProvider } = props;
  const { companyName, companyLogo, linkRef, linkRefVisible, linkRefReview, linkRefReviewVisible, info, infoVisible, landingVisible, postVisible, landing, post, infoReview, visibleReadMoreText, infoReviewVisible, landingReview, landingReviewVisible, postReview, postReviewVisible, inANutshell, inANutshellVisible, ourRating, yourRating, partner, awardLogo, awardLogoVisible, companyDisplayName } = props.company;
  const classes = useStyles();
  var linkHref = "";
  var linkHrefReview = "";
  //console.log('visibleReadMoreText: ', visibleReadMoreText);
  const materialUiScoreOur = ourRating / 2;
  const materialUiScoreYour = yourRating / 2;
  const smallDistanceStyle = {};
  const [isReadMoreOpen, setIsReadMoreOpen] = useState(false);

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});


  //external link
  if (linkRefVisible.length > 0) {
    linkHref = linkRefVisible[0].url
  }

  //landing page
  if ((landingVisible != null) && (landingVisible.length > 0)) {
    linkHref = landingVisible[0].subfolder[0].folder + landingVisible[0].slug
  }

  //info page
  if ((infoVisible != null) && (infoVisible.length > 0)) {
    linkHref = infoVisible[0].subfolder[0].folder + infoVisible[0].slug
  }

  //article page
  if ((postVisible != null) && (postVisible.length > 0)) {
    linkHref = "/learn/articles/" + postVisible[0].slug
  }


  //external link
  if (linkRefReviewVisible.length > 0) {
    linkHrefReview = linkRefReviewVisible[0].url
  }

  //landing page
  if ((landingReviewVisible != null) && (landingReviewVisible.length > 0)) {
    linkHrefReview = landingReviewVisible[0].subfolder[0].folder + landingReviewVisible[0].slug
  }

  //info page
  if ((infoReviewVisible != null) && (infoReviewVisible.length > 0)) {
    linkHrefReview = infoReviewVisible[0].subfolder[0].folder + infoReviewVisible[0].slug
  }

  //article page
  if ((postReviewVisible != null) && (postReviewVisible.length > 0)) {
    linkHrefReview = "/learn/articles/" + postReviewVisible[0].slug
  }

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'recommended_provider_card_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'recommended_provider_card_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);


  var leaveReviewButtonClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'leave review button',
      CompanyName: companyName
    });
  }

  var visitSiteButtonClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'visit site button',
      CompanyName: companyName
    });
  }

  var logoClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'logo',
      CompanyName: companyName
    });
  }

  try {

    return (
      <>
        <Grid container>
          <Grid item>
          </Grid>
        </Grid>
        <Hidden smDown implementation="js">

          <div className={`${isSponsoredProvider ? classes.sponsoredProviderContaier : classes.providerContainer} ${visibleReadMoreText.length > 0 ? classes.providerContainerWithReadMore : ''}`} >
            <Grid container>
              <Grid item xs={12} className={classes.positionRelative}>
                <div className={classes.imageContainer}>
                  <Grid container alignItems="center" justify="center" >
                    <Grid item className={classes.imageWrapper} >
                      <a href={AddTrailingSlashToUrl(linkHref)}
                        onClick={() => { logoClicked() }}
                      >
                        <img src={imageUrlFor(buildImageObj(companyLogo))
                          .width(100)
                          .height(100)
                          .fit("crop")
                          .auto("format")
                          .url()}
                          alt={companyName}
                          className={classes.logoImage}
                        />
                      </a>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.columnsContainer}>
                  <Grid container alignItems="center" justify="flex-end" >

                    <Grid item className={classes.nutshellContainer}>
                      <Typography variant="body1" className={classes.sectionTitle}>
                        {companyDisplayName}

                      </Typography>

                      {inANutshellVisible && inANutshellVisible.map((text) => {
                        return (
                          <Grid
                            container
                            alignItems="center"
                            justify="flex-start"
                            className={classes.inANutshellContainer}
                            key={text}
                          >
                            <img src={VisibleTick} alt="TickSquare" className={classes.tickSquare} />
                            <Typography
                              variant="body1"
                              className={classes.inANutshellText}
                            >
                              {text}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item className={classes.ratingContainer}>
                      {
                        awardLogoVisible.length === 1 && awardLogoVisible[0].providerPageLogo &&
                        <div className={classes.awardImageContainer}>
                          <img src={imageUrlFor(buildImageObj(awardLogoVisible[0].providerPageLogo))
                            .width(100)
                            .height(100)
                            .fit("crop")
                            .auto("format")
                            .url()}
                            alt={companyName}
                            className={classes.awardImage}
                          />
                        </div>
                      }
                    </Grid>
                    <Grid item className={classes.buttonsContainer}>
                      <Grid
                        container
                        className={classes.paper}
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                      >

                        <Grid container justify="space-between">
                          {linkHrefReview && (
                            <a href={AddTrailingSlashToUrl(linkHrefReview)}
                              className={classes.buttonContainerHelper2}
                              onClick={() => { leaveReviewButtonClicked() }}
                            >
                              <Button className={classes.reviewButton}>
                                <Typography
                                  variant="button"
                                  className={classes.buttonTextMoreInfo}
                                >

                                  Our Review

                      </Typography>
                              </Button>
                            </a>
                          )}
                          {linkHref && (
                            <a href={AddTrailingSlashToUrl(linkHref)}
                              className={classes.buttonContainerHelper2}
                              onClick={() => { visitSiteButtonClicked() }}
                            >

                              <Button
                                className={`${partner == true
                                  ? classes.visitSiteButtonAffiliate
                                  : classes.visitSiteButton
                                  } ${linkHrefReview ? classes.buttonMarginTop : ''}`}
                              >
                                <Typography
                                  variant="button"
                                  className={classes.buttonTextVisitSite}
                                >

                                  Visit Site

                      </Typography>
                              </Button>
                            </a>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.readMoreContainer}>
                  {
                    visibleReadMoreText && visibleReadMoreText.length > 0 &&
                    <div className={`${classes.readMoreButtonContainer} ${isReadMoreOpen ? classes.readMoreButtonContainerOpen : ''}`} onClick={() => { setIsReadMoreOpen(!isReadMoreOpen) }}>
                      <label className={classes.readMoreAnchor}>{isReadMoreOpen ? 'Read less' : 'Read more'}</label>
                      <img className={classes.readMoreChevron} src={ReadMoreChevron} />
                    </div>
                }
                  {
                    isReadMoreOpen && <div className={classes.readMoreTextContainer}><PortableText blocks={visibleReadMoreText} /></div>
                  }
                </div>
              </Grid>
            </Grid>
          </div>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Grid container className={isSponsoredProvider ? classes.mobileSponsoredProviderContainer : classes.mobileProviderContainer}>
            <Grid className={classes.mobileCompanyNameContainer} item xs={12}>
              <Typography variant="body1" className={classes.sectionTitle}>
                {companyDisplayName}

              </Typography>
            </Grid>
            <Grid className={classes.mobileSecondRowContainer} item xs={12}>
              <Grid container>
                <div className={classes.mobileLeftSide}>
                  <div className={classes.mobileLogoContainer}>
                    <a href={AddTrailingSlashToUrl(linkHref)}
                      onClick={() => { logoClicked() }}
                    >
                      <img src={imageUrlFor(buildImageObj(companyLogo))
                        .width(100)
                        .height(100)
                        .fit("crop")
                        .auto("format")
                        .url()}
                        alt={companyName}
                        className={classes.mobileLogoImage}
                      />
                    </a>
                  </div>
                  <div className={classes.mobileOurRatingContainer} >
                    <Grid container alignItems="center">
                      <Grid item xs={12} className={classes.positionRelative}>

                      </Grid>


                    </Grid>

                  </div>
                </div>
                <div className={classes.mobileAwardImageContainer}>
                  {
                    awardLogoVisible.length === 1 && awardLogoVisible[0].providerPageLogo &&
                    <div className={classes.awardImageContainer}>
                      <img src={imageUrlFor(buildImageObj(awardLogoVisible[0].providerPageLogo))
                        .width(100)
                        .height(100)
                        .fit("crop")
                        .auto("format")
                        .url()}
                        alt={companyName}
                        className={classes.mobileAwardImage}
                      />
                    </div>
                  }
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.mobileNutshellContainer}>


              {inANutshell.map((text) => {
                return (
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    className={classes.inANutshellContainer}
                    key={text}
                  >
                    <img src={VisibleTick} alt="TickSquare" className={classes.tickSquare} />
                    <Typography
                      variant="body1"
                      className={classes.inANutshellText}
                    >
                      {text}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12} className={classes.mobileTopButtonPadding}>
              {linkHref && (
                <a href={AddTrailingSlashToUrl(linkHref)}
                  className={classes.buttonContainerHelper2}
                  onClick={() => { visitSiteButtonClicked() }}
                >

                  <Button
                    className={`${partner == true
                      ? classes.visitSiteButtonAffiliate
                      : classes.visitSiteButton
                      } ${linkHrefReview ? classes.buttonMarginTop : ''}`}
                  >
                    <Typography
                      variant="button"
                      className={classes.buttonTextVisitSite}
                    >

                      Visit Site

                      </Typography>
                  </Button>
                </a>
              )}
            </Grid>
            <Grid item xs={12} className={classes.mobileLeftRightPadding}>
              {linkHrefReview && (
                <a href={AddTrailingSlashToUrl(linkHrefReview)}
                  className={classes.buttonContainerHelper2}
                  onClick={() => { leaveReviewButtonClicked() }}
                >
                  <Button className={classes.reviewButton}>
                    <Typography
                      variant="button"
                      className={classes.buttonTextMoreInfo}
                    >

                      Our Review

                      </Typography>
                  </Button>
                </a>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className={classes.readMoreContainer}>
                {
                  visibleReadMoreText && visibleReadMoreText.length > 0 &&
                  <div className={`${classes.mobileReadMoreButtonContainer} ${isReadMoreOpen ? classes.readMoreButtonContainerOpen : ''}`} onClick={() => { setIsReadMoreOpen(!isReadMoreOpen) }}>
                    <label className={classes.mobileReadMoreAnchor}>{isReadMoreOpen ? 'Read less' : 'Read more'}</label>
                    <img className={classes.readMoreChevron} src={ReadMoreChevron} />
                  </div>
                }
                {
                  isReadMoreOpen && <div className={classes.mobileReadMoreTextContainer}><PortableText blocks={visibleReadMoreText} /></div>
                }
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
    //return (
    //  <>
    //    <div className={classes.holdingDiv}>
    //  {/*<a href={linkHref}>*/}
    //    <div className={classes.compDiv}>

    //      <img src={imageUrlFor(buildImageObj(companyLogo))
    //        .width(100)
    //        .height(100)
    //        .fit("crop")
    //        .auto("format")
    //        .url()}
    //        alt={companyName}
    //        className={classes.imgComp}
    //      />
    //      {console.log("inANutshell", inANutshell)}
    //    </div>

    //    {/*  </a>*/}
    //      <div className={classes.nutshellDiv}>
    //        In a nutshell
    //      <ul className={classes.nsUL}>
    //      {inANutshell && inANutshell.map((ns, index) =>
    //        <li key={index}>{ns}</li>
    //      )}
    //      </ul>
    //    </div>
    //    <div className={classes.ratingsDiv}>
    //      <div>Our rating {ourRating}</div>
    //      <div>Your rating {yourRating}</div>
    //    </div>
    //    <div className={classes.ratingsDiv}>
    //      <div>Btn</div>
    //      <div>Btn</div>
    //    </div>
    // </div>
    // </>
    //)
  } catch (e) {
    return null;
  }

}
