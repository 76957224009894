import React, { useState, useEffect } from "react";
import AnchorMenu from "./sidebarComponents/anchorMenu";
import { getPageAnchor, } from "../lib/helpers";
import { Hidden } from "@material-ui/core";

const AnchorMenuMobile = () => {
  const [menuItems, setMenuItems] = useState();

  useEffect(async () => {
    var pgAnchor = await getPageAnchor();

    if (pgAnchor) {
      setMenuItems(pgAnchor[0].linkMenu);
    }
  }, []);

  return (
    <Hidden mdUp>
      <>
        {menuItems && menuItems[0] && <AnchorMenu isMobileAnchor={true} menuItems={menuItems[0]} />}
      </>
    </Hidden>
  );
};

export default AnchorMenuMobile;
