import React from "react";
import expandIcon from "./img/expand-icon.svg";
import { makeStyles } from "@material-ui/core/styles";

const ExpandIcon = () => {

  const useStyles = makeStyles((theme) => ({
    container: {
      background: '#3C465F',
      boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
      borderRadius: '8px',
      position: 'relative',
      height: '32px',
      width: '32px',
      marginLeft: "auto",
      marginTop: "16px",
      marginBottom: "16px",
    },
    img: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '45%',
    }
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <img className={classes.img} src={expandIcon} />
      </div>
    </>
  );
};

export default ExpandIcon;
