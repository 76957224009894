import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Checkbox, Icon } from "@material-ui/core";
import Checked from "./img/checked.svg";
import NonChecked from "./img/non-checked.svg";
import SearchImage from "./img/search.svg";
import * as styles from "./customerReviews.module.css";


const useStyles = makeStyles((theme) => ({
  checkedLabel: {
    cursor: "pointer",
    height: "100%",
    display: "block",
  },
  nonCheckedLabel: {
    cursor: "pointer",
    display: "block",
    height: "100%",
  },
  checkboxSpan: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#3C455C',
    [theme.breakpoints.down(1309)]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  progressBarBase: {
    background: '#F5F7FA',
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    borderRadius: '4px',
    position: 'absolute',
    left: 0,
    height: '1em',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  progressBar: {
    borderRadius: '4px',
    background: '#FFCB45',
    position: 'absolute',
    left: '0',
    height: '1em',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  progressBarContainer: {
    position: 'relative',
    width: '80%',
    [theme.breakpoints.down(1338)]: {
      width: '79%',
    },
    [theme.breakpoints.down(1269)]: {
      width: '72%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '66%',
    },
    [theme.breakpoints.down(1016)]: {
      width: '66%',
    },
    [theme.breakpoints.down(959)]: {
      width: '80%',
    },
    [theme.breakpoints.down(766)]: {
      width: '72%',
    },
    [theme.breakpoints.down(596)]: {
      width: '66%',
    },
    [theme.breakpoints.down(510)]: {
      width: '65%',
    },
    [theme.breakpoints.down(490)]: {
      width: '64%',
    },
    [theme.breakpoints.down(438)]: {
      width: '60%',
    },
    [theme.breakpoints.down(372)]: {
      width: '56%',
    },
    [theme.breakpoints.down(332)]: {
      width: '52%',
    },
    [theme.breakpoints.down(328)]: {
      width: '50%',
    },
  },
  percentageContainer: {
    width: '6%',
    textAlign: 'right',
    [theme.breakpoints.down(1269)]: {
      width: '9%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '12%',
    },
    [theme.breakpoints.down(1016)]: {
      width: '10%',
    },
    [theme.breakpoints.down(959)]: {
      width: '6%',
    },
    [theme.breakpoints.down(766)]: {
      width: '9%',
    },
    [theme.breakpoints.down(596)]: {
      width: '12%',
    },
    [theme.breakpoints.down(532)]: {
      width: '10%',
    },
    [theme.breakpoints.down(510)]: {
      width: '11%',
    },
    [theme.breakpoints.down(490)]: {
      width: '11%',
    },
    [theme.breakpoints.down(438)]: {
      width: '11%',
    },
    [theme.breakpoints.down(372)]: {
      width: '12%',
    },
    [theme.breakpoints.down(332)]: {
      width: '14%',
    },
  },
  checkboxContainer: {
    width: '14%',
    [theme.breakpoints.down(1338)]: {
      width: '15%',
    },
    [theme.breakpoints.down(1269)]: {
      width: '17%',
    },
    [theme.breakpoints.down(1100)]: {
      width: '22%',
    },
    [theme.breakpoints.down(1016)]: {
      width: '24%',
    },
    [theme.breakpoints.down(959)]: {
      width: '14%',
    },
    [theme.breakpoints.down(766)]: {
      width: '19%',
    },
    [theme.breakpoints.down(596)]: {
      width: '22%',
    },
    [theme.breakpoints.down(532)]: {
      width: '22%',
    },
    [theme.breakpoints.down(490)]: {
      width: '25%',
    },
    [theme.breakpoints.down(438)]: {
      width: '29%',
    },
    [theme.breakpoints.down(372)]: {
      width: '32%',
    },
    [theme.breakpoints.down(332)]: {
      width: '34%',
    },
    [theme.breakpoints.down(328)]: {
      width: '36%',
    },
  },
  filtersContainer: {
    background: '#FFFFFF',

    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    borderRadius: '8px'
  },
  filterItemContainer: {
    paddingLeft: '4px',
    paddingRight: '16px',
  },
  filterHeaderContainer: {
    borderBottom: '1px solid #DBE3EF'
  },
  filterHeaderLabel: {
    color: '#262641',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '8px',
    display: 'block'
  },
  percentageLabel: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    color: '#3C455C'
  },
  filterItemPaddedTop: {
    paddingTop: '3px'
  },
  searchBarcontainer: {
    borderTop: '1px solid #DBE3EF',
    position: 'relative',
    padding: '16px'
  },
  searchImage: {
    position: 'absolute',
    right: '24px',
    top: '24px',
    height: '32px',
    width: '32px',
    cursor: 'pointer'
  },
  paddingBottomThreePixels: {
    paddingBottom: '3px'
  }

}));

export default function CustomerReviewsFilters({ oneStar,
  twoStars,
  threeStars,
  fourStars,
  fiveStars,
  fiveStarsReviewsNumber,
  fourStarsReviewsNumber,
  threeStarsReviewsNumber,
  twoStarsReviewsNumber,
  oneStarReviewsNumber,
  allReviewsLength,
  HandleChange,
  HandleSearch}) {


  const [searchPhrase, setSearchPhrase] = useState('');

  const classes = useStyles();

  const searchButton = useRef(null);

  var handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      HandleSearch(searchPhrase);
    }
  }

  return (
    <>
      <Grid container className={classes.filtersContainer} id="filters-container">
        <Grid item xs={12} className={classes.filterHeaderContainer}>
          <label className={classes.filterHeaderLabel}>Filter reviews by customer rating</label>
        </Grid>
        <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
          <Grid container alignItems="center" justify="center" >
            <Grid item className={classes.checkboxContainer}>
              <label
                className={
                  fiveStars
                    ? classes.checkedLabel
                    : classes.nonCheckedLabel
                }
              >
                <Checkbox
                  icon={
                    <Icon>
                      <img src={NonChecked} height={18} width={18} />
                    </Icon>
                  }
                  checkedIcon={
                    <Icon>
                      <img src={Checked} height={18} width={18} />
                    </Icon>
                  }
                  checked={fiveStars}
                  onChange={HandleChange}
                  name="fiveStars"
                />
                <span className={classes.checkboxSpan}>5 star</span>
              </label>
            </Grid>
            <Grid item className={classes.progressBarContainer}>
              <div class={classes.progressBarBase}></div>
              <div class={classes.progressBar}
                style={{ width: Math.round((fiveStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
            </Grid>
            <Grid item className={classes.percentageContainer}>
              <label className={classes.percentageLabel}>{Math.round((fiveStarsReviewsNumber / allReviewsLength) * 100)}%</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.filterItemContainer}>
          <Grid container alignItems="center" justify="center" >
            <Grid item className={classes.checkboxContainer}>
              <label
                className={
                  fourStars
                    ? classes.checkedLabel
                    : classes.nonCheckedLabel
                }
              >
                <Checkbox
                  icon={
                    <Icon>
                      <img src={NonChecked} height={18} width={18} />
                    </Icon>
                  }
                  checkedIcon={
                    <Icon>
                      <img src={Checked} height={18} width={18} />
                    </Icon>
                  }
                  checked={fourStars}
                  onChange={HandleChange}
                  name="fourStars"
                />
                <span className={classes.checkboxSpan}>4 star</span>
              </label>
            </Grid>
            <Grid item className={classes.progressBarContainer}>
              <div class={classes.progressBarBase}></div>
              <div class={classes.progressBar}
                style={{ width: Math.round((fourStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
            </Grid>
            <Grid item className={classes.percentageContainer}>
              <label className={classes.percentageLabel}>{Math.round((fourStarsReviewsNumber / allReviewsLength) * 100)}%</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.filterItemContainer}>
          <Grid container alignItems="center" justify="center" >
            <Grid item className={classes.checkboxContainer}>
              <label
                className={
                  threeStars
                    ? classes.checkedLabel
                    : classes.nonCheckedLabel
                }
              >
                <Checkbox
                  icon={
                    <Icon>
                      <img src={NonChecked} height={18} width={18} />
                    </Icon>
                  }
                  checkedIcon={
                    <Icon>
                      <img src={Checked} height={18} width={18} />
                    </Icon>
                  }
                  checked={threeStars}
                  onChange={HandleChange}
                  name="threeStars"
                />
                <span className={classes.checkboxSpan}>3 star</span>
              </label>
            </Grid>
            <Grid item className={classes.progressBarContainer}>
              <div class={classes.progressBarBase}></div>
              <div class={classes.progressBar}
                style={{ width: Math.round((threeStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
            </Grid>
            <Grid item className={classes.percentageContainer}>
              <label className={classes.percentageLabel}>{Math.round((threeStarsReviewsNumber / allReviewsLength) * 100)}%</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.filterItemContainer}>
          <Grid container alignItems="center" justify="center" >
            <Grid item className={classes.checkboxContainer}>
              <label
                className={
                  twoStars
                    ? classes.checkedLabel
                    : classes.nonCheckedLabel
                }
              >
                <Checkbox
                  icon={
                    <Icon>
                      <img src={NonChecked} height={18} width={18} />
                    </Icon>
                  }
                  checkedIcon={
                    <Icon>
                      <img src={Checked} height={18} width={18} />
                    </Icon>
                  }
                  checked={twoStars}
                  onChange={HandleChange}
                  name="twoStars"
                />
                <span className={classes.checkboxSpan}>2 star</span>
              </label>
            </Grid>
            <Grid item className={classes.progressBarContainer}>
              <div class={classes.progressBarBase}></div>
              <div class={classes.progressBar}
                style={{ width: Math.round((twoStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
            </Grid>
            <Grid item className={classes.percentageContainer}>
              <label className={classes.percentageLabel}>{Math.round((twoStarsReviewsNumber / allReviewsLength) * 100)}%</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.paddingBottomThreePixels}`}>
          <Grid container alignItems="center" justify="center" >
            <Grid item className={classes.checkboxContainer}>
              <label
                className={
                  oneStar
                    ? classes.checkedLabel
                    : classes.nonCheckedLabel
                }
              >
                <Checkbox
                  icon={
                    <Icon>
                      <img src={NonChecked} height={18} width={18} />
                    </Icon>
                  }
                  checkedIcon={
                    <Icon>
                      <img src={Checked} height={18} width={18} />
                    </Icon>
                  }
                  checked={oneStar}
                  onChange={HandleChange}
                  name="oneStar"
                />
                <span className={classes.checkboxSpan}>1 star</span>
              </label>
            </Grid>
            <Grid item className={classes.progressBarContainer}>
              <div class={classes.progressBarBase}></div>
              <div class={classes.progressBar}
                style={{ width: Math.round((oneStarReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
            </Grid>
            <Grid item className={classes.percentageContainer}>
              <label className={classes.percentageLabel}>{Math.round((oneStarReviewsNumber / allReviewsLength) * 100)}%</label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={`${classes.searchBarcontainer}`} xs={12}>
          <input placeholder="Search reviews" type="text" onChange={event => setSearchPhrase(event.target.value)} className={styles.searchBar} onKeyDown={handleKeyDown} />
          <img src={SearchImage} className={classes.searchImage} onClick={() => HandleSearch(searchPhrase)} ref={searchButton}/>
        </Grid>
      </Grid>
    </>
  );
};
