import React, { useState, useEffect } from "react";
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/core/styles";
import ButtonIcon from "../icon/ArrowRightSquare.svg";
import WhiteButtonIcon from "../icon/whiteArrowRightSquare.svg";
import {
  Portal,
  Grid,
  ClickAwayListener,
  Button,
  Typography,
} from "@material-ui/core";
import ContactForm from "./contactForm";

import { toggleBlur, toggleOff } from "../../redux/reducers/blur"
import CampusCTA from "../landingPageComponents/blocks/reuseableCampusComponents/campusCTA";
import PinkButtonIcon from "../icon/whiteArrowRightPinkHover.svg"

const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: "fixed",
    width: 400,
    height: 525,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    zIndex: 999,
    borderRadius: "8px",
    padding: "24px",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    [theme.breakpoints.down("xs")]: {
      width: 335,
      padding: "16px",
    },
    [theme.breakpoints.down(345)]: {
      width: 300,
      padding: "16px",
    },
  },
  backdrop: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
    background:
      "linear-gradient(90deg, rgba(96, 108, 136, 0.45) 0%, rgba(63, 76, 107, 0.45) 100%)",
    backdropFilter: "blur(5px)",
  },
  contactMeButton: {
    borderRadius: "8px",
    width: "220px",
    height: "56px",
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginTop: "28px",
    },
  },
  contactMeButtonHalfMarginTop: {
    borderRadius: "8px",
    width: "220px",
    height: "56px",
    [theme.breakpoints.down("lg")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginTop: "16px",
    },
  },
  pinkContactMeButton: {
    backgroundColor: "#FF0F5F",
    color: 'white',

    "&:hover":{
      backgroundColor: "#FFFFFF !important",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      "& .MuiTypography-button":{
        color: "#FF0F5F !important",
      }
    }
  },
  greyContactMeButton: {
    backgroundColor: "#DBE3EF",
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      background: '#3C465F',
      '& span': {
        color: 'white'
      }
    }
  },
  contactMeButtonText: {
    marginRight: "11px",
    color: "#50586C",
    '&:hover': {
      color: 'white'
    },
  },
  pinkContactMeButtonText: {
    marginRight: "11px",
    color: '#FFFFFF',
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contactMeButtonContainerMd: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: '100% !important',
    [theme.breakpoints.up("md")]: {
      height: "48px",
    },
  },
  buttonIcon:{
    height: "21px",
  },
}));
function ContactMeButton({ buttonText, dashboardId, fullWidthButtonContainer, pinkCta, halfButtonPaddingTop, isBlur, dispatch, isRecommendedAdviserButton, nameOfAdviser, isCoursePage, isAdviserProfile}) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isHover, setIsHovering] = useState(false)
  const handleClick = () => {
    setOpen((prev) => !prev);
    dispatch(toggleBlur());
  };

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () =>{
    setIsHovering(false)
  }

  useEffect(() => {
    if (!open){
      dispatch(toggleOff())
    }
    (async () => {
      if (open) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'message_popup_open_value': window.location.href });

          window.dataLayer.push({ event: 'message_popup_open' });

          if (isRecommendedAdviserButton) {
            var googleAnalyticsObject = {
              Source: 'contact button',
              AdviserName: nameOfAdviser
            }

            window.dataLayer.push({ 'recommended_adviser_card_clicked_value': JSON.stringify(googleAnalyticsObject) });

            window.dataLayer.push({ event: 'recommended_adviser_card_clicked' });
          }
        }
      }
    })();
  }, [open]);

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div className={`${halfButtonPaddingTop ? classes.contactMeButtonContainerMd : classes.contactMeButtonContainer} ${fullWidthButtonContainer ? classes.fullWidth : ''}`}>
      {open && <div className={classes.backdrop} />}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          {isCoursePage ? <CampusCTA linkText={"Contact"} buttonType={"rectangular"} variant={isAdviserProfile ? "large" : "medium"} pageType={"available courses"} arrowIcon={true} customClickEvent={handleClick} > </CampusCTA> :
          <Button onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`${halfButtonPaddingTop ? classes.contactMeButtonHalfMarginTop : classes.contactMeButton} ${pinkCta ? classes.pinkContactMeButton : classes.greyContactMeButton} ${fullWidthButtonContainer ? classes.fullWidth : ''}`} onClick={handleClick}>
            <Typography
              variant="button"
              className={`${pinkCta ? classes.pinkContactMeButtonText : classes.contactMeButtonText}`}
            >
              {buttonText}
            </Typography>
            <img className={classes.buttonIcon} src={pinkCta ? `${isHover ? PinkButtonIcon : WhiteButtonIcon}` : `${isHover ? WhiteButtonIcon : ButtonIcon}`} alt="ButtonIcon" />
          </Button>}
          {open ? (
            <Portal>
              <Grid className={classes.dropdown}>
                <ContactForm dashboardId={dashboardId} onClickingAwayHandler={handleClickAway} isCoursePage={isCoursePage} />
              </Grid>
            </Portal>
          ) : null}
        </div>
      </ClickAwayListener>
    </div>
  );
}

export default connect(state => ({
  isBlur: state.blurReducer.isBlur
}), null)(ContactMeButton)
