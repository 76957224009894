import React, { Component, useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Collapse,
  Divider,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useActiveId } from "./useActiveId";
import IconExpandMore from "../icon/IconExpandMore.svg";
import IconExpandLess from "../icon/IconExpandLess.svg";
import { Link } from "gatsby";

const useStyles = makeStyles((theme) => ({
  menuLink: {
    color: "#262641",
    textDecoration: "none",
  },
  subMenuLink: {
    color: "#8C8CA1",
    textDecoration: "none",
  },
  circle: {
    height: "32px",
    width: "32px",
    border: "0",
    marginLeft: "8px",

  },
  subMenuParent: {
    padding: "10px 20px",
    width: "100%",
    display: "inline-flex",
    flexWrap: "nowrap",
  },
  padding:{
    paddingTop: "15px"
  },
  animatedItem: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
    // boxShadow: "0px 1px 4px 0px #343F5140",
  },
  animatedItemExiting: {
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeInOut}`,
    boxShadow: "0",
  },
  list: {
    margin: "0",
  },
  listWithBorder:{
    borderBottom: "1px solid #D0D8E3",
    margin: "0",
    paddingBottom: "15px",
    '& :last-child': {
      borderBottom: '0'
    }
  },
  liColor: {
    color: "#50586C",
  },
  "@keyframes myEffect": {
    "0%": {
      transform: "rotate(-180deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      transform: "rotate(180deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));
function anchorify(name) {
  return "#" + name;
}

function AnchorMenuBody(props) {
  const classes = useStyles();
  const { tl, idList, last } = props;
  const [open, setOpen] = React.useState(null);
  const activeId = useActiveId(idList);
  function handleClick() {
    setOpen(!open);
  }


  return (
    <Grid>
      <Grid
        className={classes.subMenuParent}
        style={{
          backgroundColor: open ? "#ECF1F4" : "#ffffff",
          borderBottomLeftRadius: open ? '0' : '12px',
          borderBottomRightRadius: open? '0' : '12px'
        }}
        container
        item
        alignItems="center"
        justify="space-between"
      >
        <Typography variant="body1">
          <Link
            className={classes.menuLink}
            href={tl.reference !== null && tl.reference?.AnchorName && anchorify(tl.reference?.AnchorName)}
            // style={{ textDecoration: "none", color: "#262641" }}
            style={{
              fontWeight:
                activeId === (tl.reference !== null && tl.reference?.AnchorName)
                  ? "700"
                  : "400",
            }}
          >
            {tl.text}
          </Link>
        </Typography>
        {tl.menuChildLinks && tl.menuChildLinks.length !== 0 && (
          <IconButton
            onClick={handleClick}
            size="small"
            className={`${
              open
                ? classes.animatedItem
                : open === false && classes.animatedItemExiting
            } ${classes.circle}`}
          >
            {open && <img src={IconExpandLess} alt="IconExpandLess" />}{" "}
            {!open && <img src={IconExpandMore} alt="IconExpandMore" />}
          </IconButton>
        )}
      </Grid>
      <ul className={!open ? classes.list : classes.listWithBorder}
        style={{
          borderBottom: open ? (last ? '0' : '1px solid #D0D8E3') : '0'
        }}
      >
        {tl.menuChildLinks && tl.menuChildLinks.map((cl) => (
          <Collapse in={open} timeout="auto" unmountOnExit key={cl.text} className={classes.padding}>
            <li className={classes.liColor}>
              <Typography variant="body2">
                <Link
                  className={classes.subMenuLink}
                  href={anchorify(cl?.reference?.AnchorName)}
                  style={{

                    fontWeight:
                      activeId === cl?.reference?.AnchorName ? "700" : "500",
                  }}
                >
                  {cl.text}
                </Link>
              </Typography>
            </li>
          </Collapse>
        ))}
      </ul>
    </Grid>
  );
}

export default AnchorMenuBody;
