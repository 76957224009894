import React, { useRef, useState, useEffect } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import queryString from 'query-string';
import { createClient } from "../../../lib/helpers";
import { useLocation } from '@reach/router';
import PortableText from "../../portableText";
import ValueColumn from "./valueColumn";

const client = createClient();
const pageType = "companyCategory";

export default function ComparisonCriteriaWidget(props) {

  const {
    companyOne,
    companyTwo
  } = props;
  const useStyles = makeStyles((theme) => ({
    comparisonCriteriaTable: {
      filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
      borderRadius: '8px',
      '& ol, ul': {
        marginBlockStart: '0 !important',
        marginBlockEnd: '0 !important',
        paddingInlineStart: '11px !important',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2F2F4E'
      },
      '& p': {
        paddingBottom: '0 !important',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#2F2F4E'
      }
    },
    nameColumn: {
      width: '168px',
      background: '#ECF1F4',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderBottom: '1px solid #DBE3EF',
      borderLeft: '1px solid #DBE3EF',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      [theme.breakpoints.down(1200)]: {
        width: '125px',
      },
      [theme.breakpoints.down(959)]: {
        width: '168px',
      },
      [theme.breakpoints.down(600)]: {
        width: '125px',
        fontSize: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '8px',
        paddingRight: '8px'
      },
      [theme.breakpoints.down(450)]: {
        width: '100px',
      },
      [theme.breakpoints.down(375)]: {
        width: '85px',
      },
    },
    valuesColumn: {
      width: 'calc(100% - 168px)',
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 125px)',
      },
      [theme.breakpoints.down(959)]: {
        width: 'calc(100% - 168px)',
      },
      [theme.breakpoints.down(600)]: {
        width: 'calc(100% - 125px)',
      },
      [theme.breakpoints.down(450)]: {
        width: 'calc(100% - 100px)',
      },
      [theme.breakpoints.down(375)]: {
        width: 'calc(100% - 85px)',
      },
    },

    topLeftColumn: {
      borderTopLeftRadius: '8px'
    },
    bottomLeftColumn: {
      borderBottomLeftRadius: '8px'
    },
    bottomColumn: {
      borderBottom: '1px solid #DBE3EF'
    },
    topRightColumn: {
      borderTopRightRadius: '8px'
    },
    bottomRightColumn: {
      borderBottomRightRadius: '8px'
    },
    noBorderBottom: {
      borderBottom: '0 !important'
    }
  }));
  const classes = useStyles();
  const location = useLocation();

  const [companies, setCompanies] = useState([]);
  const [mappedCriteria, setMappedCriteria] = useState([]);

  useEffect(() => {
    (async () => {

      await getComps();

    })();
  }, []);

  var getComps = async function () {

    var companyId1 = '';
    var companyId2 = '';

    if (props.companyOne !== null && typeof props.companyOne !== 'undefined') {
      companyId1 = props.companyOne.dashboardId;
    } else {
      companyId1 = queryString.parse(location.search).company1;
    }

    if (props.companyTwo !== null && typeof props.companyTwo !== 'undefined') {
      companyId2 = props.companyTwo.dashboardId;
    } else {
      companyId2 = queryString.parse(location.search).company2;
    }

    var comps = await getCompsList(companyId1, companyId2);

    //console.log('criteria:', comps);

    if (!comps) {
      return false;
    }

    setCompanies(comps);

    var mappedCriteria = [];

    mappedCriteria.push({
      name: 'logo',
      order: 0,
      companyOneValue: {
        Logo: comps[0].companyLogo,
        LinkHref: getReviewLink(comps[0].infoReview)
      },
      companyTwoValue: {
        Logo: comps[1].companyLogo,
        LinkHref: getReviewLink(comps[1].infoReview)
      }
    });

    mappedCriteria.push({
      name: 'Provider',
      order: 1,
      companyOneValue: comps[0].companyDisplayName,
      companyTwoValue: comps[1].companyDisplayName
    });

    mappedCriteria.push({
      name: 'Our rating',
      order: 2,
      companyOneValue: comps[0].ourRating,
      companyTwoValue: comps[1].ourRating
    });

    mappedCriteria.push({
      name: 'Your rating',
      order: 3,
      companyOneValue: {
        Rating: comps[0].yourRating,
        NumberOfReviews: comps[0].noOfReviews,
        ReviewLink: getReviewLink(comps[0].infoReview)
      },
      companyTwoValue: {
        Rating: comps[1].yourRating,
        NumberOfReviews: comps[1].noOfReviews,
        ReviewLink: getReviewLink(comps[1].infoReview)
      },
    });


    mappedCriteria.push({
      name: 'Visit site',
      order: 4,
      companyOneValue: {
        VisitSiteHref: comps[0].bestBuyLink.url,
        IsAffiliate: comps[0].isAffiliate
      },
      companyTwoValue: {
        VisitSiteHref: comps[1].bestBuyLink.url,
        IsAffiliate: comps[1].isAffiliate
      },
    });

    var maxOrder = 4;

    if (comps[0].comparisonCriteria && comps[1].comparisonCriteria) {


      for (var i = 0; i < comps[0].comparisonCriteria.length; i++) {
        var orderValue = comps[0].comparisonCriteria[i].order;

        // because we've got 4 predefined criteria, if no order is set we default to 5
        var orderingNumber = isNaN(orderValue) ? 5 : orderValue + 4;

        if (orderingNumber > maxOrder) {
          maxOrder = orderingNumber;
        }

        mappedCriteria.push({
          name: comps[0].comparisonCriteria[i].name,
          order: orderingNumber,
          companyOneValue: comps[0].comparisonCriteria[i].text,
          companyTwoValue: null
        });
      }

      for (var i = 0; i < comps[1].comparisonCriteria.length; i++) {
        var criteriaName = comps[1].comparisonCriteria[i].name;
        var orderValue = comps[1].comparisonCriteria[i].order;

        // because we've got 4 predefined criteria, if no order is set we default to 5
        var orderingNumber = isNaN(orderValue) ? 5 : orderValue + 4;

        if (orderingNumber > maxOrder) {
          maxOrder = orderingNumber;
        }

        if (comps[0].comparisonCriteria.some(e => e.name === criteriaName)) {
          for (var j = 0; j < mappedCriteria.length; j++) {
            if (mappedCriteria[j].name === criteriaName) {
              mappedCriteria[j].companyTwoValue = comps[1].comparisonCriteria[i].text
            }
          }
        } else {
          mappedCriteria.push({
            name: criteriaName,
            order: orderingNumber,
            companyOneValue: null,
            companyTwoValue: comps[1].comparisonCriteria[i].text
          });
        }
      }
    }

    // last 2 predefined criteria need to have order > maxOrder
    mappedCriteria.push({
      name: 'Visit site',
      order: maxOrder + 1,
      companyOneValue: {
        VisitSiteHref: comps[0].bestBuyLink.url,
        IsAffiliate: comps[0].isAffiliate
      },
      companyTwoValue: {
        VisitSiteHref: comps[1].bestBuyLink.url,
        IsAffiliate: comps[1].isAffiliate
      },
    });

    mappedCriteria.push({
      name: 'Read more',
      order: maxOrder + 2,
      companyOneValue: getReviewLink(comps[0].infoReview),
      companyTwoValue: getReviewLink(comps[1].infoReview),
    });

    var sortedCriteria = mappedCriteria.sort(criteriaSortFunction);

    setMappedCriteria(sortedCriteria);

    //console.log('mapped criteria: ', mappedCriteria, '. sorted: ', sortedCriteria);
  }

  var criteriaSortFunction = function (a, b) {
    return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
  };

  var getCompsList = async function (companyId1, companyId2) {
    //console.log('criteria: ', companyId1, '. 2: ', companyId2);

    if (!companyId1 || !companyId2 || companyId1 === '' || companyId2 === '') {
      return false;
    }

    const params = {
      pageType: pageType,
      compId1: companyId1,
      compId2: companyId2
    }

    var query = '*[_type == $pageType && defined(publishedAt)  && dashboardId in [$compId1,$compId2]]{companyName,companyDisplayName,companyLogo,ourRating,yourRating,noOfReviews,"bestBuyLink": reference -> { url }, "isAffiliate": partner,'
      + 'comparisonCriteria[]{"name": comparisonCriteria->name, "order": comparisonCriteria->order, text},'
      + '"linkRef": * [_type == "linkCategory" && _id == ^.reference._ref]{ url },'
      + '"post": * [_type == "post" && _id == ^.reference._ref]{ "slug": slug.current },'
      + '"landing": * [_type == "landingPage" && _id == ^.reference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"info": * [_type == "information" && _id == ^.reference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"linkRefReview": * [_type == "linkCategory" && _id == ^.reviewReference._ref]{ url },'
      + '"postReview": * [_type == "post" && _id == ^.reviewReference._ref]{ "slug": slug.current },'
      + '"landingReview": * [_type == "landingPage" && _id == ^.reviewReference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"infoReview": * [_type == "information" && _id == ^.reviewReference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } }'
      + ' }| order(ourRating desc)'

    return client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  var getReviewLink = function (infoReview) {
    if (!infoReview || infoReview.length === 0) {
      return null;
    }

    return infoReview[0].subfolder[0]["folder"] + infoReview[0]["slug"];
  }

  return (
    <>
      <Grid container className={classes.comparisonCriteriaTable}>
        {
          mappedCriteria && mappedCriteria.map((criteria, index) => (
            <>
              <Grid item className={`${classes.nameColumn} ${index === 0 ? classes.topLeftColumn : ''} ${index === mappedCriteria.length - 1 ? classes.bottomLeftColumn : ''} ${index === mappedCriteria.length - 1 ? classes.bottomColumn : ''} ${criteria.name === 'Visit site' ? classes.noBorderBottom : ''}`}>
                <label>
                  {criteria.name !== 'logo' && criteria.name !== 'Visit site' && criteria.name !== 'Read more' ? criteria.name : ''}
                </label>
              </Grid>
              <Grid container className={classes.valuesColumn}>
                <ValueColumn
                  bottomColumnClassName={index === mappedCriteria.length - 1 ? classes.bottomColumn : ''}
                  topRightColumnClassName={''}
                  bottomRightColumnClassName={''}
                  displayValue={criteria.companyOneValue}
                  noBorderBottomClassName={criteria.name === 'Visit site' ? classes.noBorderBottom : ''}
                  name={criteria.name}
                  companyName={companies.length > 0 ? companies[0].companyName : ''}
                />

                <ValueColumn
                  bottomColumnClassName={index === mappedCriteria.length - 1 ? classes.bottomColumn : ''}
                  topRightColumnClassName={index === 0 ? classes.topRightColumn : ''}
                  bottomRightColumnClassName={index === mappedCriteria.length - 1 ? classes.bottomRightColumn : ''}
                  displayValue={criteria.companyTwoValue}
                  name={criteria.name}
                  noBorderBottomClassName={criteria.name === 'Visit site' ? classes.noBorderBottom : ''}
                  companyName={companies.length > 0 ? companies[1].companyName : ''}
                />

              </Grid>
            </>
          ))
        }
      </Grid>
    </>
  )

}

