import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import { makeStyles } from "@material-ui/core/styles";
import { checkIsCampusTheme } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  leftPanelContainer: {
    marginRight: '24px',
    [theme.breakpoints.down(1078)]: {
      marginRight: 'initial'
    },
    [theme.breakpoints.down(959)]: {
      marginRight: '24px'
    },
    [theme.breakpoints.down(600)]: {
      marginRight: 'initial'
    },
  },
  rightPanelContainer: {
    width: 'calc(100% - 294px)',
    [theme.breakpoints.down(1078)]: {
      width: '100%'
    },
    [theme.breakpoints.down(959)]: {
      width: 'calc(100% - 294px)'
    },
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
  }
}));

const ProvidererReviewsWidget = ({ companyReference, companyReviewPageReference }) => {
  const [allReviews, setAllReviews] = useState([]);
  const [allNonEmptyReviews, setAllNonEmptyReviews] = useState([]);
  const [fiveStarsReviewsNumber, setFiveStarsReviewsNumber] = useState(0);
  const [fourStarsReviewsNumber, setFourStarsReviewsNumber] = useState(0);
  const [threeStarsReviewsNumber, setThreeStarsReviewsNumber] = useState(0);
  const [twoStarsReviewsNumber, setTwoStarsReviewsNumber] = useState(0);
  const [oneStarReviewsNumber, setOneStarReviewsNumber] = useState(0);
  const [overallServiceAverage, setOverallServiceAverage] = useState(0);
  const [websiteExperienceAverage, setWebsiteExperienceAverage] = useState(0);
  const [valueForMoneyAverage, setValueForMoneyAverage] = useState(0);

  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true);
      setIsCampusTheme(isCampusTheme);
    })();
  }, [])

  useEffect(() => {
    (async () => {
      var model = {
        CompanyId: companyReference.dashboardId,
        Offset: 0,
        ItemsPerPage: 999999999
      };

      var reviews = await callApiForReviews(model);
     // console.log('reviews: ', reviews);
      setAllReviews(reviews);

      setFiveStarsReviewsNumber(reviews.filter(fiveStarsFilterFunction).length);

      setFourStarsReviewsNumber(reviews.filter(fourStarsFilterFunction).length);

      setThreeStarsReviewsNumber(reviews.filter(threeStarsFilterFunction).length);

      setTwoStarsReviewsNumber(reviews.filter(twoStarsFilterFunction).length);

      setOneStarReviewsNumber(reviews.filter(oneStarFilterFunction).length);

      setWebsiteExperienceAverage(Math.round(reviews.reduce((acc, val) => {
        return acc + (val.WebsiteExperience / reviews.length);
      }, 0)));

      setOverallServiceAverage(Math.round(reviews.reduce((acc, val) => {
        return acc + (val.OverallService / reviews.length);
      }, 0)));

      setValueForMoneyAverage(Math.round(reviews.reduce((acc, val) => {
        return acc + (val.ValueForMoney / reviews.length);
      }, 0)));

      setAllNonEmptyReviews(reviews.filter(nonEmptyReviewsFilterFunction));
    })();
  }, []);

  var oneStarFilterFunction = function (review) {
    return review.StarRating >= 0 && review.StarRating < 3;
  };

  var twoStarsFilterFunction = function (review) {
    return review.StarRating >= 3 && review.StarRating < 5;
  };

  var threeStarsFilterFunction = function (review) {
    return review.StarRating >= 5 && review.StarRating < 7;
  };

  var fourStarsFilterFunction = function (review) {
    return review.StarRating >= 7 && review.StarRating < 9;
  };

  var fiveStarsFilterFunction = function (review) {
    return review.StarRating >= 9;
  };

  var nonEmptyReviewsFilterFunction = function (review) {
    return review.Review && review.Review !== '';
  };

  var callApiForReviews = async function (model) {

    return fetch(process.env.GATSBY_QUESTIONAPI + '/CompanyDisplayRatings/CompanyDisplayRatings', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  const classes = useStyles();

  return (
    finishedCheckingTheme ?
    <>
      <Grid container>
        <Grid item className={classes.leftPanelContainer}>
          <LeftPanel
            fiveStarsReviewsNumber={fiveStarsReviewsNumber}
            fourStarsReviewsNumber={fourStarsReviewsNumber}
            threeStarsReviewsNumber={threeStarsReviewsNumber}
            twoStarsReviewsNumber={twoStarsReviewsNumber}
            oneStarReviewsNumber={oneStarReviewsNumber}
            allReviewsLength={allReviews.length}
            overallServiceAverageScore={overallServiceAverage}
            valueForMoneyAverageScore={valueForMoneyAverage}
            websiteExperienceAverageScore={websiteExperienceAverage}
            isCampusTheme={isCampusTheme}
          />
        </Grid>
        <Grid item className={classes.rightPanelContainer}>
          <RightPanel isCampusTheme={isCampusTheme} reviews={allNonEmptyReviews.slice(0, 3)} companyReviewPageReference={companyReviewPageReference} />
        </Grid>
      </Grid>
    </> : <></>
  );
};

export default ProvidererReviewsWidget;
