import React, { useEffect, useState } from "react";
import OurRatingIcon from "./icon/our-rating.svg";
import OurRatingIconVisible from "./icon/our-rating-visible.svg"
import OurRatingBackground from "./icon/our-rating-background.png";
import { makeStyles } from "@material-ui/core/styles";
import * as styles from "./companyRatings.module.css";
import Rating from '@material-ui/lab/Rating';
import StarIcon from "./icon/Star.svg";
import StarIconVisible from "./icon/StarVisible.svg";
import EmptyStarIcon from "./icon/empty-star.svg";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";
import { styled } from '@mui/system';
import useStarIcons from "./starIcons"

import { checkIsCampusTheme } from "../lib/helpers";

import {
  compose,
  spacing,
  palette,
  styleFunctionSx
} from "@material-ui/system";

const styleFunction = styleFunctionSx(compose(spacing, palette));
const StyledGrid = styled(Grid)(styleFunction);

const useStyles = makeStyles((theme) => ({
  rightHandSideContainer: {
    minHeight: '100px',
    position: 'relative'
  },
  ratingContainer: {
    borderRadius: '8px',
    background: 'white',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    marginBottom: '1em'
  },
  ratingContainerVisible: {
    borderRadius: '8px',
    background: 'white',
    border: "0.5px solid #D4D4D4",
    marginBottom: '1em'
  },
  yourRatingLabel: {
    color: '#262641',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: '600'
  },

  yourRatingLabelVisible: {
    color: '#3C465F',
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '700'
  },
  howOurRatingWorksLabel: {
    color: '#2F2F4E',
    fontSize: '14px',
    lineHeight: '20px'
  },
  howOurRatingWorksLabelVisible: {
    color: "#3C465F",
    fontSize: '14px',
    lineHeight: '20px'
  },
  yourRatingIcon: {
    marginLeft: '1em'
  },
  rightGrid: {
    textAlign: 'right',
    padding: '1em',
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%'
  },
  ratingWord: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
    height: '1em'
  },
  numberOfReviewsAnchor: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    height: '1em'
  },
  imageContainer: {
    width: '100px'
  },
  mobileContainer: {
    background: 'linear-gradient(93.44deg, #FC4588 50.91%, #EB3E7D 99.75%)',
    borderRadius: '8px',
    padding: '1.5em',
    height: '120px',
    marginBottom: '1em'
  },
  mobileOurRatingLabel: {
    color: 'white',
    letterSpacing: '-0.02em',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: '600'
  },
  mobileHowRatingWorksLabel: {
    color: 'white',
    fontSize: '14px',
    lineHeight: '20px'
  },
  mobileRightHandSideContainer: {
    textAlign: 'right'
  }
}));

const OurRatingWidget = ({ companyReference }) => {
  const classes = useStyles();
  const starIcons = useStarIcons()
  var ratingWord = '';

  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true);
      setIsCampusTheme(isCampusTheme);
    })();
  }, [])

  if (companyReference) {

    switch (parseInt(companyReference.ourRating)) {
      case 1:
        ratingWord = 'Bad';

        break;
      case 2:
        ratingWord = 'Bad';

        break;
      case 3:
        ratingWord = 'Poor';

        break;
      case 4:
        ratingWord = 'Poor';

        break;
      case 5:
        ratingWord = 'Average';

        break;
      case 6:
        ratingWord = 'Average';

        break;
      case 7:
        ratingWord = 'Average';

        break;
      case 8:
        ratingWord = 'Great';

        break;
      case 9:
        ratingWord = 'Excellent';

        break;
      case 10:
        ratingWord = 'Excellent';
        break;
      default:
      // code block
    }
  }

  return (
    finishedCheckingTheme ?
    <>

      <StyledGrid
        container
        alignItems="center"
        justify="center"
        className={isCampusTheme ? classes.ratingContainerVisible : classes.ratingContainer}
        sx={{ display: { xl: 'flex !important', lg: 'flex !important', md: 'none !important', sm: 'none !important', xs: 'none !important' } }}
      >
        <Grid item xs={6}>
          <Grid container alignItems="center">
            <Grid item className={classes.imageContainer}>
              <img src={isCampusTheme ? OurRatingIconVisible : OurRatingIcon} className={classes.yourRatingIcon} />
            </Grid>
            <Grid item xs={6}>
              <Grid container alignItems="center"
                justify="center">
                <Grid item xs={12}>
                  <label className={classes.yourRatingLabel}>Our rating</label>
                </Grid>
                <Grid item xs={12}>
                  <a href="/how-our-ratings-work/" target="_blank" className={isCampusTheme ? classes.howOurRatingWorksLabelVisible : classes.howOurRatingWorksLabel}>How our rating works</a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Grid item xs={6} className={`${styles.ourRatingBackground} ${classes.rightHandSideContainer}`}>
          <Grid container alignItems="center"
            justify="center" className={classes.rightGrid}>
            <Grid item xs={12}>
              <Rating
                readOnly
                name="Rating"
                precision={0.5}
                value={companyReference && companyReference.ourRating / 2}
                icon={
                  <img
                    src={isCampusTheme ? StarIconVisible : StarIcon}
                    alt="StarIcon"
                    className={starIcons.starImageWidget}
                  />
                }
                emptyIcon={
                  <img
                    src={EmptyStarIcon}
                    alt="EmptyStarIcon"
                    className={starIcons.starImageWidget}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} className={classes.mobileRightHandSideContainer}>
              <label className={classes.ratingWord}>
                {ratingWord}
              </label>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>


      <StyledGrid container className={classes.mobileContainer} alignItems="center" justify="center" sx={{ display: { xl: 'none !important', lg: 'none !important', md: 'flex !important', sm: 'flex !important', xs: 'flex !important' } }}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <label className={classes.mobileOurRatingLabel}>Our rating</label>
            </Grid>
            <Grid item xs={12}>
              <a href="/how-our-ratings-work/" target="_blank" className={classes.mobileHowRatingWorksLabel}>How our rating works</a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container className={classes.mobileRightHandSideContainer}>
            <Grid item xs={12} className={styles.newContainer}>
              <Rating
                readOnly
                name="Rating"
                precision={0.5}
                value={companyReference && companyReference.ourRating / 2}
                icon={
                  <img
                    src={isCampusTheme ? StarIconVisible : StarIcon}
                    alt="StarIcon"
                    className={starIcons.starImageWidget}
                  />
                }
                emptyIcon={
                  <img
                    src={EmptyStarIcon}
                    alt="EmptyStarIcon"
                    className={starIcons.starImageWidget}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <label className={classes.ratingWord}>
                {ratingWord}
              </label>
            </Grid>
          </Grid>
        </Grid>
      </StyledGrid>
    </> : <></>
  );
};

export default OurRatingWidget;
