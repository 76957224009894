// extracted by mini-css-extract-plugin
export var base = "typography-module--base--FphVn";
export var blockQuote = "typography-module--blockQuote--NvnVP";
export var extrasmall = "typography-module--extrasmall--70SS0";
export var large = "typography-module--large--WR8oq";
export var mainAnchor = "typography-module--mainAnchor--u6DQi";
export var micro = "typography-module--micro--ywG8y";
export var paragraph = "typography-module--paragraph--5Wxws";
export var responsiveTitle1 = "typography-module--responsiveTitle1--0t0jA";
export var responsiveTitle2 = "typography-module--responsiveTitle2--LNl4r";
export var responsiveTitle3 = "typography-module--responsiveTitle3--K+vPf";
export var responsiveTitle4 = "typography-module--responsiveTitle4--RYCAI";
export var small = "typography-module--small--KHf9L";
export var title1 = "typography-module--title1--j5E2+";
export var title2 = "typography-module--title2--n-7kF";
export var title3 = "typography-module--title3--wIhjs";