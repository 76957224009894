import React from "react";

const Increment = ({ customClass, customEvent }) => {
  return (<svg onContextMenu={(e) => e.preventDefault()} onClick={customEvent} className={customClass.inputAdornmentIncrease} width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_282_471)">
      <path d="M0 0L32 0C36.4183 0 40 3.58172 40 8V28L0 28L0 0Z" fill="white"/>
      <path d="M15 17L20 12L25 17" stroke="#8C8CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_i_282_471" x="0" y="0" width="40" height="28" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                       result="hardAlpha"/>
        <feOffset dy="-1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.858824 0 0 0 0 0.890196 0 0 0 0 0.937255 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_282_471"/>
      </filter>
    </defs>
  </svg>)
}

export default Increment;
