import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import { Grid, Button, Typography } from "@material-ui/core";
import ContainerForLinks from "../../containerForLinks";
import Container from "../../container";
import serializers from "../../serializers";
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { createClient, checkIsCampusTheme, checkIsLesson } from "../../../lib/helpers";

import RecProvidersComp from "./recProvidersComp";
import RecProvidersCompCampus from "./recProvidersCompCampus";


const client = createClient();
const pageType = "companyCategory";

const useStyles = makeStyles((theme) => ({

  titleText: {
    marginBottom: "10px"
  },
  companyCard: {
    marginTop: "10px",
  }
}));

export default function RecProviders(props) {

  const { title, position, overrideCompanies, isSponsoredProvider } = props;
  const [companies, setCompanies] = useState([]);
  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  const classes = useStyles();
  const location = useLocation();

  var whichQS = "companies" + position;

  var qs = queryString.parse(location.search).companies1;
  var qsSearch = queryString.parse(location.search)

  qs = qsSearch[whichQS];

  //console.log("qsSearch", qsSearch[whichQS])

  useEffect(() => {
    (async () => {
      if ((qs != null) && (qs != "") || (props.overrideCompanies && props.overrideCompanies.length > 0)) {

        await getComps();
      }
      else {
        //console.log("no qs");
      }

    })();
  }, []);

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();
      var isLesson = await checkIsLesson();

      setFinishedCheckingTheme(true);
      if (isCampusTheme) {
        setIsCampusTheme(isCampusTheme);
      } else if (isLesson) {

        if (isLesson[0].theme !== "Boring Money theme"){
          setIsCampusTheme(true)
        } else {
          setIsCampusTheme(false)
        }
      }
    })();
  }, [])

  var getComps = async function () {

    var comps = await getCompsList(qs);
    //console.log('comps: ', comps);
    setCompanies(comps)

  }

  var getCompsList = async function (qs) {

    var myArr = [];

    if (qs) {
      myArr = qs.split(",");
    }

    var a = ((myArr[0] == null) ? '0' : myArr[0]);
    var b = ((myArr[1] == null) ? '0' : myArr[1]);
    var c = ((myArr[2] == null) ? '0' : myArr[2]);

    var companyIds = [];


    if (props.overrideCompanies && props.overrideCompanies.length > 0) {
      for (var i = 0; i < props.overrideCompanies.length; i++) {
        var overridenCompanyId = props.overrideCompanies[i].dashboardId;

        if (overridenCompanyId.toLowerCase() !== a.toLowerCase()
          && overridenCompanyId.toLowerCase() !== b.toLowerCase()
          && overridenCompanyId.toLowerCase() !== c.toLowerCase()) {
          companyIds.push(overridenCompanyId);
        }
      }
    } else {
      companyIds.push(a);
      companyIds.push(b);
      companyIds.push(c);
    }

    const params = {
      pageType: pageType,
    }

    var queryCompanyIds = '';

    for (var i = 0; i < companyIds.length; i++) {
      var propertyName = 'compId' + i.toString();
      params[propertyName] = companyIds[i];

      queryCompanyIds = queryCompanyIds + '$compId' + i.toString() + ',';
    }

    var query = '*[_type == $pageType && defined(publishedAt)  && dashboardId in [' + queryCompanyIds.replace(/,\s*$/, "") + ']]{'
      + 'companyName, companyLogo, companyDisplayName, reference,referenceVisible,inANutshell,inANutshellVisible,ourRating,yourRating,partner,'
      + 'visibleReadMoreText[]{'
      + '...,'
      + '  markDefs[]{'
      + '...,'
      + ' }'
      + '},'
      + '"linkRef": * [_type == "linkCategory" && _id == ^.reference._ref]{ url },'
      + '"post": * [_type == "post" && _id == ^.reference._ref]{ "slug": slug.current },'
      + '"landing": * [_type == "landingPage" && _id == ^.reference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"info": * [_type == "information" && _id == ^.reference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"linkRefVisible": * [_type == "linkCategory" && _id == ^.visibleReference._ref]{ url },'
      + '"postVisible": * [_type == "post" && _id == ^.visibleReference._ref]{ "slug": slug.current },'
      + '"landingVisible": * [_type == "landingPage" && _id == ^.visibleReference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"infoVisible": * [_type == "information" && _id == ^.visibleReference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"linkRefReview": * [_type == "linkCategory" && _id == ^.reviewReference._ref]{ url },'
      + '"linkRefReviewVisible": * [_type == "linkCategory" && _id == ^.reviewReferenceVisible._ref]{ url },'
      + '"awardLogo": * [_type == "bestBuyAward" && _id == ^.bestBuyAward._ref]{ providerPageLogo },'
      + '"awardLogoVisible": * [_type == "bestBuyAward" && _id == ^.bestBuyAwardVisible._ref]{ providerPageLogo },'
      + '"postReview": * [_type == "post" && _id == ^.reviewReference._ref]{ "slug": slug.current },'
      + '"postReviewVisible": * [_type == "post" && _id == ^.reviewReferenceVisible._ref]{ "slug": slug.current },'
      + '"landingReview": * [_type == "landingPage" && _id == ^.reviewReference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"landingReviewVisible": * [_type == "landingPage" && _id == ^.reviewReferenceVisible._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"infoReview": * [_type == "information" && _id == ^.reviewReference._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } },'
      + '"infoReviewVisible": * [_type == "information" && _id == ^.reviewReferenceVisible._ref]{ "slug": slug.current, "subfolder":* [_type == "folderCategory" && _id == ^.subfolder._ref]{ folder } }'
      + ' }| order(ourRating desc)';

    return client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  if ((qs != null) && (qs != "") || (props.overrideCompanies && props.overrideCompanies.length > 0)) {
    return (
      finishedCheckingTheme ?
        <>
          <Grid>

            <Typography variant="h3" className={classes.titleText}>
              {title}
            </Typography>
            {companies && companies.map(company =>
              isCampusTheme ? <RecProvidersCompCampus className={classes.companyCard} company={company} isSponsoredProvider={isSponsoredProvider} /> : <RecProvidersComp className={classes.companyCard} company={company} />
            )}

          </Grid>
        </> : <></>
    )
  } else {
    return (<>
      <Grid></Grid>
    </>);
  }
}
