import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { createClient } from "../../lib/helpers";
import { Grid, Button, Typography } from "@material-ui/core";
import Container from "../container";
import { Link } from "gatsby";

import AdviserRecommendedCard from "./adviserRecommendedCard"

const client = createClient();
const pageType = "people";

const useStyles = makeStyles((theme) => ({

  titleText: {
    color: '#000000',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: "1em",
  },
  central: {
    textAlign: "center",
  },
  viewAdviser: {
    textDecoration: "none",
    color: "#3C455C",
    width: "100%",
    textAlign: "center",
    maxWidth: "180px",
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    backgroundColor: 'white',
    fontWeight: '600',
    padding: '1em',
    borderRadius: '8px',
    marginTop: '1em',
    display: 'block',
    [theme.breakpoints.down("md")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      maxWidth: "100%",
      width: "50%",
      textAlign: "center",
    },
  },
}));


function AdviserRecommended(props) {

  const { title } = props;
  const [advisers, setAdvisers] = useState([]);

  const classes = useStyles();
  const location = useLocation();
  var qs = queryString.parse(location.search).advisers;

  useEffect(() => {
    (async () => {

      if ((qs != null) && (qs != "")) {

        await getAdvisers();
      }
      else {
        //console.log("no qs");
      }

    })();
  }, []);

  var getAdvisers = async function () {

    var adv = await getAdvisersList(qs);
    setAdvisers(adv)

  }

  var getAdvisersList = async function (qs) {

    const myArr = qs.split(",");

    var a = ((myArr[0] == null) ? '0' : myArr[0]);
    var b = ((myArr[1] == null) ? '0' : myArr[1]);
    var c = ((myArr[2] == null) ? '0' : myArr[2]);

    const params = {
      pageType: pageType,
      advId1: a,
      advId2: b,
      advId3: c,
    }


    var query = '*[_type == $pageType && defined(publishedAt) && dashboardId in [$advId1,$advId2,$advId3] &&!(_id in path("drafts.**"))]'
    query += '{ _id, dashboardId, name, jobTitle, numberOfLikes, email, image, averageRating, "slug": slug.current }'

    return client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }



  if ((qs != null) && (qs != "")) {
    //console.log("advisers", advisers)
    return (
      <>
        <Grid>
          {advisers && advisers.map(adv =>
            <>
              
              <AdviserRecommendedCard name={adv.name} jobTitle={adv.jobTitle} image={adv.image} amountOfLikes={adv.numberOfLikes} profileSender={adv.slug} dashboard={adv.dashboardId} averageRating={adv.averageRating}/>
            </>
          )}
        </Grid>
        <Grid>
          <a href="/advisers/" className={classes.viewAdviser}>VIEW ALL</a>
        </Grid>
      </>
    )
  } else {
    return (<>
      <Grid></Grid>      <Grid>
        <a href="/advisers/" className={classes.viewAdviser}>VIEW ALL</a>
      </Grid>
    </>)
  }

}

export default AdviserRecommended
