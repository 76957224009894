import React, { useState } from "react";
import PortableText from "../portableText";
import { makeStyles } from "@material-ui/core/styles";
import ExpandIcon from "./expandIcon";
import CollapseIcon from "./collapseIcon";
import * as styles from "./dropdownWidget.module.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const DropdownWidget = ({ items, isForLandingPage }) => {
  // state containing the item index set by internal accordion component state
  const [expanded, setExpanded] = useState();

  // state to hold the current selected item
  const [accordionExpanded, setAccordionExpanded] = useState();

  const useStyles = makeStyles((theme) => ({
    accordionItem: {
      background: "#FFFFFF",
      border: "1px solid #DBE3EF",
      borderRadius: "8px !important",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      marginBottom: "1em",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        marginBottom: "1em !important",
        border: "0!important",
        boxShadow: "none",
      },
    },
    accordionItemHeading: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      minHeight: "48px",
      color: "#2F2F4E",
      padding: "0 1.5em 0 0",
      display: "flex",
      alignItems: "center",
      "& > div":{
        width: "100%",
      },
      "&$expanded": {
        background: "#ECF1F4",
        border: "1px solid #DBE3EF",
        borderRadius: "8px 8px 0px 0px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        lineHeight: "24px",
        boxShadow: "none !important",
      },
    },
    accordionItemHeadingActive: {
      boxSizing: "content-box",
  border: "1px solid #DBE3EF",
  fontSize: "16px",
  background: "#ECF1F4",
  boxShadow: "none !important",
  fontStyle: "normal",
  fontFamily: "Poppins",
  display: "flex",
  alignItems: "center",
  padding: "0 1.5em 0 0",
  fontWeight: 500,
  minHeight: "64px",
  lineHeight: "24px",
  borderRadius: "8px 8px 0px 0px",
  "& > div":{
    width: "100%",
  },
    },
    content: {
      padding: "1.5em",
      marginTop: "0",
      marginBottom: 0,
      flexBasis: "100%",
      maxWidth: "calc(100% - 2.5em)",
      "&$expanded": {
        margin: "0",

        "@nest .expanded-label &": {
          display: "block",
        },
        "@nest .non-expanded-label &": {
          display: "none",
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "16px",
      },
    },
    expanded: {},
    expandIcon: {
      transform: "none !important",
    },
    accordionItemButton: {
      display: "flex",
      alignItems: "center",
    },
    accordionItemPanel: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "4500",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#2F2F4E",
      padding: "1.5em 1.5em calc(1.5em - 15px) 1.5em",
      [theme.breakpoints.down("md")]: {
        padding: "16px 16px 1px 16px",
      },
    },
  }));

  const classes = useStyles();

  const handleChange = (index) => {
    //console.log("index:", index);
    setAccordionExpanded(index);
  };

  return (
    <>
      <Accordion allowZeroExpanded={true}>
        {items &&
          Array.isArray(items) &&
          items.map((item, index) => (
            <AccordionItem className={classes.accordionItem}>
              <AccordionItemHeading
                className={accordionExpanded === index && expanded === index ? classes.accordionItemHeadingActive : classes.accordionItemHeading}
                id={index.toString()}
              >

                <AccordionItemState>{({expanded}) => (expanded ? setExpanded(index) : accordionExpanded === index && setExpanded(null))}</AccordionItemState>
                <AccordionItemButton onClick={() => handleChange(index)}>
                  <div
                    className={classes.accordionItemButton}
                    onClick={() => handleChange(index)}
                  >
                    <label
                      className={`${"non-expanded-label"} ${
                        styles.overflowElipsis
                      } ${styles.fullWidth}`}
                    >
                      {item.title}
                    </label>
                    <label
                      className={`${"expanded-label"} ${styles.expandedLabel}`}
                    >
                      {item.title}
                    </label>
                    {accordionExpanded  === index  && expanded === index ? <ExpandIcon /> : <CollapseIcon />}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={classes.accordionItemPanel}>
                <PortableText
                  blocks={isForLandingPage ? item._rawText : item.text}
                />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
      </Accordion>
    </>
  );
};

export default DropdownWidget;
