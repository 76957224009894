import React, { useState, useEffect} from "react";
import { Grid, Typography, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../.././client-config";
import serializers from "../serializers";
import HeartIcon from "../icon/HeartIcon.svg";
import ContainerForSVG from "../containerForSVG";
import Dialog from "../popups/contactPopup";
import { buildImageObj } from "../../lib/helpers";
import { AddTrailingSlashToUrl } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import * as styles from "./adviserList.module.css";
import AdviserLikes from "./adviserLikes";
import AdviserLikesMobile from "./adviserLikesMobile";

const useStyles = makeStyles((theme) => ({
  grey: {
    backgroundColor: "#F5F7FA",
    paddingTop: "15px",
    paddingBottom: "30px",
    // paddingLeft: "30px",
    // paddingRight: "30px"
  },

  imageHolder: {
    // maxWidth: "216px",
    // maxHeight: "216px",
    maxHeight: "141.22px",
    maxWidth: "141.22px",
    position: 'relative'
    // [theme.breakpoints.down("xs")]: {
    //   maxWidth: "72px",
    //   maxHeight: "72px",
    // },
  },

  jobTitle: {
    color: "#8C8CA1",
    paddingBottom: "16px",
    fontWeight: "500",
  },
  likes: {
    color: "#8C8CA1",
    marginRight: "17px",
    fontWeight: "500",
    lineHeight: "16px",
  },
  amountOfLikes: {
    marginLeft: "10px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  likesContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    width: "130px",
    height: "48px",
    justifyContent: "center",
    alignItems: "center",
  },
  likesSmallContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  likesButtonContainer: {
    width: "100%",
    marginTop: "28px",
  },
  profileText: {
    justifyContent: "space-between",
    direction: "column",
    paddingLeft: "24px",
  },
  nameText: {
    marginBottom: "8px",
    color: "#2F2F4E",
  },
  contactMeButton: {
    borderRadius: "8px",
    width: "100%",
    maxWidth: "180px",
    height: "56px",
    backgroundColor: "#DBE3EF",
    [theme.breakpoints.down("lg")]: {
      marginTop: "28px",
      width: "50%",
      maxWidth: "100%",
    },
  },
  contactMeButtonText: {
    marginRight: "11px",
    color: "#50586C",
  },
  contactMeButtonContainer: {
    alignItems: "center",
    paddingTop: "25px",
    paddingBottom: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      marginTop: '28px'
    },
  },
  viewAdviser: {
    textDecoration: "none",
    color: "#8C8CA2",
    width: "100%",
    textAlign: "center",
    border: '1px solid #94A4D6',
    borderRadius: '8px',
    marginTop: '16px',
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      maxWidth: "100%",
      textAlign: "center",

    },
  },
  noTextDecoration: {
    textDecoration: 'none'
  },
  viewAdviserSpan: {
    color: "#3C455C",
    textDecoration: 'none',
    textTransform: 'uppercase',
    display: 'block',
    width: '100%',
    height: '100%',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  adviserOverlay: {
    position: 'absolute',
    top: '0',
    opacity: '0.35',
    background: 'linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)',
    width: '100%',
    height: '100%',
    borderRadius: '10px'
  },
  adviserImage: {
    borderRadius: '10px',
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      width: '100%'
    },
  },
  likesButtonContainerNoPaddingTop: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
}));

function AdviserRecommendedCard(props) {
  const { name, jobTitle, image, amountOfLikes, profileSender, dashboardId, averageRating } =
    props;

  let mainImageWithType = image;

  if (image != null) {
    mainImageWithType._type = "mainImage";
    mainImageWithType.isPeopleBioImage = true;
    mainImageWithType.isAdviserFilteredImage = true;

  }

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  const advUrl = "/advisers/" + profileSender;

  const classes = useStyles();

  const divClasses = styles.filtersContainerQuizResponse + ` adviserListCard ` + styles.adviserProfileBox;

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'recommended_adviser_card_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'recommended_adviser_card_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);


  var nameClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'adviser name',
      AdviserName: props.name
    });
  }

  var viewProfileClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'view profile button',
      AdviserName: props.name
    });
  }

  var logoClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'logo',
      AdviserName: props.name
    });
  }

  return (
    <div>
      <div
        className={divClasses}
      >
        <Grid container className={classes.profile}>
          <Grid container lg={9} md={12} alignItems="center">
            <Grid xs={4} item className={classes.imageHolder}>
              <a href={AddTrailingSlashToUrl(advUrl)}
                onClick={() => { logoClicked() }}
              >
                <Hidden mdUp implementation="js">
                  <img className={classes.adviserImage} src={imageUrlFor(buildImageObj(image))
                    .width(80)
                    .height(80)
                    .fit("crop")
                    .auto("format")
                    .url()}
                    alt={name}
                  />
                </Hidden>
                <Hidden smDown implementation="js">
                  <img className={classes.adviserImage} src={imageUrlFor(buildImageObj(image))
                    .width(141)
                    .height(141)
                    .fit("crop")
                    .auto("format")
                    .url()}
                    alt={name}
                  />
                </Hidden>

                <div className={classes.adviserOverlay}></div>

              </a>
            </Grid>
            <Grid xs={8} container item className={classes.profileText}>
              <Grid>
                <a href={AddTrailingSlashToUrl(advUrl)}
                  className={classes.noTextDecoration}
                  onClick={() => { nameClicked() }}
                >
                  <Typography variant="h4" className={classes.nameText}>
                    {name}
                  </Typography>
                </a>

                <Typography variant="body2" className={classes.jobTitle}>
                  {jobTitle}
                </Typography>

              </Grid>
              <Hidden smDown implementation="js">

                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  className={classes.likesButtonContainer}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    className={averageRating != undefined && averageRating != null && averageRating > 0 ? classes.likesButtonContainerNoPaddingTop : classes.likesButtonContainer}
                  >

                    <AdviserLikes amountOfLikes={amountOfLikes} averageRating={averageRating} />
                  </Grid>
                </Grid>
              </Hidden>

            </Grid>
          </Grid>
          <Grid
            container
            lg={3}
            md={12}
            sm={12}
            direction="row"
            alignItems="center"
            justify="center"
            className={classes.contactMeButtonContainer}
          >
            <Hidden mdUp implementation="js">
              <AdviserLikesMobile amountOfLikes={amountOfLikes} averageRating={averageRating} />
            </Hidden>
            <Dialog
              nameOfAdviser={name}
              buttonText={
                <Typography
                  variant="button"
                  className={classes.contactMeButtonText}
                >
                  CONTACT
            </Typography>
              }
              fullWidthButtonContainer={true}
              dashboardId={dashboardId}
              isRecommendedAdviserButton={true}
            />
            <Typography variant="button" className={classes.viewAdviser}>
              <a href={AddTrailingSlashToUrl(advUrl)}
                className={classes.viewAdviserSpan}
                onClick={() => { viewProfileClicked() }}
              >
                View Profile
          </a>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default AdviserRecommendedCard;
