import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import PortableText from "../../portableText";
import LogoImage from "./logoImage";
import Rating from "./rating";
import { AddTrailingSlashToUrl } from "../../../lib/helpers";

export default function ValueColumn(props) {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  const {
    bottomColumnClassName,
    topRightColumnClassName,
    bottomRightColumnClassName,
    displayValue,
    name,
    flushState,
    noBorderBottomClassName,
    companyName
  } = props;
  const useStyles = makeStyles((theme) => ({
    noMaxHeight: {
      maxHeight: 'initial !important'
    },
    readButton: {
      cursor: "pointer",
      color: "#0070EA",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      letterSpacing: "0px",
    },
    valueColumn: {
      background: '#FFFFFF',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingTop: '8px',
      paddingBottom: '8px',
      borderBottom: '1px solid #DBE3EF',
      borderLeft: '1px solid #DBE3EF',
      [theme.breakpoints.down(600)]: {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '8px',
        paddingRight: '8px'
      }
    },
    portableTextWrapper: {
      display: '-webkit-box',
      overflow: 'hidden',
      fontSize: '14px',
      wordWrap: 'break-word',
      lineHeight: '20px',
      textOverflow: 'ellipsis',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 4,
      maxHeight: '93px',
      position: 'relative',
    },
    buttonContainer: {
      width: '100% !important',
      overflow: 'visible !important'
    },
    rightColumn: {
      borderRight: '1px solid #DBE3EF'
    },
    providerName: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#2F2F4E'
    },
    imageWrapper: {
      height: '120px',
      border: '0.513043px solid #DBE3EF',
      boxShadow: '0px 4.21429px 8.42857px rgba(64, 64, 64, 0.1)',
      borderRadius: '9.44px',
      width: '120px',
      [theme.breakpoints.down(450)]: {
        width: '90px',
        height: '90px'
      }
    },
    ourRating: {
      height: '18px !important'
    },
    numberOfReviewsLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#2F2F4E',
      display: 'block',
      marginTop: '26px',
      textAlign: 'center'
    },
    numberOfReviewsAnchor: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '18px',
      color: '#2F2F4E'
    },
    alignMiddle: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      marginLeft: '3px'
    },
    ratingContainer: {
      marginLeft: '3px'
    },
    buttonContainerHelper2: {
      textDecoration: "none",
      width: "100%",
      cursor: 'pointer'
    },
    visitSiteButtonAffiliate: {
      borderRadius: '8px',
      backgroundColor: "#FB755C",
      width: "100%",
      padding: "12px",
      "&:hover": {
        background: "#ffffff",
      },
      "&:hover $buttonTextVisitSite": {
        color: "#FB755C",
      },
      boxShadow: '0 2px 4px rgb(64 64 64 / 10%)',
    },
    visitSiteButton: {
      borderRadius: '8px',
      backgroundColor: "#FF0F5F ",
      color: "black",
      width: "100%",
      boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
      padding: "12px",
      "&:hover": {
        background: "#FFF",
      },
      "&:hover $buttonTextVisitSite": {
        color: "#FF0F5F",
      },
    },
    buttonTextVisitSite: {
      color: "#fff",
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "24px",
    },
    readMoreButtonText: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      letterWpacing: '0.04em',
      textTransform: 'uppercase',
      color: '#3C455C'
    },
    readMoreButton: {
      width: '100%',
      borderRadius: '8px',
      padding: '12px',
      backgroundColor: '#FFFFFF',
      border: '1px solid #94A4D6'
    }
  }));
  const classes = useStyles();

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  const ref = useRef(null);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    if (!props.displayValue) {
      return false;
    }
    setHeight(ref.current.clientHeight);
    setScrollHeight(ref.current.scrollHeight);
  });

  useEffect(() => {
    setOpen(false);
  }, [flushState]);


  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'comparison_criteria_hyperlink_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'comparison_criteria_hyperlink_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  var visitSiteButtonClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'visit site button',
      CompanyName: props.companyName
    });
  }

  var loadMoreButtonClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'load more button',
      CompanyName: props.companyName
    });
  }

  return (
    <Grid item xs={6} className={`${classes.valueColumn} ${props.bottomColumnClassName} ${props.topRightColumnClassName} ${props.bottomRightColumnClassName} ${props.noBorderBottomClassName}`} >
      <Grid container alignItems={props.name === 'logo' || props.name === 'Provider' || props.name === 'Our rating' || props.name === 'Your rating' ? 'center' : ''} justify={props.name === 'logo' || props.name === 'Provider' || props.name === 'Our rating' || props.name === 'Your rating' ? 'center' : ''}>
        <Grid item className={`${open ? '' : props.name === 'logo' ? classes.imageWrapper : classes.portableTextWrapper} ${props.name === 'Our rating' ? classes.ourRating : ''} ${props.name === 'Visit site' || props.name === 'Read more' ? classes.buttonContainer : ''}`} ref={ref}>
          {
            props.displayValue && props.name !== 'logo' && props.name !== 'Provider' && props.name !== 'Our rating' && <PortableText blocks={props.displayValue} />
          }
          {
            props.displayValue && props.name === 'logo' && <LogoImage logoImage={props.displayValue.Logo} linkHref={props.displayValue.LinkHref} companyName={props.companyName}/>
          }
          {
            props.displayValue && props.name === 'Provider' && <label className={classes.providerName}>{props.displayValue}</label>
          }
          {
            props.displayValue && props.name === 'Our rating' && <Rating score={props.displayValue} className={classes.ratingContainer} />
          }
          {
            props.displayValue && props.name === 'Your rating' &&

            <Grid container alignItems="center" justify="center">
              <Grid item xs={12}> <Rating score={props.displayValue.Rating} className={classes.alignMiddle} /></Grid>
              <Grid item xs={12}>
                {
                  props.displayValue.NumberOfReviews &&

                  <label className={classes.numberOfReviewsLabel}>Based on <a href={AddTrailingSlashToUrl(props.displayValue.ReviewLink)} className={classes.numberOfReviewsAnchor}>{props.displayValue.NumberOfReviews} reviews</a></label>
                }
              </Grid>
            </Grid>
          }

          {
            props.displayValue && props.name === 'Visit site' &&


            <a href={props.displayValue.VisitSiteHref} className={classes.buttonContainerHelper2} target="_blank" onClick={() => { visitSiteButtonClicked() }}>
              <Button
                className={`${props.displayValue.IsAffiliate == true
                  ? classes.visitSiteButtonAffiliate
                  : classes.visitSiteButton
                  }`}
              >
                <Typography
                  variant="button"
                  className={classes.buttonTextVisitSite}
                >
                  Visit Site
              </Typography>
              </Button>
            </a>

          }

          {
            props.displayValue && props.name === 'Read more' &&

            <a href={AddTrailingSlashToUrl(props.displayValue)} className={classes.buttonContainerHelper2} target="_blank" onClick={() => { loadMoreButtonClicked() }}>
              <Button
                className={`${classes.readMoreButton}`}
              >
                <Typography
                  variant="button"
                  className={classes.readMoreButtonText}
                >
                  Learn more
              </Typography>
              </Button>
            </a>

          }

        </Grid>
      </Grid>
      {props.name !== 'logo' && props.name !== 'Our rating' && scrollHeight > height ? (
        <a onClick={handleClick}>
          <Typography variant="button" className={`${classes.readButton}`}>
            {open ? "Read Less >" : "Read More >"}
          </Typography>
        </a>
      ) : open ? (
        <a onClick={handleClick}>
          <Typography variant="button" className={`${classes.readButton}`}>
            Read Less >
              </Typography>
        </a>
      ) : (
            <Grid className={classes.fallback} />
          )}
    </Grid>
  )

}

