import React, { Component, useEffect, useState } from "react";
import { Typography, Grid, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AnchorMenuSubMenu from "./anchorMenuBody";

const useStyles = makeStyles((theme) => ({
  navMenu: {
    marginTop: "12px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    width: '300px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    zIndex: '22',
    marginBottom: "1.5em",
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
  },
  navMenuMobile: {
    marginTop: "12px",
    boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    filter: "drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24))",
    width: '300px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    zIndex: '22',
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
  },
  blueTopBar: {
    backgroundColor: "#3C465F",
    borderRadius: "12px 12px 0px 0px",
    padding: "20px",
    color: "white",
    width: '100%'
  },
  anchorMenuBody: {
    backgroundColor: "#ffffff",
    borderRadius: "0px 0px 12px 12px",
    padding: "0",
    margin: "0",
    width: '100%'
  },
  menuLink: {
    color: "#262641",
    textDecoration: "none",
  },
  divider: {
    backgroundColor: "#FF0F5F",
    height: "3px",
    width: "100%",
  },
}));

function AnchorMenu(props) {
  const [idList, setIdList] = useState([]);
  const { menuItems, isMobileAnchor } = props;
  const classes = useStyles();

  var titleMenu = "";
  if ((menuItems.menuTitle == null)) {
    titleMenu = "Topics in this Guide";
  }
  else {
    titleMenu = menuItems.menuTitle;
  }

  useEffect(() => {
    setIdList(getIds(menuItems.menuLinksTop));
  }, []);
  function getIds(linksIds) {
    return linksIds.reduce((result, linkId) => {
      if (linkId.reference) {
        result.push(linkId.reference.AnchorName);
      }
      if (linkId.menuChildLinks) {
        result.push(...getIds(linkId.menuChildLinks));
      }
      return result;
    }, []);
  }
  // console.log(activeId);
  return (
    <nav aria-label="Table of contents" className={isMobileAnchor ? classes.navMenuMobile : classes.navMenu}>
      <Grid className={classes.blueTopBar}>
        <Typography variant="h4">{titleMenu}</Typography>
      </Grid>
      <Divider className={classes.divider} />
      <Grid className={classes.anchorMenuBody}>
        {menuItems.menuLinksTop.map((tl, index) => (

          <AnchorMenuSubMenu tl={tl} key={tl} idList={idList} last={(index + 1) === menuItems.menuLinksTop.length} />
        ))}
      </Grid>
    </nav>
  );
}

export default AnchorMenu;
