import React, { useState, useEffect } from "react";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Typography } from "@material-ui/core";
import StarIcon from "./icon/Star.svg";
import EmptyStarIcon from "./icon/empty-star.svg";
import Rating from '@material-ui/lab/Rating';
import { createClient } from "../lib/helpers";
import DisplayCompanyReviewsCard from "./displayCompanyReviewsCard";
import { checkIsCampusTheme } from "../lib/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2em',
    paddingBottom: '2em',
    '& .Mui-selected': {
      background: '#3C465F;',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& .MuiPaginationItem-root': {
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial !important'
      }
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em',
      [theme.breakpoints.down("sm")]: {
        height: '3em',
        width: '3em',
        borderRadius: '1.5em',
        fontSize: '9px'
      },
    }
  },

  reviewCard: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DBE3EF',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    borderRadius: '8px',
    padding: '1em',
    height: '100%',
    position: 'relative'
  },
  reviewType: {
    color: '#50586C',
    fontSize: '16px',
    lineHeight: '24px'
  },



  titleLabel: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262641'
  },

  reviewHeading: {
    color: '#262641',
    fontSize: '36px',
    lineHeight: '47px',
    fontWeight: '600',
    paddingBottom: '1em',
    paddingTop: '1em',
  },
  reviewHeadingFundPage: {
    color: '#262641',
    fontSize: '25px',
    lineHeight: '36px',
    fontWeight: '600',
    paddingBottom: '1em',
    paddingTop: '1em',
  },
  verifiedLabel: {
    color: '#009089',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '500',
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingLeft: '1em'
  },
  verifiedImage: {
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  fullWidth: {
    width: '100%'
  },
  halfWidth: {
    width: '50%',
    display: 'inline-block'
  }
}));

const DisplayCompanyReviewsWidget = ({ companyReference, companyID, fundPageHeaderStyling }) => {

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [headerText, setHeaderText] = useState('');
  const [reviews, setReviews] = useState([]);
  const [flushState, setFlushState] = useState(false);
  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true);
      setIsCampusTheme(isCampusTheme);
    })();
  }, [])

  useEffect(() => {
    (async () => {
      //var dictionary = await getDictionary();

      //setHeaderText(dictionary.find(x => x.key === 'company_display_reviews_header_text').value);

      await getReviews(1);

    })();
  }, []);

  var callApiForReviews = async function (model) {

    return fetch(process.env.GATSBY_QUESTIONAPI + '/CompanyDisplayRatings/CompanyDisplayRatings', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(model)
    })
      .then((response) => response.json())
      .then((responseJson) => { return responseJson });
  }

  var getReviews = async function (pageNumber) {
    setPage(pageNumber);

    var companyGUID = "";
    if (companyReference != null) {
      companyGUID = companyReference.dashboardId;
    } else {
      if (companyID != null) {
        companyGUID = companyID
      }

    }

    var model = {
      CompanyId: companyGUID,
      Offset: itemsPerPage * pageNumber - itemsPerPage,
      ItemsPerPage: itemsPerPage
    };

    var rev = await callApiForReviews(model);


    try {
      setTotalPages(Math.ceil(rev[0].TotalRows / itemsPerPage));
    } catch (e) {
      setTotalPages(0)
    }

    setReviews(rev)
  }

  const classes = useStyles();

  const handlePageChange = async (event, value) => {
    await getReviews(value);
    setFlushState(!flushState)

  };

   return (
     finishedCheckingTheme ?
      <>
        {reviews.length > 0 && <Grid containera>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {reviews.map((review) => (
                <DisplayCompanyReviewsCard isCampusTheme={isCampusTheme} review={review} flushState={flushState} />

              ))}
            </Grid>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              {totalPages > 1 &&
                <Pagination
                  count={totalPages}
                  className={classes.root}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      classes={{ selected: classes.selected }}
                    />
                  )}
                />}
            </Grid>
          </Grid>
        </Grid>
        }
      </> : null
    );

};

export default DisplayCompanyReviewsWidget;
