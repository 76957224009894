import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import DisplayCompanyReviewsCard from "./providerReviewsCard";
import ContainerForLinks from "../../containerForLinks";

const useStyles = makeStyles((theme) => ({
  filterHeaderLabel: {
    color: '#262641',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'block'
  },
  filterHeaderLabelVisible: {
    color: "#3C465F",
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'block'
  },
  reviewsHeaderContainer: {
    borderTop: "1px solid #DBE3EF",
    paddingTop: '1em',
    paddingBottom: '1em',
    [theme.breakpoints.down(1078)]: {
      marginTop: '1em'
    },
    [theme.breakpoints.down(959)]: {
      marginTop: 'initial'
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '1em'

    },
  },
  reviewsHeaderContainerVisible: {
    borderTop: "1px solid #D4D4D4",
    paddingTop: '1em',
    paddingBottom: '1em',
    [theme.breakpoints.down(1078)]: {
      marginTop: '1em'
    },
    [theme.breakpoints.down(959)]: {
      marginTop: 'initial'
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '1em'

    },
  },
  allReviewsAnchor: {
    display: 'block',
    width: '100%',
    color: '#65689F',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    lineHeight: '24px',
    textTransform: 'uppercase',
    background: '#FFFFFF',
    border: '1px solid #94A4D6',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    textDecoration: 'none',
    textAlign: 'center',
    padding: '12px',
    marginTop: '1em'
  },
  allReviewsAnchorVisible: {
    display: 'block',
    width: '100%',
    color: "#3C465F",
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    lineHeight: '16px',
    textTransform: 'uppercase',
    background: '#FFFFFF',
    border: "1px solid #3C465F",
    textDecoration: 'none',
    textAlign: 'center',
    padding: '16px',
    marginTop: '1em'
  }
}));

export default function RightPanel({ reviews, companyReviewPageReference, isCampusTheme
}) {

  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={isCampusTheme ? classes.reviewsHeaderContainerVisible : classes.reviewsHeaderContainer}>
          <label className={isCampusTheme ? classes.filterHeaderLabelVisible : classes.filterHeaderLabel}>Most recent reviews</label>
        </Grid>

        {reviews.map((review) => (
          <DisplayCompanyReviewsCard review={review} flushState={false} companyReviewPageReference={companyReviewPageReference} isCampusTheme={isCampusTheme}/>
        ))}

        <Grid item xs={12}>
          {
            companyReviewPageReference && companyReviewPageReference._type &&
            <ContainerForLinks
              reference={companyReviewPageReference}
              _type={companyReviewPageReference._type}
              customClassName={isCampusTheme ? classes.allReviewsAnchorVisible : classes.allReviewsAnchor}
            >
              {'See all reviews'}
            </ContainerForLinks>
          }

        </Grid>
      </Grid>
    </>
  );
};
