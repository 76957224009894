import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { CircleProgress } from 'react-gradient-progress'


const useStyles = makeStyles((theme) => ({


    circular:{
        // background: "linear-gradient(90deg, #FBC2EB 0%, #A6C1EE 100%)",
    position: 'absolute',
    top: '50%',
      transform: 'translateY(-50%)'
    }
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function WouldRecommend(props) {
    const classes = useStyles();
  const [progress, setProgress] = React.useState(10);
  const { value } = props;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress > value ? prevProgress : prevProgress + 1
      );
    }, 1);
    if (progress == value) {
      return () => {
        clearInterval(timer);
        //console.log("cleared");
      };
    }

  }, []);

  return <CircleProgress width={90} className={classes.circular} percentage={progress} primaryColor={['#FBC2EB', '#A6C1EE']} strokeWidth={6} fontSize={18.5} fontFamily={'Poppins'} fontColor={'#3C465F'}/>;
}
