import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { buildImageObj, createClient } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import {
  Grid,
  Button,
  Typography,
  Hidden,
  Portal,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Container from "./container";
import ContainerForLinks from "./containerForLinks";
import Rating from "@material-ui/lab/Rating";
import StarIcon from "./icon/Star.svg";
import WouldRecommendIcon from "./icon/would-recommend.svg";
import WouldRecommendIconVisible from "./icon/would-recommend-visible.svg"
import EmptyStarIcon from "./icon/empty-star.svg";
import { withStyles } from "@material-ui/core/styles";
import RateCompanyForm from "./popups/rateCompanyForm";
import useStarIcons from "./starIcons";

import { toggleBlur, toggleOff } from "../redux/reducers/blur";

import { checkIsCampusTheme } from "../lib/helpers";

const StyledRating = withStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
}))(Rating);

const useStyles = makeStyles((theme) => ({
  providerHeaderContainer: {
    borderRadius: "8px",
    background: '#3C465F',
    boxShadow: "inset 0px 0px 3.03448px rgba(0, 0, 0, 0.15)",
    padding: "1.5em",
    position: 'relative',
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
    [theme.breakpoints.down(959)]: {
      display: "flex",
    },
    [theme.breakpoints.down(690)]: {
      display: "none",
    },
  },
  providerHeaderContainerVisible: {
    padding: "1.5em",
    position: 'relative',
    background: "#6464FF",
    border: "2px solid #6464FF",
    borderRadius: "32px",
    [theme.breakpoints.down(1200)]: {
      display: "none",
    },
    [theme.breakpoints.down(959)]: {
      display: "flex",
    },
    [theme.breakpoints.down(690)]: {
      display: "none",
    },
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down(690)]: {
      display: "none",
    },
  },
  companyLogo: {
    borderRadius: "8px",
    display: "block",
    maxWidth: "106px",
  },
  companyLogoVisible: {
    display: "block",
    maxWidth: "106px",
    border: "1px solid #D4D4D4",
    borderRadius: "24px",
  },
  bestBuyLogo: {
    maxWidth: "106px",
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '1em'
  },
  companyLogoMobile: {
    borderRadius: "8px",
    maxWidth: "110px",
    height: "110px",
  },
  companyLogoMobileVisible: {
    borderRadius: "24px",
    maxWidth: "110px",
    height: "110px",
  },
  awardLogoMobile: {
    borderRadius: "8px",
    float: "right",
    maxWidth: "100%",
    [theme.breakpoints.down(474)]: {
      maxWidth: "64px",
    },

    [theme.breakpoints.down(400)]: {
      maxWidth: "50px",
    },
  },
  companyName: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "47px",
    color: "white",
    letterSpacing: "-0.02em",
    /* textTransform: 'capitalize',*/
    marginBottom: 0,
    [theme.breakpoints.down(1245)]: {
      display: 'none'
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  companyNameVisible: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    letterSpacing: "-0.02em",
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "50px",
    color: "#FFFFFF !important",
    marginBottom: 0,
    "& h2, h3":{
      color: "#FFFFFF !important",
    },
    [theme.breakpoints.down(1245)]: {
      display: 'none'
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  companyNameH3: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "47px",
    color: "white",
    letterSpacing: "-0.02em",
    /* textTransform: 'capitalize',*/
    marginBottom: 0,
    [theme.breakpoints.up(1245)]: {
      display: 'none'
    },
  },
  fontSize36: {
    fontSize: '36px !important'
  },
  companyNameMobile: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "47px",
    color: "white",
    letterSpacing: "-0.02em",
    /* textTransform: 'capitalize',*/
    marginBottom: 0,
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  companyNameMobileVisible: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "47px",
    color: "#FFFFFF !important",
    letterSpacing: "-0.02em",
    /* textTransform: 'capitalize',*/
    marginBottom: 0,
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  providerHeaderContainerMobile: {
    borderRadius: "8px",
    background: '#3C465F',
    padding: "1.5em",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: ".85em",
    },
    [theme.breakpoints.up(1200)]: {
      display: "none",
    },
    [theme.breakpoints.down(1200)]: {
      display: "flex",
    },
    [theme.breakpoints.down(959)]: {
      display: "none",
    },
    [theme.breakpoints.down(690)]: {
      display: "flex",
    },
  },

  providerHeaderContainerMobileVisible: {
    padding: "1.5em",
    width: "100%",
    background: "#6464FF",
    border: "2px solid #6464FF",
    borderRadius: "32px",
    [theme.breakpoints.down("md")]: {
      padding: ".85em",
    },
    [theme.breakpoints.up(1200)]: {
      display: "none",
    },
    [theme.breakpoints.down(1200)]: {
      display: "flex",
    },
    [theme.breakpoints.down(959)]: {
      display: "none",
    },
    [theme.breakpoints.down(690)]: {
      display: "flex",
    },
  },
  contactMeButtonContainerMobile: {
    [theme.breakpoints.up(1200)]: {
      display: "none",
    },
    [theme.breakpoints.down(1200)]: {
      display: "block",
    },
    [theme.breakpoints.down(959)]: {
      display: "none",
    },
    [theme.breakpoints.down(690)]: {
      display: "block",
    },
  },
  numberOfReviewsLabel: {
    color: "#DBE3EF",
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '500'
  },
  numberOfReviewsLabelVisible: {
    color: "#FFFFFF",
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500'
  },
  numberOfReviewsLabelMobile: {
    color: "#FFFFFF",
    display: "block",
    marginLeft: "9.5em",
    paddingTop: ".15em",
  },
  numberOfReviewsAnchor: {
    color: "#DBE3EF",
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '500'
  },
  numberOfReviewsAnchorVisible: {
    color: "#FFFFFF",
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '500',
  },
  positionRelative: {
    position: "relative",
  },
  contentContainer: {
    marginTop: "-10px",
    marginLeft: "1.3em",
  },
  contentContainerVisible: {
    marginTop: "-10px",
    marginLeft: "1.3em",
    "& > div":{
      "& > h2, h3":{
        color: "#FFFFFF !important",
      },
    },
  },
  backdrop: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
    background:
      "linear-gradient(90deg, rgba(96, 108, 136, 0.45) 0%, rgba(63, 76, 107, 0.45) 100%)",
    backdropFilter: "blur(5px)",
  },
  dropdown: {
    position: "fixed",
    width: 400,
    height: 537,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    zIndex: 999,
    borderRadius: "8px",
    padding: "1.5em",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    [theme.breakpoints.down("xs")]: {
      width: 335,
      padding: "16px",
    },
    [theme.breakpoints.down(345)]: {
      width: 300,
      padding: "16px",
    },
  },
  contactMeButton: {
    display: "block",
    marginLeft: "1em",
  },
  contactMeButtonNoPadding: {
    display: 'block'
  },
  contactMeButtonMobile: {
    display: "block",
    [theme.breakpoints.down(410)]: {
      marginTop: '1em'
    },
  },
  contactMeButtonText: {
    color: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: '8px',
    display: 'block'
  },
  contactMeButtonTextMobile: {
    color: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    display: 'block'
  },
  wouldRecommendText: {
    color: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "18px",
    textShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  wouldRecommendTextVisible: {
    color: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "20px",
    fontStyle: 'normal',
    fontWeight: 'bold'
  },
  logoContainer: {
    width: "106px",
  },
  contentMobile: {
    paddingLeft: "16px",
    minHeight: "110px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: ".65em",
    },
  },
  logoColumnMobile: {
    width: "110px",
    height: "110px",
    marginBottom: ".6em",
  },
  mobileNameLogoContainer: {
    width: "calc(100% - 110px)",
    minHeight: "110px",
  },
  mobileLogoMinHeight: {
    minHeight: "110px",
  },
  wouldRecommendContainer: {
    borderRadius: '8px',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%)',
    border: '0.5px solid #94A4D6',
    padding: '8px',
    width: '242px',
    marginTop: '8px'
  },
  wouldRecommendContainerVisible: {
    borderRadius: '8px',
    border: "0.5px solid #D4D4D4",
    padding: '8px',
    width: '242px',
    marginTop: '8px'
  },
  wouldRecommendContainerMobile: {
    borderRadius: '8px',
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%)',
    border: '0.5px solid #94A4D6',
    padding: '8px',
    width: '242px',
    marginRight: '10px'
  },
  wouldRecommendContainerMobileVisible: {
    borderRadius: '8px',
    border: "0.5px solid #D4D4D4",
    padding: '8px',
    width: '242px',
    marginRight: '10px'
  },
  wouldRecommendIconContainer: {
    width: '40px',
    height: '40px',
    marginRight: '8px'
  },
  wouldRecommendIconContainerMobile: {
    width: '32px',
    height: '32px',
    marginRight: '8px'
  },
  leaveReviewContainer: {
    width: 'calc(100% - 48px)'
  },
  wouldRecommendImageMobile: {
    maxHeight: '32px'
  },
  wouldRecText: {
    whiteSpace: "nowrap"
  },
}));

const ProviderHeader = ({ companyReference, node, isBlur, dispatch }) => {
  const client = createClient();

  var reviewCount = 0;
  var wouldRecommendScore = 0;

  //console.log("companyReference: ", companyReference);

  try {
    if (
      companyReference != null &&
      typeof companyReference.noOfReviews != "undefined" &&
      typeof companyReference.wouldRecommendRating != 'undefined'
    ) {
      reviewCount = companyReference.noOfReviews;
      wouldRecommendScore = Math.round(companyReference.wouldRecommendRating);
    }
  } catch (e) { }

  const classes = useStyles();
  const starIcons = useStarIcons();
  const [numberOfReviews, setNumberOfReviews] = useState(reviewCount);
  const [award, setAward] = useState([]);

  const [showDesktopReviewForm, setShowDesktopReviewForm] = useState(false);
  const [showMobileReviewForm, setShowMobileReviewForm] = useState(false);

  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);

  useEffect(() => {
    decideShowLeaveReviewForm(window.outerWidth);

    window.addEventListener("resize", () => {
      decideShowLeaveReviewForm(window.outerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);


  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true);
      setIsCampusTheme(isCampusTheme);
    })();
  }, [])

  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [providerHeaderLogoGoogleAnalyticsObject, setProviderHeaderLogoGoogleAnalyticsObject] = React.useState({});

  const decideShowLeaveReviewForm = function (windowSize) {
    if (windowSize >= 1200) {
      setShowMobileReviewForm(false);
      setShowDesktopReviewForm(true);
    } else if (windowSize < 1200 && windowSize >= 959) {
      setShowMobileReviewForm(true);
      setShowDesktopReviewForm(false);
    } else if (windowSize < 959 && windowSize >= 690) {
      setShowMobileReviewForm(false);
      setShowDesktopReviewForm(true);
    } else if (windowSize < 690) {
      setShowMobileReviewForm(true);
      setShowDesktopReviewForm(false);
    }
  }

  const handleClick = () => {
    setOpen((prev) => !prev);
    dispatch(toggleBlur());
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const mobileHandleClick = () => {
    setMobileOpen((prev) => !prev);
  };

  const mobileHandleClickAway = () => {
    setMobileOpen(false);
  };

  var compName = "";

  if (
    companyReference != null &&
    companyReference.companyDisplayName != "" &&
    typeof companyReference.companyDisplayName != "undefined"
  ) {
    compName = companyReference.companyDisplayName;
  } else {
    if (companyReference != null) {
      compName = companyReference.companyName;
    }
  }
  useEffect(() => {
    (async () => {
      var award = await getAward();

      setAward(award);

      // console.log("award: ", award);

      if (isCampusTheme) {
        var award = await getCampusAward();
        //console.log('award: ', award);
        setAward(award);
      }
    })();
  }, [isCampusTheme]);

  useEffect(() => {
    if (!open) {
      dispatch(toggleOff())
    }
    (async () => {
      if (open) {
        if (window.dataLayer) {
          var model = {
            Source: "Provider header hyperlink",
            CompanyName: compName,
          };

          window.dataLayer.push({
            company_review_popup_open_value: JSON.stringify(model),
          });

          window.dataLayer.push({ event: "company_review_popup_open" });
        }
      }
    })();
  }, [open]);

  useEffect(() => {
    (async () => {
      if (mobileOpen) {
        if (window.dataLayer) {
          var model = {
            Source: "Provider header hyperlink",
            CompanyName: compName,
          };

          window.dataLayer.push({
            company_review_popup_open_value: JSON.stringify(model),
          });

          window.dataLayer.push({ event: "company_review_popup_open" });
        }
      }
    })();
  }, [mobileOpen]);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(providerHeaderLogoGoogleAnalyticsObject) === '{}') {
        return;
      }

      if (window.dataLayer) {
        var model = {
          ProviderName: compName,
          Source: 'Provider header logo'
        }

        window.dataLayer.push({
          'provider_header_logo_clicked_value': JSON.stringify(model)
        });

        window.dataLayer.push({ event: 'provider_header_logo_clicked' });
      }
    })();
  }, [providerHeaderLogoGoogleAnalyticsObject]);

  var getAward = async function () {
    var dashboardId = "";

    if (
      companyReference != null &&
      companyReference.dashboardId != "" &&
      typeof companyReference.dashboardId != "undefined"
    ) {
      dashboardId = companyReference.dashboardId;
    }

    const params = {
      dashboardId: dashboardId,
    };
    //var query = '*[_type == $pageType && defined(bestBuy) && bestBuy[0].companyBBType==$tableType && bestBuy[0].showBBTable==true]{'
    //  + '"bestBuyLink": bestBuy[0].linkReference -> { url }, "isAffiliate": bestBuy[0].isAffiliate, "companyCats":bestBuy[0].companyBBCats,'
    //  + 'bestBuyLogo, body, companyDisplayName, companyHighlight, inANutshell, ourRating, yourRating}';

    var query =
      '* [_type == "companyCategory" && dashboardId==$dashboardId]' +
      "{" +
      '"bestBuyAward": bestBuyAward ->{providerPageLogo}' +
      "}";

    return client
      .fetch(query, params)
      .then((response) => response)
      .then((responseJson) => {
        return responseJson;
      });
  };

  var getCampusAward = async function () {
    var dashboardId = "";

    if (
      companyReference != null &&
      companyReference.dashboardId != "" &&
      typeof companyReference.dashboardId != "undefined"
    ) {
      dashboardId = companyReference.dashboardId;
    }

    const params = {
      dashboardId: dashboardId,
    };
    //var query = '*[_type == $pageType && defined(bestBuy) && bestBuy[0].companyBBType==$tableType && bestBuy[0].showBBTable==true]{'
    //  + '"bestBuyLink": bestBuy[0].linkReference -> { url }, "isAffiliate": bestBuy[0].isAffiliate, "companyCats":bestBuy[0].companyBBCats,'
    //  + 'bestBuyLogo, body, companyDisplayName, companyHighlight, inANutshell, ourRating, yourRating}';

    var query =
      '* [_type == "companyCategory" && dashboardId==$dashboardId]' +
      "{"
      + '"awardLogoVisible": * [_type == "bestBuyAward" && _id == ^.bestBuyAwardVisible._ref]{ providerPageLogo },' +
      "}";

    return client
      .fetch(query, params)
      .then((response) => response)
      .then((responseJson) => {
        return responseJson;
      });
  };
  var logoClickHandler = function () {
    setProviderHeaderLogoGoogleAnalyticsObject({
      NotEmptyObject: ''
    });
  }

  return (
    finishedCheckingTheme ?
    <>
      <Grid
        container
        alignItems="center"
        className={isCampusTheme ? classes.providerHeaderContainerVisible : classes.providerHeaderContainer}
      >
        <Grid item className={classes.logoContainer}>
          {companyReference &&
            companyReference.companyLogo &&
            companyReference.companyLogo.asset && (
              <ContainerForLinks
                reference={companyReference.reference}
                _type={companyReference.reference._type}
                newTab="true"
                onClickHandler={logoClickHandler}
              >
                <img
                  className={isCampusTheme ? classes.companyLogoVisible : classes.companyLogo}
                  src={imageUrlFor(buildImageObj(companyReference.companyLogo))
                    .width(106)
                    .height(106)
                    .fit("crop")
                    .url()}
                  alt=""
                />
              </ContainerForLinks>
            )}
        </Grid>
        <Grid item xs={8}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={isCampusTheme ? classes.contentContainerVisible : classes.contentContainer}
          >
            <Grid item xs={12}>
              <Typography variant="h2" className={isCampusTheme ? classes.companyNameVisible : classes.companyName}>
                {compName}
              </Typography>
              <Typography variant="h3" className={`${compName.length < 19 ? classes.fontSize36 : ''} ${classes.companyNameH3}`}>
                {compName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                {
                  numberOfReviews > 0 &&
                  <Grid item className={classes.positionRelative}>
                    <Grid container className={isCampusTheme ? classes.wouldRecommendContainerVisible : classes.wouldRecommendContainer}>
                      <Grid item className={classes.wouldRecommendIconContainer}>
                        <img src={isCampusTheme ? WouldRecommendIconVisible : WouldRecommendIcon} />
                      </Grid>
                      <Grid item className={classes.leaveReviewContainer}>
                        <Grid container>
                          <Grid item xs={12} className={classes.wouldRecText}>
                            <label
                              variant="h4"
                              className={`${isCampusTheme ? classes.wouldRecommendTextVisible : classes.wouldRecommendText}`}
                            >
                              {wouldRecommendScore}% Would recommend
                            </label>
                          </Grid>
                          <Grid item xs={12} className={classes.wouldRecNoReviews}>
                            <label className={isCampusTheme ? classes.numberOfReviewsLabelVisible : classes.numberOfReviewsLabel}>
                              Based on{' '}
                              <a
                                href="#customerreviews"
                                className={isCampusTheme ? classes.numberOfReviewsAnchorVisible : classes.numberOfReviewsAnchor}
                              >
                                {numberOfReviews} reviews
                    </a>
                            </label>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }


                <Grid item>
                  {
                    true &&

                    <div className={classes.contactMeButtonContainer}>
                      {open && <div className={classes.backdrop} />}
                      <ClickAwayListener onClickAway={handleClickAway}>
                        <div className={classes.rateButtonContainer}>
                          <a
                            className={`${numberOfReviews > 0 ? classes.contactMeButton : classes.contactMeButtonNoPadding}`}
                            onClick={handleClick}
                          >
                            <label
                              variant="h4"
                              className={`${classes.contactMeButtonText}`}
                            >
                              Leave a review
                            </label>
                          </a>
                          {open ? (
                            <Portal>
                              <Grid className={classes.dropdown}>
                                <RateCompanyForm
                                  onClickingAwayHandler={handleClickAway}
                                  companyId={
                                    companyReference &&
                                    companyReference.dashboardId
                                  }
                                  source={"Provider header hyperlink"}
                                  companyName={compName}
                                  one={'one'}
                                />
                              </Grid>
                            </Portal>
                          ) : null}
                        </div>
                      </ClickAwayListener>
                    </div>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          {!isCampusTheme && companyReference &&
            award.length > 0 &&
            award[0] &&
            award[0].bestBuyAward &&
            award[0].bestBuyAward.providerPageLogo &&
            award[0].bestBuyAward.providerPageLogo.asset && (
              <img
                className={classes.bestBuyLogo}
                src={imageUrlFor(
                  buildImageObj(award[0].bestBuyAward.providerPageLogo)
                )
                  .width(106)
                  .height(106)
                  .fit("crop")
                  .url()}
                alt=""
              />
              )}
            {isCampusTheme && companyReference &&
              award.length > 0 &&
              award[0] &&
              award[0].awardLogoVisible &&
              award[0].awardLogoVisible.length > 0 &&
              award[0].awardLogoVisible[0] &&
              award[0].awardLogoVisible[0].providerPageLogo &&
              award[0].awardLogoVisible[0].providerPageLogo.asset &&
              (
                <img
                  className={classes.awardLogoMobile}
                  src={imageUrlFor(
                    buildImageObj(award[0].awardLogoVisible[0].providerPageLogo)
                  )
                    .width(106)
                    .height(106)
                    .fit("crop")
                    .url()}
                  alt=""
                />
              )}
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="flex-start"
        className={isCampusTheme ? classes.providerHeaderContainerMobileVisible : classes.providerHeaderContainerMobile}
      >
        <div className={classes.logoColumnMobile}>
          {companyReference &&
            companyReference.companyLogo &&
            companyReference.companyLogo.asset && (
              <ContainerForLinks
                reference={companyReference.reference}
                _type={companyReference.reference._type}
                onClickHandler={logoClickHandler}
                newTab="true"
              >
                <img
                  className={isCampusTheme ? classes.companyLogoMobileVisible : classes.companyLogoMobile}
                  src={imageUrlFor(buildImageObj(companyReference.companyLogo))
                    .width(106)
                    .height(106)
                    .fit("crop")
                    .url()}
                  alt=""
                />
              </ContainerForLinks>
            )}
        </div>
        <Grid
          container
          alignItems="flex-start"
          className={classes.mobileNameLogoContainer}
        >
          <Grid item xs={9} className={classes.mobileLogoMinHeight}>
            <Grid
              container
              className={classes.contentMobile}
              alignItems="center"
              justify="flex-start"
            >
              <Grid item xs={12}>
                <Hidden smUp implementation="js">
                  <Typography variant="h4" className={isCampusTheme ? classes.companyNameMobileVisible : classes.companyNameMobile}>
                    {compName}
                  </Typography>
                </Hidden>
                <Hidden xsDown implementation="js">
                  <Typography variant="h3" className={isCampusTheme ? classes.companyNameMobileVisible : classes.companyNameMobile}>
                    {compName}
                  </Typography>
                </Hidden>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            {!isCampusTheme && companyReference &&
              award.length > 0 &&
              award[0] &&
              award[0].bestBuyAward &&
              award[0].bestBuyAward.providerPageLogo &&
              award[0].bestBuyAward.providerPageLogo.asset && (
                <img
                  className={classes.awardLogoMobile}
                  src={imageUrlFor(
                    buildImageObj(award[0].bestBuyAward.providerPageLogo)
                  )
                    .width(106)
                    .height(106)
                    .fit("crop")
                    .url()}
                  alt=""
                />
                )}
              {isCampusTheme && companyReference &&
                award.length > 0 &&
                award[0] &&
                award[0].awardLogoVisible &&
                award[0].awardLogoVisible.length > 0 &&
                award[0].awardLogoVisible[0] &&
                award[0].awardLogoVisible[0].providerPageLogo &&
                award[0].awardLogoVisible[0].providerPageLogo.asset &&
               (
                  <img
                    className={classes.awardLogoMobile}
                    src={imageUrlFor(
                      buildImageObj(award[0].awardLogoVisible[0].providerPageLogo)
                    )
                      .width(106)
                      .height(106)
                      .fit("crop")
                      .url()}
                    alt=""
                  />
                )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            {
              numberOfReviews > 0 &&

              <Grid item className={classes.positionRelative}>
                <Grid container className={isCampusTheme ? classes.wouldRecommendContainerMobileVisible : classes.wouldRecommendContainerMobile} alignItems="center" justify="flex-start">
                  <Grid item className={classes.wouldRecommendIconContainerMobile}>
                    <img src={isCampusTheme ? WouldRecommendIconVisible : WouldRecommendIcon} className={classes.wouldRecommendImageMobile} />
                  </Grid>
                  <Grid item className={classes.leaveReviewContainer}>
                    <Grid container>
                      <Grid item xs={12}>
                        <label
                          variant="h4"
                          className={`${classes.wouldRecommendText}`}
                        >
                          {wouldRecommendScore}% Would recommend
                            </label>
                      </Grid>
                      <Grid item xs={12}>
                        <label className={isCampusTheme ? classes.numberOfReviewsLabelVisible : classes.numberOfReviewsLabel}>
                          Based on{' '}
                          <a
                            href="#customerreviews"
                            className={isCampusTheme ? classes.numberOfReviewsAnchorVisible : classes.numberOfReviewsAnchor}
                          >
                            {numberOfReviews} reviews
                    </a>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item>
              {
                true &&

                <div className={classes.contactMeButtonContainerMobile}>
                  {mobileOpen && <div className={classes.backdrop} />}
                  <ClickAwayListener onClickAway={mobileHandleClickAway}>
                    <div className={classes.rateButtonContainer}>
                      <a
                        className={`${classes.contactMeButtonMobile}`}
                        onClick={mobileHandleClick}
                      >
                        <label
                          variant="h4"
                          className={`${classes.contactMeButtonTextMobile}`}
                        >
                          Leave a review
                      </label>
                      </a>
                      {mobileOpen ? (
                        <Portal>
                          <Grid className={classes.dropdown}>
                            <RateCompanyForm
                              onClickingAwayHandler={mobileHandleClickAway}
                              companyId={
                                companyReference && companyReference.dashboardId
                              }
                              source={"Provider header hyperlink"}
                              companyName={compName}
                              one={'two'}

                            />
                          </Grid>
                        </Portal>
                      ) : null}
                    </div>
                  </ClickAwayListener>
                </div>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </> : <></>
  );
};

export default connect((state) => ({
  isBlur: state.blurReducer.isBlur
}), null)(ProviderHeader);
