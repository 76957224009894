import React, {useEffect, useRef, useState} from "react";
import { providerCalculator, providerFeeCalculatorLog } from "../../lib/helpers";
import { useStaticQuery, graphql } from "gatsby";
import {makeStyles, withStyles, useTheme} from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import {Button, Grid, Input, InputAdornment, Typography} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from 'react-number-format';
import ReusableButton from "../reusableButton";
import Increment from "../icon/Increment";
import Decrement from "../icon/Decrement";
import calculator from "../icon/calculator.gif"
import ContainerForLinks from "../containerForLinks";
import {Link} from "gatsby";


const StyledInput = withStyles({

  root:{

  }
})(Input)

const InitialCalc = (props) => {
  const data = useStaticQuery(graphql`
    {
      allCalcApiIsa {
        nodes {
          platformType
          dashboardId
          cost
          companyName
          defaultCost
          defaultTrades
       }
      }

      allCalcApiJisa {
        nodes {
          platformType
          dashboardId
          cost
          companyName
          defaultCost
          defaultTrades
        }
      }

      allCalcApiLisa {
        nodes {
          platformType
          dashboardId
          cost
          companyName
          defaultCost
          defaultTrades
        }
      }

      allCalcApiPension {
        nodes {
          platformType
          dashboardId
          cost
          companyName
          defaultCost
          defaultTrades
        }
      }

      allSanityCalcAssumptions {
        nodes {
          lumpSum
          title
          assumptionRow
        }
      }
    }
  `);

  switch (props) {
    case "ISA":
      return data.allCalcApiIsa.nodes;
      break;
    case "JISA":
      return data.allCalcApiJisa.nodes;
      break;
    case "LISA":
      return data.allCalcApiLisa.nodes;
      break;
    case "PENSION":
      return data.allCalcApiPension.nodes;
      break;
    case "ASSUMPTIONS":
      return data.allSanityCalcAssumptions.nodes;
      break;
    default:
      return null;
      break;
  }

  //return result;
};



const CustomInput = (props) => {

  return (
    <Input {...props} type={"tel"} disableUnderline={true} />
  )
}

export default function Calculator(props) {

  const { dashboardId, defaultWrapper, wrapperOptions, wrapperText, title, introText } = props;
  const [initialCosts, setIntitialCosts] = useState(InitialCalc(defaultWrapper));
  const [assumptions, setCalcAssumptions] = useState(InitialCalc("ASSUMPTIONS"));
  const [getWrapper, setWrapper] = useState(defaultWrapper);
  const [calculating, setCalculating] = useState(false);
  const [costHolderHeight, setCostHolderHeight] = useState(0);
  const costHolderRef = useRef()

  const theme = useTheme()
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));

  //filter by companyID
  var companyInitData = function () {
    return initialCosts.find(entry => entry.dashboardId === dashboardId);
  }

  //filter by wrapper type
  var wrapperAssumptions = function () {

    //console.log("defaultWrapper", defaultWrapper, assumptions)
    return assumptions.find(entry => entry.title.toLowerCase().replace(" model","") == defaultWrapper.toLowerCase());
  }

  var wAssumptions = wrapperAssumptions();
  var cInitData = companyInitData();
  let companyName = cInitData?.companyName

  const [lumpSum, setLumpSum] = useState(wAssumptions && wAssumptions.lumpSum !== null && wAssumptions.lumpSum !== '' ? wAssumptions.lumpSum : 10000);
  const [assumptionRow, setAssumptionRow] = useState(wAssumptions && wAssumptions.assumptionRow !== null && wAssumptions.assumptionRow !== '' ? wAssumptions.assumptionRow : null);
  const [initialPrice, setInitialPrice] = useState(cInitData && cInitData.cost !== null && cInitData.cost !== '' ? cInitData.cost : 0);
  const [companyType, setCompanyType] = useState(cInitData && cInitData.platformType !== null && cInitData.platformType != '' ? cInitData.platformType: "ISA");


  // useEffect(() => {
  //   setCostHolderHeight(costHolderRef.current.clientHeight)
  // },[])

  const useStyles = makeStyles((theme) => ({
    formContainer:{
      borderRadius: "12px",
      border: "var(--Scale-spacing-0, 1px) solid var(--Border-Borders-light, #DBE3EF)",
      boxShadow: "0px 3px 8px -1px rgba(50, 50, 71, 0.05), 0px 0px 1px 0px rgba(12, 26, 75, 0.24)",
      background: "var(--Background-Background-white, #FFF)",
    },
    formSubContainer:{
      padding: "28px 28px 28px 28px",
      width: "100%",
      [theme.breakpoints.down("md")]:{
        padding: "16px",
      },
    },
    title: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      color: "#262641",
      lineHeight: "24px",
      paddingBottom: "16px",
    },
    subtitle:{
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
    },
    buttonRowContainer:{
      display: "flex",
      flexDirection: "row",
      paddingBottom: "28px",
      gap: "12px",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]:{
        paddingBottom: "24px",
        gap: "8px",
      },
    },
    visitSiteButton:{
      padding: "24px",

    },

    whiteContainer: {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      border: "1px solid var(--Border-Borders-light, #DBE3EF)",
      background: "var(--background-background-base, #F5F7FA)",
      display: "flex",
      paddingLeft: "16px",
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "24px",
      alignSelf: "stretch",
      width: "100%",
      height: "56px",
      boxShadow: "0px 2px 2px -1px rgba(74, 74, 104, 0.10) inset",
      [theme.breakpoints.down("md")]:{
        fontSize: "16px",
      },
      "& .MuiInput-input":{
        color: "#2F2F4E",
      },
      "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    endAdornmentOverride:{
    },
    inputValueButtons:{
      display: "flex",
      flexDirection: "column",
      height: "100%",
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      border: "1px solid var(--Border-Borders-light, #DBE3EF)",
      overflow: "hidden",
      borderLeft: 0,
    },
    inputAdornmentIncrease:{
      userSelect: "none",
      cursor: "pointer",
      "-webkit-touch-callout": "none",
    },
    inputAdornmentDecrease:{
      userSelect: "none",
      cursor: "pointer",
      "-webkit-touch-callout": "none",
    },
    inputHolder:{
      height: "56px",
      display: "flex",
    },
    amountsContainer:{
      display: "flex",
      flexDirection: "column",
    },
    buttonHolder:{
      paddingTop: "24px",
      width: "100%",
      "& .MuiButton-root":{
        maxHeight: "56px",
      },
    },
    blueSection:{
      backgroundColor: "#3C465F",
      borderRadius: "0 0 12px 12px",
      padding: "28px 28px 28px 28px",
      minHeight: calculating ? costHolderHeight : "auto",
      [theme.breakpoints.down("md")]:{
        padding: "16px",
        flexDirection: "column",
        minHeight: calculating ? costHolderHeight : "auto",
      },
      flexWrap: "nowrap"
    },
    blueSectionLoading:{
      backgroundColor: "#3C465F",
      borderRadius: "0 0 12px 12px",
      padding: "28px 28px 28px 28px",
      [theme.breakpoints.down("md")]:{
        padding: "16px",
        height: calculating ? costHolderHeight : "auto",
        flexDirection: "column",
      },
      flexWrap: "nowrap"
    },
    textWrapper:{
      borderRadius: "8px",
      backgroundColor: "#FFF",
      padding: "12px",
    },
    investmentFeesTitle:{
      color: "#262641",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
      margin: 0,
      paddingBottom: "8px"
    },
    investmentFeesSubtitle:{
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      margin: 0,
    },
    estimatedCostHolder:{
      color: "#FFFFFF",
      flexDirection:"column",
      flexGrow: 1,
      paddingRight: "24px",
      [theme.breakpoints.down("md")]:{
        paddingRight: 0,
        paddingBottom: "24px",
      },
    },
    estimatedCostTitle:{
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      margin: 0,
      paddingBottom: "10px",
      [theme.breakpoints.down("md")]:{
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px",
        letterSpacing: "-0.4px",
      },
    },
    estimatedCostTotal:{
      flex: 1,
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "58px",
      letterSpacing: "-2px",
      margin: 0,
      [theme.breakpoints.down("md")]:{
        lineHeight: "42px" ,
        letterSpacing: "-0.72px",
        fontSize: "36px",
      },
    },
    introBlockTitle:{
      fontSize: "36px",
      fontWeight: 600,
      lineHeight: "47px",
      letterSpacing: "-0.72px",
      marginBottom: "16px",
    },
    introBlockPara:{
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      marginBottom: "28px",
      [theme.breakpoints.down("md")]:{
        marginBottom: "24px",
      },
    },
    calculatorSpinner:{
      maxWidth: "107.744px",
      maxHeight: "96px",
      display: "flex",
    },
    calculatorSpinnerHolder:{
      // display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
    },
    disclaimerTitle:{
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      paddingBottom: "8px",
    },
    disclaimerSubtitle:{
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "normal",
    },
    linkText:{
      fontWeight: 400,
      textDecoration: "none",
      color: "#0070EA",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    disclaimerContainer:{
      paddingTop: "28px",
      [theme.breakpoints.down("md")]:{
        paddingTop: "24px",
      },
    },
  }));

  const classes = useStyles();
  function incrementCount() {
    const lumpSumParsed  = parseInt(lumpSum)
    setLumpSum(lumpSumParsed + 1)
  }

  function decrementCount() {
    const lumpSumParsed = parseInt(lumpSum)
    setLumpSum(lumpSumParsed - 1)
  }

  var callCalc = async function (amount, wrapper) {

    var trades = 0;

    if (wrapper == "CALCULATE MY FEES") {
      wrapper = getWrapper;
    }


    try {
      for (var i = 0; i < assumptionRow.length; i++) {
        var assumptionsArr = assumptionRow[i].split(',')

        var band1 = parseFloat(assumptionsArr[0]);
        var band2 = parseFloat(assumptionsArr[1]);

        if (amount >= band1 && amount <= band2) {
          trades = assumptionsArr[2]
          break; // If found, exit the loop
        }
      }
    } catch (e) {
      trades = 0;
    }


    var isaPerc = 0;
    var giaPerc = 0;
    var sippPerc = 0;
    var jisaPerc = 0;

    switch (wrapper) {
      case "ISA":
        isaPerc = "100.00"
        break;
      case "JISA":
        jisaPerc = "100.00"
        break;
      case "LISA":
        isaPerc = "100.00"
        break;
      case "PENSION":
        sippPerc = "100.00"
        break;
      default:
        isaPerc = "100.00"
        break;
    }

    const model = {
      "Amount": amount,
      "Provider": dashboardId,
      "Split": wrapper,
      "FundTradesFreqYear": trades,
      "isaPerc": isaPerc,
      "giaPerc": giaPerc,
      "sippPerc": sippPerc,
      "jisaPerc": jisaPerc,
      "WhereFrom":"provider"
    }

    var jsonModel = JSON.stringify(model);

    const result = await providerCalculator(jsonModel);
    console.log("result: ", result)
    const jsonObject = JSON.parse(result);

    return jsonObject;
  }

  const handleInputChange = (event) => {
    setLumpSum(event.value);
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    // Do something with the submitted value (lumpSum)

    setCalculating(true)
    const button = event.target;

    if (button.textContent != "CALCULATE MY FEES") {
      setWrapper(button.textContent)
    }

    if ((lumpSum !== "") && (lumpSum !== null)) {
      var c = await callCalc(lumpSum, button.textContent)

      //console.log('Submitted value:', lumpSum, " cost:", c[0].cost);
      try {
        const now = new Date().valueOf()
        setInitialPrice(c[0].cost)
        const waitTime = Math.max(0, -(new Date().valueOf() - now) + 1000)
        await new Promise(resolve => setTimeout(resolve, waitTime))
      } catch (e) {
        setInitialPrice(0)
      } finally {
        setCalculating(false)
      }
    }
    else {
      setInitialPrice(0)
      setCalculating(false)
    }


    //  Only Braze and Google Analytics log if submit button clicked
    if (button.textContent === "CALCULATE MY FEES") {
      providerFeeCalculatorLog(companyName, dashboardId, getWrapper, lumpSum)
    }

  };

  const determineButtonClassName = (buttonValue) => {
    return getWrapper === buttonValue
  };

  useEffect(() => {
    // Function to get and set the element's height
    const updateElementHeight = () => {
      if (costHolderRef.current) {
        const height = costHolderRef.current.getBoundingClientRect().height;
        setCostHolderHeight(height);
      }
    };

    // Call the function once when the component mounts
    updateElementHeight();

    // Attach an event listener to window resize to update height on resize
    window.addEventListener('resize', updateElementHeight);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateElementHeight);
    };
  }, []);
  return (
    <div>
      {introText &&
      <div>
        <Typography variant={"h2"} className={classes.introBlockTitle}>{title}</Typography>
        <Typography variant={"body1"} className={classes.introBlockPara}>{introText}</Typography>
      </div>
      }
      <Grid container className={classes.formContainer}>
        <div className={classes.formSubContainer}>
        <Grid container direction={"column"}  className={classes.titleContainer}>
          <Typography className={classes.title} variant={"h4"}>
            Step 1. Select account type.
          </Typography>
        </Grid>
        <Grid container alignItems="center" className={classes.buttonRowContainer}>
          {wrapperOptions.map((option) => {
            return <ReusableButton active={determineButtonClassName(option)} variant={matchesMobile ? "small" : "large"} linkText={option} arrowIcon={false} customClickEvent={handleSubmit} buttonColour={"dark-grey"}/>
          })}
        </Grid>
        <Grid container direction={"column"} className={classes.titleContainer}>
          <Typography className={classes.title} variant={"h4"}>
            Step 2. Enter the amount you are looking to invest.
          </Typography>
        </Grid>
        <Grid
          container
          className={classes.amountsContainer}
        >

          <Grid className={classes.inputHolder}>
            <NumberFormat
              value={lumpSum}
              customInput={CustomInput}
              onValueChange={handleInputChange}
              prefix={"£"}
              thousandSeparator={true}
              className={classes.whiteContainer}
              />

          <Grid className={classes.endAdornmentOverride} position="end">
            <div className={classes.inputValueButtons}>
              <Increment onContextMenu={(e) => e.preventDefault()} customEvent={incrementCount} customClass={classes} />
              <Decrement  customEvent={decrementCount} customClass={classes} />
            </div>
          </Grid>
          </Grid>
          <Grid container className={classes.buttonHolder}>
            <ReusableButton fullWidth={true} linkText={"CALCULATE MY FEES"} arrowIcon={false} variant={"large"} customClickEvent={handleSubmit} />
          </Grid>
        </Grid>
        </div>
        <Grid ref={costHolderRef}container className={classes.blueSection}>
          {calculating ? <div className={classes.calculatorSpinnerHolder}><img className={classes.calculatorSpinner} src={calculator} /></div> :
            <>
          <Grid container className={classes.estimatedCostHolder}>
              <p className={classes.estimatedCostTitle}>Estimated fees:</p>
              <NumberFormat
                            renderText={(value) => <p className={classes.estimatedCostTotal}>{value}</p>}
                            displayType={"text"}
                            className={classes.title}
                            decimalScale={2}
                            prefix={'£'}
                            thousandSeparator={true}
                            value={initialPrice}
              />
          </Grid>
          <Grid className={classes.containerForText}>
            <Typography className={classes.textWrapper}>
              <p className={classes.investmentFeesTitle}>{companyType === "Robo-advisor" ? 'All Inclusive' : '+ Investment fees'}</p>
              <p className={classes.investmentFeesSubtitle}>{wrapperText}</p>
            </Typography>
          </Grid>
            </>
        }
        </Grid>
      </Grid>
      <div className={classes.disclaimerContainer}>
        <Typography variant={"body1"} className={classes.disclaimerTitle}>
          The boring disclaimer bit
        </Typography>
        <Typography variant={"body1"} className={classes.disclaimerSubtitle}>
          This is a free resource for our readers. We’ve tried really hard to ensure that these calculations are correct and can promise you there’s no monkey business or favouritism going on. But please don’t rely on these calculations without double checking yourself. If you do think you have seen an error please contact <a className={classes.linkText} href={"mailto:community@boringmoney.co.uk"}>community@boringmoney.co.uk</a> and help us to make this better. More information on the assumptions we've used to calculate these investment fees can be found <Link className={classes.linkText} to={"/fees-calculator-assumptions"}>here</Link>.
        </Typography>
      </div>
    </div>
  );



}
