import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Checkbox, Grid, Icon, Typography } from "@material-ui/core";
import DataCaptureInput from "./inputs/dataCaptureInput";
// @ts-ignore
import Checked from "../../../icon/checked.svg";
// @ts-ignore
import NonChecked from "../../../icon/non-checked.svg";
import ReusableButton from "../../../reusableButton";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { DevTool } from "@hookform/devtools"
import axios from "axios";
import fileDownload from "js-file-download";
import { Link } from "gatsby";
import { SuccessfulSubmission } from "./layout/successfulSubmission";
import { VerifyForm } from "./layout/verifyForm";
import jwtDecode from "jwt-decode";
import RadioGroups from "./layout/radioGroups";

import { submitMultistageForm } from "../../../../lib/helpers";
import { emailCaptureSubmissionLogging } from "../../../../lib/helpers"


var CryptoJS = require("crypto-js");

import AuthenticatorNoRedirect from "../../../auth0/AuthenticatorNoRedirect";

const sign = require('jwt-encode');

function DataCaptureForm(props) {
  const {
    brazeCustomAttributes,
    dataCaptureInputs,
    downloadUrl,
    linkText,
    signupRedirectLink,
    _rawThankYouText,
    isSignUpPage,
    isVerifyPage,
    isLoginPage,
    isProfileCompletePage,
    handleChangeSignup,
    handleEmailSubmitSignup,
    email,
    signUpError,
    setEmailState,
    handleCodeSubmit,
    stage,
    setStage,
  } = props;



  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      brazeCustomAttributes: brazeCustomAttributes,
      marketingConsent: false,
      age: '0'
    },
  });


  // isProfileCompletePage && useCompleteProfile()

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "24px",
      paddingBottom: "24px",
      width: "100%",
      color: "#2F2F4E",
    },
    dataCaptureContainer: {
      width: "100%",
      borderRadius: "10px",
      padding: "1rem",
      marginLeft: "4.166666666666667%",
      marginRight: "4.166666666666667%",
      background: "#FFFFFF",
      border: "0.5px solid #DBE3EF",
      boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
      [theme.breakpoints.down("md")]: {
        padding: "24px 16px 24px 16px",
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: "528px",
        padding: "40px 48px",
      },
    },
    dataCaptureContainerProfileComplete: {
      width: "100%",
      borderRadius: "10px",
      padding: "1rem",
      marginLeft: "4.166666666666667%",
      marginRight: "4.166666666666667%",
      background: "#FFFFFF",
      border: "0.5px solid #DBE3EF",
      boxShadow: "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
      maxWidth: "528px",
      [theme.breakpoints.down("md")]: {
        padding: "16px",
      },
      [theme.breakpoints.up("md")]: {
        padding: stage === "1" ? "40px 48px" : "24px 48px",
      },
    },
    marketingConsentContainer: {
      marginTop: isSignUpPage ? "16px" : "24px",
      marginBottom: isSignUpPage ? 0 : "24px",
      [theme.breakpoints.up("md")]: {
        marginTop: isSignUpPage ? "16px" : "28px",
        marginBottom: isSignUpPage ? 0 : "28px",
      },
    },
    checkboxSpan: {
      color: "#2F2F4E",
      display: "inline-block",
      width: "calc(100% - 47px)",
      cursor: "pointer",
      fontSize: "12px",
      lineHeight: "16px",
    },
    icon: {
      width: "100%",
      height: "100%",
    },
    thankYouContainer: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      height: "100%",
      justifyContent: "center",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    formLabel: {
      display: "flex",
    },
    signUpTitle: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "28px",
      paddingBottom: "12px",
    },
    completeProfileTitle: {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "28px",
      paddingBottom: "8px",
    },
    alreadySignedInText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      paddingTop: "28px",
    },
    emailText: {
      textDecoration: "none",
      fontWeight: 500,
    },
    holderForAuth: {
      paddingTop: "28px",
    },
    authButtonContainer: {
      paddingTop: "24px",
    },
    linkText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      textDecoration: "none",
      color: "#0070EA",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    buttonCheckboxContainer: {
      flexDirection: isSignUpPage ? "column-reverse" : "column",
      paddingTop:
        isSignUpPage || isLoginPage || (isProfileCompletePage && stage === "2")
          ? "16px"
          : (isProfileCompletePage && stage === "1") ||
            (isProfileCompletePage && stage === "true")
            ? "28px"
            : 0,
      [theme.breakpoints.down("md")]: {
        paddingTop:
          isSignUpPage || isLoginPage || (isProfileCompletePage && stage === "2")
            ? "16px"
            : 0
      }
    },
    errorText: {
      fontWeight: 400,
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      paddingTop: "8px",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2F2F4E",
    },
    errorIcon: {
      height: "16px",
      paddingRight: "8px",
    },
    completeProfileBodyText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      paddingBottom: "8px",
    },
    completeProfileBodyTextStage1: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      paddingBottom: "28px",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "24px",
      }
    },
    signUpBodyText: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      textAlign: "center",
      paddingBottom: "28px",
      [theme.breakpoints.down("md")]: {
        paddingBottom: "24px",
      },
    },
    verificationText: {
      textAlign: "center",
    },
    buttonHolderRegister: {
      marginTop: "8px",
      paddingTop: "8px",
    },
  }));

  const classes = useStyles();

  const formRef = useRef(null);
  const [formHeight, setFormHeight] = useState(0);
  const [result, setResult] = useState();
  const [age, setAge] = useState<string>('')
  const [getEmail, setEmail] = useState(null);
  const [logGA, setLogGA] = useState(false);

  useEffect(() => {
    (async () => {
      if (logGA) emailCaptureSubmissionLogging()
    })();
  }, [logGA]);

  var auth = AuthenticatorNoRedirect();

  //console.log("signupRedirectLink-datacaptureform", signupRedirectLink, isProfileCompletePage)

  //useEffect(() => {
  //  setInputFieldNum(fieldContainerRef?.current?.children?.length)
  //}, [fieldContainerRef])

  useEffect(() => {
    if (formRef.current) setFormHeight(formRef.current.offsetHeight);
  }, [formRef]);

  const checkBoxStyles = () => ({
    root: {
      display: "inline-block",
      marginRight: "12px",
      padding: "0 !important",
      verticalAlign: "top",
      "&$checked": {
        color: "#3D70B2",
      },
      "& .MuiIconButton-label": {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    checked: {},
  });

  const iconStyles = () => ({
    root: {},
  });

  var onCodeSubmit = async function () {
    //console.log("onCodeSubmit")
    handleCodeSubmit(result);
  }

  const returnURL = localStorage.getItem("returnUrl")

  var retUrl = signupRedirectLink;

  if (retUrl == null) {
    retUrl = returnURL;
  }

  if (returnURL == "" || returnURL == null || returnURL == "null") {
    if (typeof window !== "undefined") {
      if (signupRedirectLink != null && signupRedirectLink != "") {
        localStorage.setItem("returnUrl", signupRedirectLink)
        retUrl = signupRedirectLink;
      }
      else {
        localStorage.setItem("returnUrl", "/members/")
        retUrl = "/members/";
      }
    }
  }

  var submitCompleteForm = async function (data) {


    console.log("submitCompleteForm")
    const tok = typeof window !== "undefined" && localStorage.getItem("id_token")

    const tokenData = jwtDecode(tok)

    const email = tokenData.email
    setEmail(email);
    const formAge = Number(age)

    if (stage !== "1") {
      const confidenceRetirementStatus = [
        {
          customAttributeKey: "Registration_Investing_Confidence",
          customAttributeValue: data.Registration_Investing_Confidence
        },
        {
          customAttributeKey: "Registration_Retirement_Status",
          customAttributeValue: data.Registration_Retirement_Status
        }]
      window.processCustomAttributes(confidenceRetirementStatus)
    }

    const result = await submitMultistageForm(data, email, stage, formAge, data.gender)

    console.log("RESULT-DCF", result)

    if (result) {
      if (isProfileCompletePage && stage === "1") {
        setStage("2")
        window.scrollTo(0, 0)
      }
      setStage(result)
      return result;
    } else {
      return result;
    }
  }

  var onSubmit = async function (data) {

    //console.log("onSubmit-dataCaptureForm", data, auth)



    setEmail(data.email);

    // localStorage.setItem("profile", "true");
    if ((signupRedirectLink == "null") || (signupRedirectLink == "") || (typeof signupRedirectLink == "undefined")) {
      //retUrl = "/members";

      if (!isProfileCompletePage) localStorage.setItem("returnUrl", retUrl)

      //console.log("onSubmit")


      //window.location.href = "/login?returnUrl=" + encodeURIComponent(retUrl) + "&usr=" + btoa(data.email);
    }

    if (isSignUpPage) {

      localStorage.setItem(
        "custom_attr",
        btoa(
          "SIGNUPPAGE_BM_SIGNUP" +
          "|" +
          "https://www.boringmoney.co.uk/sign-up/"
        )
      );
    }


    //store form values so they can be added post login/auth.
    if ((window.location.pathname != "/sign-up/") && (window.location.pathname != "/login/") && (!isProfileCompletePage)) {

      const secret = 'formBuilder';
      const jwt = sign(data, secret);
      localStorage.setItem("CaptureTemp", jwt)
      emailCaptureSubmissionLogging()

      if (!isProfileCompletePage) localStorage.setItem("returnUrl", retUrl)

      if ((signupRedirectLink == "null" && !returnURL) || (signupRedirectLink == "" && !returnURL) || (typeof signupRedirectLink == "undefined" && !returnURL)) {
        setLogGA(true)
        retUrl = "/members";

        window.location.href = "/login?returnUrl=" + encodeURIComponent(retUrl) + "&usr=" + btoa(data.email);
      }
      else {
        setLogGA(true)
        window.location.href = "/login?returnUrl=" + encodeURIComponent(retUrl) + "&usr=" + btoa(data.email);
      }


    }

    if (isLoginPage && !signUpError || isSignUpPage && !signUpError) {
      if (typeof window !== 'undefined') {
        window.brazeLogInReset();
      }
      localStorage.setItem("genMkt", data.marketingConsent)
      emailCaptureSubmissionLogging()
      //console.log("384")

      window.history.pushState(null, null, "?returnUrl=" + encodeURIComponent(returnURL) + "&usr=" + btoa(data.email))
      handleEmailSubmitSignup(data.email)

    } else if (isVerifyPage) {

      handleCodeSubmit(result)

    }
    else if (isProfileCompletePage) {

      // if (stage === 2){
      //   console.log("errors", errors)
      //   return null
      // }

      const completedFormValue = await submitCompleteForm(data)

      //console.log("setting profile on line 340 dataCaptForm value: ", completedFormValue)

      if (typeof window !== "undefined") {

        if (completedFormValue && stage === "2") {
          localStorage.setItem("profile", completedFormValue.toString())
        }

        //braze
        const tok = typeof window !== "undefined" && localStorage.getItem("id_token")
        const tokenData = jwtDecode(tok)

        const brazeSetRegDetails = async () => {

          var res = await window.brazeSetRegDetails(data.firstName, data.lastName, data.gender, data.age, tokenData.BrazeID);

          return res
        }

        var returnLink = "/members"

        if ((returnURL != "") && (returnURL != null) && (returnURL != "null")) {
          returnLink = retUrl
        }


        brazeSetRegDetails()
          .then(res => {
            console.log("res", res);

          })
          .catch((err) => { console.error(err); })
          .finally(() => {
            setTimeout(function () {
              if (stage === "2") {
                window.location.href = returnLink;
              }
            }, 200)
          })
      }

      //console.log("return url from data sub form: ", returnURL, retUrl)

      //setTimeout(function () {
      //  if ((returnURL != "") && (returnURL != null) && (returnURL != "null")) {
      //    window.location.href = retUrl
      //  }
      //  else {
      //    location.pathname = "/members";
      //  }
      //}, 1000);

      return null;
    }
    else {
      // Remove empty slots & blank custom attributes from array
      const flatAttributeArray = data.brazeCustomAttributes.flat();
      const filteredCustAttrArray = flatAttributeArray.filter(
        (custAttr) => custAttr.customAttributeValue !== undefined
      );
      if (auth) {
        var frmEmail = data.email;
        if ((frmEmail == null) || (typeof frmEmail == "undefined")) {
          const tok = typeof window !== "undefined" && localStorage.getItem("id_token")
          const tokenData = jwtDecode(tok)

          frmEmail = tokenData.email;
        }

        //async function processCustomAttributes() {
        //  if (brazeCustomAttributes && brazeCustomAttributes.length > 0) {
        //    for (var i = 0; i < brazeCustomAttributes.length; i++) {

        //      window.addCustomAttribute(brazeCustomAttributes[i].customAttributeKey, brazeCustomAttributes[i].customAttributeValue)

        //    }
        //  }
        //}
        if (typeof window !== 'undefined') {

          window.processCustomAttributes(brazeCustomAttributes).then(() => {
            if (signupRedirectLink && signupRedirectLink != null && signupRedirectLink != "") {
              location.pathname = signupRedirectLink;
            }
            else {
              location.pathname = "/members"
            }
          });
        }

      }

    }

  };

  useEffect(() => {
    if (signupRedirectLink && isSubmitSuccessful) {

      //console.log("referrer3", document.referrer, signupRedirectLink, auth)

      if (auth) {
        window.location.href = signupRedirectLink;
      }
      else {
        //not logged in so force through login process and don't redirect now, pass return url to be used once auth
        var retUrl = signupRedirectLink;
        if ((signupRedirectLink == "null") || (signupRedirectLink == "") || (typeof signupRedirectLink == "undefined")) {
          retUrl = "/members";

        }

        var url = "/login?returnUrl=" + encodeURIComponent(retUrl) + "&usr=" + btoa(getEmail);
        //console.log("url", url)
        window.location.href = url;
      }

      return null;
    }
    if (downloadUrl && isSubmitSuccessful) {
      var lastIndexOfDot = downloadUrl.lastIndexOf(".");

      if (lastIndexOfDot != -1) {
        var lastIndexOfForwardSlash = downloadUrl.lastIndexOf("/");

        var fileName = downloadUrl.slice(
          lastIndexOfForwardSlash + 1,
          downloadUrl.length
        );

        axios
          .get(downloadUrl, {
            responseType: "blob",
          })
          .then((res) => {
            fileDownload(res.data, fileName);
          });
      }
    }
  }, [isSubmitSuccessful]);

  const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
  const CustomIcon = withStyles(iconStyles)(Icon);

  function getBottomText(type) {

    const BottomText = {
      isSignUpPage: <Typography variant={"body1"} className={classes.alreadySignedInText}>
        Already have an account? <a className={classes.linkText} href={"/login"}>Login</a>
      </Typography>,
      isLoginPage: <Typography variant={"body1"} className={classes.alreadySignedInText}>
        Don't have an account? <a className={classes.linkText} href={"/sign-up"}>Sign up</a>
      </Typography>,
      default: <></>,
    }

    return BottomText[type] || BottomText.default
  }

  function getTopText(type) {

    const TopText = {
      isSignUpPage:
        <>
          <Typography variant={"body1"} className={classes.signUpTitle}>
            Join Boring Money!
          </Typography>
          <Typography variant={"body1"} className={classes.signUpBodyText}>
            Get free access to investing tips, bite-sized courses and independent expert help. Join over 37,000 members and start making better choices with
            your money today.
          </Typography>
        </>,
      isLoginPage: <Typography variant={"body1"} className={classes.signUpTitle}>
        Login to Boring Money
      </Typography>,
      isProfileCompletePage:
        <>
          <Typography variant={"body1"} className={classes.completeProfileTitle}>

            {stage === "1" ?
              "Welcome to Boring Money!" :
              stage === "2" ?
                "Almost there!" :
                stage === "true" ?
                  "Welcome back!" : "Welcome to Boring Money!"
            }
          </Typography>
          <Typography variant={"body1"} className={stage === "1" || stage === "true" ? classes.completeProfileBodyTextStage1 : classes.completeProfileBodyText}>
            {stage === "1" ?
              "A few quick questions help us to cut to the chase and support you better." :
              stage === "2" ?
                "Two more questions so we don’t baffle or bore you. Then we’re done." :
                stage === "true" ?
                  "Before you dig in, a few simple questions which help us to share more useful tips and ideas." :
                  "A few quick questions help us to cut to the chase and support you better."
            }


          </Typography>
        </>,
      default: <></>,
    }

    return TopText[type] || TopText.default
  }




  return (
    <div className={classes.container}>
      <div
        className={
          isProfileCompletePage
            ? classes.dataCaptureContainerProfileComplete
            : classes.dataCaptureContainer
        }
      >
        {isVerifyPage ?
          <VerifyForm
            signUpError={signUpError}
            email={email}
            ref={formRef}
            classes={classes}
            setResult={setResult}
            result={result}
            handleCodeSubmit={handleCodeSubmit}
            onCodeSubmit={onCodeSubmit}
          />
          : (
            <>
              {getTopText(
                isLoginPage
                  ? "isLoginPage"
                  : isSignUpPage
                    ? "isSignUpPage"
                    : isProfileCompletePage
                      ? "isProfileCompletePage"
                      : null
              )}
              <form onSubmit={(e) => e.preventDefault()} className={classes.formContainer} ref={formRef}>
                <Grid container spacing={isProfileCompletePage && stage === "2" ? 0 : 2}>
                  {isProfileCompletePage && stage === "2" ?
                    <>
                      <RadioGroups errors={errors} control={control} />
                    </> :
                    dataCaptureInputs && dataCaptureInputs.length > 0 && dataCaptureInputs.map(
                      (
                        {
                          inputFieldLabel,
                          inputFieldType,
                          inputFieldPlaceholderText,
                          inputFieldBrazeCustomAttributeKey,
                          requiredField,
                          errorText,
                        },
                        index
                      ) => {
                        return (
                          <DataCaptureInput
                            inputFieldType={inputFieldType}
                            inputFieldLabel={inputFieldLabel}
                            requiredField={requiredField}
                            inputFieldPlaceholderText={inputFieldPlaceholderText}
                            register={register}
                            control={control}
                            errors={errors}
                            errorText={errorText}
                            brazeCustomAttributes={brazeCustomAttributes}
                            index={index}
                            setValue={setValue}
                            age={age}
                            setAge={setAge}
                            inputFieldBrazeCustomAttributeKey={
                              inputFieldBrazeCustomAttributeKey
                            }
                            isSignUpPage={isSignUpPage}
                            isLoginPage={isLoginPage}
                            handleChangeSignup={handleChangeSignup}
                            handleEmailSubmitSignup={handleEmailSubmitSignup}
                            signupRedirectLink={signupRedirectLink}
                            auth={auth}
                            stage={stage}
                          />
                        );
                      }
                    )}
                </Grid>
                <Grid className={classes.buttonCheckboxContainer} container xs={12} >
                  {!isLoginPage && !isProfileCompletePage && !auth && <Grid item xs={12} className={classes.marketingConsentContainer}>
                    <label className={classes.formLabel}>
                      <Controller
                        name={"marketingConsent"}
                        control={control}
                        defaultValue={false}
                        render={({ field: props }) => (
                          <CustomCheckbox
                            {...props}
                            disableRipple
                            style={{ backgroundColor: "transparent" }}
                            icon={
                              <CustomIcon className={classes.icon}>
                                <img src={NonChecked} height={24} width={24} />
                              </CustomIcon>
                            }
                            checkedIcon={
                              <CustomIcon className={classes.icon}>
                                <img src={Checked} height={24} width={24} />
                              </CustomIcon>
                            }
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                            name="marketingConsent"
                          />
                        )}
                      />
                      <span className={classes.checkboxSpan}>
                        I would like to hear from you about products, events, general
                        marketing and other things from Boring Money – including
                        personalised content and ads - but please try not to make them
                        as boring as everything else in finance!
                      </span>
                    </label>
                  </Grid>
                  }
                  <Grid item xs={12} className={auth && !isProfileCompletePage ? classes.buttonHolderRegister : null}>
                    <ReusableButton
                      type={isSignUpPage || isVerifyPage ? "submit" : null}
                      pageType={"full width"}
                      linkText={linkText}
                      buttonType={"rectangular"}
                      customClickEvent={handleSubmit(onSubmit)}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </form>
              {isSignUpPage && getBottomText("isSignUpPage")}
              {isLoginPage && getBottomText("isLoginPage")}
            </>
          )}
      </div>
      {/* Debug useHookForm */}
      {/* <DevTool  control={control}/> */}
    </div>
  );
}

export default DataCaptureForm;
