import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RecaptchaLoader from "../../lib/RecaptchaLoader";

import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import DangerTriangle from "../icon/DangerTriangle.svg";
import CloseSquare from "../icon/CloseSquare.svg";
import ArrowRightSquare from "../icon/ArrowRightSquare.svg";
import ArrowLeft from "../icon/ArrowLeft.svg";
import Rating from '@material-ui/lab/Rating';
import RateCompanyStep3MessageSent from "./components/rateCompanyStep3MessageSent";
import Send from "../icon/Send.svg";
import StarIcon from "../icon/Star.svg";
import EmptyStarIcon from "./img/empty-star.svg";
import { createClient } from "../../lib/helpers";
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Recaptcha from "react-recaptcha";
import { useStaticQuery, graphql } from "gatsby";
import useStarIcons from "../starIcons";

let recaptchaInstance;
const executeCaptcha = (e) => {
  e.preventDefault();
  recaptchaInstance.reset();
  recaptchaInstance.execute();

};

const useStyles = makeStyles((theme) => ({

  sendAMessage: {
    color: "#3C455C",
  },
  collapsePopupButton: {
    minWidth: "24px !important",
    width: 24,
    height: 24,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  popupHeader: {
    marginBottom: "1em",
  },
  yourQuestionText: {
    marginBottom: "16px",
    "& textarea": {
      padding: "17px 24px",
      lineHeight: "24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  disclaimerContainer: {
    padding: "8px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginBottom: "16px",
  },
  nextButton: {
    backgroundColor: "#DBE3EF !important",
    borderRadius: "8px",
    width: "123px",
    height: "56px",
    position: 'absolute !important',
    bottom: '0',
    right: '0',
    [theme.breakpoints.down("xs")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
  },
  nextImage: {
    marginLeft: '.3em'
  },
  basicFieldName: {
    marginBottom: "16px",
    width: "96%",
    "& input": {
      padding: "17px 24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  basicFieldNameFullWidth: {
    marginBottom: "16px",
    width: "100%",
    "& input": {
      padding: "17px 24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  select: {
    width: "100%",
    borderRadius: "8px",
    padding: "16px, 24px, 16px, 24px",
    "& .MuiPaper-root": {
      left: "24px !important",
      top: "280px !important",
      [theme.breakpoints.down("xs")]: {
        left: "14px !important",
      },
    },
    "& .MuiInputBase-root": {},
  },
  captchaDisclaimer: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#8C8CA1",
    marginTop: "27px",
    marginBottom: "27px",
  },
  popupSubmitButton: {
    backgroundColor: "#FF0F5F",
    padding: "16px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    "&:hover": {
      backgroundColor: "#FF0F5F",
    },
    borderRadius: '8px'
  },
  popupSubmitButtonText: {
    color: "#fff",
    paddingRight: "8px"
  },
  popupBackButton: {
    padding: "1em",
    background: '#FFFFFF',
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    borderRadius: '8px'
  },
  popupBackButtonText: {
    paddingLeft: "8px"
  },
  ratingContainer: {
    marginBottom: '.8em',
    marginTop: '9px'
  },
  stepOneContainer: {
  },
  form: {
    height: '100%',
    position: 'relative'
  },
  errorLabel: {
    color: '#FF0F5F',
    display: 'block',
    width: '300px'
  },
  error: {
    border: '1px solid #FF0F5F',
    borderRadius: '8px',
    marginBottom: '0 !important'
  },
  ratingErrorLabel: {
    color: '#FF0F5F',
    display: 'block',
    lineHeight: '.9em'
  },
  disclaimer: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#2F2F4E",
    maxWidth: "312px",
    paddingLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "263px",
    },
  },

  clientLabel: {

  },
  assetsFieldset: {
  },
  assetLabel: {
  },
  buttonsContainer: {
    // position: 'absolute',
    bottom: '0',
    left: '0'
  },
  companyName: {
    color: '#262641',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '1em'
  },
  body: {
    color: '#2F2F4E',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px'
  },
  stepTwoBody: {
    color: '#2F2F4E',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '1em'
  },
  recap: {
    display: "none",
  },
  root: {
    marginTop: '1em',
    marginBottom: '1.4em',
    '& .MuiPaginationItem-root': {
      minWidth: '29px !important',
      height: '29px !important',
      borderRadius: '14.5px !important',
      backgroundColor: '#F5F7FA',
      [theme.breakpoints.down("xs")]: {
        minWidth: '23px !important',
        height: '23px !important',
        borderRadius: '11.5px !important',
      },
    },
    '& .Mui-selected': {
      background: '#3C465F;',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      height: '29px',
      width: '29px',
      borderRadius: '14.5px'
    },
    '& .MuiPaginationItem-ellipsis': {
      paddingTop: '.8em'
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '1.5rem'
    },
    '& ul > li > button:not(.Mui-selected),.MuiPaginationItem-ellipsis': {
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#2F2F4E',
      height: '3.5em',
      width: '3.5em',
      borderRadius: '1.75em'
    },

  },
}));

const GetDictionary = () => {
  const data = useStaticQuery(graphql`
      {
         allSanityDictionary(
          filter: {key: {in: [
          "rate_company_popup_first_step_header_text",
          "rate_company_popup_second_step_header_text",
          "rate_company_popup_would_you_recommend",
          "rate_company_popup_recommendation_required_error_message",
          "rate_company_popup_value_for_money_text",
          "rate_company_popup_value_for_money_error_message",
          "rate_company_popup_overall_service_text",
          "rate_company_popup_overall_service_required_error_message",
          "rate_company_popup_rate_website_experience_text",
          "rate_company_popup_rate_website_experience_error_message",
          "rate_company_popup_next_button_text",
          "rate_company_popup_review_placeholder",
          "rate_company_popup_review_required_error_message",
          "rate_company_popup_first_name_will_be_published_text",
          "rate_company_popup_first_name_placeholder",
          "rate_company_popup_first_name_required_error_message",
          "rate_company_popup_surname_placeholder",
          "rate_company_popup_email_placeholder",
          "rate_company_popup_email_required_error_message",
          "rate_company_popup_email_invalid_error_message",
          "rate_company_popup_disclaimer_text",
          "rate_company_popup_back_button_text",
          "rate_company_popup_submit_button_text",
          "rate_company_popup_thank_you_message",
          "rate_company_popup_thank_you_message_body",
          "rate_company_popup_close_button_text"
          ]}}
        ) {
          nodes {
            value
            key
          }
        }
      }
    `)

  return data.allSanityDictionary.nodes;
}

export default function RateCompanyForm(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    clearErrors,
    setValue
  } = useForm();

  const [page, setPage] = useState(0);
  const [valueForMoney, setValueForMoney] = useState(0);
  const [overallService, setOverallService] = useState(0);
  const [websiteExperience, setWebsiteExperience] = useState(0);

  const [firstStepHeaderText, setFirstStepHeaderText] = useState('');
  const [secondStepHeaderText, setSecondStepHeaderText] = useState('');
  const [wouldYouRecommend, setWouldYouRecommendText] = useState('');
  const [recommendationRequiredErrorMessage, setRecommendationRequiredErrorMessage] = useState('');
  const [valueForMoneyText, setValueForMoneyText] = useState('');
  const [valueForMoneyRequiredErrorMessage, setValueForMoneyRequiredErrorMessage] = useState('');
  const [overallServiceText, setOverallServiceText] = useState('');
  const [overalServiceRequiredErrorMessage, setOveralServiceRequiredErrorMessage] = useState('');
  const [rateWebsiteExperienceText, setRateWebsiteExperienceText] = useState('');
  const [websiteExperienceRequiredErrorMessage, setWebsiteExperienceRequiredErrorMessage] = useState('');
  const [nextButtonText, setNextButtonText] = useState('');
  const [reviewPlaceholder, setReviewPlaceholder] = useState('');
  const [reviewRequiredErrorMessage, setReviewRequiredErrorMessage] = useState('');
  const [firstNameWillBePublishedText, setFirstNameWillBePublishedText] = useState('');
  const [firstNamePlaceholder, setFirstNamePlaceholder] = useState('');
  const [firstNameRequirerdErrorMessage, setFirstNameRequirerdErrorMessage] = useState('');
  const [surnamePlaceholder, setSurnamePlaceholder] = useState('');
  const [emailPlaceholder, setEmailPlaceholder] = useState('');
  const [emailRequiredErrorMessage, setEmailRequiredErrorMessage] = useState('');
  const [emailInvalidErrorMessage, setEmailInvalidErrorMessage] = useState('');
  const [disclaimerText, setDisclaimerText] = useState('');
  const [backButtonText, setBackButtonText] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('');
  const [thankYouMessage, setThankYouMessage] = useState('');
  const [thankYouMessageBody, setThankYouMessageBody] = useState('');
  const [closeButtonText, setCloseButtonText] = useState('');
  const [dictionary, setDictionary] = useState(GetDictionary);
  const [companyReviewSubmitted, setCompanyReviewSubmitted] = useState(false);

  const classes = useStyles();
  const starIcons = useStarIcons();

  useEffect(() => {
    (async () => {
      setFirstStepHeaderText(dictionary.find(x => x.key === 'rate_company_popup_first_step_header_text').value);
      setSecondStepHeaderText(dictionary.find(x => x.key === 'rate_company_popup_second_step_header_text').value);
      setWouldYouRecommendText(dictionary.find(x => x.key === 'rate_company_popup_would_you_recommend').value);
      setRecommendationRequiredErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_recommendation_required_error_message').value);
      setValueForMoneyText(dictionary.find(x => x.key === 'rate_company_popup_value_for_money_text').value);
      setValueForMoneyRequiredErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_value_for_money_error_message').value);
      setOverallServiceText(dictionary.find(x => x.key === 'rate_company_popup_overall_service_text').value);
      setOveralServiceRequiredErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_overall_service_required_error_message').value);
      setRateWebsiteExperienceText(dictionary.find(x => x.key === 'rate_company_popup_rate_website_experience_text').value);
      setWebsiteExperienceRequiredErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_rate_website_experience_error_message').value);
      setNextButtonText(dictionary.find(x => x.key === 'rate_company_popup_next_button_text').value);
      setReviewPlaceholder(dictionary.find(x => x.key === 'rate_company_popup_review_placeholder').value);
      setReviewRequiredErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_review_required_error_message').value);
      setFirstNameWillBePublishedText(dictionary.find(x => x.key === 'rate_company_popup_first_name_will_be_published_text').value);
      setFirstNamePlaceholder(dictionary.find(x => x.key === 'rate_company_popup_first_name_placeholder').value);
      setFirstNameRequirerdErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_first_name_required_error_message').value);
      setSurnamePlaceholder(dictionary.find(x => x.key === 'rate_company_popup_surname_placeholder').value);
      setEmailPlaceholder(dictionary.find(x => x.key === 'rate_company_popup_email_placeholder').value);
      setEmailRequiredErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_email_required_error_message').value);
      setEmailInvalidErrorMessage(dictionary.find(x => x.key === 'rate_company_popup_email_invalid_error_message').value);
      setDisclaimerText(dictionary.find(x => x.key === 'rate_company_popup_disclaimer_text').value);
      setBackButtonText(dictionary.find(x => x.key === 'rate_company_popup_back_button_text').value);
      setSubmitButtonText(dictionary.find(x => x.key === 'rate_company_popup_submit_button_text').value);
      setThankYouMessage(dictionary.find(x => x.key === 'rate_company_popup_thank_you_message').value);
      setThankYouMessageBody(dictionary.find(x => x.key === 'rate_company_popup_thank_you_message_body').value);
      setCloseButtonText(dictionary.find(x => x.key === 'rate_company_popup_close_button_text').value);
    })();
  }, [dictionary]);

  function handleOnClickingAwayHandler() {
    props.onClickingAwayHandler();
  }

  function onSubmit(data) {


    if (typeof data.Review =="undefined") {
      //console.log('data.Review:', data.Review);

      data.Review = "no text"
    }

    if (typeof data.LastName == "undefined") {
      //console.log('data.LastName:', data.LastName);

      data.LastName = "-"
    }

    var rateCompanyModel = {
      CompanyId: props.companyId,
      ReaderFirstName: data.FirstName,
      ReaderLastName: data.LastName,
      ReaderEmail: data.Email,
      Review: data.Review,
      CompanyRating: data.Recommend,
      OverallService: data.OverallService * 2,
      ValueForMoney: data.ValueForMoney * 2,
      WebsiteExperience: data.WebsiteExperience * 2
    }

    //console.log('ratecompany model:', rateCompanyModel);

    fetch(process.env.GATSBY_QUESTIONAPI + "/AddCompanyRating/PostReaderMessage", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rateCompanyModel),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => {
        console.error('Error:', error);
      });

    setStep(3);
    setCompanyReviewSubmitted(true);
  }
  const [step, setStep] = React.useState(1);
  const [selectedPage, setSelectedPage] = React.useState(0);

  // var apiURL = process.env.GATSBY_QUESTIONAPI;
  // console.log(apiURL)
  function handleNext(data) {
    data.OverallService && data.Recommend && data.ValueForMoney && data.WebsiteExperience && setStep(2);
  }
  function handleBack(data) {
    setStep(1);
  }
  function handlePageItemClick(event) {
    var newValue = parseInt(event.target.innerText);

    setSelectedPage(newValue);
    setValue('Recommend', newValue, { shouldDirty: true });
    clearErrors('Recommend');
  }

  useEffect(() => {
    (async () => {
      if (companyReviewSubmitted) {
        var model = {
          Source: props.source,
          CompanyName: props.companyName
        }

        if (window.dataLayer) {
          window.dataLayer.push({ 'company_review_submitted_value': JSON.stringify(model) });

          window.dataLayer.push({ event: 'company_review_submitted' });
        }

        //braze
        try {
          window.trackCompanyReviewSubmit(props.companyName, props.companyId, props.source)
        } catch (e) {

        }


      }
    })();
  }, [companyReviewSubmitted]);

  return (
    <form className={classes.form}>
      <RecaptchaLoader />
      <Recaptcha
        ref={e => recaptchaInstance = e}
        sitekey="6LcWa-IbAAAAAAY8dsZcdQVnp8ycB-9iM0dbl6g8"
        size="invisible"
        verifyCallback={handleSubmit(onSubmit)}
        className={classes.recap}
      />
      {(step === 1 || step === 2) && (
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          className={classes.popupHeader}
        >
          <Typography variant="h4" className={classes.sendAMessage}>
            {step === 1 && firstStepHeaderText} {step === 2 && secondStepHeaderText}
          </Typography>

          <Button
            className={classes.collapsePopupButton}
            onClick={() => handleOnClickingAwayHandler()}
          >
            <img src={CloseSquare} alt="CloseSquare" />
          </Button>
        </Grid>
      )}
      {step === 1 && (
        <div className={classes.stepOneContainer}>
          <Typography variant="h4" className={classes.companyName}>
            {props.companyName}
          </Typography>
          <Typography variant="h4" className={classes.body}>
            {wouldYouRecommend}
          </Typography>
          <Controller
            name="Recommend"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <div className={`${errors.Recommend?.type === "required" ? classes.error : ''} ${classes.root}`}>
                <PaginationItem
                  page={1}
                  selected={selectedPage == 1}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={2}
                  selected={selectedPage == 2}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={3}
                  selected={selectedPage == 3}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={4}
                  selected={selectedPage == 4}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={5}
                  selected={selectedPage == 5}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={6}
                  selected={selectedPage == 6}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={7}
                  selected={selectedPage == 7}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={8}
                  selected={selectedPage == 8}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={9}
                  selected={selectedPage == 9}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
                <PaginationItem
                  page={10}
                  selected={selectedPage == 10}
                  classes={{ selected: classes.selected }}
                  onClick={(event) => handlePageItemClick(event)}
                />
              </div>
            )}
          />
          {errors.Recommend?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
              {recommendationRequiredErrorMessage}
            </span>}
          <Typography variant="h4" className={classes.body}>
            {valueForMoneyText}
          </Typography>
          <Controller
            name="ValueForMoney"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="ValueForMoney"
                  precision={1}
                  value={valueForMoney}
                  className={`${errors.ValueForMoney?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setValueForMoney(newValue);
                    setValue('ValueForMoney', newValue, { shouldDirty: true });
                    clearErrors('ValueForMoney');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={starIcons.starImageRate}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={starIcons.starImageRate}
                    />
                  }
                />
              </>

            )}
          />
          {errors.ValueForMoney?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
              {valueForMoneyRequiredErrorMessage}
            </span>}
          <Typography variant="h4" className={classes.body}>
            {overallServiceText}
          </Typography>
          <Controller
            name="OverallService"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="OverallService"
                  precision={1}
                  value={overallService}
                  className={`${errors.OverallService?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setOverallService(newValue);
                    setValue('OverallService', newValue, { shouldDirty: true });
                    clearErrors('OverallService');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={starIcons.starImageRate}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={starIcons.starImageRate}
                    />
                  }
                />
              </>

            )}
          />
          {errors.OverallService?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
              {overalServiceRequiredErrorMessage}
            </span>}
          <Typography variant="h4" className={classes.body}>
            {rateWebsiteExperienceText}
          </Typography>
          <Controller
            name="WebsiteExperience"
            control={control}
            rules={{ required: true }}
            render={(field) => (
              <>
                <Rating
                  name="WebsiteExperience"
                  precision={1}
                  value={websiteExperience}
                  className={`${errors.WebsiteExperience?.type === "required" ? classes.error : ''} ${classes.ratingContainer}`}
                  onChange={(event, newValue) => {
                    setWebsiteExperience(newValue);
                    setValue('WebsiteExperience', newValue, { shouldDirty: true });
                    clearErrors('WebsiteExperience');
                  }}
                  icon={
                    <img
                      src={StarIcon}
                      alt="StarIcon"
                      className={starIcons.starImageRate}
                    />
                  }
                  emptyIcon={
                    <img
                      src={EmptyStarIcon}
                      alt="EmptyStarIcon"
                      className={starIcons.starImageRate}
                    />
                  }
                />
              </>

            )}
          />
          {errors.WebsiteExperience?.type === "required" &&
            <span className={classes.ratingErrorLabel}>
              {websiteExperienceRequiredErrorMessage}
            </span>}
          <Button
            className={classes.nextButton}
            onClick={handleSubmit(handleNext)}
          >
            <Typography variant="button">{nextButtonText}</Typography>{" "}
            <img src={ArrowRightSquare} alt="ArrowRightSquare" className={classes.nextImage} />
          </Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <Grid item container>
            <Grid item xs={12}>
              <Controller
                name="Review"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={3}
                    placeholder={reviewPlaceholder}
                    className={`${errors.Review?.type === "required" ? classes.error : ''} ${classes.yourQuestionText}`}
                  />
                )}
              />
              {errors.Review?.type === "required" &&
                <span className={classes.errorLabel}>
                  {reviewRequiredErrorMessage}
                </span>}
            </Grid>
            <Typography variant="h4" className={classes.stepTwoBody}>
              {firstNameWillBePublishedText}
            </Typography>
            <Grid item xs={6}>
              <Controller
                name="FirstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder={firstNamePlaceholder}
                    className={`${errors.FirstName?.type === "required" ? classes.error : ''} ${classes.basicFieldName}`}
                    fullWidth
                  />
                )}
              />
              {errors.FirstName?.type === "required" &&
                <span className={classes.errorLabel}>
                  {firstNameRequirerdErrorMessage}
                </span>}
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Controller
                name="LastName"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder={surnamePlaceholder}
                    className={classes.basicFieldName}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Controller
            name="Email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                placeholder={emailPlaceholder}
                className={`${errors.Email?.type === "required" || errors.Email?.type === "pattern" ? classes.error : ''} ${classes.basicFieldNameFullWidth}`}
                fullWidth
              />
            )}
          />
          {(errors.Email?.type === "required" &&
            <span className={classes.errorLabel}>
              {emailRequiredErrorMessage}
            </span>) || (
              errors.Email?.type === "pattern" &&
              <span className={classes.errorLabel}>
                {emailInvalidErrorMessage}
              </span>
            )}
          <Grid
            item
            container
            alignItems="flex-start"
            className={classes.disclaimerContainer}
          >
            <Grid item>
              <img src={DangerTriangle} alt="DangerTriangle" />
            </Grid>
            <Grid item className={classes.disclaimer}>
              <Typography variant="body2" className={classes.disclaimer}>
                {disclaimerText}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justify="space-between" className={classes.buttonsContainer}>
            <Button onClick={() => handleBack()} className={classes.popupBackButton}>
              <img src={ArrowLeft} alt="ArrowLeft" />
              <Typography variant="button" className={classes.popupBackButtonText}>{backButtonText}</Typography>
            </Button>
            <Button type="submit" className={classes.popupSubmitButton} onClick={executeCaptcha}>
              <Typography variant="button" className={classes.popupSubmitButtonText}>{submitButtonText} </Typography>
              <img src={Send} alt="Send" />
            </Button>
          </Grid>
        </div>
      )}

      {step === 3 && (
        <RateCompanyStep3MessageSent onClickingAwayHandler={handleOnClickingAwayHandler} thankYouMessage={thankYouMessage} thankYouMessageBody={thankYouMessageBody} closeButtonText={closeButtonText} />
      )}
    </form>
  );
}
{
}
