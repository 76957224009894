import * as React from "react";
import {
  Checkbox,
  Grid,
  Icon,
  Typography,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import RadioInput from "../inputs/radioInput";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
// @ts-ignore
import FairlyConfident from "../icons/confidenceImages/FairlyConfident.svg";
// @ts-ignore
import NotAtAllConfident from "../icons/confidenceImages/NotAtAllConfident.svg";
// @ts-ignore
import NotVeryConfident from "../icons/confidenceImages/NotVeryConfident.svg";
// @ts-ignore
import VeryConfident from "../icons/confidenceImages/VeryConfident.svg";
import { ErrorMessage } from "@hookform/error-message";
import errorIcon from "../../../../widgets/emailSignupWidget/errorIcon.svg";

function RadioGroups(props) {
  const { control, errors } = props;

  const [errorActive, setErrorActive] = useState(false);

  const useStyles = makeStyles((theme) => ({
    imageBlocksContainer: {
      display: "flex",
    },
    boldTitle: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      paddingTop: "8px",
      paddingBottom: "8px",
    },
    boldTitlePrimary: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      paddingTop: "8px",
      paddingBottom: "16px",
    },
    subTitle: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      paddingBottom: "16px",
    },
    subTitleList: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "16px",
      paddingBottom: "8px",
    },
    cardsContainer: {
      width: "calc(100% + 16px)",
      margin: "-6px",
    },
    errorText: {
      fontWeight: 400,
      display: "inline-flex",
      alignItems: "center",
      width: "100%",
      paddingTop: "8px",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#2F2F4E",
    },
    errorIcon: {
      height: "16px",
      paddingRight: "8px",
    },
    listFieldset: {
      marginTop: "8px",
    },
  }));

  const classes = useStyles();

  const inputs = [
    {
      type: "imageBlocks",
      customAttributeKey: "Registration_Investing_Confidence",
      options: [
        {
          text: "I don’t have a clue",
          customAttributeValue: "Very_Low",
          image: NotAtAllConfident,
        },
        {
          text: "I’ve got a bit of an idea",
          customAttributeValue: "Low",
          image: NotVeryConfident,
        },
        {
          text: "I sort of know what I’m doing",
          customAttributeValue: "High",
          image: FairlyConfident,
        },
        {
          text: "I really know my stuff",
          customAttributeValue: "Very_High",
          image: VeryConfident,
        },
      ],
    },
    {
      type: "list",
      customAttributeKey: "Registration_Retirement_Status",
      options: [
        {
          text: "No",
          customAttributeValue: "Not_Retired",
        },
        {
          text: "In the next 10 years",
          customAttributeValue: "Retire_Within_10_Years",
        },
        {
          text: "Semi-retired",
          customAttributeValue: "Semi_Retired",
        },
        {
          text: "Totally retired",
          customAttributeValue: "Fully_Retired",
        },
      ],
    },
  ];

  function getErrorText(type) {
    const ErrorText = {
      imageBlocks: <>Investing confidence field is required</>,
      list: <>Retirement field is required</>,
      default: <></>,
    };
    return ErrorText[type] || ErrorText.default;
  }

  function renderList(items) {
    return items.map((item) => {
      if (item.type === "imageBlocks") {
        return (
          <>
            <Controller
              rules={{ required: true }}
              defaultValue={false}
              control={control}
              name={item.customAttributeKey}
              render={({ field }) => (
                <FormControl component={"fieldset"}>
                  <FormLabel component={"legend"}>
                    <Typography variant={"body1"} className={classes.boldTitlePrimary}>
                      How confident are you about investing?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    name={"investing"}
                    aria-label={"investing"}
                  >
                    <Grid
                      className={classes.cardsContainer}
                      container
                    >
                      {item.options.map((option) => {
                        return (
                          <>
                            <RadioInput
                              key={option.customAttributeValue}
                              errors={errors}
                              image={option.image}
                              value={option.customAttributeValue}
                              text={option.text}
                              type={item.type}
                              name={item.customAttributeKey}
                            />
                          </>
                        );
                      })}
                    </Grid>
                  </RadioGroup>

                  <ErrorMessage
                    name={item.customAttributeKey}
                    errors={errors}
                    render={() => {
                      setErrorActive(true);
                      return (
                        <span className={classes.errorText}>
                          <img
                            className={classes.errorIcon}
                            src={errorIcon}
                            alt="error"
                          />
                          {getErrorText(item.type)}
                        </span>
                      );
                    }}
                  />
                </FormControl>
              )}
            />
          </>
        );
      } else if (item.type === "list") {
        return (
          <>
            <Controller
              rules={{ required: true }}
              defaultValue={false}
              control={control}
              name={item.customAttributeKey}
              render={({ field }) => (
                <FormControl className={classes.listFieldset} component={"fieldset"}>
                  <FormLabel component={"legend"}>
                    <Typography variant={"body1"} className={classes.boldTitle}>
                      Are you retired?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    name={"retirement"}
                    aria-label={"retirement"}
                  >
                    {item.options.map((option) => {
                      return (
                        <>
                          <RadioInput
                            errors={errors}
                            value={option.customAttributeValue}
                            text={option.text}
                            type={item.type}
                            name={item.customAttributeKey}
                          />
                        </>
                      );
                    })}
                  </RadioGroup>
                  <ErrorMessage
                    name={item.customAttributeKey}
                    errors={errors}
                    render={() => {
                      setErrorActive(true);
                      return (
                        <span className={classes.errorText}>
                          <img
                            className={classes.errorIcon}
                            src={errorIcon}
                            alt="error"
                          />
                          {getErrorText(item.type)}
                        </span>
                      );
                    }}
                  />
                </FormControl>
              )}
            />
          </>
        );
      } else {
        return null;
      }
    });
  }

  return <Grid>{renderList(inputs)}</Grid>;
}

export default RadioGroups;
