import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "../../icon/Star.svg";
import EmptyStarIcon from "../../icon/StarUnfilled.svg";
import Rating from "@material-ui/lab/Rating";

export default function RatingComponent(props) {

  const { score, className } = props;

  const useStyles = makeStyles((theme) => ({
    logoImage: {
      borderRadius: '9.44px'
    },
    scale: {
      marginRight: '6px',
      [theme.breakpoints.down(464)]: {
        marginRight: '2px',
        height: '14px'
      }
    },
    verticallyAlignMiddle: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)'
    }
  }));

  const classes = useStyles();

  return (
    <>
      { score && (
        <Rating
          className={className}
          name="half-rating-read"
          defaultValue={score / 2}
          itemStyle={''}
          precision={0.5}
          readOnly
          icon={<img src={StarIcon} alt="StarIcon" className={classes.scale} />}
          emptyIcon={
            <img
              src={EmptyStarIcon}
              alt="EmptyStarIcon"
              className={classes.scale}
            />
          }
          />     
      )}
    </>
  )
}

