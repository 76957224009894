import React, { useState, useEffect, useRef, useContext } from "react";
import { signupToBlog, createClient } from "../../../lib/helpers";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Hidden,
  Button,
  TextField,
  Checkbox,
  Icon,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import SanityImage from "gatsby-plugin-sanity-image";
import emailIcon from "./subButton.svg";
import errorIcon from "./errorIcon.svg";
import * as styles from "../../sidebarComponents/sidebar.module.css";
import subscribed from "../../sidebarComponents/img/subscribed.svg";
import BasePortableText from "@sanity/block-content-to-react";

import {useStaticQuery, graphql, Link} from "gatsby";
import { isValid } from "date-fns";
import { withStyles } from "@material-ui/core/styles";
import Checked from "../../icon/checked.svg";
import NonChecked from "../../icon/non-checked.svg";
import clientConfig from "../../../../client-config";
import serializers from "../../serializers";

import axios from 'axios';
import fileDownload from 'js-file-download';
import ReusableCheckbox from "../../landingPageComponents/blocks/reusableCheckbox";
import AuthenticatorNoRedirect from "../../auth0/AuthenticatorNoRedirect";
import {AuthContext} from "../../../context/AuthContext";
import { emailCaptureSubmissionLogging } from "../../../lib/helpers";

const checkBoxStyles = (theme) => ({
  root: {
    display: "inline-block",
    width: "47px",
    padding: "0 !important",
    verticalAlign: "top",
    "&$checked": {
      color: "#3D70B2",
    },
    "& .MuiIconButton-label": {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },

  checked: {},
});

const iconStyles = (theme) => ({
  root: {},
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
const CustomIcon = withStyles(iconStyles)(Icon);

const GetDictionary = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityDictionary(
        filter: { key: { in: ["signup_widget_thank_you_text"] } }
      ) {
        nodes {
          value
          key
        }
      }
    }
  `);

  return data.allSanityDictionary.nodes;
};

const useStyles = makeStyles((theme) => ({
  signUpContainer: {
    background: '#3C465F',
    borderRadius: "8px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row-reverse",
    },
  },
  signUpImageContainer: {
    [theme.breakpoints.up("md")]: {
      padding: "29px 24px 29px 24px",
      display: "none",
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  signUpImage: {
    height: "100%",
    width: "100%",
    borderRadius: "8px 8px 0 0",
    objectFit: "cover",
    [theme.breakpoints.up("md")]: {
      borderRadius: "8px",
    },
  },
  textButtonInputContainer: {
    padding: "1rem",
    color: "#ffffff",
    [theme.breakpoints.up("md")]: {
      padding: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "1.5rem 7px 1.5rem 1.5rem",
    },
  },
  textButtonInputContainerNoImg: {
    padding: "1rem",
    color: "#ffffff",
  },
  titleAndParaHolder: {
    flexDirection: "column",
    marginBottom: "1.5rem",
    [theme.breakpoints.up("md")]: {
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "1.5rem",
    },
    '& p, h1, h2, h3, h4, h5, label, span, li, ul': {
      color: '#FFF !important'
    }
  },
  thankYouTextHolder: {
    '& p, h1, h2, h3, h4, h5, label, span, li, ul': {
      color: '#FFF !important'
    }
  },
  inputField: {
    width: "100%",
    height: "52px",
    background: "#FFFFFF",
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    borderRadius: "8px",
    "& > div": {
      fontSize: "14px",
      height: "100%",
      "&::before": {
        borderBottom: "none !important",
      },
    },
    "& input": {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  buttonContainer: {
    marginTop: "1rem",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      paddingLeft: 8,
    },
  },
  buttonContainerError: {
    display: "flex",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      paddingTop: "0",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      paddingLeft: 8,
    },
  },
  submitButton: {
    background: "#FF0F5F",
    color: "white",
    textTransform: "uppercase",
    width: "100%",
    height: "52px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
    "&:hover": {
      background: "#FF0F5F",
      color: "white",
    },
    "&:focus": {
      background: "#ff0f5f",
      color: "white",
    },
    "&:active": {
      background: "#FF0F5F",
      color: "white",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "1.5rem",
    },
  },
  submitButtonNoImg: {
    background: "#FF0F5F",
    color: "white",
    textTransform: "uppercase",
    width: "100%",
    height: "52px",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
    "&:hover": {
      background: "#FF0F5F",
      color: "white",
    },
    "&:focus": {
      background: "#ff0f5f",
      color: "white",
    },
    "&:active": {
      background: "#FF0F5F",
      color: "white",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "1.5rem",
      maxWidth: "fit-content",
    },
  },
  submitIcon: {
    paddingLeft: "9px",
  },
  inputError: {
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    border: "#FF0F5F 1px solid",
    borderRadius: "8px",
    color: "white",
  },
  subscribedContainer: {
    padding: "1rem",
  },
  paragraphText: {
    color: "white",
  },
  errorText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    display: "inline-flex",
    paddingTop: "8px",
    width: "100%",
    color: "white",
    // [theme.breakpoints.down("sm")]:{
    //   height: 0,
    // bottom: "100px",
    // paddingTop: 0,
    // },
    // [theme.breakpoints.between("md", "lg")]:{
    //   paddingLeft: "16px",
    //   bottom: "16px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   bottom: "24px",
    //   paddingLeft: "24px",
    //   paddingBottom: 0,
    // },
  },
  errorIcon: {
    height: "16px",
    width: "16px",
  },
  bodyText: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "white",
  },
  holderForParaOnly: {
    paddingTop: "4px",
  },
  checkboxSpan: {
    display: "inline-block",
    width: "calc(100% - 47px)",
    cursor: "pointer",
  },
  marketingConsentContainer: {
    marginTop: "1em",
  },
  checkboxContainer:{
    display: "flex",
  },
  linkText:{
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    textDecoration: "none",
    color: "#57C7FF",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  helpContainer:{
    marginTop: "12px",
    [theme.breakpoints.down("md")]:{
      textAlign: "start",
      marginTop: "16px",
    }
  },
  helpText:{
    fontWeight: 400,
    color: "#ffffff"
  },

}));

const EmailSignupWidget = ({
  title,
  image,
  paragraphText,
  thankYouText,
  brazeCustomAttributes,
  downloadUrl,
  placeholderText,
  buttonText, signupRedirectLink,
}) => {
  const [state, setState] = useState({
    username: "",
    errors: {},
    formIsValid: false,
    isSubmitted: false,
    isToggleOn: true,
    marketingConsent: false,
  });


  const [logGA, setLogGA] = useState(false);

  useEffect(() => {
    (async () => {
      if (logGA) emailCaptureSubmissionLogging()
    })();
  }, [logGA]);

  const classes = useStyles();

  const holderEl = useRef(null);

  var authStatus = useContext(AuthContext);
  var isAuthed = authStatus.isAuthed

  useEffect(() => {
    if (state.isSubmitted && window.dataLayer) {
      window.dataLayer.push({ event: "subscribe_form_submitted" });
    }
  }, [state]);

  const handleUsername = (event) => {
    let errors = {};
    let formIsValid = true;
    let field = event.target.value;

    setState((state) => ({
      ...state,
      username: event.target.value,
      errors: errors,
      formIsValid: formIsValid,
    }));
  };

  const submitted = (event) => {
    let formIsValid = state.formIsValid;
    let userName = state.username;
    let errors = {};

    if (typeof state.formIsValid == "undefined") {
      formIsValid = false;
      errors["empty"] = "Field is empty";
      setState((state) => ({ ...state, errors: errors }));
    }

    if (typeof state.formIsValid !== "undefined") {
      let lastAtPos = userName.lastIndexOf("@");
      let lastDotPos = userName.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          userName.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          userName.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is invalid";
        setState((state) => ({ ...state, errors: errors }));
      }
    }

    event.preventDefault();

    if (formIsValid) {

      setLogGA(true)
      if (brazeCustomAttributes && brazeCustomAttributes[0]?.customAttributeKey){
        localStorage.setItem('custom_attr', btoa(brazeCustomAttributes[0].customAttributeKey + "|" + brazeCustomAttributes[0].customAttributeValue));
      }
      localStorage.setItem("genMkt", state.marketingConsent)
      localStorage.setItem('returnUrl', encodeURIComponent(signupRedirectLink ? signupRedirectLink : "/members"));
      window.location.href = "/login?returnUrl=" + encodeURIComponent(signupRedirectLink ? signupRedirectLink : "/members") + "&usr=" + btoa(state.username);
      return null;
      //signupToBlog(state.username);
      //try {
      //  window.signup(state.username, brazeSubscriptionRefID, state.marketingConsent, brazeCustomAttributes);
      //} catch (e) {
      //  console.log("ERR-emailSignup",e)
      //}


      //// if download url, we should download the file
      //if (downloadUrl) {
      //  var lastIndexOfDot = downloadUrl.lastIndexOf('.');

      //  if (lastIndexOfDot != -1) {
      //    var lastIndexOfForwardSlash = downloadUrl.lastIndexOf('/');

      //    var fileName = downloadUrl.slice(lastIndexOfForwardSlash + 1, downloadUrl.length);

      //    axios.get(downloadUrl, {
      //      responseType: 'blob',
      //    })
      //      .then((res) => {
      //        fileDownload(res.data, fileName);
      //      });
      //  }
      //}

      setState((state) => ({
        ...state,
        isToggleOn: !state.isToggleOn,
        isSubmitted: true,
      }));
    } else {
      holderEl.current.className =
        holderEl.current.className + ` ${classes.inputError}`;
      //console.log("formIsValid", formIsValid);
    }
  };

  const getSignupThankYouText = async function () {
    var params = {};

    var query =
      "*[_type == 'dictionary'][key match ['signup_widget_thank_you_text']]";

    return createClient()
      .fetch(query, params)
      .then((response) => response)
      .then((responseJson) => {
        return responseJson;
      });
  };

  const handleChange = (event) => {
    setState({ ...state, marketingConsent: event.target.checked });
  };

  if (isAuthed){
    return <></>;
  }

  return (
    <Grid
      container
      className={classes.signUpContainer}
      alignItems={state.isToggleOn ? null : "center"}
    >
      <>
        {image && image.asset &&
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={5}
            className={classes.signUpImageContainer}
          >
            {image && image.asset && (
              <SanityImage asset={image?.asset} className={classes.signUpImage} />
            )}
          </Grid>
        }
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={image && image.asset ? 7 : 12}
          className={image && image.asset ? classes.textButtonInputContainer : classes.textButtonInputContainerNoImg}
        >
        <Grid container className={classes.titleAndParaHolder}>
            <Grid item>
              <Typography variant="h4">
                {title}
              </Typography>
            </Grid>
            <Grid item className={classes.holderForParaOnly}>
              <Typography variant="body-2" className={classes.bodyText}>
                {paragraphText}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={7}>

            {state.isToggleOn ? (
              <TextField
                ref={holderEl}
                id="emailField"
                className={classes.inputField}
                placeholder={placeholderText ? placeholderText : "Enter your email address..."}
                value={state.username}
                onChange={handleUsername}
              />
            ) : null}
          </Grid>

          <Hidden smDown>
            {state.isToggleOn && (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={5}
                className={
                  state.errors && Object.keys(state.errors).length > 0
                    ? classes.buttonContainerError
                    : classes.buttonContainer
                }
              >
                <Button className={image && image.asset ? classes.submitButton : classes.submitButtonNoImg} onClick={submitted}>
                  {buttonText ? buttonText : "subscribe"}
                  <img src={emailIcon} className={classes.submitIcon} />
                </Button>
              </Grid>
            )}
          </Hidden>

          <Hidden smDown>
            {state.errors && Object.keys(state.errors).length > 0 ? (
              <span className={classes.errorText}>
                <img
                  className={classes.errorIcon}
                  src={errorIcon}
                  alt="error"
                />
                &nbsp;Please enter a valid email address{" "}
              </span>
            ) : null}
          </Hidden>
          <Hidden mdUp>
            {state.errors && Object.keys(state.errors).length > 0 ? (
              <span className={classes.errorText}>
                <img
                  className={classes.errorIcon}
                  src={errorIcon}
                  alt="error"
                />
                &nbsp;Please enter a valid email address{" "}
              </span>
            ) : null}
          </Hidden>
          <Grid item xs={12} className={classes.marketingConsentContainer}>
            {state.isToggleOn ? (
              <label className={classes.checkboxContainer}>
                <ReusableCheckbox checked={state.marketingConsent} handleChange={handleChange} />
                <span className={classes.checkboxSpan}>
                  I would like to hear from you about products, events, general
                  marketing and other things from Boring Money – including
                  personalised content and ads - but please try not to make them
                  as boring as everything else in finance!
                </span>
              </label>
            ) : null}
          </Grid>
          <Hidden smDown>
           <Grid item xs={12} className={classes.helpContainer}>
             <Typography className={classes.helpText} variant={"body1"}>
               Already have an account?{" "}
               <a className={classes.linkText} href={"/login"}>Login</a>
             </Typography>
           </Grid>
          </Hidden>
          <Hidden mdUp>
            {state.isToggleOn && (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={5}
                className={
                  state.errors && Object.keys(state.errors).length > 0
                    ? classes.buttonContainerError
                    : classes.buttonContainer
                }
              >
                <Button className={classes.submitButton} onClick={submitted}>
                  {buttonText ? buttonText : "subscribe"}
                  <img src={emailIcon} className={classes.submitIcon} />
                </Button>
              </Grid>
            )}
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} className={classes.helpContainer}>
              <Typography className={classes.helpText} variant={"body1"}>
                Already have an account?{" "}
                <a className={classes.linkText} href={"/login"}>Login</a>
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </>
    </Grid>
  );
};

export default EmailSignupWidget;
