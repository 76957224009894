// @ts-ignore
import React from "react";
import getVideoId from "get-video-id";
import YoutubeEmbed from "../YouTube/youTubeEmbed";
import VimeoEmbed from "../Vimeo/vimeoEmbed"

interface VideoLinkHandlerProps {
    videoUrl: string;
    type: string;
}

const VideoLinkHandler = ({ videoUrl, type }: VideoLinkHandlerProps) => {

    if (videoUrl.includes("youtube")){

        const { id } = getVideoId(videoUrl)
        return <YoutubeEmbed embedId={id} type={type} />
    }
    if (videoUrl.includes("youtu.be")){
      const { id } = getVideoId(videoUrl)
      return <YoutubeEmbed embedId={id} type={type} />
    }
    if (videoUrl.includes("vimeo")){
        const { id } = getVideoId(videoUrl)
        return <VimeoEmbed embedId={id!} type={type}/>
    }
    else {
        return null;
    }
}

export default VideoLinkHandler
