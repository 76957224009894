import React, { useEffect, useState } from "react";
import { createClient } from "../../lib/helpers";
import * as styles from "./../typography.module.css";
import { AddTrailingSlashToUrl } from "../../lib/helpers";

const client = createClient();

export default function LinkHandler(props) {

  const { subfolderRef, slug, children, url, linkTarget, whiteTextColor, blueBg } = props;

  const [fullPath, setFullPath] = useState([]);

  var href = slug.current;

  useEffect(() => {
    (async () => {
      await getPathFull();
    })();
  }, []);

  var getPath = async function () {

    const params = {
      pageType: "folderCategory",
      id: subfolderRef,
    }
    const query = "*[_type ==$pageType && _id==$id]{folder}";

    return client.fetch(query, params)
      .then((response) => response)
      .then((responseJson) => { return responseJson });
  }

  var getPathFull = async function () {
    var path = await getPath();
    href = path[0].folder + slug.current;

    setFullPath(href);

  }


  if (fullPath!=null) {
    return (
      <>
{whiteTextColor=="true"?
          <a style={{ color: "#fff", textDecoration: "underline" }} className={styles.mainAnchor} href={AddTrailingSlashToUrl(fullPath)} target={linkTarget}>{children}</a>:
          <a style={{ color: blueBg ? "#57C7FF" : "#0070EA"}} className={styles.mainAnchor} href={AddTrailingSlashToUrl(fullPath)} target={linkTarget}>{children}</a>}


      </>
    )
  }
  else {
    return (
      <>




      </>
    )
  }


}

