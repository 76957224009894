import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  containerForSVG: {
    width: "inherit",
  },
}));

const ContainerForSVG = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      className={classes.containerForSVG}
    >
      {children}
    </Grid>
  );
};

export default ContainerForSVG;
