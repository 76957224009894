import React, { useState, useEffect } from "react";
import PortableText from "../portableText";
import * as styles from "./blockQuote.module.css";
import BasePortableText from "@sanity/block-content-to-react";
import serializers from "../serializers";
import clientConfig from "../../.././client-config";
import { Grid } from "@material-ui/core";
import quoteImg from "./img/quote.svg";
import campusQuoteImg from "./img/campus-quote.svg";
import { checkIsCampusTheme } from "../../lib/helpers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  blockQuoteContainer: {
    '& div p': {
      color: '#3C465F !important'
    },
    '& div h1': {
      color: '#3C465F !important'
    },
    '& div h2': {
      color: '#3C465F !important'
    },
    '& div h3': {
      color: '#3C465F !important'
    },
    '& div h4': {
      color: '#3C465F !important'
    },
    '& div h5': {
      color: '#3C465F !important'
    },
  }
}));

const BlockQuote = ({ quote, photo, adviserName, jobTitle }) => {
  let mainImageWithType = photo;
  const [isCampusTheme, setIsCampusTheme] = useState(false);
  const [finishedCheckingTheme, setFinishedCheckingTheme] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      var isCampusTheme = await checkIsCampusTheme();

      setFinishedCheckingTheme(true)
      setIsCampusTheme(isCampusTheme);
    })();
  }, []);

  if (photo) {
    mainImageWithType._type = "mainImage";
    mainImageWithType.isBlockQuoteImage = true;
    mainImageWithType.isAdviserImage = true;
  }

  return (
    finishedCheckingTheme ?
    <div className={styles.blockQuoteContainer}>
      <div className={`${isCampusTheme ? styles.campusQuoteContainer : styles.quoteContainer} ${isCampusTheme ? classes.blockQuoteContainer : ''}`}>
        <PortableText blocks={quote} serializers={serializers}/>
      </div>
      <Grid container alignItems="center" className={styles.iconNameTitleContainer}>
        {mainImageWithType &&
          <Grid>
            <div className={styles.adviserImageContainer}>
              <BasePortableText
                blocks={mainImageWithType}
                serializers={serializers}
                {...clientConfig.sanity}
              />
            </div>
          </Grid>}
        {adviserName && jobTitle &&
          <Grid className={styles.infoContainer}>
            <span className={styles.adviserName}>{adviserName}</span>
            <span className={styles.jobTitle}>{jobTitle}</span>
          </Grid>}
      </Grid>
      <img className={styles.quoteImg} src={isCampusTheme ? campusQuoteImg : quoteImg} />
    </div>
      : <></>
  );
};

export default BlockQuote;
