import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import OverallScoreWidget from "./overallScoreWidget";

const useStyles = makeStyles((theme) => ({
  checkboxSpan: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#3C455C',
    marginRight: '8px'
  },
  checkboxSpanVisible: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3C465F',
    marginRight: '8px'
  },
  progressBarBase: {
    background: '#F5F7FA',
    boxShadow: 'inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)',
    borderRadius: '4px',
    position: 'absolute',
    left: 0,
    height: '1em',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    marginRight: '1em'
  },
  progressBarBaseVisible: {
    background: '#FFFFFF',
    border: "0.5px solid #D4D4D4",
    borderRadius: '8px',
    position: 'absolute',
    left: 0,
    height: '1em',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
    marginRight: '1em'
  },
  progressBar: {
    borderRadius: '4px',
    background: '#FFCB45',
    position: 'absolute',
    left: '0',
    height: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    marginRight: '1em'
  },
  progressBarVisible: {
    borderRadius: '8px',
    background: '#FAE27D',
    position: 'absolute',
    left: '0',
    height: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    marginRight: '1em'
  },
  progressBarContainer: {
    position: 'relative',
    width: '65%',
    [theme.breakpoints.down(1078)]: {
      width: '80%'
    },
    [theme.breakpoints.down(976)]: {
      width: '79%'
    },
    [theme.breakpoints.down(959)]: {
      width: '65%'
    },
    [theme.breakpoints.down(600)]: {
      width: '80%'
    },
    [theme.breakpoints.down(474)]: {
      width: '76%',
    },
    [theme.breakpoints.down(423)]: {
      width: '70%',
    },
    [theme.breakpoints.down(350)]: {
      width: '65%',
    },
  },
  percentageContainer: {
    width: '14%',
    textAlign: 'right',
    [theme.breakpoints.down(1078)]: {
      width: '8%'
    },
    [theme.breakpoints.down(959)]: {
      width: '14%'
    },
    [theme.breakpoints.down(600)]: {
      width: '8%'
    },
    [theme.breakpoints.down(474)]: {
      width: '10%',
    },
    [theme.breakpoints.down(423)]: {
      width: '12%',
    },
    [theme.breakpoints.down(350)]: {
      width: '14%',
    },
  },
  checkboxContainer: {
    width: '21%',
    [theme.breakpoints.down(1078)]: {
      width: '12%'
    },
    [theme.breakpoints.down(976)]: {
      width: '13%'
    },
    [theme.breakpoints.down(959)]: {
      width: '21%'
    },
    [theme.breakpoints.down(600)]: {
      width: '12%'
    },
    [theme.breakpoints.down(474)]: {
      width: '14%',
    },
    [theme.breakpoints.down(423)]: {
      width: '18%',
    },
    [theme.breakpoints.down(350)]: {
      width: '21%',
    },
  },
  filtersContainer: {
    background: '#FFFFFF',

    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    borderRadius: '8px',
    width: '270px',
    [theme.breakpoints.down(1078)]: {
      width: '100%'
    },
    [theme.breakpoints.down(959)]: {
      width: '270px'
    },
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
  },
  filtersContainerVisible: {
    background: '#FFFFFF',
    border: "1px solid #D4D4D4",
    borderRadius: '8px',
    width: '270px',
    [theme.breakpoints.down(1078)]: {
      width: '100%'
    },
    [theme.breakpoints.down(959)]: {
      width: '270px'
    },
    [theme.breakpoints.down(600)]: {
      width: '100%'
    },
  },
  filterItemContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '6px',
    paddingBottom: '6px'
  },
  filterHeaderContainer: {
    borderBottom: '1px solid #DBE3EF'
  },
  filterHeaderLabel: {
    color: '#262641',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingRight: '16px',
    paddingBottom: '8px',
    display: 'block'
  },
  filterHeaderLabelVisible: {
    color: '#3C465F',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingRight: '16px',
    paddingBottom: '8px',
    display: 'block'
  },
  percentageLabel: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    color: '#3C455C'
  },
  percentageLabelVisible: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    color: "#3C465F",
  },
  overallScoreLabel: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    color: '#2F2F4E'
  },
  overallScoreLabelVisible: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    color: '#3C465F'
  },
  filterItemPaddedTop: {
    paddingTop: '8px'
  },
  filterItemPaddedBottom: {
    paddingBottom: '16px'
  },
  paddingBottomThreePixels: {
    paddingBottom: '3px'
  }

}));

export default function LeftPanel({
  fiveStarsReviewsNumber,
  fourStarsReviewsNumber,
  threeStarsReviewsNumber,
  twoStarsReviewsNumber,
  oneStarReviewsNumber,
  allReviewsLength,
  overallServiceAverageScore,
  valueForMoneyAverageScore,
  websiteExperienceAverageScore,
  isCampusTheme, }) {

  const classes = useStyles();

  return (
    <Grid container className={isCampusTheme ? classes.filtersContainerVisible : classes.filtersContainer} id="filters-container">
      <Grid item xs={12} className={classes.filterHeaderContainer}>
        <label className={isCampusTheme ? classes.filterHeaderLabelVisible : classes.filterHeaderLabel}>Overall rating</label>
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
        <Grid container alignItems="center" justify="center" >
          <Grid item className={classes.checkboxContainer}>
            <span className={isCampusTheme ? classes.checkboxSpanVisible : classes.checkboxSpan}>5 star</span>
          </Grid>
          <Grid item className={classes.progressBarContainer}>
            <div class={isCampusTheme ? classes.progressBarBaseVisible : classes.progressBarBase}></div>
            <div class={isCampusTheme ? classes.progressBarVisible : classes.progressBar}
              style={{ width: Math.round((fiveStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
          </Grid>
          <Grid item className={classes.percentageContainer}>
            <label className={isCampusTheme ? classes.percentageLabelVisible : classes.percentageLabel}>{Math.round((fiveStarsReviewsNumber / allReviewsLength) * 100)}%</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.filterItemContainer}>
        <Grid container alignItems="center" justify="center" >
          <Grid item className={classes.checkboxContainer}>
            <span className={isCampusTheme ? classes.checkboxSpanVisible : classes.checkboxSpan}>4 star</span>
          </Grid>
          <Grid item className={classes.progressBarContainer}>
            <div class={isCampusTheme ? classes.progressBarBaseVisible : classes.progressBarBase}></div>
            <div class={isCampusTheme ? classes.progressBarVisible : classes.progressBar}
              style={{ width: Math.round((fourStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
          </Grid>
          <Grid item className={classes.percentageContainer}>
            <label className={isCampusTheme ? classes.percentageLabelVisible : classes.percentageLabel}>{Math.round((fourStarsReviewsNumber / allReviewsLength) * 100)}%</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.filterItemContainer}>
        <Grid container alignItems="center" justify="center" >
          <Grid item className={classes.checkboxContainer}>
            <span className={isCampusTheme ? classes.checkboxSpanVisible : classes.checkboxSpan}>3 star</span>
          </Grid>
          <Grid item className={classes.progressBarContainer}>
            <div class={isCampusTheme ? classes.progressBarBaseVisible : classes.progressBarBase}></div>
            <div class={isCampusTheme ? classes.progressBarVisible : classes.progressBar}
              style={{ width: Math.round((threeStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
          </Grid>
          <Grid item className={classes.percentageContainer}>
            <label className={isCampusTheme ? classes.percentageLabelVisible : classes.percentageLabel}>{Math.round((threeStarsReviewsNumber / allReviewsLength) * 100)}%</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.filterItemContainer}>
        <Grid container alignItems="center" justify="center" >
          <Grid item className={classes.checkboxContainer}>
            <span className={isCampusTheme ? classes.checkboxSpanVisible : classes.checkboxSpan}>2 star</span>
          </Grid>
          <Grid item className={classes.progressBarContainer}>
            <div class={isCampusTheme ? classes.progressBarBaseVisible : classes.progressBarBase}></div>
            <div class={isCampusTheme ? classes.progressBarVisible : classes.progressBar}
              style={{ width: Math.round((twoStarsReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
          </Grid>
          <Grid item className={classes.percentageContainer}>
            <label className={isCampusTheme ? classes.percentageLabelVisible : classes.percentageLabel}>{Math.round((twoStarsReviewsNumber / allReviewsLength) * 100)}%</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.paddingBottomThreePixels}`}>
        <Grid container alignItems="center" justify="center" >
          <Grid item className={classes.checkboxContainer}>

            <span className={isCampusTheme ? classes.checkboxSpanVisible : classes.checkboxSpan}>1 star</span>
          </Grid>
          <Grid item className={classes.progressBarContainer}>
            <div class={isCampusTheme ? classes.progressBarBaseVisible : classes.progressBarBase}></div>
            <div class={isCampusTheme ? classes.progressBarVisible : classes.progressBar}
              style={{ width: Math.round((oneStarReviewsNumber / allReviewsLength) * 100).toString() + '%' }}></div>
          </Grid>
          <Grid item className={classes.percentageContainer}>
            <label className={isCampusTheme ? classes.percentageLabelVisible : classes.percentageLabel}>{Math.round((oneStarReviewsNumber / allReviewsLength) * 100)}%</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.filterHeaderContainer}>
        <label className={isCampusTheme ? classes.filterHeaderLabelVisible : classes.filterHeaderLabel}>Quality rating</label>
      </Grid>

      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
        <label className={isCampusTheme ? classes.overallScoreLabelVisible : classes.overallScoreLabel}>Overall service</label>
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
        <OverallScoreWidget score={overallServiceAverageScore} isCampusTheme={isCampusTheme} />
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
        <label className={isCampusTheme ? classes.overallScoreLabelVisible : classes.overallScoreLabel}>Value for money</label>
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
        <OverallScoreWidget score={valueForMoneyAverageScore} isCampusTheme={isCampusTheme}/>
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop}`}>
        <label className={isCampusTheme ? classes.overallScoreLabelVisible : classes.overallScoreLabel}>Website experience</label>
      </Grid>
      <Grid item xs={12} className={`${classes.filterItemContainer} ${classes.filterItemPaddedTop} ${classes.filterItemPaddedBottom}`}>
        <OverallScoreWidget score={websiteExperienceAverageScore} isCampusTheme={isCampusTheme}/>
      </Grid>
    </Grid>
  );
};
