import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BasePortableText from "@sanity/block-content-to-react";
import clientConfig from "../../../../client-config";
import { Grid, Button, Typography, Hidden } from "@material-ui/core";
import ContainerForLinks from "../../containerForLinks";
import Container from "../../container";
import serializers from "../../serializers";
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
import { AddTrailingSlashToUrl } from "../../../lib/helpers";

import Rating from "@material-ui/lab/Rating";
import StarIcon from "../../icon/Star.svg";
import EmptyStarIcon from "../../icon/StarUnfilled.svg";
import ShieldDone from "../../icon/ShieldDone.svg";
import TickSquare from "../../icon/quiz-results-check.svg";
import WouldRecommend from "./comparisonTableComponents/wouldRecommend";
import { withStyles } from '@material-ui/core/styles';

const StyledRating = withStyles((theme) => ({
  root: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))(Rating);

const useStyles = makeStyles((theme) => ({
  imgComp: {
    width: "140px",
    border: "1px solid #d8dadc",
    height: "140px"
  },
  compDiv: {
    /* marginRight: "10px",*/
    height: "140px",
    width: "140px",
    display: "inline-block",
    /* textAlign: "center",*/
    //border: "1px solid #d8dadc",
    borderRadius: "10px",
    backgroundColor: "#f5f7fa",
    overflow: "hidden"
  },
  holdingDiv: {
    overflow: "hidden",
    width: "100%",
    border: "1px solid #d8dadc",
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "white",
    marginBottom: "10px",
  },
  nutshellDiv: {
    display: "inline-block",
    verticalAlign: "top",
    paddingLeft: "30px",
    paddingRight: "10px",
    width: "40%",
  },
  ratingsDiv: {
    display: "inline-block",
    verticalAlign: "top",
    paddingRight: "10px",
  },
  nsUL: {
    border: "0px",
    padding: "0px",
    margin: "0px",
    marginLeft: "18px",
  },
  paper: {
    textAlign: "center",
    borderRadius: "8px",
    height: "100%",
  },
  paperContained: {
    textAlign: "center",
    //borderRadius: "8px",
    //boxShadow: "0px 2px 4px 0px #4040401A",
    height: "100%",
    //border: "1px solid #DBE3EF",
    //padding: "18px",
  },
  comparisonUnitContainer: {
    flexGrow: 1,
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "12px",
    boxShadow: "0px 2px 4px 0px #4040401A",
    marginBottom: "24px",
    // height: "242px",
    maxWidth: "100%",
  },
  comparisonUnitImageContainer: {
    marginBottom: "12px",
    maxWidth: "100%",
  },
  comparisonUnitScoreContainer: {
    border: "1px solid #DBE3EF",
    borderRadius: "8px",
    // minHeight: "90px"
  },
  inANutshellText: {
    fontSize: "14px",
    color: "#2F2F4E",
    fontWeight: '500',
    lineHeight: "20px",
    paddingLeft: "4px",
    paddingBottom: "2px",
    paddingTop: "2px",
    width: "91%",
    [theme.breakpoints.down(1290)]: {
      fontSize: '12.18px',
      paddingBottom: '0',
      paddingTop: '9px',
      lineHeight: '1em',
      width: '91%'
    }
  },
  leftAlignText: {
    textAlign: "left",
  },
  weSayContainer: {
    backgroundColor: "#F5F7FA",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px 0px #4040401A",
    borderRadius: "6px",
    padding: "8px",
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#fff",
      justifyContent: "center",
    },
  },
  weSayTitle: {
    fontSize: "18px",
    letterSpacing: "0.01em",
    lineHeight: "32px",
    fontWeight: "500",
    color: "#3C455C",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      paddingLeft: "1px",
      paddingRight: "1px",
    },
  },
  weSayResizer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
    [theme.breakpoints.down(360)]: {
      paddingLeft: "1px",
      paddingRight: "1px",
    },
  },
  moreInfoButton: {
    padding: "16px 7px 16px 7px",

    "&:hover": {
      background: "#fff",
    },
  },
  reviewButton: {
    backgroundColor: "#FFF",
    width: "100%",
    padding: "16px 24px 16px 24px",
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    color: '#3C455C',
    fontSize: '15px',
    border: '1px solid #94A4D6',
    borderRadius: '6px',
    "&:hover": {
      background: "#FFF",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#3C455C",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "33px",
    },
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    },
    [theme.breakpoints.down(900)]: {
      marginTop: "15px",
    }
  },

  visitSiteButtonAffiliate: {
    backgroundColor: "#FB755C",
    width: "100%",
    padding: "16px 24px 16px 24px",
    fontSize: '15px',
    "&:hover": {
      background: "#ffffff",
    },
    "&:hover $buttonTextVisitSite": {
      color: "#FB755C",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "33px",
    },
    marginTop: "25px",
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    }
  },
  visitSiteButton: {
    backgroundColor: "#FB755C",
    color: '#FFF',
    width: "100%",
    boxShadow: '0px 2px 4px rgba(64, 64, 64, 0.1)',
    fontSize: '15px',
    padding: "16px 24px 16px 24px",
    "&:hover": {
      background: "#FB755C",
    },
    "&:hover $buttonTextVisitSite": {
      color: "'#FFF",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "initial",
    }
    ,
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    }
  },
  buttonTextMoreInfo: {
    color: "#3C455C",
    backgroundColor: "#FFF",
    textTransform: 'uppercase',
    fontSize: '15px',
    [theme.breakpoints.down(1290)]: {
      fontSize: '13.92px'
    }
  },
  buttonTextVisitSite: {
    color: "#fff",
    textTransform: 'uppercase',
    [theme.breakpoints.down(1290)]: {
      fontSize: '13.92px'
    }
  },
  sectionTitle: {
    color: "#3C465F",
    fontWeight: "700",
    paddingBottom: "5px",
    textTransform: 'uppercase',
    fontSize: '15px',
    [theme.breakpoints.down(1290)]: {
      fontSize: '13.92px',
      paddingBottom: '3px',
      lineHeight: '1em'
    },
    [theme.breakpoints.down(900)]: {
      fontSize: '14px'
    }
  },
  ratingSectionTitle: {
    color: "#3C465F",
    fontWeight: "700",
    textTransform: 'uppercase',
    fontSize: '15px',
    textAlign: 'center',
    [theme.breakpoints.down(1290)]: {
      padding: '12px 16px 12px 16px'
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: '13.92px'
    }
    ,
    [theme.breakpoints.down(900)]: {
      fontSize: '14px'
    }
  },
  inANutshellContainer: {
    minHeight: "24px",
    [theme.breakpoints.down(900)]: {
      minHeight: "32px",
    }
  },
  comparisonTablePortableText: {
    "& p": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "21px",
      letterSpacing: "0em",
      color: "#3C455C",
      paddingBottom: "12px !important",
      [theme.breakpoints.down("sm")]: {
        fontWeight: 400,
      },
    },
  },
  comparisonTableWouldRecommend: {
    fontWeight: "500",
    color: "#3C455C",
    display: 'inline-block',
    fontSize: '1em',
    lineHeight: '24px',
    whiteSpace: 'break-spaces'
  },
  comparisonTableBasedOn: {
    color: "#8C8CA1",
    lineHeight: "24px",
    fontWeight: "500",
    display: 'inline-block',
    fontSize: '.85em'
  },
  comparisonUnitFirst: {
    [theme.breakpoints.up("md")]: {
      maxWidth: '19.15%'
    },
  },
  comparisonUnitSecond: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "37.183727%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  comparisonUnitThird: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "19.51006124234471%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  comparisonUnitFourth: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "24.14698162729659%",
    },
  },
  buttonContainerHelper1: {
    // [theme.breakpoints.down("sm")]: {
    //   order: "2",
    // },
    [theme.breakpoints.down(1340)]: {
      width: "100%",
      order: "2",
    },
    textDecoration: "none",
  },
  buttonContainerHelper2: {
    // [theme.breakpoints.down("sm")]: {
    //   order: "1",
    // },
    [theme.breakpoints.down(1340)]: {
      width: "100%",
      order: "1",
    },
    textDecoration: "none",
    width: "100%",
  },
  scale: {
    padding: "3px",
    [theme.breakpoints.down(1290)]: {
      padding: '1px',
      width: '20px'
    },
    [theme.breakpoints.down(900)]: {
      padding: '1px',
      width: '22px'
    }
  },
  spinnerText: {
    textAlign: 'left',
    paddingLeft: '1em'
  },
  relativePositioned: {
    position: 'relative',
    height: '90px'
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '100%'
  },
  imageContainer: {
    width: '156px',
    height: '140px',
    display: 'block',
    float: 'left',
    paddingRight: '16px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down(1290)]: {
      width: '103px',
      height: '87px'
    }
  },
  nutshellContainer: {
    width: '54%',
    [theme.breakpoints.down(1290)]: {
      width: '54%'
    }
  },
  ratingContainer: {
    width: '21%'
  },
  mobileRatingContainer: {
    height: '1.5em'
  },
  buttonsContainer: {
    minWidth: '25%',
    width: '20%',
    [theme.breakpoints.down(1290)]: {
    }
  },
  providerContainer: {
    background: 'white',
    borderRadius: '8px',
    maxWidth: '100%',
    margin: '0',
    overflow: 'hidden',
    padding: '1em',
    boxShadow: "0px 2px 4px 0px #4040401A",
    position: 'relative',
    marginBottom: '1em',

    [theme.breakpoints.up(1290)]: {
      minHeight: '165px',
    }
  },
  columnsContainer: {
    width: 'calc(100% - 156px)',
    display: 'block',
    float: 'right',
    [theme.breakpoints.down(1290)]: {
      width: 'calc(100% - 103px)',
    }
  },
  logoImage: {
    border: "1px solid #d8dadc",
    borderRadius: '8px',
    maxWidth: '100%',
    width: '140px',
    height: '140px',
    [theme.breakpoints.down(1290)]: {
      width: '87px',
      height: '87px'
    }
  },
  imageWrapper: {
    width: '100%'
  },
  buttonMarginTop: {
    marginTop: '25px',
    [theme.breakpoints.down(1290)]: {
      marginTop: '7.5px',
    }
  },
  ratingSectionTitlePaddedTop: {
    paddingTop: '1em'
  },
  tickSquare: {
    paddingRight: '.3em',

    [theme.breakpoints.down(1290)]: {
      height: '1em',
      marginTop: '9px'
    }
    ,

    [theme.breakpoints.down(900)]: {
      height: '1.4em',
    }
  },
  mobileProviderContainer: {
    filter: 'drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))',
    borderRadius: '8px',
    border: '1px solid #DBE3EF',
    padding: '1em',
    backgroundColor: 'white',
    marginBottom: '1em'
  },
  mobileLogoImage: {
    border: "1px solid #d8dadc",
    borderRadius: '8px',
    maxWidth: '100%',
    width: '110px',
    height: '110px'
  },
  ratingWrapper: {
    width: '100%',
    position: 'relative',
    height: '2em'
  },
  positionRelative: {
    position: 'relative'
  },
  mobileNutshellContainer: {
    paddingTop: '.85em'
  },
  mobileTopButtonPadding: {
    paddingTop: '.8em'
  }
}));

export default function RecProviders(props) {

  //console.log(props)
  const { companyName, companyLogo, linkRef, linkRefReview, info, landing, post, infoReview, landingReview, postReview, inANutshell, ourRating, yourRating, partner } = props.company;
  const classes = useStyles();
  var linkHref = "";
  var linkHrefReview = "";

  const materialUiScoreOur = ourRating / 2;
  const materialUiScoreYour = yourRating / 2;
  const smallDistanceStyle = {};

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});


  //external link
  if (linkRef.length > 0) {
    linkHref = linkRef[0].url
  }

  //landing page
  if ((landing != null) && (landing.length > 0)) {
    linkHref = landing[0].subfolder[0].folder + landing[0].slug
  }

  //info page
  if ((info != null) && (info.length > 0)) {
    linkHref = info[0].subfolder[0].folder + info[0].slug
  }

  //article page
  if ((post != null) && (post.length > 0)) {
    linkHref = "/learn/articles/" + post[0].slug
  }


  //external link
  if (linkRefReview.length > 0) {
    linkHrefReview = linkRefReview[0].url
  }

  //landing page
  if ((landingReview != null) && (landingReview.length > 0)) {
    linkHrefReview = landingReview[0].subfolder[0].folder + landingReview[0].slug
  }

  //info page
  if ((infoReview != null) && (infoReview.length > 0)) {
    linkHrefReview = infoReview[0].subfolder[0].folder + infoReview[0].slug
  }

  //article page
  if ((postReview != null) && (postReview.length > 0)) {
    linkHrefReview = "/learn/articles/" + postReview[0].slug
  }

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'recommended_provider_card_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'recommended_provider_card_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);


  var leaveReviewButtonClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'leave review button',
      CompanyName: companyName
    });
  }

  var visitSiteButtonClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'visit site button',
      CompanyName: companyName
    });
  }

  var logoClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'logo',
      CompanyName: companyName
    });
  }

  try {

    return (
      <>
        <Grid container>
          <Grid item>
          </Grid>
        </Grid>
        <Hidden smDown implementation="js">

          <div className={classes.providerContainer} >
            <div className={classes.imageContainer}>
              <Grid container alignItems="center" justify="center" >
                <Grid item className={classes.imageWrapper} >
                  <a href={AddTrailingSlashToUrl(linkHref)}
                    onClick={() => { logoClicked() }}
                  >
                    <img src={imageUrlFor(buildImageObj(companyLogo))
                      .width(100)
                      .height(100)
                      .fit("crop")
                      .auto("format")
                      .url()}
                      alt={companyName}
                      className={classes.logoImage}
                    />
                  </a>
                </Grid>
              </Grid>
            </div>
            <div className={classes.columnsContainer}>
              <Grid container alignItems="center" justify="flex-end" >

                <Grid item className={classes.nutshellContainer}>
                  <Typography variant="body1" className={classes.sectionTitle}>
                    In a nutshell

              </Typography>

                  {inANutshell.map((text) => {
                    return (
                      <Grid
                        container
                        alignItems="center"
                        justify="flex-start"
                        className={classes.inANutshellContainer}
                        key={text}
                      >
                        <img src={TickSquare} alt="TickSquare" className={classes.tickSquare} />
                        <Typography
                          variant="body1"
                          className={classes.inANutshellText}
                        >
                          {text}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item className={classes.ratingContainer}>
                  <Typography variant="body1" className={classes.ratingSectionTitle}>
                    Our Rating

            </Typography>
                  <div className={classes.ratingWrapper}>

                    <StyledRating
                      className={classes.weSayResizer}
                      name="half-rating-read"
                      defaultValue={materialUiScoreOur}
                      itemStyle={smallDistanceStyle}
                      precision={0.5}
                      readOnly
                      icon={
                        <img
                          src={StarIcon}
                          alt="StarIcon"
                          className={classes.scale}
                        />
                      }
                      emptyIcon={
                        <img
                          src={EmptyStarIcon}
                          alt="EmptyStarIcon"
                          className={classes.scale}
                        />
                      }
                    />
                  </div>
                  <Typography variant="body1" className={`${classes.ratingSectionTitle}`}>
                    Your Rating

            </Typography>
                  <div className={classes.ratingWrapper}>

                    <StyledRating
                      className={classes.weSayResizer}
                      name="half-rating-read"
                      defaultValue={materialUiScoreYour}
                      itemStyle={smallDistanceStyle}
                      precision={0.5}
                      readOnly
                      icon={
                        <img
                          src={StarIcon}
                          alt="StarIcon"
                          className={classes.scale}
                        />
                      }
                      emptyIcon={
                        <img
                          src={EmptyStarIcon}
                          alt="EmptyStarIcon"
                          className={classes.scale}
                        />
                      } />
                  </div>
                </Grid>
                <Grid item className={classes.buttonsContainer}>
                  <Grid
                    container
                    className={classes.paper}
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                  >

                    <Grid container justify="space-between">
                      {linkHrefReview && (
                        <a href={AddTrailingSlashToUrl(linkHrefReview)}
                          className={classes.buttonContainerHelper2}
                          onClick={() => { leaveReviewButtonClicked() }}
                        >
                          <Button className={classes.reviewButton}>
                            <Typography
                              variant="button"
                              className={classes.buttonTextMoreInfo}
                            >

                              Our Review

                      </Typography>
                          </Button>
                        </a>
                      )}
                      {linkHref && (
                        <a href={AddTrailingSlashToUrl(linkHref)}
                          className={classes.buttonContainerHelper2}
                          onClick={() => { visitSiteButtonClicked() }}
                        >

                          <Button
                            className={`${partner == true
                              ? classes.visitSiteButtonAffiliate
                              : classes.visitSiteButton
                              } ${linkHrefReview ? classes.buttonMarginTop : ''}`}
                          >
                            <Typography
                              variant="button"
                              className={classes.buttonTextVisitSite}
                            >

                              Visit Site

                      </Typography>
                          </Button>
                        </a>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Grid container className={classes.mobileProviderContainer}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <a href={AddTrailingSlashToUrl(linkHref)}
                    onClick={() => { logoClicked() }}
                  >
                    <img src={imageUrlFor(buildImageObj(companyLogo))
                      .width(100)
                      .height(100)
                      .fit("crop")
                      .auto("format")
                      .url()}
                      alt={companyName}
                      className={classes.mobileLogoImage}
                    />
                  </a>
                </Grid>
                <Grid item xs={6} >
                  <Grid container alignItems="center" justify="flex-end">
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8} className={classes.positionRelative}>
                      <Typography variant="body1" className={classes.ratingSectionTitle}>
                        Our Rating

            </Typography>
                      <div className={classes.mobileRatingContainer}>
                        <StyledRating
                          className={classes.weSayResizer}
                          name="half-rating-read"
                          defaultValue={materialUiScoreOur}
                          itemStyle={smallDistanceStyle}
                          precision={0.5}
                          readOnly
                          icon={
                            <img
                              src={StarIcon}
                              alt="StarIcon"
                              className={classes.scale}
                            />
                          }
                          emptyIcon={
                            <img
                              src={EmptyStarIcon}
                              alt="EmptyStarIcon"
                              className={classes.scale}
                            />
                          }
                        />
                      </div>
                      <Typography variant="body1" className={`${classes.ratingSectionTitle} ${classes.ratingSectionTitlePaddedTop}`}>
                        Your Rating
            </Typography>
                      <div className={classes.mobileRatingContainer}>

                        <StyledRating
                          className={classes.weSayResizer}
                          name="half-rating-read"
                          defaultValue={materialUiScoreYour}
                          itemStyle={smallDistanceStyle}
                          precision={0.5}
                          readOnly
                          icon={
                            <img
                              src={StarIcon}
                              alt="StarIcon"
                              className={classes.scale}
                            />
                          }
                          emptyIcon={
                            <img
                              src={EmptyStarIcon}
                              alt="EmptyStarIcon"
                              className={classes.scale}
                            />
                          } />
                      </div>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.mobileNutshellContainer}>
              <Typography variant="body1" className={classes.sectionTitle}>
                In a nutshell

              </Typography>

              {inANutshell.map((text) => {
                return (
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-start"
                    className={classes.inANutshellContainer}
                    key={text}
                  >
                    <img src={TickSquare} alt="TickSquare" className={classes.tickSquare} />
                    <Typography
                      variant="body1"
                      className={classes.inANutshellText}
                    >
                      {text}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12} className={classes.mobileTopButtonPadding}>
              {linkHref && (
                <a href={AddTrailingSlashToUrl(linkHref)}
                  className={classes.buttonContainerHelper2}
                  onClick={() => { visitSiteButtonClicked() }}
                >

                  <Button
                    className={`${partner == true
                      ? classes.visitSiteButtonAffiliate
                      : classes.visitSiteButton
                      } ${linkHrefReview ? classes.buttonMarginTop : ''}`}
                  >
                    <Typography
                      variant="button"
                      className={classes.buttonTextVisitSite}
                    >

                      Visit Site

                      </Typography>
                  </Button>
                </a>
              )}
            </Grid>
            <Grid item xs={12}>
              {linkHrefReview && (
                <a href={AddTrailingSlashToUrl(linkHrefReview)}
                  className={classes.buttonContainerHelper2}
                  onClick={() => { leaveReviewButtonClicked() }}
                >
                  <Button className={classes.reviewButton}>
                    <Typography
                      variant="button"
                      className={classes.buttonTextMoreInfo}
                    >

                      Our Review

                      </Typography>
                  </Button>
                </a>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
    //return (
    //  <>
    //    <div className={classes.holdingDiv}>
    //  {/*<a href={linkHref}>*/}
    //    <div className={classes.compDiv}>

    //      <img src={imageUrlFor(buildImageObj(companyLogo))
    //        .width(100)
    //        .height(100)
    //        .fit("crop")
    //        .auto("format")
    //        .url()}
    //        alt={companyName}
    //        className={classes.imgComp}
    //      />
    //      {console.log("inANutshell", inANutshell)}
    //    </div>

    //    {/*  </a>*/}
    //      <div className={classes.nutshellDiv}>
    //        In a nutshell
    //      <ul className={classes.nsUL}>
    //      {inANutshell && inANutshell.map((ns, index) =>
    //        <li key={index}>{ns}</li>
    //      )}
    //      </ul>
    //    </div>
    //    <div className={classes.ratingsDiv}>
    //      <div>Our rating {ourRating}</div>
    //      <div>Your rating {yourRating}</div>
    //    </div>
    //    <div className={classes.ratingsDiv}>
    //      <div>Btn</div>
    //      <div>Btn</div>
    //    </div>
    // </div>
    // </>
    //)
  } catch (e) {
    return null;
  }

}
