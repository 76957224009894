import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import PortableText from "../../portableText";
import { imageUrlFor } from "../../../lib/image-url";
import { buildImageObj } from "../../../lib/helpers";
import { AddTrailingSlashToUrl } from "../../../lib/helpers";

export default function CompanyOneColumn(props) {

  const { logoImage, linkHref, companyName } = props;

  const [googleAnalyticsObject, setGoogleAnalyticsObject] = useState({});

  const useStyles = makeStyles((theme) => ({
    logoImage: {
      borderRadius: '9.44px',
      maxWidth: '118px',
      maxHeight: '118px',
      [theme.breakpoints.down(450)]: {
        width: '88px',
        height: '88px'
      }
    }
  }));

  useEffect(() => {
    (async () => {
      if (JSON.stringify(googleAnalyticsObject) === '{}') {
        return;
      }

      if (googleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'comparison_criteria_hyperlink_clicked_value': JSON.stringify(googleAnalyticsObject) });

          window.dataLayer.push({ event: 'comparison_criteria_hyperlink_clicked' });
        }
      }
    })();
  }, [googleAnalyticsObject]);

  const classes = useStyles();

  var logoClicked = function () {
    setGoogleAnalyticsObject({
      Source: 'logo',
      CompanyName: props.companyName
    });
  }

  return (
    <>
      { logoImage && (
        <a href={AddTrailingSlashToUrl(props.linkHref)} onClick={() => { logoClicked() }}>
          <img
            src={imageUrlFor(buildImageObj(logoImage))
              .width(118)
              .height(118)
              .fit("crop")
              .auto("format")
              .url()}
            alt={'logo-image'}
            className={classes.logoImage}
          />
        </a>
      )}
    </>
  )
}

