import React from "react";

const Decrement = ({customClass, customEvent}) => {
  return (
  <svg onContextMenu={(e) => e.preventDefault()} onClick={customEvent} className={customClass.inputAdornmentDecrease} width="40" height="28" viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40 20C40 24.4183 36.4183 28 32 28L3.8147e-06 28L1.8914e-06 6.35916e-07L40 -3.8147e-06L40 20Z"
          fill="white"/>
    <path d="M25 11L20 16L15 11" stroke="#8C8CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default Decrement;
